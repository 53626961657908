import React from 'react'
import { Card } from '../../../components/ui'
import useAuth from '../../../hooks/useAuth'
import { OnboardingFlow } from '../../../graphql'
import OrganizationalOnboarding from './OrganizationalOnboarding'
import PersonalOnboarding from './PersonalOnboarding'

type ExperienceCardProps = {
  title: string
  description: string
  onClick: () => void
}

const ExperienceCard = ({ title, description, onClick }: ExperienceCardProps) => {
  return (
    <div className={'max-w-[230px]'}>
      <Card
        onClick={onClick}
        headerIcon={false}
        headerDivider={false}
        headerClass={'text-lg font-semibold'}
        footerClass={'text-blue-500 font-bold'}>
        <div className={'h-[150px] text-center'}>
          <h3 className={'font-semibold'}>{title}</h3>
          <div className={'text-md font-normal text-[#737373]'}>{description}</div>
        </div>
      </Card>
    </div>
  )
}

/*export type SetupExperienceStepProps = {
  onClick: (step: 'organization' | 'program') => void
}*/

const Onboarding = () => {
  const { setOnboardingFlow /*set: setAuth, token*/ } = useAuth()

  //const client = useApolloClient()
  //const navigate = useNavigate()

  const { user } = useAuth()

  const onboardingFlow = user?.onboardingFlow

  /*const { data } = useMeQuery({
    fetchPolicy: 'network-only',
    onError: async () => {
      navigate('/sign-up')
      await client.clearStore()
      await persistor.purge()
    },
  })

  useEffect(() => {
    if (token && data?.me && user) {
      setAuth({
        token,
        user: data.me,
      })
    }
  }, [data?.me, token, user, setAuth])*/

  return onboardingFlow === OnboardingFlow.Organizational ? (
    <OrganizationalOnboarding />
  ) : onboardingFlow === OnboardingFlow.Personal ? (
    <PersonalOnboarding />
  ) : (
    <div className={'flex flex-col h-full justify-center'}>
      <div className={'text-center'}>
        <h2>How are you planning to use TMS?</h2>
        <p className={'body-md'}>We'll tailor your setup accordingly.</p>
        <div className="h-[44px]" />
        <div className={'flex justify-center gap-6 flex-wrap'}>
          <ExperienceCard
            onClick={() => {
              setOnboardingFlow(OnboardingFlow.Personal)
            }}
            title={'For personal use'}
            description={'Use TMS for your own personal growth and transformation.'}
          />
          <ExperienceCard
            onClick={() => {
              setOnboardingFlow(OnboardingFlow.Organizational)
            }}
            title={'For organization'}
            description={'Use TMS to create and administer transformational programs.'}
          />
        </div>
      </div>
    </div>
  )
}

export default Onboarding
