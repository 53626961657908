import { createSlice, PayloadAction } from '@reduxjs/toolkit'
//import UseUploadParams from '../../hooks/useUpload'
import { v4 as uuidv4 } from 'uuid'

export type UploadItemState = {
  //uploadParams: UseUploadParams
  id: string
  entryId: string
  src: string
  blob: File
  fileName: string
  progress?: number
  remainingSeconds?: number
  status: 'waiting' | 'in_progress' | 'complete'
  totalSeconds?: number
  startTime?: number
  lastEtaOn?: number
  fileType: 'video' | 'image'
  thumbnail?: string
  clearSelfTimeout?: number
}

export const defaultClearSelfTimeout = 7000

export type UploadState = {
  list: {
    [index: string]: UploadItemState
  }
}

const initialState: UploadState = {
  list: {},
}

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    addItem: (state, { payload }: PayloadAction<Omit<UploadItemState, 'status' | 'id'>>) => {
      const id = uuidv4()
      state.list[id] = {
        clearSelfTimeout: defaultClearSelfTimeout,
        ...payload,
        id,
        status: 'waiting',
      }
    },
    updateProgress: (
      state,
      {
        payload,
      }: PayloadAction<{
        progress?: number
        remainingSeconds?: number
        totalSeconds?: number
        id: string
        startTime?: number
      }>,
    ) => {
      const item = state.list[payload.id]

      if (item) {
        const {
          progress = item.progress,
          remainingSeconds = item.remainingSeconds,
          totalSeconds = item.totalSeconds,
        } = payload
        item.progress = progress || item.progress
        item.remainingSeconds = remainingSeconds || item.remainingSeconds
        item.totalSeconds = totalSeconds || item.totalSeconds
        item.status = 'in_progress'
        item.startTime = payload.startTime || item.startTime
        item.lastEtaOn = Date.now()
      }
    },
    complete: (state, { payload }: PayloadAction<{ id: string; totalSeconds?: number }>) => {
      const item = state.list[payload.id]
      if (item) {
        const { totalSeconds = item.totalSeconds } = payload
        item.status = 'complete'
        item.remainingSeconds = 0
        item.progress = 1
        item.totalSeconds = totalSeconds || item.totalSeconds
      }
    },
    removeItem: (state, { payload }: PayloadAction<{ id: string }>) => {
      delete state.list[payload.id]
    },
  },
})

export function selectUploadItems(state: { upload: UploadState }) {
  return Object.values(state.upload.list)
}

export const { addItem, updateProgress, complete, removeItem } = uploadSlice.actions

export default uploadSlice.reducer
