import React from 'react'
import Tabs from '../../../../../components/ui/Tabs'
import Icon from '../../../../../components/ui/Icon'
import { useMatch, useNavigate } from 'react-router-dom'
import useRouteId from '../../../../../hooks/useRouteId'

const defaultTab = 'general'

const ProgramTabbar = () => {
  const navigate = useNavigate()

  const tabMatch = useMatch('/program/:id/:tab')

  const { routeId } = useRouteId({
    baseRoute: '/program',
  })

  /*const { tab, id } = useMemo(() => {
    const tab = match?.params.tab
    const tab = match?.params.tab
    let id = match?.params.id
    if (!id) {
      // Todo: handle when program id is not found in URL on the program detail view tabbar better
      //throw new Error('Program id is required')
      id = '1'
    }
    if (!tab) {
      return { tab: defaultTab, id }
    }
    return { tab, id }
  }, [tabMatch?.params.tab, match?.params.id])*/

  const tab = tabMatch?.params.tab || defaultTab

  return (
    <div className={'flex justify-center w-full pt-[15px]'}>
      <div className={'flex flex-col grow max-w-[540px] min-w-0'}>
        <Tabs
          value={tab}
          variant={'pill'}
          onChange={(tab) => {
            if (tab === defaultTab) {
              navigate(`/program/${routeId}`)
            } else {
              navigate(`/program/${routeId}/${tab}`)
            }
          }}>
          <Tabs.TabList>
            <Tabs.TabNav value={'general'} icon={<Icon name={'settings'} color={'gray-dark'} />}>
              General
            </Tabs.TabNav>
            <Tabs.TabNav value={'journeys'} icon={<Icon name={'compass'} color={'gray-dark'} />}>
              Journeys
            </Tabs.TabNav>
            <Tabs.TabNav value={'members'} icon={<Icon name={'users'} color={'gray-dark'} />}>
              Members
            </Tabs.TabNav>
          </Tabs.TabList>
        </Tabs>
      </div>
    </div>
  )
}

export default ProgramTabbar
