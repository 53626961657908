import React, { useCallback } from 'react'

type ColorMap = {
  [key: string]: string
}

type CircleProps = {
  percent: number
  strokeLinecap?: 'round' | 'square'
  strokeWidth?: number
  gapDegree?: number
  gapPosition?: 'top' | 'right' | 'bottom' | 'left'
  strokeColor?: string
  backgroundColor?: string
  customInfo?: string | React.ReactNode
  size?: 'sm' | 'md' | 'lg'
  showInfo?: boolean
  width?: number | string
  infoPosition?: 'center' | 'right'
}

const tailwindColors: ColorMap = {
  'blue-600': '#0891B2',
  'orange-600': '#EA580C',
  'fuchsia-600': '#C026D3',
  white: '#FFFFFF',
}

const Circle: React.FC<CircleProps> = ({
  percent,
  strokeLinecap = 'round',
  gapDegree = 0,
  gapPosition = 'top',
  strokeColor = 'blue-600',
  backgroundColor = '#EDEDED',
  customInfo,
  size = 'lg',
  strokeWidth = size === 'md' ? 3 : 5,
  infoPosition = 'center',
  showInfo = true,
}) => {
  const actualStrokeColor = tailwindColors[strokeColor] || strokeColor
  const actualBackgroundColor = tailwindColors[backgroundColor] || backgroundColor

  const sizeMap: Record<'sm' | 'md' | 'lg', number> = {
    sm: 17,
    md: 40,
    lg: 70,
  }

  const diameter = sizeMap[size]

  const getPathStyles = useCallback(() => {
    const radius = 50 - strokeWidth / 2
    let beginPositionX = 0
    let beginPositionY = -radius
    let endPositionX = 0
    let endPositionY = -2 * radius

    switch (gapPosition) {
      case 'left':
        beginPositionX = -radius
        beginPositionY = 0
        endPositionX = 2 * radius
        endPositionY = 0
        break
      case 'right':
        beginPositionX = radius
        beginPositionY = 0
        endPositionX = -2 * radius
        endPositionY = 0
        break
      case 'bottom':
        beginPositionY = radius
        endPositionY = 2 * radius
        break
      default:
    }

    const pathString = `M 50,50 m ${beginPositionX},${beginPositionY} a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY} a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`

    const len = Math.PI * 2 * radius
    const trailPathStyle = {
      strokeDasharray: `${len - gapDegree}px ${len}px`,
      strokeDashoffset: `-${gapDegree / 2}px`,
      stroke: actualBackgroundColor,
      strokeWidth: strokeWidth,
      mixBlendMode: 'multiply' as 'multiply',
    }

    const strokePathStyle = {
      strokeDasharray: `${(percent / 100) * (len - gapDegree)}px ${len}px`,
      strokeDashoffset: `-${gapDegree / 2}px`,
      stroke: actualStrokeColor,
      strokeLinecap: strokeLinecap,
      strokeWidth: percent === 0 ? 0 : strokeWidth,
    }

    return {
      pathString,
      trailPathStyle,
      strokePathStyle,
    }
  }, [gapDegree, gapPosition, percent, strokeWidth, actualBackgroundColor, actualStrokeColor, strokeLinecap])

  const { pathString, trailPathStyle, strokePathStyle } = getPathStyles()

  const renderInfo = () => {
    if (!showInfo) return null

    const baseClasses = 'font-medium text-gray-600'
    if (size === 'lg' && infoPosition === 'center') {
      return (
        <div
          className={`${baseClasses} absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
          style={{ fontSize: '14px' }}>
          {customInfo || `${percent}%`}
        </div>
      )
    } else {
      return (
        <div
          className={`${baseClasses} absolute top-1/2 left-full ml-2 transform -translate-y-1/2`}
          style={{ fontSize: '14px', minWidth: 120 }}>
          {customInfo || `${percent}%`}
        </div>
      )
    }
  }

  return (
    <div
      className={`relative ${infoPosition === 'right' ? 'flex items-center' : ''}`}
      style={{ width: diameter, height: diameter }}>
      <svg className={'w-full h-full'} viewBox={'0 0 100 100'}>
        <path d={pathString} style={trailPathStyle} fillOpacity={'0'} />
        <path d={pathString} style={strokePathStyle} fillOpacity={'0'} />
      </svg>
      {renderInfo()}
    </div>
  )
}

export default Circle
