import Icon from '../../../components/ui/Icon'
import React, { useMemo } from 'react'
import { JourneyPhaseFieldsFragment } from '../../../graphql'
import AvatarGroup from '../../../components/ui/Avatar/AvatarGroup'
import { Avatar } from '../../../components/ui'

type JourneyPhaseHeaderProps = {
  phase: JourneyPhaseFieldsFragment
  collapsed: boolean
  onToggleCollapse: () => void
}
const JourneyPhaseHeader: React.FC<JourneyPhaseHeaderProps> = ({
  phase,
  collapsed,
  onToggleCollapse: pOnToggleCollapse,
}) => {
  const onToggleCollapse = () => {
    pOnToggleCollapse()
  }

  const phaseProgress = useMemo(() => {
    const totalAssigned = phase.steps.reduce((acc, step) => acc + step.totalAssigned, 0)
    const totalCompleted = phase.steps.reduce((acc, step) => acc + step.totalCompleted, 0)

    return totalAssigned === 0 ? 0 : Math.round((totalCompleted / totalAssigned) * 100)
  }, [phase])

  return (
    <div className={'relative overflow-hidden cursor-pointer'} onClick={onToggleCollapse}>
      <div className={'absolute h-[5px] top-[0px] left-[0px] right-[0px] bg-gray-light overflow-hidden'}>
        <div
          className={`absolute h-[5px] left-0`}
          style={{ width: `${phaseProgress}%`, backgroundColor: phase?.color }}
        />
      </div>
      <div className={'flex p-[25px] pb-[15px] justify-between items-center'}>
        <div className={'flex items-center gap-[20px] justify-between flex-wrap w-full'}>
          <div className={'flex gap-[6px]'}>
            {phase?.iconName && <Icon size={24} color={phase?.color} name={phase.iconName as IconName} />}
            <div className={`text-md font-semibold`} style={{ color: phase?.color }}>
              {phase?.name + ' Phase'}
            </div>
          </div>
          <div className={'flex gap-[10px] items-center justify-between'}>
            <div className={'flex gap-[10px] items-center flex-wrap'}>
              <AvatarGroup chained omittedAvatarProps={{ size: 'sm' }}>
                {phase.participants.map((participant) => (
                  <Avatar
                    shape="circle"
                    size="sm"
                    src={participant.profileImage?.url}
                    name={participant.person.firstName + ' ' + participant.person.lastName}
                  />
                ))}
              </AvatarGroup>
              <div className={'text-gray-dark text-sm leading-[19.6px]'}>
                {phase.participants?.length || 0} {phase.participants?.length === 1 ? 'participant' : 'participants'}
              </div>
            </div>
          </div>
        </div>
        <div className={'ml-[10px] relative w-[20px] h-[20px]'}>
          <div
            className={`absolute cursor-pointer ${collapsed ? 'opacity-100' : 'opacity-0'} transition-opacity transition-duration-150`}>
            <Icon size={20} color={'#333132'} name={'chevron-down'} />
          </div>
          <div
            className={`absolute cursor-pointer ${collapsed ? 'opacity-0' : 'opacity-100'} transition-opacity duration-150`}>
            <Icon size={20} color={'#333132'} name={'chevron-up'} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default JourneyPhaseHeader
