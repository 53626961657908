import { ModalConfig } from './index'
import Compose from '../../views/step/views/Compose'
import NewStep from '../../views/step/views/NewStep'

const leaderModals: ModalConfig[] = [
  {
    key: 'compose',
    component: <Compose />,
  },
  {
    key: 'new-step',
    component: <NewStep />,
  },
]

export default leaderModals
