import { z } from 'zod'

const client = z.object({
  API_BASE_URL: z.string().url(),
  API_GRAPHQL_PATH: z.string(),
  ISSUE_ID: z.string().optional(),
  MOCK: z.boolean().optional().default(false),
  ASSET_BASE_URL: z.string().url(),
})

const _clientEnv = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL as string,
  API_GRAPHQL_PATH: process.env.REACT_APP_API_GRAPHQL_PATH as string,
  ISSUE_ID: process.env.REACT_APP_ISSUE_ID as string,
  MOCK: process.env.REACT_APP_MOCK === 'true',
  ASSET_BASE_URL: process.env.REACT_APP_ASSET_BASE_URL as string,
}

const parsedClient = client.safeParse(_clientEnv)

if (!parsedClient.success) {
  console.error(
    'Invalid client environment variables:',
    parsedClient.error.flatten().fieldErrors,
    `\nMissing or invalid variables in env file.`,
  )
  throw new Error('Invalid environment variables. Check the console for more information.')
}

const env = parsedClient.data

export { env }
