import React, { FC } from 'react'
import { Alert, Button, Dialogue, Form, Input, Icon, Text } from '../../../components/ui'
import { Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import * as Yup from 'yup'
import useConnections from '../../../hooks/useConnections'

type AddConnectionProps = {
  open?: boolean
  onClose?: () => void
}

const AddConnection: FC<AddConnectionProps> = ({ open = false, onClose }) => {
  const { createConnection, creating } = useConnections()
  const errorMessage = null

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
  })

  return (
    <Dialogue isOpen={open} className={'bg-gray-900 bg-opacity-30'} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          firstName: '',
          middleName: '',
          lastName: '',
          notes: '',
        }}
        onSubmit={async (data, { setSubmitting }) => {
          await createConnection(data)
          onClose?.()
        }}>
        {({ isSubmitting, errors, touched, getFieldProps, values }) => (
          <div id="add-connection-modal" className="w-[339px] self-center justify-self-center">
            <Form.Container className={'!p-0'}>
              <Text variant={'h2'} className={'leading-[22px]'}>
                Add a New Connection
              </Text>
              <Form.Item
                label={'First Name'}
                errorMessage={touched.firstName && errors.firstName ? errors.firstName : undefined}
                invalid={touched.firstName && !!errors.firstName}>
                <Input
                  size={'sm'}
                  type={'firstName'}
                  className={'bg-gray-50'}
                  placeholder={''}
                  invalid={touched.firstName && !!errors.firstName}
                  {...getFieldProps('firstName')}
                />
              </Form.Item>
              <Form.Item label={'Middle Name'}>
                <Input
                  size={'sm'}
                  type={'middleName'}
                  className={'bg-gray-50'}
                  placeholder={''}
                  {...getFieldProps('middleName')}
                />
              </Form.Item>
              <Form.Item
                label={'Last Name'}
                errorMessage={touched.lastName && errors.lastName ? errors.lastName : undefined}
                invalid={touched.lastName && !!errors.lastName}>
                <Input
                  size={'sm'}
                  type={'lastName'}
                  placeholder={''}
                  className={'bg-gray-50'}
                  invalid={touched.lastName && !!errors.lastName}
                  {...getFieldProps('lastName')}
                />
              </Form.Item>
              <Form.Item label={'Notes'} size={'sm'} className={'relative'}>
                <Text variant={'p'} size={'sm'} className={'text-gray-dark absolute top-[3px] right-0'}>
                  Optional
                </Text>
                <Input
                  size={'sm'}
                  onChange={(e) => {
                    values.notes = e.target.value
                  }}
                  textArea={true}
                  className={'bg-gray-50'}
                  placeholder={''}
                />
              </Form.Item>
              <AnimatePresence>{errorMessage && <Alert type={'danger'} label={errorMessage} />}</AnimatePresence>
              <div className={`flex flex-col justify-center gap-5`}>
                <Button
                  disabled={creating || !values.firstName.trim()}
                  loading={creating}
                  type={'submit'}
                  size={'md'}
                  icon={<Icon name={'plus'} size={20} color={'white'} />}
                  label={'Add Connection'}
                  block
                  variant={'solid'}
                />
                <Button
                  type={'button'}
                  size={'md'}
                  label={'Cancel'}
                  loading={false}
                  block
                  variant={'default'}
                  onClick={() => onClose?.()}
                />
              </div>
            </Form.Container>
          </div>
        )}
      </Formik>
    </Dialogue>
  )
}

export default AddConnection
