import React, { FC, useState } from 'react'
import {
  ProcessType,
  ProgramDetailDocument,
  ProgramDetailFieldsFragment,
  useUpdateProgramMutation,
} from '../../../../../graphql'
import { Field, Formik } from 'formik'
import { Button, Form, Icon, Input, Divider, Dialogue, Tooltip, Alert } from '../../../../../components/ui'
import Select from '../../../../../components/ui/Select'
import { MultiValue, SingleValue } from 'react-select'
import useToast from '../../../../../hooks/useToast'
import * as Yup from 'yup'
import ImageUpload from '../../../../../components/shared/ImageUpload'
import { generateHash } from '../../../../../utils/crypto'
import useOrganization from '../../../../../hooks/useOrganization'

type ProgramGeneralProps = {
  program?: ProgramDetailFieldsFragment
}

const processTypeOptions = Object.values(ProcessType).map((type) => {
  return { label: type, value: type }
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

type ProgramFormFields = {
  name: string
  image?: { name: string; blob: File; src: string; key: string }
}

const ProgramGeneral: FC<ProgramGeneralProps> = ({ program }) => {
  const organization = useOrganization()
  const toast = useToast()
  const [process, setProcess] = useState<
    | MultiValue<{ value: ProcessType | undefined; label: ProcessType | undefined }>
    | SingleValue<{ value: ProcessType | undefined; label: ProcessType | undefined }>
  >({
    label: program?.process?.template?.type,
    value: program?.process?.template?.type,
  })
  const [deleteProgramModal, setDeleteProgramModal] = useState<{ open: boolean }>({
    open: false,
  })

  const [updateProgram, { data }] = useUpdateProgramMutation({
    refetchQueries: [ProgramDetailDocument],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.updateProgram.__typename === 'UpdateProgramSuccess') {
        toast.push(data.updateProgram.message, data.updateProgram.program.name, 'success')
      }
    },
    onError: (error) => {
      toast.push('Error', error.message, 'error')
    },
  })

  const showAlert = data?.updateProgram.__typename === 'UpdateProgramError'
  const alertMessage = data?.updateProgram.message

  return program ? (
    <div className={'flex justify-center'}>
      <div className={'flex flex-col gap-[25px] grow max-w-[540px]'}>
        <Formik
          initialValues={
            {
              name: program.name,
              image: program.image?.url ? { name: '', blob: null, src: program.image.url, key: '' } : undefined,
            } as ProgramFormFields
          }
          validationSchema={validationSchema}
          onSubmit={async ({ name, image }) => {
            await updateProgram({
              variables: {
                where: {
                  id: program.id,
                },
                data: {
                  name,
                  image:
                    image?.blob && organization
                      ? {
                          create: {
                            hash: await generateHash(image.blob),
                            ext: image.blob.type.split('/')[1],
                            mime: image.blob.type,
                            size: image.blob.size,
                            name: image.name,
                            url: image.src,
                            folder: organization.id,
                            folderPath: image.key.replace(image.name, ''),
                          },
                        }
                      : undefined,
                },
              },
            })
          }}>
          {({ isSubmitting, errors, values, isValid }) => (
            <Form>
              <div className={'flex flex-col gap-[25px]'}>
                <span className={'h4'}>Program details</span>
                <div className={'flex gap-[20px] items-center'}>
                  <Field name={'image'} component={ImageUpload} />
                  <Form.Item
                    label={'Name'}
                    errorMessage={errors.name ? errors.name : undefined}
                    invalid={!!errors.name}>
                    <Field size={'sm'} name={'name'} component={Input} />
                  </Form.Item>
                </div>
                <div className={'flex justify-between items-center flex-1'}>
                  <div className={'flex flex-col gap-[7px] w-3/4'}>
                    <span className={'h4'}>Process</span>
                    <span className={'text-sm'}>Current process type setting</span>
                  </div>
                  <div className={'w-1/4'}>
                    <Tooltip
                      delay={1000}
                      title={
                        "This feature isn't available yet. Please contact LifeStone to make changes to your process"
                      }>
                      <Select
                        isDisabled
                        value={process}
                        options={processTypeOptions}
                        isMulti={false}
                        onChange={(option) => {
                          setProcess(option)
                        }}
                        size="xs"
                        isSearchable={false}
                      />
                    </Tooltip>
                  </div>
                </div>
                <Alert show={showAlert} type={'danger'} label={alertMessage} />
                <Form.Actions layout={'vertical'}>
                  <Button
                    disabled={
                      (values.name.trim() === program.name && values.image?.src === program.image?.url) || !isValid
                    }
                    type={'submit'}
                    variant={'solid'}
                    size={'sm'}
                    loading={isSubmitting}>
                    Update
                  </Button>
                </Form.Actions>
              </div>
            </Form>
          )}
        </Formik>
        <Divider />
        <div className={'flex flex-col gap-[25px]'}>
          <div className={'flex flex-col gap-[15px]'}>
            <span className={'h4'}>Delete program</span>
            <span className={'text-sm'}>
              If you want to permanently delete this program and all of its data, including but not limited to users,
              programs, and steps, you can do so below.
            </span>
          </div>
          <Button
            icon={<Icon color={'red'} name={'trash-2'} />}
            className={'border-red'}
            color={'red'}
            size={'sm'}
            onClick={() => {
              setDeleteProgramModal({
                open: true,
              })
            }}>
            Delete this program
          </Button>
        </div>
      </div>
      <Dialogue
        isOpen={deleteProgramModal.open}
        width="500px"
        height="185px"
        onRequestClose={() => {
          setDeleteProgramModal({
            open: false,
          })
        }}>
        <Dialogue.Header className={'flex justify-between !items-start'}>
          <span
            className={'min-w-0'}>{`Are you sure you want to permanently delete the program ${program?.name}?`}</span>
          <div
            onClick={() => {
              setDeleteProgramModal({
                open: false,
              })
            }}
            className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
            <Icon name="x" size={'20'} color={'#333132'} />
          </div>
        </Dialogue.Header>
        <Dialogue.Body>This action cannot be undone.</Dialogue.Body>
        <Dialogue.Footer className={'flex justify-end space-x-2'}>
          <Button
            shape={'rounded'}
            size={'sm'}
            variant={'plain'}
            isWide={true}
            onClick={() => {
              setDeleteProgramModal({
                open: false,
              })
            }}>
            Cancel
          </Button>
          <Button
            shape={'rounded'}
            size={'sm'}
            variant={'solid'}
            color={'red-400'}
            isWide={true}
            onClick={() => {
              setDeleteProgramModal({
                open: false,
              })
            }}>
            Delete program
          </Button>
        </Dialogue.Footer>
      </Dialogue>
    </div>
  ) : null
}

export default ProgramGeneral
