import React from 'react'

interface RadioGroupContextProps {
  vertical: boolean
  value: any
  onChange?: (value: any, event: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioGroupContext = React.createContext<RadioGroupContextProps>({
  vertical: false,
  value: null,
  onChange: () => {},
})

export default RadioGroupContext
