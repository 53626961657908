import React, { FC, useEffect } from 'react'
import UserDropdown from '../../../components/layout/components/Header/HeaderItem/UserDropdown'
import { useApolloClient } from '@apollo/client'
//import { LoginFieldsFragmentDoc, Role } from '../../../graphql'

const UserDropdownExample: FC = () => {
  const client = useApolloClient()

  useEffect(() => {
    /*client.cache.writeFragment({
      data: {
        __typename: 'LoginSuccess',
        user: {
          avatar: {
            src: 'https://randomuser.me/api/portraits/men/85.jpg',
          },
          firstName: 'Test',
          lastName: 'User',
          name: 'Test User',
          email: 'testuser@email.com',
          viewingAs: Role.Admin,
          type: 'apprentice',
          __typename: 'User',
        }
      },
      id: 'Login:{}',
      fragment: LoginFieldsFragmentDoc,
    })*/
  }, [client])

  return (
    <div className="flex justify-center flex-col gap-3 items-center mt-24">
      <UserDropdown />
    </div>
  )
}

export default UserDropdownExample
