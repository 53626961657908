import React from 'react'
import { Switcher } from '../../../components/ui'

const SwitcherExample: React.FC = () => {
  const handleSwitchChange = (checked: boolean) => {
    console.log(`Switch is now ${checked ? 'ON' : 'OFF'}`)
  }

  return (
    <div className={'p-4 space-y-4 bg-gray-100'}>
      <div className={'bg-white p-4 shadow-md rounded-md'}>
        <label className={'text-lg font-medium mr-5'}>Default Switcher</label>
        <Switcher onChange={handleSwitchChange} />
      </div>
      <div className={'bg-white p-4 shadow-md rounded-md'}>
        <label className={'text-lg font-medium mr-5'}>Switcher with Custom Color (Green)</label>
        <Switcher color={'bg-green-400'} onChange={handleSwitchChange} />
      </div>
      <div className={'bg-white p-4 shadow-md rounded-md'}>
        <label className={'text-lg font-medium mr-5'}>Disabled Switcher</label>
        <Switcher disabled />
      </div>
      <div className={'bg-white p-4 shadow-md rounded-md'}>
        <label className={'text-lg font-medium mr-5'}>Disabled Switcher On</label>
        <Switcher checked disabled />
      </div>
      <div className={'bg-white p-4 shadow-md rounded-md'}>
        <label className={'text-lg font-medium mr-5'}>Switcher with Loading State</label>
        <Switcher isLoading onChange={handleSwitchChange} />
      </div>
      <div className={'bg-white p-4 shadow-md rounded-md'}>
        <label className={'text-lg font-medium mr-5'}>Read Only Switcher</label>
        <Switcher readOnly onChange={handleSwitchChange} />
      </div>
      <div className={'bg-white p-4 shadow-md rounded-md'}>
        <label className={'text-lg font-medium mr-5'}>Switcher with Initial State ON</label>
        <Switcher defaultChecked onChange={handleSwitchChange} />
      </div>
    </div>
  )
}

export default SwitcherExample
