import {
  ConfirmationFieldsFragment,
  ConfirmationPurpose,
  OrganizationDocument,
  ProgramDropdownDocument,
  useOrganizationLazyQuery,
  useProgramDropdownLazyQuery,
  useReceiveConfirmationMutation,
  useSendConfirmationMutation,
} from '../graphql'
import { useMemo } from 'react'
import { handleAuthRequest } from './useSignIn'
import useParams from './useParams'
import { useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

export type ReceiveConfirmationParams = {
  code: string
  confirmation?: ConfirmationFieldsFragment
  persist?: boolean
}

function useConfirmation() {
  const params = useParams()
  const navigate = useNavigate()

  const [send, { loading: sendLoading, data: sendData }] = useSendConfirmationMutation()

  const [receive, { data: receiveData, loading: receiveLoading }] = useReceiveConfirmationMutation({
    /*onCompleted: ({ receiveConfirmation }, options) => {
      if (receiveConfirmation.__typename === 'ReceiveConfirmationSuccess') {
        navigate('/onboarding')
      }
    },*/
    update: (cache, { data }) => {
      if (data?.receiveConfirmation.__typename === 'ReceiveConfirmationSuccess') {
        //setAuth(data.receiveConfirmation.auth, cache)
      }
    },
  })

  const client = useApolloClient()

  const [programs] = useProgramDropdownLazyQuery({
    onCompleted: (data) => {
      if (data?.programs) {
        // Super-duper hacky way to update query cache after a lazy query
        setTimeout(() => {
          client.writeQuery({
            query: ProgramDropdownDocument,
            data: {
              programs: {
                list: [...data.programs.list],
                __typename: 'Programs',
              },
            },
          })
        }, 0)
      }
    },
  })
  const [organization] = useOrganizationLazyQuery({
    onCompleted: (data) => {
      if (data?.tenant) {
        // More super-duper hacky way to update query cache after a lazy query
        setTimeout(() => {
          client.writeQuery({
            query: OrganizationDocument,
            data: {
              tenant: {
                ...data.tenant,
                __typename: 'Tenant',
              },
            },
          })
        }, 0)
      }
    },
  })

  const data = useMemo(() => {
    if (sendData?.sendConfirmation.__typename === 'SendConfirmationSuccess') {
      return sendData.sendConfirmation.confirmation
    }
  }, [sendData])

  return {
    data,
    loading: sendLoading || receiveLoading,
    sendResponse: sendData?.sendConfirmation,
    receiveResponse: receiveData?.receiveConfirmation,
    send,
    receive: ({ code, confirmation = data, persist }: ReceiveConfirmationParams) => {
      /*if (purpose === ConfirmationPurpose.SignIn) {
        const token = btoa(`${id}:${code}`)
        return signIn({ token, staySignedIn })
      }*/
      if (confirmation) {
        return handleAuthRequest(
          receive({
            variables: {
              data: {
                clientId: confirmation.credential.id,
                code,
              },
            },
          }),
          {
            persist: confirmation.purpose === ConfirmationPurpose.SignIn ? persist : false,
            params,
            navigate,
            queryPrograms: async (token: string) =>
              await programs({
                context: {
                  headers: {
                    authorization: `Bearer ${token}`,
                  },
                },
                fetchPolicy: 'cache-and-network',
              }),
            queryOrganization: async (token: string, id: string) =>
              await organization({
                context: {
                  headers: {
                    authorization: `Bearer ${token}`,
                  },
                },
                variables: {
                  id,
                },
              }),
          },
        )
      }
    },
  }
}

export default useConfirmation
