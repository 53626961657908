import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

type SelectedJourney = {
  id: string
  name?: string
} | null

export interface JourneysState {
  selected: SelectedJourney
}

export const initialState: JourneysState = {
  selected: {
    name: '',
    id: 'all',
  },
}

export const journeysSlice = createSlice({
  name: 'journey/journeys',
  initialState,
  reducers: {
    setSelectedJourney: (state, action: PayloadAction<SelectedJourney>) => {
      state.selected = action.payload
    },
  },
})

export const selectJourneys = createSelector(
  (state: { journey: { journeys: JourneysState } }) => state.journey.journeys,
  (journeys) => journeys,
)

export const { setSelectedJourney } = journeysSlice.actions

export default journeysSlice.reducer
