import { useUploadCredentialsLazyQuery } from '../graphql'
import { uploadFile, UploadFileCompleteEvent, UploadFileParts, UploadFileProgress } from '../utils/upload'
import useOrganization from './useOrganization'
import { useState } from 'react'
//import useToast from './useToast'

type StartUploadParams = {
  src?: string
  blob: File
  name: string
}

type UseUploadParams = {
  onComplete?: (event: UploadFileCompleteEvent) => void
  onProgress?: (progress: UploadFileProgress, parts: UploadFileParts) => void
}

const useUpload = ({ onComplete, onProgress }: UseUploadParams = {}) => {
  const [uploadCredentials] = useUploadCredentialsLazyQuery({
    fetchPolicy: 'network-only',
  })
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState<UploadFileProgress | null>(null)

  const organization = useOrganization()

  //const toast = useToast()

  const handleOnProgress = (progress: UploadFileProgress, parts: UploadFileParts) => {
    onProgress?.(progress, parts)
    setProgress(progress)
  }

  const handleOnComplete = (event: UploadFileCompleteEvent) => {
    onComplete?.(event)
    setProgress(null)
    setUploading(false)
  }

  const onGetCredentials = async (tenantId: string) => {
    try {
      const { data } = await uploadCredentials({
        variables: {
          tenantId,
        },
      })
      if (data?.uploadCredentials) {
        return {
          key: data.uploadCredentials.key,
          resource: data.uploadCredentials.resource,
          resourceName: data.uploadCredentials.resourceName,
          accessKeyId: data.uploadCredentials.accessKeyId,
          secretAccessKey: data.uploadCredentials.secretAccessKey,
          sessionToken: data.uploadCredentials.sessionToken,
        }
      }
    } catch (e) {
      console.log(e)
    }
    throw new Error('Failed to get AWS credentials')
  }

  const start = async ({ src, blob, name }: StartUploadParams) => {
    if (!organization?.id) {
      throw new Error('Failed to upload file: No organization selected')
    }

    setUploading(true)
    uploadFile({
      getCredentials: () => onGetCredentials(organization.id),
      src,
      blob,
      fileName: name,
      onComplete: handleOnComplete,
      onProgress: handleOnProgress,
    }).catch(() => {
      console.error('Failed to upload file')
      //Todo do an error toast if upload failed
    })
  }

  return {
    start,
    uploading,
    progress,
  }
}

export default useUpload
