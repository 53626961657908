import React, { FC } from 'react'
import ViewHeader from '../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../components/shared/BreadcrumbBar'
import { Text } from '../../../components/ui'

const AccountSettingsHeader: FC = () => {
  return (
    <div className={'flex flex-col gap-[25px]'}>
      <ViewHeader
        start={[
          <BreadcrumbBar
            routeTitleMap={{
              account: 'Account Settings',
            }}
            routePathMap={{
              account: '/account',
            }}
          />,
        ]}
      />
      <div className={'flex justify-center pl-[16px] pr-[16px] sm:pl-[20px] sm:pr-[20px] md:pl-[25px] md:pr-[25px]'}>
        <div className={'flex flex-col grow max-w-[540px] gap-[10px]'}>
          <div className={'flex gap-[10px]'}>
            <Text className={'flex-1'} variant={'h2'}>
              Account Settings
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSettingsHeader
