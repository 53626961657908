import useMyJourney from '../../../hooks/useMyJourney'
import ProgressLoader from '../../../components/ui/ProgressLoader'
import Icon from '../../../components/ui/Icon'
import Table from '../../../components/ui/Table/Table'
import React from 'react'

const MyJourneyTableHeader = () => {
  const { myJourney } = useMyJourney()

  const phase = myJourney?.currentPhase?.phase
  const stepsCompleted = myJourney?.currentPhase?.stepsCompleted || 0
  const totalSteps = myJourney?.currentPhase?.totalSteps || 0

  const progressPercentage = totalSteps ? Math.round((stepsCompleted / totalSteps) * 100) : 0

  return (
    <Table.THead>
      <div className={'flex flex-col gap-[25px] p-[25px]'}>
        <div className={'flex justify-between'}>
          <div className="flex gap-[20px]  items-center">
            <ProgressLoader
              variant="circle"
              percent={progressPercentage}
              color={phase?.color}
              size={'lg'}
              showInfo={false}
              icon={<Icon size={24} color={phase?.color} name="users" />}
            />
            <div style={{ color: phase?.color }}>
              <div className="text-md font-semibold">{phase?.name + ' Phase'}</div>
            </div>
          </div>
          <div className={'text-gray-dark'}>{`${stepsCompleted} / ${totalSteps} steps completed`}</div>
        </div>
        <div>{phase?.description}</div>
      </div>
    </Table.THead>
  )
}

export default MyJourneyTableHeader
