import React, { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

type TextProps = {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
  spacing?: boolean
  className?: string
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | '8xl' | '9xl' | 'base'
}

const Text: FC<PropsWithChildren<TextProps>> = ({
  variant: Tag = 'p',
  className: _className,
  spacing = false,
  size,
  ...rest
}) => {
  const className = classNames(size && `text-${size}`, !spacing && 'm-0', _className)

  return <Tag className={className} {...rest}></Tag>
}

export default Text
