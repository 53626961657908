import React, { FC, ReactNode, PropsWithChildren } from 'react'
import classNames from 'classnames'
import Tooltip, { TooltipProps } from '../Tooltip'

type MiniBadgeProps = {
  prefix?: ReactNode
  className?: string
  tooltip?: TooltipProps
}

const MiniBadge: FC<PropsWithChildren<MiniBadgeProps>> = ({ prefix, children, className, tooltip }) => {
  const Badge = (
    <div
      className={classNames(
        `cursor-default flex gap-[5px] pl-[8px] pr-[8px] pt-[3px] pb-[3px] h5 leading-[1px] text-gray-dark items-center justify-center rounded-[7px] bg-gray-200`,
        className,
      )}>
      {prefix}
      {children}
    </div>
  )
  return tooltip ? <Tooltip {...tooltip}>{Badge}</Tooltip> : Badge
}

export default MiniBadge
