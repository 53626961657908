import React from 'react'
import { Calendar, RangeCalendar } from '../../../components/ui'

const CalendarExample = () => {
  const [calendarMonth, setCalendarMonth] = React.useState<Date | undefined>(new Date())
  const [rangedCalendarMonth, setRangedCalendarMonth] = React.useState<Date | undefined>(new Date())
  const [value, setValue] = React.useState<Date | Date[] | undefined>(new Date())
  const [rangedValue, setRangedValue] = React.useState<[Date, Date]>([new Date(), new Date()])

  const handleValueChange = (date: Date | Date[]) => {
    setValue(date)
  }

  const handleRangedValueChange = (dates: [Date | null, Date | null]) => {
    setRangedValue(dates as [Date, Date])
  }

  return (
    <div className={'p-[50px] w-[400px]'}>
      <div className={'mt-[20px] text-brand-600 font-bold text-xl'}>Calendar</div>
      <Calendar
        locale={'en'}
        month={calendarMonth as Date}
        defaultMonth={new Date()}
        onMonthChange={setCalendarMonth}
        value={value as Date}
        onChange={handleValueChange}
        labelFormat={{
          month: 'MMM',
          year: 'YYYY',
          weekday: 'dd',
        }}
        dayClassName={(date: Date) => {
          return date.getDate().toString()
        }}
        dayStyle={() => {
          return {}
        }}
        disableOutOfMonth={false}
        minDate={undefined}
        maxDate={undefined}
        disableDate={() => {
          return false
        }}
        firstDayOfWeek={'sunday'}
        preventFocus={false}
        dateViewCount={1}
        enableHeaderLabel={true}
        defaultView={'date'}
        hideOutOfMonthDates={false}
        hideWeekdays={false}
        renderDay={(date: Date) => {
          return <div>{date.getDate()}</div>
        }}
        weekendDays={[0, 6]}
      />
      <div className={'mt-[20px] text-brand-600 font-bold text-xl'}>Range Calendar</div>
      <RangeCalendar
        locale={'en'}
        month={rangedCalendarMonth as Date}
        defaultMonth={new Date()}
        onMonthChange={setRangedCalendarMonth}
        value={rangedValue}
        onChange={handleRangedValueChange}
        labelFormat={{
          month: 'MMM',
          year: 'YYYY',
          weekday: 'dd',
        }}
        dayClassName={(date: Date) => {
          return date.getDate().toString()
        }}
        dayStyle={() => {
          return {}
        }}
        disableOutOfMonth={false}
        minDate={undefined}
        maxDate={undefined}
        disableDate={() => {
          return false
        }}
        firstDayOfWeek={'sunday'}
        preventFocus={false}
        dateViewCount={1}
        enableHeaderLabel={true}
        defaultView={'date'}
        hideOutOfMonthDates={false}
        hideWeekdays={false}
        renderDay={(date: Date) => {
          return <>{date.getDate()}</>
        }}
        weekendDays={[0, 6]}
      />
    </div>
  )
}

export default CalendarExample
