import { FC } from 'react'
import { SlidesQuery } from '../../../../graphql'
import appConfig from '../../../../config/app'

type SideCarouselSlideProps = SlidesQuery['slides']['list'][number]

const SideCarouselSlide: FC<SideCarouselSlideProps> = ({ image, title, subtitle, text }) => {
  return (
    <div className="relative h-screen w-full flex-shrink-0 overflow-hidden">
      <div
        className="absolute inset-0 z-0"
        style={{
          // Todo: remove issue path hardcode from here
          backgroundImage: `url(${appConfig.issuePath}${image?.url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}></div>
      <div
        className="absolute inset-0 z-10"
        style={{
          backgroundImage: `linear-gradient(to bottom, #A9A9A9, #1F2937)`,
          mixBlendMode: 'multiply',
          opacity: 0.9,
        }}></div>
      <div className="absolute left-16 bottom-[26px] z-20">
        <blockquote
          className="mb-10 overflow-hidden text-white font-serif"
          style={{
            fontFamily: '"DM Serif Text", serif',
            fontSize: '46px',
            lineHeight: '1.2',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 6,
            maxHeight: '407px',
            maxWidth: '80%',
          }}>
          {text}
        </blockquote>
        <div className={'mr-[170px]'}>
          <h2 className="text-white font-bold">{title}</h2>
          <h6 className="text-white">{subtitle}</h6>
        </div>
      </div>
    </div>
  )
}

export default SideCarouselSlide
