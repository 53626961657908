import { useEditor } from '@tiptap/react'
import type { AnyExtension } from '@tiptap/core'
import classNames from 'classnames'

import { ExtensionKit } from '../components/shared/TipTap/extensions/extension-kit'

export const useBlockEditor = ({
  editable = true,
  className,
  onChange,
  content,
}: {
  editable?: boolean
  className?: string
  onChange?: (content: string) => void
  content?: string
} = {}) => {
  const editor = useEditor({
    content,
    onUpdate: ({ editor }) => {
      console.log('Change to content')
      const html = editor.getHTML()
      console.log(html)
      onChange?.(html)
    },
    immediatelyRender: true,
    shouldRerenderOnTransaction: false,
    autofocus: true,
    editable,
    extensions: [...ExtensionKit()].filter((e): e is AnyExtension => e !== undefined),
    editorProps: {
      attributes: {
        autocomplete: 'off',
        autocorrect: 'off',
        autocapitalize: 'off',
        class: classNames('caret-black dark:caret-white outline-0 pb-[10px] z-0 self-start', className),
      },
    },
  })

  return { editor }
}
