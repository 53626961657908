import React, { FC, useMemo } from 'react'
import { ContentStepType, JourneyPhaseFieldsFragment, JourneyStep } from '../../../graphql'
import Table from '../../../components/ui/Table/Table'
import Icon from '../../../components/ui/Icon'
import StepIcon from '../../../components/shared/StepIcon'
import StatusTag from '../../../components/shared/StatusTag'
import { Avatar } from '../../../components/ui'
import AvatarGroup from '../../../components/ui/Avatar/AvatarGroup'
import ProgressLoader from '../../../components/ui/ProgressLoader'
import Tooltip from '../../../components/ui/Tooltip'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import formatRouteId from '../../../utils/formatRouteId'

type JourneyPhaseTableRowProps = {
  step: JourneyStep
  phase: JourneyPhaseFieldsFragment
  key: string
  iconColor?: string
}

const getContentTypeTitlePrefix = (contentType: ContentStepType) => {
  switch (contentType) {
    case ContentStepType.Video:
      return 'Watch'
    case ContentStepType.File:
      return 'Download'
    default:
      return 'Read'
  }
}

const JourneyPhaseTableRow: FC<JourneyPhaseTableRowProps> = ({ step, phase, key, iconColor }) => {
  const isSingle = step.assignees.length === 1

  const assignedToEveryone = step.assignees?.length >= phase.participants?.length

  const title = (step.contentType ? `${getContentTypeTitlePrefix(step.contentType)}: ` : '') + step.name

  const routeId = useMemo(() => {
    const { routeId } = formatRouteId(step?.name, step?.id)
    return routeId
  }, [step?.name, step?.id])

  const progress = step.totalAssigned === 0 ? 0 : Math.round((step.totalCompleted / step.totalAssigned) * 100)

  return (
    <Table.Tr className={'cursor-pointer'} key={key}>
      <Table.Td colSpan={4} className={'max-w-none !p-0'}>
        <Link to={`/step/${routeId}`} className={'flex gap-[20px] min-h-[40px] items-center px-[24px] py-[16px]'}>
          <div>
            <StepIcon color={iconColor} type={step.type} contentType={step.contentType} />
          </div>
          <div className={'relative min-w-[200px] flex-1 flex gap-[20px] items-center'}>
            <div className={'absolute w-full flex items-center gap-[20px]'}>
              <Tooltip title={title} delay={1250}>
                <div
                  style={{
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  className={'font-semibold'}>
                  {title}
                </div>
              </Tooltip>
              {!assignedToEveryone ? (
                <Tooltip
                  delay={300}
                  title={
                    isSingle ? `Assigned to ${step.assignees[0].person.fullName}` : `${step.assignees.length} assignees`
                  }>
                  <AvatarGroup chained omittedAvatarProps={{ size: 'sm' }}>
                    {step.assignees?.map((participant, index) => (
                      <Avatar
                        key={index}
                        shape="circle"
                        size="sm"
                        src={participant.profileImage?.url}
                        name={participant.person.firstName + ' ' + participant.person.lastName}
                      />
                    ))}
                  </AvatarGroup>
                </Tooltip>
              ) : (
                <div className={'w-[20px] h-[20px]'}>
                  <Tooltip title={'Assigned to everyone'} delay={300}>
                    <div className={'bg-gray-200 w-[20px] h-[20px] flex justify-center items-center rounded-[7px]'}>
                      <Icon name={'people-filled'} size={12} color={'#737373'} />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          {!isSingle && (
            <Tooltip
              delay={300}
              title={`${step.activeParticipants.length} active ${pluralize('assignee', step.activeParticipants.length)}`}>
              <AvatarGroup chained omittedAvatarProps={{ size: 'sm' }}>
                {step.activeParticipants?.map((participant, index) => (
                  <Avatar
                    key={index}
                    shape="circle"
                    size="sm"
                    src={participant.profileImage?.url}
                    name={participant.person.firstName + ' ' + participant.person.lastName}
                  />
                ))}
              </AvatarGroup>
            </Tooltip>
          )}
          <div>
            {isSingle ? (
              <div className={'whitespace-nowrap'}>
                <StatusTag status={step.status} />
              </div>
            ) : (
              <Tooltip
                placement={'top-end'}
                delay={300}
                title={`${step.totalCompleted} out of ${step.totalAssigned} ${pluralize('assignee', step.totalAssigned)} have completed this step`}>
                <div className={'flex gap-[8px] items-center'}>
                  <ProgressLoader variant="circle" percent={progress} color={'#10B981'} size={'md'} showInfo={false} />
                  <div className={'text-gray-dark text-sm ml-[8px] whitespace-nowrap'}>
                    {step.totalCompleted} / {step.totalAssigned}
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
          <Icon size={20} name={'chevron-right'} />
        </Link>
      </Table.Td>
    </Table.Tr>
  )
}

export default JourneyPhaseTableRow
