import React, { FC } from 'react'
import Gold from './Gold.svg'

const GoldSvg: FC = () => {
  return (
    <div>
      <img src={Gold} alt="Gold" />
    </div>
  )
}
export default GoldSvg
