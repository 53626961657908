import React from 'react'
import { ConnectionsDropdownQueryResult, useConnectionsDropdownLazyQuery } from '../graphql'
import { Avatar, Icon } from '../components/ui'
import Option from '../components/shared/Option'
import usePrograms from './usePrograms'

type ConnectionOption =
  | NonNullable<ConnectionsDropdownQueryResult['data']>['connections']['list'][0]
  | { id: null; name: string }

type LoadAssigneeOptions = (
  inputValue: string,
  callback: (options: ConnectionOption[]) => void,
) => Promise<ConnectionOption[]>

type UseConnectionOptionsParams = {
  size?: 'xs' | 'sm'
  emptyOption?: boolean
  programId?: string
}

const useConnectionOptions = ({ size = 'sm', emptyOption = false, programId }: UseConnectionOptionsParams = {}) => {
  const { selectedProgram } = usePrograms()
  const [query, { loading }] = useConnectionsDropdownLazyQuery()

  const mainIconSize = size === 'xs' ? 18 : 24
  const smallIconSize = size === 'xs' ? 11 : 16
  const fontSize = size === 'xs' ? 'sm' : 'md'

  const placeholder = (
    <Option iconName={'user'} name={'None'} iconSize={mainIconSize} fontSize={fontSize} iconColor={'gray'} />
  )

  const loadOptions: LoadAssigneeOptions = (inputValue) =>
    new Promise<ConnectionOption[]>(async (resolve) => {
      const _programId = programId || selectedProgram?.id
      if (!_programId) {
        return resolve([])
      }
      console.log(inputValue)
      const response = await query({
        variables: {
          programId: _programId,
        },
        fetchPolicy: 'cache-and-network',
      })
      const options: ConnectionOption[] | undefined = [...(response.data?.connections?.list || [])]
      if (options && emptyOption) {
        options.unshift({
          id: null,
          name: 'No assignee',
        })
      }
      resolve(options?.filter((i) => i?.name?.toLowerCase().includes(inputValue.toLowerCase())) || [])
    })

  const formatOptionLabel = (option: ConnectionOption) => {
    if (option.id) {
      return (
        <Option
          name={option.name}
          fontSize={fontSize}
          prefix={<Avatar size={mainIconSize} name={option.name} src={option.profileImage?.url} />}
        />
      )
    } else {
      return (
        <Option
          name={'No assignee'}
          fontSize={fontSize}
          prefix={<Avatar size={mainIconSize} icon={<Icon name={'user'} size={smallIconSize} />} />}
        />
      )
    }
  }

  const isOptionSelected = (option: ConnectionOption, selectValue: ReadonlyArray<ConnectionOption | null>) => {
    if (selectValue.length) {
      return !!selectValue?.find((v) => v?.id === option?.id || v === option?.id)
    } else {
      return option.id === null
    }
  }

  const selectProps = {
    loadOptions,
    placeholder,
    formatOptionLabel,
    isOptionSelected,
    isLoading: loading,
  }

  return [selectProps, query] as [typeof selectProps, typeof query]
}

export default useConnectionOptions
