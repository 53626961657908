import { ConfirmationType } from '../graphql'

export type Confirmation = {
  token: string
  source: string
  sourceType: ConfirmationType
}

export const setConfirmation = (confirmation: Confirmation) => {
  localStorage.setItem('tms_confirmation', JSON.stringify(confirmation))
}

export const getConfirmation = (): Confirmation | null => {
  const confirmation = localStorage.getItem('tms_confirmation')
  if (confirmation) {
    return JSON.parse(confirmation) as Confirmation
  }
  return null
}

export const removeConfirmation = () => {
  localStorage.removeItem('tms_confirmation')
}
