// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type NavigationItemConfig<k extends string = string> = {
  key: string
  title: string
  path: Path
  icon?: (<Props extends { color: string; size: string | number }>({ color, size }: Props) => JSX.Element) | IconName
}

export type NavigationItemKey =
  | 'dashboard'
  | 'people'
  | 'connections'
  | 'steps'
  | 'phases'
  | 'organization'
  | 'myJourney'
  | 'forgotPassword'
  | 'resetPassword'
  | 'signIn'
  | string

export type NavigationItemDictionary = {
  [index in NavigationItemKey]: NavigationItemConfig<index>
}

const navigationItems: NavigationItemDictionary = {
  dashboard: {
    key: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
  },
  people: {
    key: 'people',
    title: 'People',
    path: '/people',
    icon: 'users',
  },
  connections: {
    key: 'connections',
    title: 'Connections',
    path: '/connections/:id',
    icon: 'users',
  },
  steps: {
    key: 'steps',
    title: 'Steps',
    path: '/steps',
    icon: 'check-square',
  },
  phases: {
    key: 'phases',
    title: 'Phases',
    path: '/phases',
    icon: 'map',
  },
  organization: {
    key: 'organization',
    title: 'Organization',
    path: '/organization/:id',
    icon: 'globe',
  },
  journeys: {
    key: 'journeys',
    title: 'Journeys',
    path: '/journeys/:id',
    icon: 'map',
  },
  myJourney: {
    key: 'myJourney',
    title: 'My Journey',
    path: '/my-journey/:id',
    icon: 'map',
  },
  forgotPassword: {
    key: 'forgotPassword',
    title: 'Forgot Password',
    path: '/forgot-password',
    // icon: 'check-square',
  },
  resetPassword: {
    key: 'resetPassword',
    title: 'Reset Password',
    path: '/reset-password',
    // icon: 'check-square',
  },
  signIn: {
    key: 'signIn',
    title: 'Sign In',
    path: '/sign-in',
    // icon: 'check-square',
  },
  drafts: {
    key: 'drafts',
    title: 'Drafts',
    path: '/drafts',
    icon: 'edit-2',
  },
}

export default navigationItems
