import React, { forwardRef, ForwardedRef } from 'react'
import { TabsContextProvider } from './context'
import useControllableState from '../../../hooks/useControllableState'
import TabList from './TabList'
import TabNav from './TabNav'
import TabContent from './TabContent'
import './_tabs.css'
import classNames from 'classnames'

export type TabsProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'defaultValue' | 'value' | 'onChange' | 'variant'
> & {
  variant?: 'underline' | 'pill'
  defaultValue?: string
  value?: string
  onChange?: (tabValue: string | undefined) => void
  className?: string
}

const Tabs = forwardRef(function Tabs(
  { value: valueProp, onChange, defaultValue = '', variant = 'underline', className, ...rest }: TabsProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const [value, setValue] = useControllableState({
    prop: valueProp,
    onChange,
    defaultProp: defaultValue,
  })
  return (
    <TabsContextProvider
      value={{
        value,
        onValueChange: setValue,
        variant,
      }}>
      <div className={classNames(className)} {...rest} ref={ref} />
    </TabsContextProvider>
  )
})

export default Tabs as ((props: TabsProps & { ref?: ForwardedRef<HTMLDivElement> }) => JSX.Element) & {
  TabList: typeof TabList
  TabNav: typeof TabNav
  TabContent: typeof TabContent
}
