import React, { useMemo, PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import { publicRoutes } from '../../../config/routes'
import View from '../components/View'
import Side from './Side'
import FloatingBrand from './FloatingBrand'

const AuthLayout = () => {
  const { pathname } = useLocation()

  const route = publicRoutes.find((r) => r.path === pathname)

  const layout = route?.layout || {}

  const Layout = useMemo(() => {
    if (layout.side) {
      return Side
    }
    return ({ children }: PropsWithChildren) => children
  }, [layout.side])

  return (
    <div className={'h-screen'}>
      <FloatingBrand side={layout.side} />
      <Layout>
        <View />
      </Layout>
    </div>
  )
}

export default AuthLayout
