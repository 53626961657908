import React from 'react'
import { Icon, Avatar } from '../../../../ui'
import { ProgramDropdownFieldsFragment } from '../../../../../graphql'

export type ProgramDropdownButtonProps = {
  selectedProgram: ProgramDropdownFieldsFragment
  collapsed: boolean
  selected?: boolean
}

const ProgramDropdownButton = ({ selectedProgram, collapsed, selected = false }: ProgramDropdownButtonProps) => {
  const { image, name } = selectedProgram

  const duration = 'duration-[150ms]'
  const containerWidth = collapsed ? 'w-[64px]' : 'w-full'
  const subContainerWidth = collapsed ? 'w-[0px]' : 'w-full'
  const opacity = collapsed ? 'opacity-0' : 'opacity-1'

  const chevronRotation = selected ? 'rotate-0' : 'rotate-180'

  return (
    <div
      className={`transition-all ${duration} ${containerWidth} overflow-hidden cursor-pointer flex items-center gap-[9px] p-[17px] hover:bg-gray-800/[0.25] ${
        selected && 'bg-gray-800/[0.25]'
      }`}>
      <Avatar size={30} className={''} shape={'rounded'} name={name} src={image?.url} alt={`${name}-icon`} />
      <div
        className={`transition-all ${duration} whitespace-nowrap flex ${subContainerWidth} items-center gap-[9px] justify-between min-w-0`}>
        <div
          className={`transition-all ${duration} ${opacity} body-sm text-white font-semibold leading-[18.2px] truncate`}>
          {name}
        </div>
        <div className={`${chevronRotation} transition-all duration-[250ms]`}>
          <Icon name={'chevron-up'} color={'white'} />
        </div>
      </div>
    </div>
  )
}

export default ProgramDropdownButton
