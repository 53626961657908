import React from 'react'
import { JourneysDropdownQueryResult, useJourneysDropdownLazyQuery } from '../graphql'
import Option from '../components/shared/Option'
import usePrograms from './usePrograms'

type JourneyOption = NonNullable<JourneysDropdownQueryResult['data']>['journeys']['list'][0]

type LoadJourneyOptions = (inputValue: string, callback: (options: JourneyOption[]) => void) => Promise<JourneyOption[]>

type UseJourneyOptionsParams = {
  size?: 'xs' | 'sm'
  programId?: string
}

const useJourneyOptions = ({ size = 'sm', programId }: UseJourneyOptionsParams = { size: 'sm' }) => {
  const { selectedProgram } = usePrograms()
  const [query, { loading }] = useJourneysDropdownLazyQuery()

  const iconSize = size === 'xs' ? 18 : 24
  const fontSize = size === 'xs' ? 'sm' : 'md'

  const placeholder = (
    <Option iconName={'compass'} name={'Journey'} iconSize={iconSize} fontSize={fontSize} iconColor={'gray'} />
  )

  const loadOptions: LoadJourneyOptions = () =>
    new Promise<JourneyOption[]>(async (resolve) => {
      const _programId = programId || selectedProgram?.id
      if (!_programId) {
        return resolve([])
      }
      const response = await query({
        variables: {
          programId: _programId,
        },
        fetchPolicy: 'cache-and-network',
      })
      const options: JourneyOption[] | undefined = response.data?.journeys?.list
      resolve(options || [])
    })

  const formatOptionLabel = (option: JourneyOption) => (
    <Option fontSize={fontSize} iconSize={iconSize} name={option.name} iconName={'compass'} iconColor={'breakerBay'} />
  )

  const isOptionSelected = (option: JourneyOption, selectValue: ReadonlyArray<JourneyOption>) => {
    return !!selectValue?.find((v) => v.id === option.id)
  }

  const selectProps = {
    loadOptions,
    placeholder,
    formatOptionLabel,
    isOptionSelected,
    isLoading: loading,
  }

  return [selectProps, query] as [typeof selectProps, typeof query]
}

export default useJourneyOptions
