import React, { FC } from 'react'
import { Alert, Button, Dialogue, Form, Icon, Indicator, Input, Text } from '../../../components/ui'
import { Field, Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import * as Yup from 'yup'
import {
  InvitationType,
  ProgramMemberRole,
  useJourneysDropdownQuery,
  useSendInvitationMutation,
} from '../../../graphql'
import usePrograms from '../../../hooks/usePrograms'
import useJourneyOptions from '../../../hooks/useJourneyOptions'
import { CSSObjectWithLabel } from 'react-select'
import Select from '../../../components/ui/Select'
import AsyncSelect from 'react-select/async'
import useToast from '../../../hooks/useToast'

type InviteConnectionProps = {
  open?: boolean
  onClose?: () => void
  personId?: string
}

type InvitationFields = {
  email?: string | null
  message?: string | null
  journey?: {
    id: string
    name: string
  } | null
}

const InviteConnection: FC<InviteConnectionProps> = ({ personId, open = false, onClose }) => {
  const toast = useToast()

  const { selectedProgram } = usePrograms()
  const [sendInvitation, { loading: inviting, data }] = useSendInvitationMutation({
    onCompleted: (data) => {
      if (data.sendInvitation?.__typename === 'SendInvitationSuccess') {
        toast.push('Success', data.sendInvitation.message, 'success')
        onClose?.()
      }
    },
    onError: (error) => {
      toast.push('Error', error.message, 'error')
    },
  })

  const [journeyProps] = useJourneyOptions({ programId: selectedProgram?.id })

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    message: Yup.string().nullable(),
    journey: Yup.object()
      .shape({
        id: Yup.string().required('Journey is required'),
        name: Yup.string().required('Journey is required'),
      })
      .required('Journey is required'),
  })

  const { data: journeyData, loading } = useJourneysDropdownQuery({
    variables: {
      programId: selectedProgram?.id || '',
    },
    fetchPolicy: 'cache-and-network',
  })

  let singleJourney: { id: string; name: string } | undefined
  if (journeyData?.journeys?.total === 1) {
    const j = journeyData?.journeys?.list[0]
    singleJourney = j && {
      id: j.id,
      name: j.name,
    }
  }

  const initialValues: InvitationFields = {
    journey: singleJourney ? singleJourney : null,
    email: null,
    message: null,
  }

  const errorMessage = data?.sendInvitation?.__typename === 'SendInvitationError' ? data.sendInvitation.message : null

  return (
    <Dialogue isOpen={open} className={'bg-gray-900 bg-opacity-30'} shouldCloseOnOverlayClick onRequestClose={onClose}>
      {loading && !journeyData ? (
        <div className={'flex justify-center items-center max-w-[749px] w-[calc(100vw-80px)] h-[203.5px]'}>
          <Indicator isSpinning />
        </div>
      ) : (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (data, { setSubmitting }) => {
            if (data.email && data.journey && personId && selectedProgram) {
              await sendInvitation({
                variables: {
                  data: {
                    email: data.email,
                    message: data.message,
                    person: {
                      id: personId,
                    },
                    program: {
                      id: selectedProgram.id,
                    },
                    journey: {
                      id: data.journey.id,
                    },
                    programMemberRoles: [ProgramMemberRole.Apprentice],
                    type: InvitationType.Guest,
                  },
                },
              })
            }
          }}>
          {({ isSubmitting, errors, touched, values }) => (
            <div id="add-connection-modal" className="w-[339px] self-center justify-self-center">
              <Form.Container className={'!p-0'}>
                <Text variant={'h2'} className={'leading-[22px]'}>
                  Invite a Connection
                </Text>
                <Form.Item
                  label={'Email'}
                  errorMessage={touched.email && errors.email ? errors.email : undefined}
                  invalid={touched.email && !!errors.email}>
                  <Field
                    size={'sm'}
                    type={'email'}
                    name={'email'}
                    className={'bg-gray-50'}
                    placeholder={'Email'}
                    invalid={touched.email && !!errors.email}
                    component={Input}
                  />
                </Form.Item>
                {!singleJourney && (
                  <Form.Item
                    label={'Journey'}
                    errorMessage={touched.journey && errors.journey ? errors.journey : undefined}
                    invalid={touched.journey && !!errors.journey}>
                    <Field
                      name={'journey'}
                      {...journeyProps}
                      tooltip={{
                        title: touched.journey && errors.journey ? errors.journey : 'Add to journey',
                        delay: 600,
                        placement: 'bottom',
                      }}
                      size={'sm'}
                      key={'journey-dropdown'}
                      cacheOptions
                      defaultOptions
                      styles={{
                        menu: (provided: CSSObjectWithLabel) => ({
                          ...provided,
                          minWidth: 207,
                          maxWidth: 350,
                        }),
                      }}
                      component={Select}
                      componentAs={AsyncSelect}
                    />
                  </Form.Item>
                )}
                <Form.Item label={'Message'} size={'sm'} className={'relative'}>
                  <Text variant={'p'} size={'sm'} className={'text-gray-dark absolute top-[3px] right-0'}>
                    Optional
                  </Text>
                  <Field
                    size={'sm'}
                    name={'message'}
                    className={'bg-gray-50'}
                    textArea
                    placeholder={''}
                    component={Input}
                  />
                </Form.Item>
                <AnimatePresence>{errorMessage && <Alert type={'danger'} label={errorMessage} />}</AnimatePresence>
                <div className={`flex flex-col justify-center gap-5`}>
                  <Button
                    loading={inviting}
                    type={'submit'}
                    size={'md'}
                    icon={<Icon name={'plus'} size={20} color={'white'} />}
                    label={'Invite Connection'}
                    block
                    variant={'solid'}
                  />
                  <Button
                    type={'button'}
                    size={'md'}
                    label={'Cancel'}
                    loading={false}
                    block
                    variant={'default'}
                    onClick={() => onClose?.()}
                  />
                </div>
              </Form.Container>
            </div>
          )}
        </Formik>
      )}
    </Dialogue>
  )
}

export default InviteConnection
