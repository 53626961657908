import { useMatch, useNavigate } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import { toUUID } from '../utils/uuid'
import formatRouteId from '../utils/formatRouteId'

type UseRouteIdParams = {
  baseRoute: string
  matchId?: string
  selected?: {
    name?: string
    id?: string
  }
  dontReplace?: boolean
  updateSelected?: (parsed: { name?: string; id: string }) => void
}

const useRouteId = ({
  baseRoute: _baseRoute,
  dontReplace = false,
  selected,
  matchId = 'id',
  updateSelected,
}: UseRouteIdParams) => {
  let baseRoute = _baseRoute
  if (baseRoute.endsWith('/')) {
    baseRoute = baseRoute.slice(0, -1)
  }
  if (!baseRoute.startsWith('/')) {
    baseRoute = `/${baseRoute}`
  }

  const match = useMatch(`${baseRoute}/:${matchId}/*`)
  const navigate = useNavigate()
  baseRoute = `${baseRoute}/`

  const { id, name } = useMemo(() => {
    const parsedId = match?.params[matchId]
    const parts = parsedId?.split('-') || []
    const rawId = parts[parts.length - 1]
    const name = parts.slice(0, parts.length - 1).join(' ')
    return {
      id: rawId && toUUID(rawId),
      name,
    }
  }, [match?.params, matchId])

  useEffect(() => {
    if (selected?.id && match) {
      const parsed = match.params[matchId]
      let rest = match.params['*']
      if (rest) {
        rest = `/${rest}`
      }
      const parsedParts = parsed?.split('-') || []

      const { formattedName, routeId, formattedId } = formatRouteId(selected.name, selected.id)
      if (parsedParts.length > 0) {
        const parsedId = parsedParts[parsedParts.length - 1]
        const parsedRawName = parsedParts.slice(0, parsedParts.length - 1).join('-')
        const parsedName = parsedRawName !== '' ? `${parsedRawName}-` : ''
        if (parsedId === matchId || parsedId === `:${matchId}`) {
          // Need to set the URL based on the selected entity
          if (!dontReplace) {
            navigate(`${baseRoute}${routeId}${rest}`, { replace: true })
          }
        } else if (parsedId === formattedId && parsedName !== formattedName) {
          // Need to update the URL name to match the selected entity's name
          if (!dontReplace) {
            navigate(`${baseRoute}${formattedName}${formattedId}${rest}`, { replace: true })
          }
        } else if (parsedId !== formattedId) {
          // Need to update the selected entity to match the URL
          updateSelected?.({ name: parsedRawName.replaceAll('-', ' '), id: parsedId })
        }
      } else {
        // Need to set the URL based on the selected entity
        if (!dontReplace) {
          navigate(`${baseRoute}${routeId}${rest}`, { replace: true })
        }
      }
    }
  }, [
    dontReplace,
    match,
    match?.params.id,
    selected?.name,
    selected?.id,
    updateSelected,
    baseRoute,
    navigate,
    matchId,
    match?.params,
  ])

  const _id = id || selected?.id || ''
  const _name = name || selected?.name

  return {
    id: _id,
    name: _name,
    routeId: match?.params[matchId] || formatRouteId(_name, _id).routeId,
  }
}

export default useRouteId
