import React from 'react'
import Dropdown from '../../../../../ui/Dropdown'
import Icon from '../../../../../ui/Icon'
import UserDropdownHeader from './UserDropdownHeader'
import UserDropdownButton from './UserDropdownButton'
import useSignOut from '../../../../../../hooks/useSignOut'
import { UserFieldsFragment } from '../../../../../../graphql'
import useViewAs from '../../../../../../hooks/useViewAs'
import { useNavigate } from 'react-router-dom'

export type UserDropdownProps = {
  user?: UserFieldsFragment
}

const UserDropdown = ({ user }: UserDropdownProps) => {
  const navigate = useNavigate()
  const signOut = useSignOut()
  const { switchViewDisplay, canSwitchView, toggleViewAs } = useViewAs()

  return (
    <Dropdown
      placement={'bottom-end'}
      selectable={false}
      renderHeader={
        <Dropdown.Header>
          <UserDropdownHeader user={user} />
        </Dropdown.Header>
      }
      menuClass={'min-w-[355px]'}
      renderToggle={<UserDropdownButton user={user} />}>
      {canSwitchView ? (
        <Dropdown.Item
          key={'profile-switch'}
          onClick={() => toggleViewAs()}
          title={`Switch to ${switchViewDisplay}`}
          icon={<Icon name={'refresh-ccw'} />}
          eventKey={`user-dropdown-item-profile-switch`}
        />
      ) : null}
      <Dropdown.Item
        key={'accountSettings-settings'}
        onClick={() => navigate('/account')}
        title={'Account Settings'}
        icon={<Icon name={'settings'} />}
        eventKey={`user-dropdown-item-account-settings`}
      />
      <Dropdown.Item
        key={'log-out'}
        onClick={() => signOut()}
        title={'Log Out'}
        icon={<Icon name={'log-out'} />}
        eventKey={`user-dropdown-item-log-out`}
      />
    </Dropdown>
  )
}

export default UserDropdown
