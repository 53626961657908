import { StepDraftFieldsFragment } from '../../graphql'
import useLeaderDrafts from '../../hooks/useLeaderDrafts'
import View from '../../components/shared/View'
import DataTable, { DataTableColumn } from '../../components/ui/Table/DataTable'
import React from 'react'
import { Tooltip } from '../../components/ui'
import StepIcon from '../../components/shared/StepIcon'
import { useNavigate } from 'react-router'
import formatRouteId from '../../utils/formatRouteId'
import TableTools from '../../components/ui/Table/TableTools'
import { useDispatch } from 'react-redux'
import { toggleModal } from '../../store/layout/modalSlice'

const Drafts = () => {
  const { drafts, loading } = useLeaderDrafts({ fetchPolicy: 'cache-and-network' })

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const columns: DataTableColumn<StepDraftFieldsFragment>[] = [
    {
      header: 'Title',
      accessorKey: 'name',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        return original?.name ? (
          <div className={'flex gap-[20px] min-h-[40px] items-center'}>
            <div>
              <StepIcon color={original.phase.color} type={original.type} contentType={original.contentType} />
            </div>
            <Tooltip title={original?.name} delay={1250}>
              <div
                style={{
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  display: '-webkit-box',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                className={'font-semibold'}>
                {original?.name}
              </div>
            </Tooltip>
          </div>
        ) : null
      },
    },
  ]

  return (
    <View loading={loading && !drafts}>
      <div className={'flex justify-center mt-[25px]'}>
        <div className={'flex flex-col w-full max-w-[1200px]'}>
          <h2 className={'h2 m-0 mb-[-30px]'}>My Drafts</h2>
          <TableTools
            tableName={'drafts'}
            onSearchInputChange={(val: string) => val}
            search
            onClickAdd={() => dispatch(toggleModal('compose'))}
            addText={'Compose'}
            viewAll={false}
          />
          <DataTable
            name={'drafts'}
            columns={columns}
            data={drafts}
            total={drafts?.length}
            pagination={true}
            selectable={false}
            sortable={false}
            onRowClick={(row) => {
              const { routeId } = formatRouteId(row.name, row.id)
              navigate(`/step/${routeId}`)
            }}
          />
        </div>
      </div>
    </View>
  )
}

export default Drafts
