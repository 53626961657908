import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

export type DrawerState = {
  show: boolean
  key?: string | null
}

export const initialState: DrawerState = {
  show: false,
  key: null,
}

export const drawerSlice = createSlice({
  name: 'layout/drawer',
  initialState,
  reducers: {
    toggleDrawer: (state, action: PayloadAction<string | undefined>) => {
      const key = action.payload
      if (key && state.key !== key) {
        state.show = true
      } else {
        state.show = !state.show
      }
      state.key = key
    },
  },
})

export const selectDrawer = createSelector(
  (state: { layout: { drawer: DrawerState } }) => state.layout.drawer,
  (drawer) => drawer,
)

export const { toggleDrawer } = drawerSlice.actions

export default drawerSlice.reducer
