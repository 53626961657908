import CryptoJS from 'crypto-js'

function byteArrayToWordArray(ba: Uint8Array) {
  const wa: number[] = []
  for (let i = 0; i < ba.length; i++) {
    wa[(i / 4) | 0] |= ba[i] << (24 - 8 * i)
  }

  return CryptoJS.lib.WordArray.create(wa, ba.length)
}

export async function generateHash(file: File | Blob) {
  const hash256 = CryptoJS.algo.SHA256.create()
  const ab = await file.arrayBuffer()
  const ba = new Uint8Array(ab)
  hash256.update(byteArrayToWordArray(ba))
  return hash256.finalize().toString()
}
