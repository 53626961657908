import { ConnectionsDocument, ContentStepType, StepType } from '../../graphql'

export const connectionMocks = [
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: ConnectionsDocument,
      variables: {
        programId: '1',
      },
    },
    result: {
      data: {
        connections: {
          hasMore: false,
          total: 6,
          list: [
            {
              id: '1',
              connection: {
                id: '1',
              },
              name: 'John Doe',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              journey: {
                phase: {
                  name: 'Establishing',
                  color: '#2D6769',
                  iconName: 'leaf',
                  state: {
                    name: 'Convert',
                    color: '#CC7A5D',
                    __typename: 'State',
                  },
                  __typename: 'Phase',
                },
                totalSteps: 8,
                stepsCompleted: 2,
                steps: [
                  {
                    id: '1',
                    name: 'Romans 12:1-2',
                    type: StepType.Content,
                    contentType: ContentStepType.Text,
                    __typename: 'UserStep',
                  },
                ],
                __typename: 'UserJourney',
              },
              myRelatedTasks: {
                totalIncomplete: 5,
                __typename: 'Tasks',
              },
              __typename: 'Person',
            },
            {
              id: '2',
              connection: {
                id: '2',
              },
              name: 'Robert Jones',
              profileImage: {
                id: '3',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              journey: {
                phase: {
                  name: 'Equipping',
                  color: '#C2410C',
                  iconName: 'users',
                  state: {
                    name: 'Disciple',
                    color: '#9CA3AF',
                    __typename: 'State',
                  },
                  __typename: 'Phase',
                },
                totalSteps: 12,
                stepsCompleted: 9,
                steps: [
                  {
                    id: '2',
                    name: 'Discipleship Basics',
                    type: StepType.Series,
                    __typename: 'UserStep',
                  },
                ],
                __typename: 'UserJourney',
              },
              myRelatedTasks: {
                totalIncomplete: 5,
                __typename: 'Tasks',
              },
              __typename: 'Person',
            },
            {
              id: '3',
              connection: {
                id: '3',
              },
              name: 'Jim Smith',
              profileImage: {
                id: '4',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              journey: {
                phase: {
                  name: 'In-Depth Training',
                  color: '#A21CAF',
                  iconName: 'training',
                  state: {
                    name: 'Worker',
                    color: '#A48664',
                    __typename: 'State',
                  },
                  __typename: 'Phase',
                },
                totalSteps: 40,
                stepsCompleted: 20,
                steps: [
                  {
                    id: '3',
                    name: 'The Harvest',
                    type: StepType.Content,
                    contentType: ContentStepType.Video,
                    __typename: 'UserStep',
                  },
                ],
                __typename: 'UserJourney',
              },
              myRelatedTasks: {
                totalIncomplete: 0,
                __typename: 'Tasks',
              },
              __typename: 'Person',
            },
            {
              id: '4',
              connection: {
                id: '4',
              },
              name: 'Connor Wilson',
              profileImage: {
                id: '6',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              journey: {
                phase: {
                  name: 'Equipping',
                  color: '#C2410C',
                  iconName: 'training',
                  state: {
                    name: 'Disciple',
                    color: '#9CA3AF',
                    __typename: 'State',
                  },
                  __typename: 'Phase',
                },
                totalSteps: 4,
                stepsCompleted: 12,
                steps: [
                  {
                    id: '4',
                    name: 'Attend Sunday Worship',
                    type: StepType.Task,
                    __typename: 'UserStep',
                  },
                ],
                __typename: 'UserJourney',
              },
              myRelatedTasks: {
                totalIncomplete: 0,
                __typename: 'Tasks',
              },
              __typename: 'Person',
            },
            {
              id: '5',
              connection: {
                id: '5',
              },
              name: 'Jim Smith',
              profileImage: {
                id: '7',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              journey: null,
              myRelatedTasks: {
                totalIncomplete: 0,
                __typename: 'Tasks',
              },
              __typename: 'Person',
            },
            {
              id: '6',
              connection: {
                id: '6',
              },
              name: 'Jim Smith',
              profileImage: {
                id: '8',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              journey: null,
              myRelatedTasks: {
                totalIncomplete: 0,
                __typename: 'Tasks',
              },
              __typename: 'Person',
            },
            {
              id: '7',
              connection: {
                id: '7',
              },
              name: 'Jim Smith',
              profileImage: {
                id: '9',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              journey: null,
              myRelatedTasks: {
                totalIncomplete: 0,
                __typename: 'Tasks',
              },
              __typename: 'Person',
            },
            {
              id: '8',
              connection: {
                id: '8',
              },
              name: 'Jim Smith',
              profileImage: {
                id: '10',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Image',
              },
              journey: null,
              myRelatedTasks: {
                totalIncomplete: 0,
                __typename: 'Tasks',
              },
              __typename: 'Person',
            },
          ],
          __typename: 'Connections',
        },
      },
    },
  },
]

export default connectionMocks
