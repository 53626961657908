import useMyJourney from '../../../hooks/useMyJourney'
import usePrograms from '../../../hooks/usePrograms'
import useRouteId from '../../../hooks/useRouteId'
import { ProgressLoader } from '../../../components/ui'
import Icon from '../../../components/ui/Icon'
import React from 'react'
import StepIcon from '../../../components/shared/StepIcon'
import StatusTag from '../../../components/shared/StatusTag'
import formatRouteId from '../../../utils/formatRouteId'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '../../../store/layout/drawerSlice'

const MyJourneyUtility = () => {
  const { selectedProgram } = usePrograms()

  // Get the programId from the route
  const { id: programId } = useRouteId({
    baseRoute: '/my-journey',
    selected: selectedProgram,
    updateSelected: (parsed) => {
      console.log('Try to update the selected program', parsed)
    },
  })

  const { myJourney } = useMyJourney({ fetchPolicy: 'cache-and-network', programId })

  const dispatch = useDispatch()

  const phase = myJourney?.currentPhase?.phase
  const stepsCompleted = myJourney?.currentPhase?.stepsCompleted || 0
  const totalSteps = myJourney?.currentPhase?.totalSteps || 0

  const progressPercentage = totalSteps ? Math.round((stepsCompleted / totalSteps) * 100) : 0

  const filteredSteps =
    myJourney?.currentPhase?.steps?.filter((step) => step.status === 'IN_PROGRESS' || step.status === 'TO_DO') || []

  return (
    <div className={'flex-col items-center'}>
      <div
        className={
          'flex items-center gap-[10px] text-gray-900 text-[13px] leading-[18.2px] font-semibold tracking-[1.3px]'
        }>
        CURRENT PHASE
        <span className={'bg-gray-light flex-1 h-[1px]'} />
      </div>
      <div className={'mt-[25px] flex items-center gap-[20px]'}>
        <ProgressLoader
          variant="circle"
          percent={progressPercentage}
          color={phase?.color}
          size={'lg'}
          showInfo={false}
          icon={<Icon size={24} color={phase?.color} name="users" />}
        />
        <div className={'text-[18px] leading-[23.4px] font-semibold'} style={{ color: phase?.color }}>
          {phase?.name}
        </div>
      </div>
      <div className={'mt-[25px] mb-[20px] text-sm'}>{phase?.description}</div>
      <div className={'w-full'}>
        {filteredSteps?.map((step, index) => {
          const opacityStyle = step.blocked ? { opacity: 0.5 } : {}
          return (
            <Link
              to={`/step/${formatRouteId(step.name, step.id).routeId}`}
              onClick={() => {
                dispatch(toggleDrawer())
              }}
              key={step.id}
              className={'cursor-pointer flex items-center border-b-[1px] border-gray-light h-[75px] gap-[20px]'}
              style={{
                borderBottomColor: index === filteredSteps?.length - 1 ? 'transparent' : '#D9D9D9',
              }}>
              <div style={opacityStyle}>
                <StepIcon
                  color={myJourney?.currentPhase?.phase.color}
                  type={step.type}
                  contentType={step.contentType}
                />
              </div>
              <div className={'flex-1'} style={opacityStyle}>
                <span className={'flex-1 font-semibold max-w-[215px] line-clamp-2 text-[14px]'}>{step.name}</span>
              </div>
              <div style={opacityStyle}>
                <StatusTag status={step.status} />
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default MyJourneyUtility
