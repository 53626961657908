import React, { FC } from 'react'
import classNames from 'classnames'
import { Icon } from '../index'

export type DialogueHeaderProps = {
  children: React.ReactNode
  className?: string
  wrapperClassName?: string
  closeButtonClassName?: string
  style?: React.CSSProperties
  closable?: boolean
  onClose?: () => void
}

const DialogueHeader: FC<DialogueHeaderProps> = ({
  children,
  className,
  closeButtonClassName,
  wrapperClassName,
  closable = false,
  onClose,
}) => (
  <div className={classNames('flex gap-[20px] mb-5', wrapperClassName)}>
    <div className={classNames('flex justify-between items-center font-semibold text-[18px] grow', className)}>
      {children}
    </div>
    {closable ? (
      <div className={closeButtonClassName}>
        <div className={'relative w-[20px] h-[20px]'}>
          <div
            onClick={onClose}
            className={
              'cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200 absolute top-[-4px] bottom-[-4px] right-[-4px] left-[-4px]'
            }>
            <Icon name="x" size={'20'} color={'#333132'} />
          </div>
        </div>
      </div>
    ) : null}
  </div>
)

export default DialogueHeader
