import React, { FC } from 'react'
import Silver from './Silver.svg'

const SilverSvg: FC = () => {
  return (
    <div>
      <img src={Silver} alt="Silver" />
    </div>
  )
}
export default SilverSvg
