import Table from '../../../components/ui/Table/Table'
import React from 'react'
import useMyJourney from '../../../hooks/useMyJourney'
import MyJourneyTableRow from './MyJourneyTableRow'

const MyJourneyTableBody = () => {
  const { myJourney } = useMyJourney()

  const steps = myJourney?.currentPhase?.steps || []
  const phase = myJourney?.currentPhase?.phase

  return (
    <Table.TBody>
      {steps.map((step) => (
        <MyJourneyTableRow iconColor={phase?.color} key={step.id} step={step} />
      ))}
    </Table.TBody>
  )
}

export default MyJourneyTableBody
