import React from 'react'
import appConfig from '../../../../config/app'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'
import { protectedRoutes, publicRoutes } from '../../../../config/routes'

const { authenticatedEntryPath, onboardingEntryPath } = appConfig

const PublicRoute = () => {
  const { authenticated, user } = useAuth()

  const { pathname } = useLocation()

  const routeConfig =
    publicRoutes.find((route) => route.path === pathname) || protectedRoutes.find((route) => route.path === pathname)
  if (!!routeConfig?.force) {
    return <Outlet />
  }

  return authenticated && user?.tenants.length && user?.programs.length ? (
    <Navigate to={authenticatedEntryPath} />
  ) : authenticated ? (
    <Navigate to={onboardingEntryPath} />
  ) : (
    <Outlet />
  )
}

export default PublicRoute
