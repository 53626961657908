import React, { FC, useState } from 'react'
import TableTools from '../../../components/ui/Table/TableTools'
import { Avatar, Badge, Button, Icon, Text, Tooltip } from '../../../components/ui'
import {
  ConnectionsQueryResult,
  ConnectionJourneyPhaseFieldsFragment,
  ContentStepType,
  StepType,
} from '../../../graphql'
import ProgressLoader from '../../../components/ui/ProgressLoader'
import DataTable, { DataTableColumn } from '../../../components/ui/Table/DataTable'
import classNames from 'classnames'
import { getContentTypeTitlePrefix } from '../../myJourney/components/MyJourneyTableRow'
import BronzeSvg from '../../../assets/BronzeSVG'
import SilverSvg from '../../../assets/SilverSVG'
import GoldSvg from '../../../assets/GoldSVG'
import { Link, useNavigate } from 'react-router-dom'
import AddConnection from './AddConnection'
import formatRouteId from '../../../utils/formatRouteId'
import InviteConnection from './InviteConnection'

type ConnectionsGeneralProps = {
  connections?: NonNullable<ConnectionsQueryResult['data']>['connections']
}

type ConnectionsGeneralDataTableProps = {
  id?: string
  connectionId?: string
  phase?: ConnectionJourneyPhaseFieldsFragment
  stepsCompleted?: number
  totalSteps?: number
  name?: string
  avatar?: string
  role?: string
  recentlyCompleted?: {
    __typename?: 'UserStep'
    id: string
    name: string
    type: StepType
    contentType?: ContentStepType | null
  }
  myRelatedTasks?: number
}

// todo: Need utility that maps phase hex color to tailwind class? Return tailwind class instead of hex in data?
export const phaseStyles = (phase: string) => {
  let content = {
    phaseTextColor: 'text-gray-dark',
    phaseBorderColor: 'border-gray-light',
    badge: <></>,
  }
  switch (phase) {
    case 'Equipping':
      content = {
        phaseTextColor: 'text-equipping-dark',
        phaseBorderColor: 'border-equipping-light',
        badge: <SilverSvg />,
      }
      break
    case 'Establishing':
      content = {
        phaseTextColor: 'text-establishing-dark',
        phaseBorderColor: 'border-establishing-light',
        badge: <BronzeSvg />,
      }
      break
    case 'In-Depth Training':
      content = {
        phaseTextColor: 'text-training-dark',
        phaseBorderColor: 'border-training-light',
        badge: <GoldSvg />,
      }
      break
    default:
      break
  }
  return content
}

type InviteConnectionProps = {
  open: boolean
  personId?: string
}

const ConnectionsGeneral: FC<ConnectionsGeneralProps> = ({ connections }) => {
  const navigate = useNavigate()

  const [openAdd, setOpenAdd] = useState(false)
  const [openInvite, setOpenInvite] = useState<InviteConnectionProps>({
    open: false,
  })

  const connectionsList: ConnectionsGeneralDataTableProps[] = []

  if (connections) {
    connections?.list?.forEach((person) => {
      connectionsList.push({
        id: person?.id,
        connectionId: person?.myConnection?.id,
        name: person?.name,
        avatar: person?.profileImage?.url,
        phase: person?.journey?.currentPhase || undefined,
        stepsCompleted: person?.journey?.currentPhase?.stepsCompleted,
        totalSteps: person?.journey?.currentPhase?.totalSteps,
        recentlyCompleted: person?.journey?.currentPhase?.steps[0],
        myRelatedTasks: person?.myRelatedTasks?.totalIncomplete,
      })
    })
  }

  const columns: DataTableColumn<ConnectionsGeneralDataTableProps>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        const { badge } = phaseStyles(original?.phase?.phase.name || '')
        const avatar = badge ? (
          <Badge position="bottom-right" render={() => badge}>
            <Avatar name={original?.name} src={original?.avatar} alt={`${original?.name}-icon`} />
          </Badge>
        ) : (
          <Avatar name={original?.name} src={original?.avatar} alt={`${original?.name}-icon`} />
        )
        return original?.name ? (
          <div className={'flex items-center gap-[10px]'}>
            <Tooltip delay={1000} title={original?.name} className={'flex min-w-0 items-center gap-[10px]'}>
              {avatar}
              <div className={'min-w-0'}>
                <Text variant={'h4'} className={'text-gray-900 truncate'}>
                  {original?.name}
                </Text>
                {original?.phase?.phase.state.name && (
                  <Text variant={'p'} size={'sm'} className={'text-gray-dark'}>
                    {original?.phase?.phase.state.name}
                  </Text>
                )}
              </div>
            </Tooltip>
          </div>
        ) : null
      },
    },
    {
      header: 'Phase',
      accessorKey: 'phase.name',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        const { phaseTextColor, phaseBorderColor } = phaseStyles(original?.phase?.phase.name || '')
        return original?.phase ? (
          <div
            className={classNames(
              'whitespace-nowrap flex items-center gap-[10px] pl-2.5 pr-2.5 border-2 rounded-[10px] h-[35px] w-fit',
              phaseBorderColor,
            )}>
            {original?.phase?.phase.iconName && (
              <Icon size={19} color={original?.phase?.phase.color} name={original?.phase?.phase.iconName as IconName} />
            )}
            <Text variant={'h6'} className={classNames('leading-[13px]', phaseTextColor)}>
              {original?.phase?.phase.name}
            </Text>
          </div>
        ) : (
          <div
            className={
              'whitespace-nowrap flex items-center gap-[10px] pl-2.5 pr-2.5 border-2 rounded-[10px] h-[35px] w-fit'
            }>
            <Icon size={19} color={'gray'} name={'message-square'} />
            <Text variant={'h6'} className={classNames('leading-[13px]', phaseTextColor)}>
              Reservoir
            </Text>
          </div>
        )
      },
    },
    {
      header: 'Progress',
      accessorKey: 'progress',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        const stepsCompleted = original?.stepsCompleted || 0
        const totalSteps = original?.totalSteps || 0
        const progressPercentage = totalSteps ? Math.round((stepsCompleted / totalSteps) * 100) : 0
        return original?.phase ? (
          <div className="flex gap-[8px] items-center">
            <ProgressLoader
              variant="circle"
              percent={progressPercentage}
              color={original?.phase?.phase.color}
              size={'md'}
              showInfo={false}
            />
            <Text
              variant={'p'}
              size={'sm'}
              className={'whitespace-nowrap text-gray-dark'}>{`${stepsCompleted} / ${totalSteps} steps`}</Text>
          </div>
        ) : (
          <Button
            variant={'plain'}
            size={'sm'}
            icon={<Icon name={'user-plus'} size={20} className={'mr-[2px]'} />}
            label={'Invite'}
            onClick={(e) => {
              e?.stopPropagation()
              setOpenInvite({
                open: true,
                personId: original?.id,
              })
              console.log(original?.id)
            }}
          />
        )
      },
    },
    {
      header: 'Recently Completed',
      accessorKey: 'recentlyCompleted',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        const { routeId } = formatRouteId(original?.recentlyCompleted?.name, original?.recentlyCompleted?.id)
        return original?.recentlyCompleted ? (
          <div className={'flex items-start flex-col gap-[6px]'}>
            <Text variant={'h6'} className={'leading-[13px] text-gray-dark'}>
              {getContentTypeTitlePrefix(original?.recentlyCompleted?.contentType as ContentStepType)}
            </Text>
            <Link
              to={`/step/${routeId}`}
              onClick={(e) => {
                e?.stopPropagation()
              }}
              className={'flex items-center gap-[3px] hover:underline cursor-pointer'}>
              <Text variant={'p'} size={'sm'} className={'leading-[13px] truncate text-gray-900'}>
                {original?.recentlyCompleted?.name}
              </Text>
              <Icon size={18} color={'gray-900'} name={'arrow-up-right'} />
            </Link>
          </div>
        ) : null
      },
    },
    {
      header: '',
      accessorKey: 'myRelatedTasks',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        return original?.myRelatedTasks ? (
          <div className={'flex items-center bg-gray-200 rounded-[7px] w-fit h-[24px] pl-[8px] pr-[8px] gap-[2px]'}>
            <Icon name={'check-square'} size={12} color={'gray-dark'} />
            <Text variant={'h5'} className={'text-gray-dark leading-[14px]'}>
              {original?.myRelatedTasks}
            </Text>
          </div>
        ) : null
      },
    },
    {
      header: '',
      accessorKey: 'more',
      sortable: false,
      cell: () => {
        return (
          <div className={'flex items-center justify-end'}>
            <Icon name={'chevron-right'} size={20} />
          </div>
        )
      },
    },
  ]

  return (
    <div className={'flex justify-center mt-[25px]'}>
      <div className={'flex flex-col w-full max-w-[1200px]'}>
        <h2 className={'h2 m-0 mb-[-30px]'}>My Connections</h2>
        <TableTools
          tableName={'connections'}
          onSearchInputChange={(val: string) => val}
          search={true}
          onClickAdd={() => setOpenAdd(true)}
          viewAll={false}
        />
        <DataTable
          name={'connections'}
          columns={columns as DataTableColumn<any>[]}
          data={connectionsList}
          total={connectionsList.length}
          selectable={false}
          sortable={false}
          onRowClick={(row) => {
            const { routeId } = formatRouteId(row.name, row.id)
            navigate(`/connection/${row?.connectionId}/${routeId}`)
          }}
        />
      </div>
      <AddConnection open={openAdd} onClose={() => setOpenAdd(false)} />
      <InviteConnection {...openInvite} onClose={() => setOpenInvite({ open: false })} />
    </div>
  )
}

export default ConnectionsGeneral
