import { ReactNodeViewRenderer } from '@tiptap/react'
import { mergeAttributes, Range, Node } from '@tiptap/core'

import { VideoBlockView } from './components/VideoBlockView'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    videoBlock: {
      setVideoBlock: (attributes: { src: string }) => ReturnType
      setVideoBlockAt: (attributes: { src: string; pos: number | Range }) => ReturnType
      setVideoBlockAlign: (align: 'left' | 'center' | 'right') => ReturnType
      setVideoBlockWidth: (width: number) => ReturnType
    }
  }
}

export const VideoBlock = Node.create({
  name: 'videoBlock',

  group: 'block',

  defining: true,

  isolating: true,

  addAttributes() {
    return {
      src: {
        default: '',
        parseHTML: (element) => element.getAttribute('src'),
        renderHTML: (attributes) => ({
          src: attributes.src,
        }),
      },
      width: {
        default: '100%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
        }),
      },
      align: {
        default: 'center',
        parseHTML: (element) => element.getAttribute('data-align'),
        renderHTML: (attributes) => ({
          'data-align': attributes.align,
        }),
      },
      /*alt: {
        default: undefined,
        parseHTML: (element) => element.getAttribute('alt'),
        renderHTML: (attributes) => ({
          alt: attributes.alt,
        }),
      },*/
    }
  },

  parseHTML() {
    return [
      {
        tag: 'video',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['video', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      setVideoBlock:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({ type: 'videoBlock', attrs: { src: attrs.src } })
        },

      setVideoBlockAt:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContentAt(attrs.pos, { type: 'videoBlock', attrs: { src: attrs.src } })
        },

      setVideoBlockAlign:
        (align) =>
        ({ commands }) =>
          commands.updateAttributes('videoBlock', { align }),

      setVideoBlockWidth:
        (width) =>
        ({ commands }) =>
          commands.updateAttributes('videoBlock', { width: `${Math.max(0, Math.min(100, width))}%` }),
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(VideoBlockView)
  },
})

export default VideoBlock
