import React, { FC, useState } from 'react'
import Select from '../../../components/ui/Select'
import { MultiValue, SingleValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { components } from 'react-select'
import Icon from '../../../components/ui/Icon'

const { MultiValueLabel, Control } = components

const customOptions = [
  { value: 1, label: 'Custom Select Option 1' },
  { value: 2, label: 'Custom Select Option 2' },
  { value: 3, label: 'Custom Select Option 3' },
  { value: 4, label: 'Custom Select Option 4' },
]

const options = [
  { value: 1, label: 'Disabled Option', isDisabled: true },
  { value: 2, label: 'Select 1' },
  { value: 3, label: 'Select 2' },
  { value: 4, label: 'Select 3' },
  { value: 5, label: 'Select 4' },
  { value: 6, label: 'Select 5' },
  {
    label: 'Grouped Options',
    options: [
      { value: 7, label: 'Select 7' },
      { value: 8, label: 'Select 8' },
      { value: 9, label: 'Select 9' },
      { value: 10, label: 'Select 10' },
    ],
  },
]

const CustomSelectOption = ({ innerProps, label, isSelected }: any) => {
  return (
    <div
      className={`flex items-center justify-between p-2 ${isSelected ? 'bg-green-100' : 'hover:bg-green-50'}`}
      {...innerProps}>
      <div className="flex items-center">
        <span className="ml-6 rtl:mr-2">{label}</span>
      </div>
      {isSelected && <Icon name={'check'} />}
    </div>
  )
}

const CustomControl = ({ children, ...props }: any) => {
  const selected = props.getValue()[0]
  return (
    // @ts-ignore
    <Control {...props}>
      {selected && (
        <div className={'mx-2'}>
          <Icon name={'truck'} color={'green'} />
        </div>
      )}
      {children}
    </Control>
  )
}

const CustomControlMulti = ({ children, ...props }: any) => {
  return (
    // @ts-ignore
    <MultiValueLabel {...props}>
      <div className="inline-flex items-center justify-center h-[24px]">
        <div className={'mr-2'}>
          <Icon name={'tv'} color={'orange'} size={14} />
        </div>
        <div className={'flex items-center justify-center'}>{children}</div>
      </div>
    </MultiValueLabel>
  )
}

const SelectExample: FC = () => {
  const [selected, setSelected] = useState<
    MultiValue<{ value: number; label: string }> | SingleValue<{ value: number; label: string }>
  >(null)
  const [selectedMulti, setSelectedMulti] = useState<
    MultiValue<{ value: number; label: string }> | SingleValue<{ value: number; label: string }>
  >(null)
  const [loading, setLoading] = useState(false)
  const [loadOptions, setLoadOptions] = useState<any>([])
  const [loadedOption, setLoadedOption] = useState<
    MultiValue<{ value: number; label: string }> | SingleValue<{ value: number; label: string }>
  >(null)
  const [invalidSelect, setInvalidSelect] = useState<
    MultiValue<{ value: number; label: string }> | SingleValue<{ value: number; label: string }>
  >(null)

  const promiseOptions = () => {
    setTimeout(() => {
      setLoadOptions(options)
      setLoading(false)
    }, 1500)
  }

  const onFocus = () => {
    if (loadOptions.length === 0) {
      setLoading(true)
      promiseOptions()
    }
  }

  return (
    <div style={styles.container}>
      <h3>Small Select</h3>
      <Select
        size={'sm'}
        isMulti={false}
        // isInvalid={true}
        options={options}
        value={selected}
        onChange={(option) => {
          setSelected(option)
        }}
      />
      <Select
        size={'sm'}
        isMulti={true}
        // isInvalid={true}
        options={options}
        value={selectedMulti}
        onChange={(option) => {
          setSelectedMulti(option)
        }}
      />
      <Select
        size={'sm'}
        isDisabled={true}
        isMulti={false}
        options={options}
        value={selected}
        onChange={(option) => {
          setSelected(option)
        }}
      />
      <h3>Medium Select</h3>
      <Select
        size={'md'}
        isMulti={false}
        options={options}
        value={selected}
        onChange={(option) => {
          setSelected(option)
        }}
      />
      <Select
        size={'md'}
        isMulti={true}
        options={options}
        value={selectedMulti}
        onChange={(option) => {
          setSelectedMulti(option)
        }}
      />
      <Select
        size={'md'}
        isDisabled={true}
        isMulti={false}
        options={options}
        value={selected}
        onChange={(option) => {
          setSelected(option)
        }}
      />
      <h3>Large Select</h3>
      <Select
        size={'lg'}
        isMulti={false}
        options={options}
        value={selected}
        onChange={(option) => {
          setSelected(option)
        }}
      />
      <Select
        size={'lg'}
        isMulti={true}
        options={options}
        value={selectedMulti}
        onChange={(option) => {
          setSelectedMulti(option)
        }}
      />
      <Select
        size={'lg'}
        isDisabled={true}
        isMulti={false}
        options={options}
        value={selected}
        onChange={(option) => {
          setSelected(option)
        }}
      />
      <h3>Invalid Select</h3>
      <Select
        size={'sm'}
        isMulti={false}
        invalid={!invalidSelect}
        options={options}
        value={invalidSelect}
        onChange={(option) => {
          setInvalidSelect(option)
        }}
      />
      <h3>Create Options Select</h3>
      <Select
        size={'sm'}
        isMulti={false}
        options={options}
        value={selected}
        placeholder={'Creatable Select'}
        componentAs={CreatableSelect}
        onChange={(option) => {
          setSelected(option)
        }}
      />
      <Select
        size={'sm'}
        isMulti={true}
        options={options}
        placeholder={'Creatable Select'}
        value={selectedMulti}
        componentAs={CreatableSelect}
        onChange={(option) => {
          setSelectedMulti(option)
        }}
      />
      <h3>Load Options Select</h3>
      <Select
        size={'sm'}
        isMulti={false}
        options={loadOptions}
        onFocus={onFocus}
        isLoading={loading}
        value={loadedOption}
        placeholder={'Load Options'}
        onChange={(option) => {
          setLoadedOption(option)
        }}
      />
      <h3>Custom Options Select</h3>
      <Select
        options={customOptions}
        components={{
          Option: CustomSelectOption,
          Control: CustomControl,
        }}
        defaultValue={customOptions[0]}
      />
      <Select
        isMulti
        options={customOptions}
        components={{
          Option: CustomSelectOption,
          MultiValueLabel: CustomControlMulti,
        }}
        defaultValue={[customOptions[1], customOptions[2]]}
      />
    </div>
  )
}

const styles: CSSObject = {
  container: {
    padding: '20%',
    gap: 40,
    display: 'flex',
    flexDirection: 'column',
  },
}
export default SelectExample
