import { useEffect, useState } from 'react'
import {
  StepDetailDocument,
  StepDetailFieldsFragment,
  StepType,
  useCreateQuestionMutation,
  useCreateStepDraftMutation,
  usePublishStepMutation,
  useStepDetailQuery,
  useUpdateStepMutation,
} from '../graphql'
import useRouteId from './useRouteId'
import { useApolloClient, WatchQueryFetchPolicy } from '@apollo/client'
import formatRouteId from '../utils/formatRouteId'
import { useNavigate } from 'react-router'
import usePrograms from './usePrograms'

type UseStepDetailParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  nextFetchPolicy?: WatchQueryFetchPolicy
  id?: string
}

const useStepDetail = ({ fetchPolicy = 'cache-first', nextFetchPolicy, id: _id }: UseStepDetailParams = {}) => {
  const [selected, setSelected] = useState<{ name?: string; id: string } | undefined>(undefined)
  const [isDraft, setIsDraft] = useState(false)

  const { selectedProgram } = usePrograms()

  const client = useApolloClient()
  const navigate = useNavigate()

  const { id } = useRouteId({
    baseRoute: '/step',
    selected,
    dontReplace: isDraft,
  })

  useEffect(() => {
    console.log('new mount')
  }, [])

  let finalId = _id || id || ''

  useEffect(() => {
    console.log('Final ID changed', finalId)
  }, [finalId])

  const { data, loading } = useStepDetailQuery({
    variables: {
      where: {
        id: finalId,
      },
      programId: selectedProgram?.id || '',
    },
    nextFetchPolicy,
    fetchPolicy,
  })

  useEffect(() => {
    if (loading) {
      console.log('Is loading again', loading)
    }
  }, [loading])

  useEffect(() => {
    if (data) {
      if (data.step.draft) {
        setIsDraft(true)
      } else {
        setIsDraft(false)
      }
      setSelected({
        name: data.step.name,
        id: data.step.id,
      })
    }
  }, [data])

  const updateStep = (step: Partial<StepDetailFieldsFragment>) => {
    client.cache.writeQuery({
      broadcast: true,
      query: StepDetailDocument,
      variables: {
        where: {
          id: _id || id || '',
        },
      },
      data: {
        step: {
          ...data?.step,
          ...step,
          questions: (step.questions || data?.step?.questions || []).map((question) => ({
            ...data?.step?.questions?.find(({ id }) => id === question.id),
            ...question,
            __typename: 'Question',
          })),
          phase: {
            ...(step.phase || data?.step.phase || {}),
            __typename: 'Phase',
          },
          __typename: 'Step',
        },
      },
    })
  }

  const addQuestion = (
    { text = '', required = false }: { text: string; required: boolean } = { text: '', required: false },
  ) => {
    try {
      if (data?.step?.id) {
        client.cache.writeQuery({
          broadcast: true,
          query: StepDetailDocument,
          variables: {
            where: {
              id: _id || id || '',
            },
          },
          data: {
            step: {
              ...data.step,
              questions: [
                ...(data.step.questions || []),
                {
                  id: null,
                  text,
                  required,
                  order: data.step.questions?.length || 1,
                  answers: [],
                  step: {
                    id: data.step.id,
                  },
                  __typename: 'Question',
                },
              ],
              __typename: 'Step',
            },
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const resetQuestions = () => {
    client.cache.writeQuery({
      broadcast: true,
      query: StepDetailDocument,
      variables: {
        where: {
          id: _id || id || '',
        },
      },
      data: {
        step: {
          ...data?.step,
          questions: data?.step?.questions.filter((question) => question.id !== null) || [],
          __typename: 'Step',
        },
      },
    })
  }

  const [createQuestionMutation, { loading: creatingQuestion }] = useCreateQuestionMutation({
    update(cache, { data: _data }) {
      if (_data?.createQuestion.__typename === 'CreateQuestionSuccess') {
        const createQuestion = {
          ..._data.createQuestion,
          answers: [],
        }
        if (createQuestion.question.step?.id === data?.step?.id && data?.step) {
          try {
            cache.writeQuery({
              query: StepDetailDocument,
              variables: {
                where: {
                  id: data.step.id,
                },
              },
              data: {
                step: {
                  ...data.step,
                  questions: [...(data?.step?.questions.filter((q) => q.id) || []), createQuestion.question],
                  __typename: 'Step',
                },
              },
            })
          } catch (e) {
            console.error(e)
          }
        }
      }
    },
  })

  const createQuestion = async (question: { text: string; required: boolean }) => {
    const existing = data?.step?.questions?.find((q) => q.id === null)
    if (data?.step.id && existing) {
      const save = saveStep()
      //updateStep({
      //  ...data.step,
      //  questions: [...(data?.step?.questions.filter((q) => q.id) || []), question],
      //})
      const createQuestion = createQuestionMutation({
        variables: {
          data: {
            step: {
              connect: {
                id: data.step.id,
              },
            },
            order: existing.order,
            required: question.required,
            text: question.text,
          },
        },
      })
      await Promise.all([save, createQuestion])
    }
  }

  const [updateStepMutation, { loading: saving }] = useUpdateStepMutation({
    variables: {
      where: {
        id: data?.step.id,
      },
      data: {
        name: data?.step.name || '',
        description: data?.step.description,
        content: data?.step.content,
        instructions: data?.step.instructions,
        leaderInformation: data?.step.leaderInformation,
        type: data?.step.type || StepType.Content,
        contentType: data?.step.contentType,
        phase: {
          connect: {
            id: data?.step.phase.id || '',
          },
        },
        journey: {
          connect: {
            id: data?.step.journey.id,
          },
        },
        assignees: {
          set: data?.step.assignees || [],
        },
      },
    },
    update(cache, { data }) {
      if (data?.updateStep.__typename === 'UpdateStepSuccess') {
        try {
          cache.writeQuery({
            query: StepDetailDocument,
            variables: {
              where: {
                id: data.updateStep.step.id,
              },
            },
            data: {
              step: data.updateStep.step,
            },
          })
        } catch (e) {
          console.error(e)
        }
        //Todo: toast when step is saved
      }
    },
  })

  const [publishing, setPublishing] = useState(false)

  const [publishStepMutation] = usePublishStepMutation({
    update(cache, { data }) {
      if (data?.publishDraftOfStep.__typename === 'PublishDraftOfStepSuccess') {
        try {
          setPublishing(false)
          cache.writeQuery({
            query: StepDetailDocument,
            variables: {
              where: {
                id: data.publishDraftOfStep.step.id,
              },
            },
            data: {
              step: data.publishDraftOfStep.step,
            },
          })
          const { routeId } = formatRouteId(data.publishDraftOfStep.step.name, data.publishDraftOfStep.step.id)
          navigate(`/step/${routeId}`)
        } catch (e) {
          console.error(e)
        }
        //Todo: toast when step is published
      }
    },
  })

  const [createStepDraftMutation, { loading: creatingDraft }] = useCreateStepDraftMutation({
    update(cache, { data: _data }) {
      if (_data?.createDraftOfStep.__typename === 'CreateDraftOfStepSuccess') {
        try {
          cache.writeQuery({
            broadcast: true,
            query: StepDetailDocument,
            variables: {
              where: {
                id: _data.createDraftOfStep.step.id,
              },
            },
            data: {
              step: {
                ..._data.createDraftOfStep.step,
                __typename: 'Step',
              },
            },
          })
          const { routeId } = formatRouteId(_data.createDraftOfStep.step.name, _data.createDraftOfStep.step.id)
          navigate(`/step/${routeId}`)
        } catch (e) {
          console.error(e)
        }
      }
    },
  })

  const saveStep = async () => {
    const step = data?.step
    if (step && step.draft) {
      await updateStepMutation()
    }
  }

  const publishStep = async () => {
    const step = data?.step
    if (step && step.draft) {
      setPublishing(true)
      await updateStepMutation()
      await publishStepMutation({
        variables: {
          id: step.id,
        },
      })
    }
  }

  const createDraft = async () => {
    const step = data?.step
    if (step && !step.draft) {
      await createStepDraftMutation({
        variables: {
          stepId: step.id,
        },
      })
    }
  }

  return {
    step: data?.step,
    loading,
    saveStep,
    saving: publishing ? false : saving,
    publishStep,
    publishing,
    createDraft,
    creatingDraft,
    updateStep,
    addQuestion,
    resetQuestions,
    createQuestion,
    creatingQuestion,
  }
}

export default useStepDetail
