import React from 'react'
import appConfig from '../../../../config/app'
import { REDIRECT_URL_KEY } from '../../../../constants'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'
import { protectedRoutes, publicRoutes } from '../../../../config/routes'

const { unAuthenticatedEntryPath, onboardingEntryPath } = appConfig

const ProtectedRoute = () => {
  const { authenticated, user } = useAuth()

  const location = useLocation()

  const onboarding = !user?.tenants.length || !user?.programs.length

  const { pathname } = useLocation()

  const routeConfig =
    publicRoutes.find((route) => route.path === pathname) || protectedRoutes.find((route) => route.path === pathname)
  if (!!routeConfig?.force) {
    return <Outlet />
  }

  if (!authenticated) {
    return <Navigate to={`${unAuthenticatedEntryPath}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />
  } else if (onboarding) {
    return <Navigate to={onboardingEntryPath} replace />
  }

  return <Outlet />
}

export default ProtectedRoute
