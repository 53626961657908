import {
  OrganizationDetailDocument,
  ProgramMemberRole,
  useOrganizationDetailQuery,
  useUpdateProgramUsersMutation,
} from '../graphql'
import { WatchQueryFetchPolicy } from '@apollo/client'
import useOrganization from './useOrganization'
import useRouteId from './useRouteId'
import useToast from './useToast'

type UseOrganizationDetailParams = {
  fetchPolicy?: WatchQueryFetchPolicy
}

type UpdateProgramUsersParams = {
  id?: string
  programId: string
  roles: ProgramMemberRole[]
}

const useOrganizationDetail = (
  { fetchPolicy = 'cache-first' }: UseOrganizationDetailParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const organization = useOrganization()
  const toast = useToast()

  const { id } = useRouteId({
    baseRoute: '/organization',
    selected: {
      name: organization?.name,
      id: organization?.id,
    },
    updateSelected: (parsed) => {
      //Add logic to change the program dropdown to a program from providence church
      // If the mutation to make that change fails because I don't have permission to a program from that org
      // Then we can show a "You don't have permission to view this page" view or something
      console.log('Try to update the selected organization', parsed)
    },
  })

  const { data, loading } = useOrganizationDetailQuery({
    fetchPolicy,
    nextFetchPolicy: 'cache-first',
    variables: {
      id: id || '',
    },
  })

  const [updateProgramUsersMutation, { loading: updatingProgramUsers }] = useUpdateProgramUsersMutation({
    refetchQueries: [OrganizationDetailDocument],
    onCompleted: (data) => {
      if (data.updateProgramUsers.__typename === 'UpdateProgramUsersSuccess') {
        toast.push('Success', data.updateProgramUsers.message, 'success')
      }
    },
    onError: (error) => {
      toast.push('Error', error.message, 'error')
    },
  })

  const updateProgramUsers = async (userId: string, programUsers: UpdateProgramUsersParams[]) => {
    await updateProgramUsersMutation({
      variables: {
        where: {
          userId,
        },
        data: {
          programUsers,
        },
      },
    })
  }

  return { organization: data?.tenant, loading, updateProgramUsers, updatingProgramUsers }
}

export default useOrganizationDetail
