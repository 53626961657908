import React, { FC } from 'react'
import { Button, Divider, Dropdown, Icon } from '../../../components/ui'

const IconComponent = <Icon name={'placeholder'} />

const items = [
  { id: '1', title: 'Dropdown Item 1', icon: IconComponent },
  { id: '2', title: 'Dropdown Item 2', icon: IconComponent },
  { id: '3', title: 'Dropdown Item 3', icon: IconComponent },
  { id: '4', title: 'Dropdown Item 4', icon: IconComponent },
  { id: '5', title: 'Dropdown Item 5', icon: IconComponent },
  { id: '6', title: 'Dropdown Item 6', icon: IconComponent },
]

const DropdownExample: FC = () => {
  return (
    <div className={'flex flex-row justify-center items-center'}>
      <div
        style={{
          display: 'flex',
          gap: 40,
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Dropdown
          renderHeader={
            <Dropdown.Header>
              <h6>Render Header</h6>
            </Dropdown.Header>
          }
          onSelect={(eventKey) => console.log('onSelect', eventKey)}
          onOpen={() => console.log('onOpen')}
          onClose={() => console.log('onClose')}
          onToggle={() => console.log('onToggle')}
          defaultSelectedKey={'dropdown-item-3'}
          renderToggle={
            <Button size={'lg'} shape={'circle'} variant={'solid'} color={'blue'} label={'Custom Dropdown Button'} />
          }>
          <Dropdown.Group title={'Group Heading'} />
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
          <div className={'p-4'}>
            <Divider />
          </div>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown
          title={'Context Dropdown Button'}
          trigger={'context'}
          onContextMenu={() => console.log('onContextMenu')}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown
          title={'Hover Dropdown Button'}
          trigger={'hover'}
          onMouseEnter={() => console.log('onMouseEnter')}
          onMouseLeave={() => console.log('onMouseLeave')}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Top Start'} placement={'top-start'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Top Center'} placement={'top'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Top End'} placement={'top-end'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Bottom Start'} placement={'bottom-start'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Bottom center'} placement={'bottom'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Bottom End'} placement={'bottom-end'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Right Start'} placement={'right-start'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Right End'} placement={'right-end'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Left Start'} placement={'left-start'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Left End'} placement={'left-end'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
      </div>
    </div>
  )
}
export default DropdownExample
