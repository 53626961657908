import { useEffect, useMemo, useState } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'
import { Config } from 'tailwindcss'

const twConfig = resolveConfig(tailwindConfig as unknown as Config)

interface WindowSize {
  width: number
  height: number
  size: Breakpoint | 'xs'
  screens: Record<Breakpoint, number>
}

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

const getCurrentSize = (): WindowSize['size'] => {
  const theme = twConfig.theme

  let breakpoints = Object.keys(theme.screens)
    .map((key) => Number(theme.screens[key as Breakpoint]?.replace(/\D/g, '') || 1e6))
    .reverse()

  let keys = Object.keys(theme.screens).reverse() as Breakpoint[]

  return keys[breakpoints.findIndex((screenSize: number) => window?.innerWidth > screenSize)] ?? 'xs'
}

export function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<Omit<WindowSize, 'screens'>>({
    width: 0,
    height: 0,
    size: 'xs',
  })

  const screens = useMemo(() => {
    const screens: Record<Breakpoint, number> = {
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
    }
    Object.keys(twConfig.theme.screens).forEach((key) => {
      screens[key as Breakpoint] = Number(twConfig.theme.screens[key as Breakpoint]?.replace(/\D/g, '') || 1e6)
    })
    return screens
  }, [])

  useEffect(() => {
    const handleSize = () => {
      const size = getCurrentSize()
      if (
        size !== windowSize.size ||
        window.innerWidth !== windowSize.width ||
        window.innerHeight !== windowSize.height
      ) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          size,
        })
      }
    }

    handleSize()
    window.addEventListener('resize', handleSize)

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [windowSize])

  return {
    ...windowSize,
    screens,
  }
}
