import useTasks from '../../hooks/useTasks'
import { Icon, Button, Divider, Text } from '../../components/ui'
import React, { useState } from 'react'
import AddUpdateTask from './views/AddUpdateTask'
import Indicator from '../../components/ui/Indicator'
import TaskItem from './components/TaskItem'
import { TaskFieldsFragment } from '../../graphql'
import { days, months } from '../../utils/dates'

const Tasks = () => {
  const { tasks, loading, removeTask, toggleTaskStatus } = useTasks({ fetchPolicy: 'cache-and-network' })

  const [selected, setSelected] = useState<TaskFieldsFragment | null>(null)
  const [open, setOpen] = useState(false)

  const sortedTasks = tasks?.list?.length ? tasks.list.sort((a: any, b: any) => a.complete - b.complete) : []

  const today = new Date()

  return loading ? (
    <div className={'flex w-full justify-center items-center'}>
      <Indicator isSpinning />
    </div>
  ) : (
    <>
      <div className={`flex flex-col ${sortedTasks?.length ? 'gap-[20px]' : 'gap-[60px]'}`}>
        {today && (
          <div className={'flex items-center w-full gap-2.5'}>
            <Text variant={'h6'}>{days[today.getDay()] + ', ' + months[today.getMonth()] + ' ' + today.getDate()}</Text>
            <Divider />
          </div>
        )}
        {sortedTasks?.length ? (
          <>
            <div className={'flex items-center w-full justify-between mb-[-10px]'}>
              <Text variant={'h2'} className={'leading-[22px]'}>
                To-Do
              </Text>
              <Button
                size={'xs'}
                icon={<Icon name={'plus'} color={'white'} />}
                label={'Add'}
                variant={'solid'}
                onClick={() => {
                  setOpen(true)
                }}
              />
            </div>
            <div className={'overflow-y-scroll'}>
              {sortedTasks?.map((task, index) => {
                return (
                  <div key={task.id}>
                    <TaskItem
                      task={task}
                      onClickCheckbox={() => toggleTaskStatus(task.id)}
                      onRemove={() => removeTask(task.id)}
                      onClick={() => {
                        setSelected(task)
                        setOpen(true)
                      }}
                    />
                    {index !== sortedTasks?.length - 1 && <Divider />}
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <div className={'flex flex-col items-center justify-center gap-[20px]'}>
            <div className={'flex items-center gap-2.5'}>
              <Icon name={'check-square'} size={24} />
              <Text variant={'h2'} className={'leading-[22px]'}>
                To-Do
              </Text>
            </div>
            <Text variant={'p'} size={'xs'} className={'text-gray-dark text-center'}>
              Add To-Dos to help yourself prioritize tasks as a leader.
            </Text>
            <Text variant={'p'} size={'xs'} className={'text-gray-dark text-center'}>
              You can specify which of your connections the task involves, and set a due date to receive a reminder.
            </Text>
            <Button
              size={'xs'}
              icon={<Icon name={'plus'} color={'white'} />}
              label={'Add'}
              variant={'solid'}
              onClick={() => {
                setOpen(true)
              }}
            />
          </div>
        )}
      </div>
      <AddUpdateTask
        open={open}
        task={selected}
        onClose={() => {
          setOpen(false)
          setSelected(null)
        }}
      />
    </>
  )
}

export default Tasks
