import React, { FC } from 'react'
import Indicator from '../../../components/ui/Indicator'

const IndicatorExample: FC = () => {
  return (
    <div style={styles.container}>
      <div style={{ marginBottom: 60, display: 'flex', gap: 40 }}>
        <Indicator isSpinning={true} size={10} />
        <Indicator isSpinning={true} size={20} color={'red'} />
        <Indicator isSpinning={true} size={30} color={'green'} />
        <Indicator isSpinning={true} size={40} color={'blue'} />
        <Indicator isSpinning={true} size={50} color={'yellow'} />
        <Indicator isSpinning={true} size={60} color={'bronze'} indicator={'placeholder'} />
        <Indicator isSpinning={true} size={70} color={'gold'} indicator={'placeholder'} />
        <Indicator isSpinning={false} size={80} color={'orange'} indicator={'placeholder'} />
        <Indicator isSpinning={false} size={90} color={'#7c908c'} indicator={'placeholder'} />
      </div>
    </div>
  )
}
const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15%',
    justifyContent: 'center',
  },
}
export default IndicatorExample
