import React from 'react'
import DefaultFilter from './components/DefaultFilter'
import { SelectProps } from '../Select'
import { TableFilters as TableFiltersT, FilterOption } from '../../../store/data/tableSlice'

export type Filters<F extends TableFiltersT, IsMulti extends true | false, Additional> = {
  [P in keyof F]?: FilterProps<FilterOption<F[P]>, IsMulti, Additional>
}

export type FilterProps<Option, IsMulti extends true | false, Additional> = SelectProps<Option, IsMulti, Additional> & {
  type?: 'default'
}

const Filter: <Option, IsMulti extends true | false, Additional>(
  props: FilterProps<Option, IsMulti, Additional>,
) => JSX.Element = ({ type = 'default', ...props }) => {
  return <DefaultFilter {...props} />
}

export default Filter
