import React, {
  forwardRef,
  useMemo,
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  ForwardedRef,
  ChangeEvent,
} from 'react'
import Checkbox from '../../ui/Checkbox'
import Table from './Table'
import {
  selectTable,
  setPageIndex,
  setPageSize,
  setSelection,
  selectRow,
  TableRow,
  setTotalRecords,
} from '../../../store/data/tableSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  SortingState,
  ColumnDef,
  Row,
  ColumnFiltersState,
  TableState,
} from '@tanstack/react-table'
import classNames from 'classnames'
import { usePrevious } from '../../../utils/hooks/usePrevious'
import Pagination from '../Pagination'

type IntermediateCheckboxProps = unknown & {
  indeterminate?: boolean
  onCheckBoxChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onIndeterminateCheckBoxChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  disabled?: boolean
}

const IndeterminateCheckbox = ({
  indeterminate,
  onChange,
  onCheckBoxChange,
  onIndeterminateCheckBoxChange,
  ...rest
}: IntermediateCheckboxProps) => {
  const resolvedRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (resolvedRef && resolvedRef.current && typeof indeterminate === 'boolean') {
      resolvedRef.current.indeterminate = indeterminate
    }
  }, [resolvedRef, indeterminate])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e)
    onCheckBoxChange?.(e)
    onIndeterminateCheckBoxChange?.(e)
  }

  return <Checkbox ref={resolvedRef} onChange={(v, e) => handleChange(e)} {...rest} />
}

export type DataTableColumn<T extends object> = ColumnDef<T> & {
  sortable?: boolean
}

type DataTableProps<T extends object> = {
  columns: DataTableColumn<T>[]
  data?: T[]
  total?: number
  onCheckBoxChange?: (checked: boolean, row: T) => void
  onIndeterminateCheckBoxChange?: (checked: boolean, rows: T[]) => void
  onSelectChange?: (pageSize: number) => void
  onSort?: (sort: { order: string; key: string }, options?: { id: string; clearSortBy: () => void }) => void
  selectable?: boolean
  autoResetSelectedRows?: boolean
  name: string
  sortable?: boolean
  onRowClick?: (row: T) => void
  pagination?: boolean
  onPaginationChange?: (pageIndex: number) => void
  filterable?: boolean
  overflow?: boolean
}

const DataTable = forwardRef(function DataTable<T extends TableRow<unknown>>(
  props: DataTableProps<T>,
  ref: ForwardedRef<unknown>,
) {
  const {
    columns: columnsProp,
    data: incomingData,
    total: incomingTotal,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onSort,
    selectable = true,
    sortable = true,
    name,
    onRowClick,
    pagination = true,
    onPaginationChange,
    onSelectChange,
    filterable = true,
    overflow = true,
  } = props
  const dispatch = useDispatch()

  const first = useRef(true)
  const cache = useRef<{ data: T[]; total?: number }>({ data: [] })

  if (incomingData) {
    cache.current = { data: incomingData, total: incomingTotal }
    first.current = false
  }

  const { selection, search, filters } = useSelector(selectTable(name))

  const { data, total } = cache.current

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])

  useEffect(() => {
    if (incomingData && incomingData.length) {
      dispatch(setTotalRecords({ total: incomingData.length, key: name }))
      if (!pagination) {
        dispatch(setPageSize({ size: Number(incomingData.length), key: name }))
      }
    }
  }, [dispatch, incomingData, name, pagination])

  useEffect(() => {
    if (filters) {
      const entries = Object.entries(filters)
      const data = entries.map(([key, val]: [id: string, value: any]) => {
        return { id: key, value: val?.value }
      })
      setColumnFilters(data)
    }
  }, [filters])

  const {
    currentPage: { size: pageSize, index },
  } = useSelector(selectTable(name))

  let pageIndex = index + 1
  if (total && pageSize * (pageIndex - 1) > total) {
    dispatch(setPageIndex({ index: 0, key: name }))
  }

  const handleCheckBoxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean, row: T) => {
      e.stopPropagation()
      dispatch(selectRow({ key: name, row, selected: checked }))
      onCheckBoxChange?.(checked, row)
    },
    [dispatch, name, onCheckBoxChange],
  )

  const handleIndeterminateCheckBoxChange = useCallback(
    (checked: boolean, selectedRows: Row<T>[]) => {
      const rows = selectedRows.map((row) => row.original)
      dispatch(setSelection({ key: name, selection: checked ? rows : [] }))
      onIndeterminateCheckBoxChange?.(checked, rows)
    },
    [dispatch, name, onIndeterminateCheckBoxChange],
  )

  useEffect(() => {
    if (Array.isArray(sorting)) {
      const sortOrder = sorting.length > 0 ? (sorting[0]?.desc ? 'desc' : 'asc') : ''
      const id = sorting.length > 0 && sorting[0]?.id ? sorting[0].id : ''
      onSort?.({ order: sortOrder, key: id })
    }
  }, [onSort, sorting])

  const hasOldColumnMetaKey = columnsProp.some(
    (col: DataTableColumn<T> & { Header?: unknown; accessor?: unknown; Cell?: unknown }) =>
      col.Header || col.accessor || col.Cell,
  )

  const finalColumns = useMemo(() => {
    let columns: DataTableColumn<T>[] = columnsProp
    if (selectable) {
      columns = [
        {
          id: 'select',
          header: ({ table }) => (
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
              onIndeterminateCheckBoxChange={(e) => {
                handleIndeterminateCheckBoxChange(e.target.checked, table.getRowModel().rows)
              }}
            />
          ),
          cell: ({ row }) => {
            const d = row.original
            const checked = !!selection.find((item) => item.id === d.id)
            return (
              <IndeterminateCheckbox
                checked={checked}
                disabled={!row.getCanSelect()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                onCheckBoxChange={(e) => handleCheckBoxChange(e, e.target.checked, row.original)}
              />
            )
          },
        },
        ...columns,
      ]
    }
    return columns
  }, [columnsProp, selectable, handleIndeterminateCheckBoxChange, selection, handleCheckBoxChange])

  let state: Partial<TableState> = {}

  if (sortable) {
    state.sorting = sorting
  }
  if (filterable) {
    state.columnFilters = columnFilters
    state.globalFilter = search
  }
  if (pagination) {
    state.pagination = {
      pageSize,
      pageIndex: index,
    }
  }

  const table = useReactTable({
    data,
    columns: hasOldColumnMetaKey ? [] : finalColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: filterable ? getFilteredRowModel() : undefined,
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
    getSortedRowModel: sortable ? getSortedRowModel() : undefined,
    enableColumnFilters: filterable,
    enableSorting: sortable,
    enableColumnResizing: true,
    enableRowSelection: selectable,
    enableFilters: filterable,
    onSortingChange: setSorting,
    state,
  })

  const resetSelected = useCallback(() => {
    table.toggleAllRowsSelected(false)
  }, [table])

  const prevSelection = usePrevious(selection)

  useEffect(() => {
    if (!selection.length && prevSelection?.length) {
      resetSelected()
    }
  }, [prevSelection?.length, resetSelected, selection])

  const resetSorting = () => {
    table.resetSorting()
  }

  useImperativeHandle(ref, () => ({
    resetSorting,
    resetSelected,
  }))

  const handlePaginationChange = (page: number | undefined) => {
    onPaginationChange?.(Number(page))
    dispatch(setPageIndex({ index: Number(page) - 1, key: name }))
  }

  const handleSelectChange = (value: number | undefined) => {
    onSelectChange?.(Number(value))
    dispatch(setPageSize({ size: Number(value), key: name }))
  }

  return (
    <>
      <Table className={'w-full overflow-x-scroll overflow-y-visible'} overflow={overflow}>
        <Table.THead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Table.Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <h6
                        className={classNames(
                          header.column.getCanSort() && 'cursor-pointer select-none',
                          '!m-0 flex flex-row-reverse items-center justify-end',
                        )}
                        onClick={header.column.getToggleSortingHandler()}>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() && sortable && <Table.Sorter sort={header.column.getIsSorted()} />}
                      </h6>
                    )}
                  </Table.Th>
                )
              })}
            </Table.Tr>
          ))}
        </Table.THead>
        <Table.TBody>
          {table
            .getRowModel()
            .rows.slice(0, pageSize)
            .map((row) => (
              <Table.Tr
                key={row.id}
                className={onRowClick ? 'cursor-pointer select-none point' : ''}
                onClick={onRowClick ? () => onRowClick(row.original) : undefined}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Td className={overflow ? 'overflow-visible' : ''} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Table.Td>
                  )
                })}
              </Table.Tr>
            ))}
        </Table.TBody>
      </Table>
      {pagination ? (
        <div className="flex w-full items-center justify-end mt-[15px]">
          <Pagination
            pageSize={pageSize}
            currentPage={pageIndex}
            total={total}
            displayTotal={true}
            onChange={handlePaginationChange}
            onSelectChange={handleSelectChange}
          />
        </div>
      ) : null}
    </>
  )
})

export default DataTable
