import { useOrganizationQuery } from '../graphql'
import usePrograms from './usePrograms'
import { WatchQueryFetchPolicy } from '@apollo/client'

type UseOrganizationParams = {
  fetchPolicy?: WatchQueryFetchPolicy
}

const useOrganization = (
  { fetchPolicy = 'cache-first' }: UseOrganizationParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const { selectedProgram } = usePrograms()

  const { data } = useOrganizationQuery({
    fetchPolicy,
    nextFetchPolicy: 'cache-first',
    variables: {
      id: selectedProgram?.tenant?.id || '',
    },
  })

  return data?.tenant
}

export default useOrganization
