import React, { FC, HTMLAttributes, ChangeEvent } from 'react'
import RadioGroupContext from './Context'

interface GroupProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value: any
  onChange: (value: any, event: ChangeEvent<HTMLInputElement>) => void
  vertical?: boolean
}

const Group: FC<GroupProps> = ({ children, value, onChange, vertical = false, ...rest }) => {
  const contextValue = { value, onChange, vertical }

  const groupClasses = `flex ${vertical ? 'flex-col' : 'flex-row'} gap-2`

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <div className={groupClasses} {...rest}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  )
}

export default Group
