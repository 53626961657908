import React, { FC } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '../../../store/layout/drawerSlice'
import { useWindowSize } from '../../../hooks/useWindowSize'
import classNames from 'classnames'
import useLayout from '../../../hooks/useLayout'

type ContainerProps = {
  children: React.ReactNode
  backgroundColor?: string
}

type ViewProps = {
  children: React.ReactNode
  backgroundColor?: string
}

type DrawerProps = {
  isOpen: boolean
  children: React.ReactNode
}

export const DrawerContainer: React.FC<ContainerProps> = ({ children, backgroundColor = 'white' }) => {
  return <div className={'flex min-h-0 w-full h-full bg-' + backgroundColor}>{children}</div>
}

export const DrawerView: React.FC<ViewProps> = ({ children, backgroundColor = 'white' }) => {
  return <div className={'flex min-w-0 min-h-0 flex-grow bg-' + backgroundColor}>{children}</div>
}

const Drawer: React.FC<DrawerProps> & { Container: FC<ContainerProps>; View: FC<ViewProps> } = ({
  isOpen,
  children,
}) => {
  const dispatch = useDispatch()
  const { width } = useWindowSize()

  const {
    drawer: { key },
  } = useLayout()

  const mobile = width < 1280

  const drawerStyles = isOpen ? 'p-10' : 'w-0 p-0'
  const contentStyles = isOpen ? ' w-full opacity-1 transition-opacity duration-200 delay-200' : 'opacity-0'

  let responsiveStyles = ''

  if (isOpen) {
    if (mobile) {
      if (width <= 470) {
        responsiveStyles = 'w-[90%] !absolute !right-0'
      } else {
        responsiveStyles = '!w-[420px] min-w-[420px] !absolute !right-0'
      }
    } else {
      responsiveStyles = '!w-[420px] min-w-[420px]'
    }
  } else {
    if (mobile) {
      responsiveStyles = '!absolute !right-0'
    }
  }

  return (
    <>
      <div
        className={classNames(
          'h-full bg-white z-10 overflow-y-auto transition-all duration-2000 ease-out',
          drawerStyles,
          responsiveStyles,
        )}>
        <div className={`${contentStyles}`}>{children}</div>
      </div>
      {isOpen && mobile && key && (
        <AnimatePresence>
          {isOpen && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <div
                onClick={() => dispatch(toggleDrawer(key))}
                className={'fixed top-0 bottom-0 right-0 left-0 bg-gray-900 opacity-30'}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  )
}

Drawer.Container = DrawerContainer
Drawer.View = DrawerView

export default Drawer
