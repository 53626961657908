import { WatchQueryFetchPolicy } from '@apollo/client'
import { useLeaderDraftsQuery } from '../graphql'
import usePrograms from './usePrograms'

type UseDraftsParams = {
  fetchPolicy?: WatchQueryFetchPolicy
}

const useLeaderDrafts = ({ fetchPolicy = 'cache-first' }: UseDraftsParams = {}) => {
  const { selectedProgram } = usePrograms()

  const { data, loading } = useLeaderDraftsQuery({
    variables: {
      programId: selectedProgram?.id || '',
    },
    fetchPolicy,
  })

  return { drafts: data?.draftSteps.list, loading }
}

export default useLeaderDrafts
