import React, { FC, useMemo } from 'react'
import { Dialogue } from '../../../ui'
import { selectModal, closeModal } from '../../../../store/layout/modalSlice'
import { useSelector, useDispatch } from 'react-redux'
import { leaderModals, apprenticeModals } from '../../../../config/modals'

const Modal: FC = () => {
  const { show, key } = useSelector(selectModal)
  const dispatch = useDispatch()

  const modal = useMemo(() => {
    if (!key) return null
    // Todo: enforce the ViewAs state when showing modals
    return [...leaderModals, ...apprenticeModals].find((modal) => modal.key === key)?.component
  }, [key])

  const handleClose = () => {
    dispatch(closeModal())
  }

  return (
    <Dialogue
      isOpen={show}
      className={'bg-gray-900 bg-opacity-30'}
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}>
      {modal}
    </Dialogue>
  )
}

export default Modal
