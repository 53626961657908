import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useMetaTags, MetaTagsConfig } from 'react-metatags-hook'
import { APP_TITLE } from '../../../../constants'
import { onboardingRoutes } from '../../../../config/routes'
import { useLocation } from 'react-router-dom'

type PublicViewProps = {
  meta?: MetaTagsConfig
  title?: string
}
const OnboardingView: FC<PropsWithChildren<PublicViewProps>> = ({ children, meta = {}, title = '' }) => {
  useMetaTags(
    {
      title: `${title} – ${APP_TITLE}`,
      ...meta,
    },
    [title, meta],
  )

  const { pathname } = useLocation()

  const route = onboardingRoutes.find((r) => r.path === pathname)

  const body = route?.body || {}

  useEffect(() => {
    document.body.classList.forEach((className) => {
      document.body.classList.remove(className)
    })
    if (body?.className) {
      const classNames = body.className.split(' ')
      classNames.forEach((className) => {
        document.body.classList.add(className)
      })
    }
  }, [body.className])

  return <div className={'flex flex-col h-screen min-h-[614px]'}>{children}</div>
}

export default OnboardingView
