import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Prev from './Prev'
import Next from './Next'
import Pager from './Pagers'
import Total from './Total'
import Select from '../Select'
import { MultiValue, SingleValue } from 'react-select'

type PaginationProps = {
  total?: number
  displayTotal?: boolean
  pageSize?: number
  pageSizes?: number[]
  currentPage?: number
  onChange?: (pageNumber: number) => void
  onSelectChange?: (pageNumber: number | undefined) => void
  darkHover?: boolean
}

const Pagination = ({
  currentPage = 1,
  displayTotal = false,
  onChange,
  onSelectChange,
  pageSize = 1,
  total = 5,
  pageSizes = [10, 25, 50, 100],
  darkHover = false,
}: PaginationProps) => {
  const [paginationTotal, setPaginationTotal] = useState(total)
  const [internalPageSize, setInternalPageSize] = useState(pageSize)

  const pageSizeOption = useMemo(
    () => pageSizes?.map((number) => ({ value: number, label: `${number} / page` })),
    [pageSizes],
  )

  const getInternalPageCount = useMemo(() => {
    if (typeof paginationTotal === 'number') {
      return Math.ceil(paginationTotal / internalPageSize)
    }
    return 1
  }, [paginationTotal, internalPageSize])

  const getValidCurrentPage = useCallback(
    (count: number) => {
      const value = count
      let internalPageCount = getInternalPageCount
      let resetValue
      if (!internalPageCount) {
        if (isNaN(value) || value < 1) {
          resetValue = 1
        }
      } else {
        if (value < 1) {
          resetValue = 1
        }
        if (value > internalPageCount) {
          resetValue = internalPageCount
        }
      }

      if ((resetValue === undefined && isNaN(value)) || resetValue === 0) {
        resetValue = 1
      }

      return resetValue === undefined ? value : resetValue
    },
    [getInternalPageCount],
  )

  const [internalCurrentPage, setInternalCurrentPage] = useState(currentPage ? getValidCurrentPage(currentPage) : 1)

  useEffect(() => {
    if (total !== paginationTotal) {
      setPaginationTotal(total)
    }

    if (pageSize !== internalPageSize) {
      setInternalPageSize(pageSize)
    }

    if (currentPage !== internalCurrentPage) {
      setInternalCurrentPage(currentPage)
    }
  }, [total, pageSize, currentPage, paginationTotal, internalPageSize, internalCurrentPage])

  const onPaginationChange = (val: number) => {
    setInternalCurrentPage(getValidCurrentPage(val))
    onChange?.(getValidCurrentPage(val))
  }

  function onPageSizeChange<T extends { value: number }>(option: MultiValue<T> | SingleValue<T>) {
    if (!option) return
    const pageSize = 'length' in option ? option[0].value : option.value
    setInternalCurrentPage(getValidCurrentPage(1))
    onChange?.(1)
    onSelectChange?.(pageSize)
  }

  const onPrev = useCallback(() => {
    const newPage = internalCurrentPage - 1
    setInternalCurrentPage(getValidCurrentPage(newPage))
    onChange?.(getValidCurrentPage(newPage))
  }, [onChange, internalCurrentPage, getValidCurrentPage])

  const onNext = useCallback(() => {
    const newPage = internalCurrentPage + 1
    setInternalCurrentPage(getValidCurrentPage(newPage))
    onChange?.(getValidCurrentPage(newPage))
  }, [onChange, internalCurrentPage, getValidCurrentPage])

  const pagerClass = {
    default:
      'relative m-0 p-0 mr-[10px] inline-flex items-center h-[30px] w-[30px] rounded-[50%] justify-center text-sm font-semibold select-none leading-[30px]',
    inactive: `cursor-pointer text-gray-900 hover:bg-${darkHover ? 'gray-100' : 'gray-light'}`,
    active: `cursor-pointer text-white bg-gray-dark hover:bg-gray-dark`,
    disabled: 'cursor-not-allowed text-gray-light',
  }

  const showPagination = getInternalPageCount > 1

  return (
    <div className={'relative z-0 inline-flex items-center'}>
      {displayTotal && <Total total={total} />}
      {showPagination ? (
        <Pager
          onChange={onPaginationChange}
          pageCount={getInternalPageCount}
          currentPage={internalCurrentPage}
          pagerClass={pagerClass}
        />
      ) : null}
      {showPagination && pageSizeOption?.length ? (
        <div className={'min-w-[130px] pr-[15px]'}>
          <Select
            size="xs"
            menuPlacement="bottom"
            isSearchable={false}
            value={pageSizeOption?.filter((option) => option.value === pageSize)?.[0]}
            options={pageSizeOption}
            onChange={onPageSizeChange}
          />
        </div>
      ) : null}
      {showPagination ? (
        <>
          <Prev currentPage={internalCurrentPage} onPrev={onPrev} darkHover={darkHover} />
          <Next
            currentPage={internalCurrentPage}
            pageCount={getInternalPageCount}
            onNext={onNext}
            darkHover={darkHover}
          />
        </>
      ) : null}
    </div>
  )
}
export default Pagination
