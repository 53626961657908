import React, { useRef } from 'react'
import { Button, Icon } from '../../../ui'
import Slide from './SideCarouselSlide'
import Slider from 'react-slick'
import { Slider as SliderEnum, useSlidesQuery } from '../../../../graphql'
import SideBrand from './SideBrand'

const SideCarousel = () => {
  const sliderRef = useRef<Slider | null>(null)
  const settings = {
    infinite: true,
    speed: 750,
    autoplaySpeed: 8000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const { data } = useSlidesQuery({
    variables: {
      where: {
        slider: {
          equals: SliderEnum.Auth,
        },
      },
    },
    onError: (e) => {
      console.log(e)
    },
    onCompleted: (d) => {
      console.log(d)
    },
  })

  return (
    <div className="overflow-hidden relative h-full w-full">
      <SideBrand />
      <Slider ref={sliderRef} {...settings}>
        {data?.slides.list.map((slide, index) => <Slide key={index} {...slide} />)}
      </Slider>
      <div className="absolute right-16 bottom-10 z-20 flex space-x-4">
        <Button
          size={'sm'}
          color={'transparent'}
          variant="solid"
          icon={<Icon name={'chevron-left'} color={'white'} />}
          onClick={() => {
            sliderRef.current?.slickPrev?.()
          }}
        />
        <Button
          size={'sm'}
          color={'transparent'}
          variant="solid"
          icon={<Icon name={'chevron-right'} color={'white'} />}
          onClick={() => {
            sliderRef.current?.slickNext?.()
          }}
        />
      </div>
    </div>
  )
}

export default SideCarousel
