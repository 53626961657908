import React, { useMemo } from 'react'
import JourneyPhase from '../components/JourneyPhase'
import { JourneyPhaseFieldsFragment, JourneyPhasesFieldsFragment, StepProgressionStatus } from '../../../graphql'
import { useLocation } from 'react-router-dom'
import useJourneys from '../../../hooks/useJourneys'

type JourneyViewProps = {
  journey?: JourneyPhasesFieldsFragment
}
const JourneysView: React.FC<JourneyViewProps> = ({ journey }) => {
  const { journeys } = useJourneys()

  const location = useLocation()

  const phases = useMemo(() => {
    const isAll = location.pathname === '/journeys/all'
    const allPhases = isAll ? journeys?.map((j) => j.phases).flat() : journey?.phases
    return allPhases?.reduce<typeof allPhases>((result, item) => {
      const existingItem = result.find((x) => x.id === item.id)
      if (existingItem) {
        const participantIds = new Set()
        existingItem.participants = existingItem.participants
          .concat(item.participants.filter((p) => !existingItem.participants.find((ep) => ep.id === p.id)))
          .filter(({ id }) => !participantIds.has(id) && participantIds.add(id))
        if (item.id === '3') {
          console.log(item.id)
        }
        existingItem.steps = existingItem.steps
          .concat(item.steps.filter((s) => !existingItem.steps.find((es) => es.id === s.id)))
          .sort((a, b) => {
            if (a.status === StepProgressionStatus.InProgress) {
              return -1
            } else if (a.status === StepProgressionStatus.Complete) {
              return 1
            }
            return 0
          })
      } else {
        result.push({
          ...item,
        })
      }
      return result
    }, [])
  }, [location.pathname, journeys, journey?.phases])

  return (
    <div className={'flex w-full justify-center items-center flex-col'}>
      <h6>{location.pathname === '/journeys/all' ? 'PHASES' : 'PHASES FOR THIS JOURNEY'}</h6>
      <div className={'pt-[10px] pb-[50px] w-full'}>
        {phases?.map((phase: JourneyPhaseFieldsFragment, index: number) => {
          return <JourneyPhase key={index} phase={phase} />
        })}
      </div>
    </div>
  )
}

export default JourneysView
