import usePrograms from './usePrograms'
import {
  useTasksQuery,
  TasksDocument,
  useCreateTaskMutation,
  CreateTaskDataInput,
  useUpdateTaskMutation,
  UpdateTaskDataInput,
  useDeleteTaskMutation,
  PersonDetailDocument,
} from '../graphql'
import { useApolloClient, WatchQueryFetchPolicy } from '@apollo/client'
import { useMatch } from 'react-router-dom'
import usePersonDetail from './usePersonDetail'

type UseTasksParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  programId?: string
}

const useTasks = (
  { fetchPolicy = 'cache-first', programId }: UseTasksParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const { selectedProgram } = usePrograms()
  const { person } = usePersonDetail()
  const connectionMatch = useMatch('/connection/:connectionId/*')
  const connectionId = connectionMatch?.params.connectionId

  const client = useApolloClient()

  const variables = {
    where: {
      program: {
        id: {
          equals: programId || selectedProgram?.id || '',
        },
      },
    },
  }

  const { data, loading } = useTasksQuery({
    variables,
    fetchPolicy,
  })

  const taskRefetchQuery = { query: TasksDocument, variables }
  const personDetailRefetchQuery = { query: PersonDetailDocument, variables: { id: person?.id, connectionId } }

  const [createTaskMutation, { loading: creating }] = useCreateTaskMutation({
    refetchQueries: person?.id && connectionId ? [taskRefetchQuery, personDetailRefetchQuery] : [taskRefetchQuery],
    awaitRefetchQueries: true,
  })

  const [updateTaskMutation, { loading: updating }] = useUpdateTaskMutation()

  const [deleteTaskMutation, { loading: deleting }] = useDeleteTaskMutation({
    refetchQueries: person?.id && connectionId ? [taskRefetchQuery, personDetailRefetchQuery] : [taskRefetchQuery],
  })

  const createTask = async (task: CreateTaskDataInput) => {
    await createTaskMutation({
      variables: {
        data: {
          ...task,
          program: {
            connect: {
              id: programId || selectedProgram?.id || '',
            },
          },
        },
      },
    })
  }

  const saveTask = async (taskId: string, task: UpdateTaskDataInput) => {
    await updateTaskMutation({
      variables: {
        where: {
          id: taskId,
        },
        data: task,
      },
    })
  }

  const toggleTaskStatus = (taskId: string) => {
    const task = data?.tasks?.list.find((task) => task.id === taskId)
    if (task) {
      client.cache.modify({
        id: client.cache.identify(task),
        fields: {
          complete: () => !task.complete,
        },
      })

      updateTaskMutation({
        variables: {
          where: {
            id: taskId,
          },
          data: {
            name: task.name,
            dueAt: task.dueAt,
            relatedPerson: { connect: task?.relatedPerson?.id ? { id: task.relatedPerson.id } : undefined },
            complete: !task.complete,
            completedAt: !task.complete ? new Date() : null,
          },
        },
      })
    } else {
      // Todo: Handle error when taskId is not found while trying to markTaskAsComplete?
    }
  }

  const removeTask = (taskId: string) => {
    // Remove tasks
    const task = data?.tasks?.list.find((task) => task.id === taskId)
    if (task) {
      client.cache.evict({
        id: client.cache.identify(task),
      })
      client.cache.gc()

      deleteTaskMutation({
        variables: {
          where: {
            id: taskId,
          },
        },
      })
    } else {
      // Todo: Handle error when taskId is not found while trying to removeTask?
    }
  }

  return {
    tasks: data?.tasks && { ...data?.tasks, list: [...data.tasks.list] },
    loading,
    createTask,
    creating,
    saveTask,
    toggleTaskStatus,
    updating,
    removeTask,
    deleting,
  }
}

export default useTasks
