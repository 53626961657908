import { useJourneyPhasesLazyQuery } from '../graphql'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { selectJourneys, setSelectedJourney } from '../store/journey/journeysSlice'
import useRouteId from './useRouteId'
import { useEffect, useMemo } from 'react'
import usePrograms from './usePrograms'
import { useNavigate } from 'react-router'
import { usePrevious } from '../utils/hooks/usePrevious'

type UseJourneyParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  id?: string
  programId?: string
  lazy?: boolean
}

const useJourney = (
  { fetchPolicy = 'cache-first', id: _id, programId, lazy = false }: UseJourneyParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const dispatch = useDispatch()
  const { selected } = useSelector(selectJourneys)
  const { selectedProgram } = usePrograms()

  const previousSelectedProgram = usePrevious(selectedProgram)

  const navigate = useNavigate()

  const { id } = useRouteId({
    baseRoute: '/journeys',
    selected: selected ? selected : undefined,
    updateSelected: (selected) => {
      dispatch(setSelectedJourney(selected))
    },
  })

  const [journeyPhasesQuery, { data, loading }] = useJourneyPhasesLazyQuery({
    fetchPolicy,
  })

  const query = useMemo(() => {
    return () => {
      return journeyPhasesQuery({
        variables: {
          where: {
            id: _id || id || '',
            program: {
              id: {
                equals: programId || selectedProgram?.id || '',
              },
            },
          },
        },
      })
    }
  }, [journeyPhasesQuery, id, _id, programId, selectedProgram?.id])

  useEffect(() => {
    if (!lazy) {
      query()
    }
  }, [lazy, query, _id, id, fetchPolicy])

  useEffect(() => {
    if (data) {
      dispatch(
        setSelectedJourney({
          name: data.journey.name,
          id: data.journey.id,
        }),
      )
    }
  }, [data, dispatch])

  useEffect(() => {
    if (selectedProgram && previousSelectedProgram && selectedProgram.id !== previousSelectedProgram?.id) {
      navigate('/journeys/all')
    }
  }, [previousSelectedProgram, dispatch, navigate, previousSelectedProgram?.id, selectedProgram, selectedProgram?.id])

  return { journey: data?.journey, query, loading }
}

export default useJourney
