import React, { FC } from 'react'
import SignInForm from './SignInForm'
import { Brand } from '../../../components/ui'

const SignIn: FC = () => {
  return (
    <div className={'flex flex-col justify-center h-screen min-h-[614px]'}>
      <Brand type={'portrait'} className={'hidden w-[115px] mb-[23px] self-center lg:block'} />
      <SignInForm />
    </div>
  )
}

export default SignIn
