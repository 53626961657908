import React, { FC } from 'react'
import UtilityToggle from '../../../components/layout/components/Header/HeaderItem/UtilityToggle'

const UtilityToggleExample: FC = () => {
  return (
    <div className={'flex flex-col space-y-4 p-4'}>
      <div>
        <h2 className={'text-lg font-semibold'}>Utility Toggle Examples</h2>
      </div>
      <div>
        <h3 className={'text-md font-medium'}>Default Size</h3>
        <UtilityToggle size={'24'} />
      </div>
      <div>
        <h3 className={'text-md font-medium'}>Large Size</h3>
        <UtilityToggle size={'40'} />
      </div>
      <div>
        <h3 className={'text-md font-medium'}>Custom Color</h3>
        <UtilityToggle size={'24'} color={'red-400'} />
      </div>
    </div>
  )
}

export default UtilityToggleExample
