import React, { FC } from 'react'
import useOrganization from '../../../../../hooks/useOrganization'
import ViewHeader from '../../../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../../../components/shared/BreadcrumbBar'
import { Text } from '../../../../../components/ui'
import useProgramDetail from '../../../../../hooks/useProgramDetail'
import JoinedBadge from '../../../../../components/shared/JoinedBadge'
import useRouteId from '../../../../../hooks/useRouteId'
import formatRouteId from '../../../../../utils/formatRouteId'

const ProgramHeader: FC = () => {
  const organization = useOrganization()
  const { program } = useProgramDetail()

  const { name, routeId } = useRouteId({
    baseRoute: '/program',
    selected: program,
  })

  const { routeId: orgRouteId } = formatRouteId(organization?.name, organization?.id)

  return (
    <div className={'flex flex-col gap-[25px]'}>
      <ViewHeader
        start={[
          <BreadcrumbBar
            routeTitleMap={{
              organization: organization?.name,
              program: program?.name || name,
            }}
            routePathMap={{
              organization: `/organization/${orgRouteId}`,
              program: `/program/${routeId}`,
            }}
          />,
        ]}
      />
      <div className={'flex justify-center pl-[16px] pr-[16px] sm:pl-[20px] sm:pr-[20px] md:pl-[25px] md:pr-[25px]'}>
        <div className={'flex flex-col grow max-w-[540px] gap-[10px]'}>
          <div className={'flex gap-[10px]'}>
            <Text className={'flex-1'} variant={'h2'}>
              {program?.name}
            </Text>
            {program?.member?.id && <JoinedBadge bgColor={'white'} />}
          </div>
          <p>Manage this program's settings, journeys, and members</p>
        </div>
      </div>
    </div>
  )
}

export default ProgramHeader
