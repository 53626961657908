import React from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { isMonthInRange } from '../utils'
import Header from './Header'
import Month from './components/Month'
import capitalize from '../../../../utils/capitalize'
import { CalendarModifiers } from '../Calendar'
import useTheme from '../../../../hooks/useTheme'
import { DayProps } from './components/utils/getDayProps'

function formatMonthLabel({ month, locale, format }: { month: Date; locale: string; format: string }) {
  return capitalize(dayjs(month).locale(locale).format(format))
}

type DateTableProps = {
  value: Date
  dateViewCount?: number
  paginateBy?: number
  month: Date
  locale: string
  minDate?: Date
  maxDate?: Date
  enableHeaderLabel?: boolean
  daysRefs: React.RefObject<HTMLButtonElement[][][]>
  onMonthChange: (date: Date) => void
  onNextLevel: (level: 'month' | 'year') => void
  onDayKeyDown: (index: number, payload: KeyDownPayload, event: React.KeyboardEvent) => void
  labelFormat: { weekday: string; month: string; year: string }
  preventFocus: boolean
  renderDay: (date: Date) => React.ReactNode
  dayClassName: (date: Date, modifiers: CalendarModifiers) => string
  dayStyle: (date: Date, props: DayProps) => React.CSSProperties
  onChange: (date: Date) => void
  disableOutOfMonth?: boolean
  disableDate: (date: Date) => boolean
  hideWeekdays?: boolean
  firstDayOfWeek?: 'monday' | 'sunday'
  className?: string
  range?: [Date, Date]
  onDayMouseEnter?: (date: Date) => void
  hideOutOfMonthDates?: boolean
  isDateFirstInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  isDateInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  isDateLastInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  weekendDays: number[]
}

const DateTable = (props: DateTableProps) => {
  const {
    dateViewCount = 1,
    paginateBy = dateViewCount,
    month,
    locale,
    minDate,
    maxDate,
    enableHeaderLabel = true,
    daysRefs,
    onMonthChange,
    onNextLevel,
    onDayKeyDown,
    className,
    labelFormat,
    preventFocus,
    renderDay,
    ...rest
  } = props

  const nextMonth = dayjs(month).add(dateViewCount, 'months').toDate()
  const previousMonth = dayjs(month).subtract(1, 'months').toDate()

  const { color, colorLevel } = useTheme()

  const pickerHeaderLabelClass = `picker-header-label hover:text-${color}-${colorLevel}`

  const months = Array(dateViewCount)
    .fill(0)
    .map((_, index) => {
      const monthDate = dayjs(month).add(index, 'months').toDate()
      return (
        <div className="day-picker" key={index}>
          <Header
            hasNext={index + 1 === dateViewCount && isMonthInRange({ date: nextMonth, minDate, maxDate })}
            hasPrevious={index === 0 && isMonthInRange({ date: previousMonth, minDate, maxDate })}
            onNext={() => onMonthChange(dayjs(month).add(paginateBy, 'months').toDate())}
            onPrevious={() => onMonthChange(dayjs(month).subtract(paginateBy, 'months').toDate())}
            preventFocus={preventFocus}
            renderCenter={dateViewCount > 1}>
            <div>
              <button
                type={'button'}
                className={classNames(pickerHeaderLabelClass)}
                disabled={!enableHeaderLabel}
                onClick={() => onNextLevel('month')}
                tabIndex={index > 0 ? -1 : 0}
                onMouseDown={(event) => preventFocus && event.preventDefault()}>
                {formatMonthLabel({ month: monthDate, locale, format: labelFormat?.month || 'MMM' })}
              </button>
              <button
                type={'button'}
                className={classNames(pickerHeaderLabelClass)}
                disabled={!enableHeaderLabel}
                onClick={() => onNextLevel('year')}
                tabIndex={index > 0 ? -1 : 0}
                onMouseDown={(event) => preventFocus && event.preventDefault()}>
                {formatMonthLabel({ month: monthDate, locale, format: labelFormat?.year || 'YYYY' })}
              </button>
            </div>
          </Header>
          <Month
            month={monthDate}
            daysRefs={daysRefs.current ? daysRefs.current[index] : []}
            onDayKeyDown={(...args) => onDayKeyDown(index, ...args)}
            minDate={minDate}
            maxDate={maxDate}
            className={className}
            locale={locale}
            focusable={index === 0}
            preventFocus={preventFocus}
            renderDay={renderDay}
            labelFormat={labelFormat}
            {...rest}
          />
        </div>
      )
    })

  return <>{months}</>
}

export default DateTable
