import React, { FC } from 'react'
import { Alert, Button, Dialogue, Form, Input, Text } from '../../../components/ui'
import { Field, Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import * as Yup from 'yup'
import usePersonDetail from '../../../hooks/usePersonDetail'
import ProfileImageUpload from '../../../components/shared/ProfileImageUpload'
import { generateHash } from '../../../utils/crypto'
import useOrganization from '../../../hooks/useOrganization'

type UpdatePersonDetailNameProps = {
  open?: boolean
  onClose?: () => void
}

type UpdatePersonDetailNameFields = {
  firstName: string
  middleName?: string
  lastName?: string
  image?: {
    name: string
    blob: File
    src: string
    key: string
  }
}

const UpdatePersonDetailName: FC<UpdatePersonDetailNameProps> = ({ open = false, onClose }) => {
  const { person } = usePersonDetail()
  const organization = useOrganization()
  const { updatePersonDetailName, updating } = usePersonDetail()
  const errorMessage = null

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
  })

  return (
    <Dialogue isOpen={open} className={'bg-gray-900 bg-opacity-30'} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={
          {
            firstName: person?.firstName || '',
            middleName: person?.middleName || '',
            lastName: person?.lastName || '',
            image: person?.profileImage?.url
              ? { name: '', blob: null, src: person.profileImage.url, key: '' }
              : undefined,
          } as UpdatePersonDetailNameFields
        }
        onSubmit={async (data) => {
          const { image, ...rest } = data
          await updatePersonDetailName({
            ...rest,
            profileImage:
              image?.blob && organization
                ? {
                    create: {
                      hash: await generateHash(image.blob),
                      ext: image.blob.type.split('/')[1],
                      mime: image.blob.type,
                      size: image.blob.size,
                      name: image.name,
                      url: image.src,
                      folder: organization.id,
                      folderPath: image.key.replace(image.name, ''),
                    },
                  }
                : undefined,
          })
          onClose?.()
        }}>
        {({ errors, touched, getFieldProps, values }) => (
          <div id="add-connection-modal" className="w-[339px] self-center justify-self-center">
            <Form.Container className={'!p-0'}>
              <Text variant={'h2'} className={'leading-[22px]'}>
                Update Name
              </Text>
              <Form.Item
                label={'First Name'}
                errorMessage={touched.firstName && errors.firstName ? errors.firstName : undefined}
                invalid={touched.firstName && !!errors.firstName}>
                <Input
                  size={'sm'}
                  type={'firstName'}
                  className={'bg-gray-50'}
                  placeholder={''}
                  invalid={touched.firstName && !!errors.firstName}
                  {...getFieldProps('firstName')}
                />
              </Form.Item>
              <Form.Item label={'Middle Name'}>
                <Input
                  size={'sm'}
                  type={'middleName'}
                  className={'bg-gray-50'}
                  placeholder={''}
                  {...getFieldProps('middleName')}
                />
              </Form.Item>
              <Form.Item
                label={'Last Name'}
                errorMessage={touched.lastName && errors.lastName ? errors.lastName : undefined}
                invalid={touched.lastName && !!errors.lastName}>
                <Input
                  size={'sm'}
                  type={'lastName'}
                  placeholder={''}
                  className={'bg-gray-50'}
                  invalid={touched.lastName && !!errors.lastName}
                  {...getFieldProps('lastName')}
                />
              </Form.Item>
              <Field name={'image'} component={ProfileImageUpload} />
              <AnimatePresence>{errorMessage && <Alert type={'danger'} label={errorMessage} />}</AnimatePresence>
              <div className={`flex flex-col justify-center gap-5`}>
                <Button
                  disabled={
                    updating ||
                    !values.firstName.trim() ||
                    (values.firstName.trim() === person?.firstName &&
                      values.middleName?.trim() === person?.middleName &&
                      values.lastName?.trim() === person?.lastName &&
                      person?.profileImage?.url === values.image?.src)
                  }
                  loading={updating}
                  type={'submit'}
                  size={'md'}
                  label={'Save'}
                  block
                  variant={'solid'}
                />
                <Button
                  type={'button'}
                  size={'md'}
                  label={'Cancel'}
                  block
                  variant={'default'}
                  onClick={() => onClose?.()}
                />
              </div>
            </Form.Container>
          </div>
        )}
      </Formik>
    </Dialogue>
  )
}

export default UpdatePersonDetailName
