import { JourneysDropdownDocument, JourneyPhasesDocument, JourneysDocument } from '../../graphql'
const people = [
  {
    __typename: 'ProgramMember',
    id: '1',
    email: 'john.doe@email.com',
    profileImage: {
      id: '2',
      url: 'https://randomuser.me/api/portraits/men/1.jpg',
      __typename: 'Media',
    },
    person: {
      id: '2',
      firstName: 'John',
      lastName: 'Doe',
      __typename: 'Person',
    },
  },
  {
    __typename: 'ProgramMember',
    id: '2',
    email: 'jane.doe@email.com',
    profileImage: {
      id: '4',
      url: 'https://randomuser.me/api/portraits/women/1.jpg',
      __typename: 'Media',
    },
    person: {
      id: '4',
      firstName: 'Jane',
      lastName: 'Doe',
      __typename: 'Person',
    },
  },
  {
    __typename: 'ProgramMember',
    id: '3',
    email: 'charles.smith@email.com',
    profileImage: {
      id: '6',
      url: 'https://randomuser.me/api/portraits/men/2.jpg',
      __typename: 'Media',
    },
    person: {
      id: '5',
      firstName: 'Charles',
      lastName: 'Smith',
      __typename: 'Person',
    },
  },
  {
    __typename: 'ProgramMember',
    id: '4',
    email: 'stacy.smith@email.com',
    profileImage: {
      id: '7',
      url: 'https://randomuser.me/api/portraits/women/2.jpg',
      __typename: 'Media',
    },
    person: {
      id: '6',
      firstName: 'Stacy',
      lastName: 'Smith',
      __typename: 'Person',
    },
  },
]

const journeyMocks = [
  // Queries
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: JourneysDropdownDocument,
      variables: {
        programId: '1',
      },
    },
    result: {
      data: {
        journeys: {
          list: [
            {
              id: '1',
              name: 'Small Group Leaders',
              __typename: 'Journey',
            },
            {
              id: '2',
              name: 'Elder Training',
              __typename: 'Journey',
            },
          ],
          total: 2,
          hasMore: false,
          __typename: 'Journeys',
        },
      },
    },
  },
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: JourneyPhasesDocument,
      variables: {
        where: {
          id: '1',
        },
      },
    },
    result: {
      data: {
        journey: {
          id: '1',
          name: 'Small Group Leaders',
          description:
            'This journey is a specific collection of steps, or path, that small group leaders must complete as they move through their training phase of the program.',
          information:
            '<h2>Leader Guide</h2><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit.</p>',
          phases: [
            {
              __typename: 'JourneyPhase',
              id: '0',
              name: 'Witnessing',
              description:
                'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam',
              color: '#737373',
              icon: null,
              iconName: 'message-square',
              participants: people,
              steps: [
                {
                  id: '1',
                  __typename: 'JourneyStep',
                  name: 'Content Step Title',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  progress: 50,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  status: 'IN_PROGRESS',
                  assignees: [people[0], people[2]],
                  activeParticipants: [people[0], people[2]],
                },
                {
                  __typename: 'JourneyStep',
                  id: '2',
                  name: 'Content Step Title',
                  progress: 50,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  assignees: [people[0]],
                  activeParticipants: [people[0]],
                },
                {
                  id: '3',
                  name: 'Content Step Title',
                  progress: 75,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  __typename: 'JourneyStep',
                  forEveryone: true,
                  totalCompleted: 3,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: people,
                },
                {
                  id: '4',
                  name: 'Task Step Title',
                  progress: 25,
                  status: 'TO_DO',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: people,
                  __typename: 'JourneyStep',
                },
              ],
            },
            {
              __typename: 'JourneyPhase',
              id: '2',
              name: 'Establishing',
              description:
                'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam',
              color: '#2D6769',
              icon: null,
              iconName: 'leaf',
              participants: [people[0], people[3]],
              steps: [
                {
                  id: '1',
                  __typename: 'JourneyStep',
                  name: 'Content Step Title',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  progress: 50,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  status: 'IN_PROGRESS',
                  assignees: [people[0], people[2]],
                  activeParticipants: [people[0], people[2]],
                },
                {
                  __typename: 'JourneyStep',
                  id: '2',
                  name: 'Content Step Title',
                  progress: 50,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  assignees: [people[0]],
                  activeParticipants: [people[0]],
                },
                {
                  id: '3',
                  name: 'Content Step Title',
                  progress: 75,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  __typename: 'JourneyStep',
                  forEveryone: true,
                  totalCompleted: 3,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: people,
                },
                {
                  id: '4',
                  name: 'Task Step Title',
                  progress: 25,
                  status: 'TO_DO',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: people,
                  __typename: 'JourneyStep',
                },
              ],
            },
            {
              __typename: 'JourneyPhase',
              id: '1',
              name: 'Equipping',
              description:
                'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam',
              color: '#EA580C',
              icon: null,
              iconName: 'users',
              participants: people,
              steps: [
                {
                  id: '1',
                  __typename: 'JourneyStep',
                  name: 'Content Step Title',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  progress: 50,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  status: 'IN_PROGRESS',
                  assignees: [people[0], people[2]],
                  activeParticipants: [people[0], people[2]],
                },
                {
                  __typename: 'JourneyStep',
                  id: '2',
                  name: 'Content Step Title',
                  progress: 50,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  assignees: [people[0]],
                  activeParticipants: [],
                },
                {
                  id: '3',
                  name: 'Content Step Title',
                  progress: 75,
                  status: 'IN_PROGESS',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  __typename: 'JourneyStep',
                  forEveryone: true,
                  totalCompleted: 3,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                },
                {
                  id: '4',
                  name: 'Task Step Title',
                  progress: 25,
                  status: 'IN_PROGRESS',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '5',
                  name: 'Task Step Title',
                  progress: 0,
                  status: 'TO_DO',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 0,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '6',
                  name: 'Series Step Title',
                  progress: 0,
                  status: 'TO_DO',
                  type: 'SERIES',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 0,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '7',
                  name: 'Content Step Title',
                  progress: 0,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  totalCompleted: 0,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '8',
                  name: 'Content Step Title',
                  progress: 0,
                  status: 'COMPLETE',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  totalCompleted: 4,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '9',
                  name: 'Content Step Title',
                  progress: 0,
                  status: 'COMPLETE',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  forEveryone: true,
                  totalCompleted: 4,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '10',
                  name: 'Task Step Type',
                  progress: 0,
                  status: 'COMPLETE',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 4,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
              ],
            },
            {
              __typename: 'JourneyPhase',
              id: '3',
              name: 'In-Depth Training',
              description:
                'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam',
              color: '#A21CAF',
              icon: null,
              iconName: 'training',
              participants: [people[1]],
              steps: [
                {
                  id: '1',
                  __typename: 'JourneyStep',
                  name: 'Content Step Title',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  progress: 50,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  status: 'IN_PROGRESS',
                  assignees: [],
                  activeParticipants: [],
                },
                {
                  __typename: 'JourneyStep',
                  id: '2',
                  name: 'Content Step Title',
                  progress: 50,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  assignees: [],
                  activeParticipants: [],
                },
                {
                  id: '3',
                  name: 'Content Step Title',
                  progress: 75,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  __typename: 'JourneyStep',
                  forEveryone: true,
                  totalCompleted: 3,
                  totalAssigned: 4,
                  assignees: [],
                  activeParticipants: [],
                },
                {
                  id: '4',
                  name: 'Task Step Title',
                  progress: 25,
                  status: 'TO_DO',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 4,
                  assignees: [],
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
              ],
            },
          ],
          __typename: 'Journey',
        },
      },
    },
  },
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: JourneyPhasesDocument,
      variables: {
        where: {
          id: '2',
        },
      },
    },
    result: {
      data: {
        journey: {
          id: '2',
          name: 'Elder Training',
          description:
            'This journey is a specific collection of steps, or path, that elder candidates must complete as they move through their training phase of the program.',
          information:
            '<h2>Leader Guide</h2><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit.</p>',
          phases: [
            {
              __typename: 'JourneyPhase',
              id: '1',
              name: 'Equipping',
              description:
                'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam',
              color: '#EA580C',
              icon: null,
              iconName: 'users',
              participants: people,
              steps: [
                {
                  id: '1',
                  __typename: 'JourneyStep',
                  name: 'Content Step Title',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  progress: 50,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  status: 'IN_PROGRESS',
                  assignees: [people[0], people[2]],
                  activeParticipants: [people[0], people[2]],
                },
                {
                  __typename: 'JourneyStep',
                  id: '2',
                  name: 'Content Step Title',
                  progress: 50,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  forEveryone: true,
                  totalCompleted: 0,
                  totalAssigned: 1,
                  assignees: [people[0]],
                  activeParticipants: [],
                },
                {
                  id: '3',
                  name: 'Content Step Title',
                  progress: 75,
                  status: 'IN_PROGRESS',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  __typename: 'JourneyStep',
                  forEveryone: true,
                  totalCompleted: 3,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: people,
                },
                {
                  id: '4',
                  name: 'Task Step Title',
                  progress: 25,
                  status: 'IN_PROGRESS',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: people,
                  __typename: 'JourneyStep',
                },
                {
                  id: '5',
                  name: 'Task Step Title',
                  progress: 0,
                  status: 'IN_PROGRESS',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 0,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: people,
                  __typename: 'JourneyStep',
                },
                {
                  id: '6',
                  name: 'Series Step Title',
                  progress: 0,
                  status: 'TO_DO',
                  type: 'SERIES',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 0,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '7',
                  name: 'Content Step Title',
                  progress: 0,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  totalCompleted: 0,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '8',
                  name: 'Content Step Title',
                  progress: 0,
                  status: 'COMPLETE',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  totalCompleted: 4,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '9',
                  name: 'Content Step Title',
                  progress: 0,
                  status: 'COMPLETE',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  forEveryone: true,
                  totalCompleted: 4,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
                {
                  id: '10',
                  name: 'Task Step Type',
                  progress: 0,
                  status: 'COMPLETE',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 4,
                  totalAssigned: 4,
                  assignees: people,
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
              ],
            },
            {
              __typename: 'JourneyPhase',
              id: '3',
              name: 'In-Depth Training',
              description:
                'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam',
              color: '#A21CAF',
              icon: null,
              iconName: 'training',
              participants: [people[1]],
              steps: [
                {
                  id: '1',
                  __typename: 'JourneyStep',
                  name: 'Content Step Title',
                  type: 'CONTENT',
                  contentType: 'TEXT',
                  forEveryone: true,
                  progress: 50,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  status: 'IN_PROGRESS',
                  assignees: [],
                  activeParticipants: [],
                },
                {
                  __typename: 'JourneyStep',
                  id: '2',
                  name: 'Content Step Title',
                  progress: 50,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 2,
                  assignees: [],
                  activeParticipants: [],
                },
                {
                  id: '3',
                  name: 'Content Step Title',
                  progress: 75,
                  status: 'TO_DO',
                  type: 'CONTENT',
                  contentType: 'VIDEO',
                  __typename: 'JourneyStep',
                  forEveryone: true,
                  totalCompleted: 3,
                  totalAssigned: 4,
                  assignees: [],
                  activeParticipants: [],
                },
                {
                  id: '4',
                  name: 'Task Step Title',
                  progress: 25,
                  status: 'TO_DO',
                  type: 'TASK',
                  contentType: null,
                  forEveryone: true,
                  totalCompleted: 1,
                  totalAssigned: 4,
                  assignees: [],
                  activeParticipants: [],
                  __typename: 'JourneyStep',
                },
              ],
            },
          ],
          __typename: 'Journey',
        },
      },
    },
  },
]

const journeysMocks = [
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: JourneysDocument,
      variables: {
        programId: '1',
      },
    },
    result: {
      data: {
        journeys: {
          list: [journeyMocks[1].result.data.journey, journeyMocks[2].result.data.journey],
          hasMore: false,
          total: 2,
          __typename: 'Journeys',
        },
      },
    },
  },
]

const retMocks = [...journeysMocks, ...journeyMocks]
export default retMocks
