import { useEffect, JSX } from 'react'
import { setCurrentRouteKey } from '../../../../store/base/commonSlice'
import { useDispatch } from 'react-redux'

type AppRouteProps = {
  children: JSX.Element
  routeKey: string
}

const AppRoute = (props: AppRouteProps) => {
  const { children, routeKey } = props

  const Children = children

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentRouteKey(routeKey))
  }, [routeKey, dispatch])

  return Children
}

export default AppRoute
