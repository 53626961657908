import React from 'react'
import OrganizationHeader from './components/OrganizationHeader'
import OrganizationTabbar from './components/OrganizationTabbar'
import { Route, Routes } from 'react-router-dom'
import View from '../../components/shared/View'
import OrganizationGeneral from './views/OrganizationGeneral'
import OrganizationPrograms from './views/OrganizationPrograms'
import OrganizationPeople from './views/OrganizationPeople'
import ProgramDetail from './views/ProgramDetail'
import useOrganizationDetail from '../../hooks/useOrganizationDetail'

const Organization = () => {
  const { organization, loading } = useOrganizationDetail({
    fetchPolicy: 'cache-and-network',
  })

  // Todo: ensure unauthorized users to an organization route ID get redirected to proper or an unauthorized view or something

  return (
    <View
      contentClassName={'mt-0'}
      loading={loading && !organization}
      header={<OrganizationHeader />}
      tabbar={<OrganizationTabbar />}>
      <Routes>
        <Route path={'/'} element={<OrganizationGeneral />} />
        <Route path={'/programs'} element={<OrganizationPrograms />} />
        <Route path={'/people/*'} element={<OrganizationPeople />} />
      </Routes>
    </View>
  )
}

export default Organization

export { ProgramDetail }
