import React from 'react'
import { BlockEditor } from '../../../components/shared/TipTap/BlockEditor'

const BlockEditorExample: React.FC = () => {
  return (
    <div className={'h-[100vh]'}>
      <BlockEditor />
    </div>
  )
}

export default BlockEditorExample
