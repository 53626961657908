import React, { useEffect } from 'react'
import { useInvitationQuery } from '../../../graphql'
import CreateUserForm from './CreateUserForm'

const AcceptInvitation = () => {
  const { data } = useInvitationQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data) {
      console.log(data)
    }
  }, [data])

  return (
    <div className={'pt-[100px]'}>
      <div className={'flex flex-col mt-[12vh]'}>
        {data?.invitation && !data.invitation?.person?.user ? <CreateUserForm invitation={data.invitation} /> : null}
      </div>
    </div>
  )
}

export default AcceptInvitation
