import React, { FC } from 'react'
import { Icon, Tag } from '../../../components/ui'

const suffixIcon = (
  <Icon
    name={'x-bold'}
    size={20}
    color={'#333132'}
    className={'cursor-pointer transition duration-200 hover:opacity-70'}
  />
)

const TagExample: FC = () => {
  const colors: Array<'blue' | 'fuchsia' | 'green' | 'orange' | 'red' | 'yellow' | 'gray' | 'darkGray'> = [
    'blue',
    'fuchsia',
    'green',
    'orange',
    'red',
    'yellow',
    'gray',
    'darkGray',
  ]

  return (
    <div className={'space-y-4'}>
      {colors.map((color) => (
        <div key={color} className={'flex space-x-4'}>
          <Tag type={'solid'} color={color} prefix suffix={suffixIcon} textColor={true}>
            Tag
          </Tag>
          <Tag type={'solid'} color={color} prefix suffix={suffixIcon} textColor={false}>
            Tag
          </Tag>
          <Tag type={'solid'} color={color} textColor={false}>
            Tag
          </Tag>
          <Tag type={'solid'} color={color} suffix={suffixIcon} textColor={false}>
            Tag
          </Tag>
          <Tag type={'solid'} color={color} prefix textColor={false}>
            Tag
          </Tag>
          <Tag type={'outline'} color={color} prefix suffix={suffixIcon} textColor={true}>
            Tag
          </Tag>
          <Tag type={'outline'} color={color} prefix suffix={suffixIcon} textColor={false}>
            Tag
          </Tag>
          <Tag type={'outline'} color={color} textColor={false}>
            Tag
          </Tag>
          <Tag type={'outline'} color={color} suffix={suffixIcon} textColor={false}>
            Tag
          </Tag>
          <Tag type={'outline'} color={color} prefix textColor={false}>
            Tag
          </Tag>
        </div>
      ))}
    </div>
  )
}

export default TagExample
