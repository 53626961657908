import React from 'react'
import useAuthority from '../../../../hooks/useAuthority'

type AuthorityGuardProps = {
  authority?: UserRole[]
  children: React.ReactNode
}
const AuthorityGuard = (props: AuthorityGuardProps) => {
  const { authority, children } = props

  const roleMatched = useAuthority(authority)

  if (!roleMatched) {
    console.log('access denied')
  }

  // Todo: Create a better Access Denied view experience
  return roleMatched ? children : 'Access Denied'
}

export default AuthorityGuard
