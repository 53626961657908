import React from 'react'
import SessionView from './index'

type SessionData = {
  sessionNumber: number
  sessionTitle: string
  steps: {
    title: string
    questions: { question: string }[]
  }[]
}

const mockSessionData: SessionData = {
  sessionNumber: 1,
  sessionTitle: 'What Is the Purpose of Life?',
  steps: [
    {
      title: 'What is the Purpose of Your Life?',
      questions: [{ question: 'Briefly describe the purpose of your life.' }],
    },
    {
      title: 'Having It All',
      questions: [
        { question: "How do material possessions impact a person's quality of life?" },
        { question: "How do material possessions impact a person's purpose of life?" },
        {
          question:
            "Should net worth, prestige, power, fame, position, success, and status be considered as factor's in defining a person's significance in life? Why or why not?",
        },
      ],
    },
    {
      title: "We're All Wondering",
      questions: [],
    },
    {
      title: 'Why Am I Here?',
      questions: [
        {
          question:
            "It's a Wonderful Life explores the importance of adding value to others. What role does this concept play in shaping your purpose in life?",
        },
      ],
    },
    {
      title: 'A Study In Purpose',
      questions: [
        { question: 'We all leave some type of legacy. What would you be remembered for if you died today?' },
      ],
    },
    {
      title: 'What Will Your Life Add Up To?',
      questions: [{ question: 'Describe what you will regret leaving undone.' }],
    },
    {
      title: 'Made for So Much More',
      questions: [
        { question: 'Read Ephesians 2:4–9. How is God described in this passage?' },
        { question: 'According to this passage, how are we saved?' },
      ],
    },
  ],
}

const SessionExample = () => {
  return <SessionView sessionData={mockSessionData} />
}

export default SessionExample
