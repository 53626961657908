import { Route, Routes } from 'react-router-dom'
import View from '../../components/shared/View'
import StepHeader from './components/StepHeader'
import StepDetail from './views/StepDetail'
import useStepDetail from '../../hooks/useStepDetail'

const Step = () => {
  // Todo: add cache-and-network fetch policy
  const { loading, step } = useStepDetail({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  return (
    <View
      loading={loading && !step}
      contentClassName={'pl-[26px] pr-[26px] sm:pl-[26px] sm:pr-[26px] md:pl-[54px] md:pr-[54px]'}
      //contentClassName={'pl-[4px] sm:pl-[4px] md:pl-[4px] lg:pl-[4px] xl:pr-[4px]'}
      header={<StepHeader />}>
      <Routes>
        <Route path={'/'} element={<StepDetail />} />
      </Routes>
    </View>
  )
}

export default Step
