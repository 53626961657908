import React from 'react'
import classNames from 'classnames'
import Header from './Header'
import { isMonthInRange, getMonthsNames, formatYear } from '../utils'
import useTheme from '../../../../hooks/useTheme'

type MonthTableProps = {
  value: {
    month: number
    year: number
  }
  year: number
  onChange: (month: number) => void
  onYearChange: (year: number) => void
  onNextLevel: () => void
  locale: string
  minDate?: Date
  maxDate?: Date
  preventFocus: boolean
  labelFormat: { weekday: string; month: string; year: string }
  className?: string
}

const MonthTable = (props: MonthTableProps) => {
  const {
    className,
    value,
    onChange,
    locale,
    year,
    onYearChange,
    onNextLevel,
    minDate,
    maxDate,
    preventFocus,
    labelFormat,
    ...rest
  } = props

  const { color, colorLevel } = useTheme()

  const range = getMonthsNames(locale, labelFormat.month)
  const minYear = minDate instanceof Date ? minDate.getFullYear() : undefined
  const maxYear = maxDate instanceof Date ? maxDate.getFullYear() : undefined

  const months = range.map((month, index) => {
    const disabled = !isMonthInRange({ date: new Date(year, index), minDate, maxDate })

    const active = index === value.month && year === value.year

    return (
      <button
        type={'button'}
        key={month}
        onClick={() => onChange(index)}
        className={classNames(
          'year-picker-cell',
          active && !disabled && `bg-${color}-${colorLevel} text-white month-picker-cell-active`,
          !active && !disabled && 'hover:bg-gray-100',
          disabled && 'month-picker-cell-disabled',
        )}
        disabled={disabled}
        onMouseDown={(event) => preventFocus && event.preventDefault()}>
        {month}
      </button>
    )
  })

  return (
    <div className={classNames('month-picker', className)} {...rest}>
      <Header
        label={formatYear(year, labelFormat.year)}
        hasNext={typeof maxYear === 'number' ? year < maxYear : true}
        hasPrevious={typeof minYear === 'number' ? year > minYear : true}
        onNext={() => onYearChange(year + 1)}
        onPrevious={() => onYearChange(year - 1)}
        onNextLevel={onNextLevel}
        nextLabel={'Next year'}
        previousLabel={'Previous year'}
        preventFocus={preventFocus}
      />
      <div className="month-table">{months}</div>
    </div>
  )
}

export default MonthTable
