import { useNavigate } from 'react-router'
import { cache } from '../services/ApolloService'

function useSignOut() {
  const navigate = useNavigate()
  return async () => {
    await cache.reset()
    // For navigate to sign in page to avoid redirect url from existing in url
    navigate('/sign-in')
  }
}

export default useSignOut
