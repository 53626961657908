import React, { FC } from 'react'
import SidePeekToggle from '../../../components/layout/components/Header/HeaderItem/SidePeekToggle'

const SidePeekToggleExample: FC = () => {
  return (
    <div className={'flex flex-col space-y-4 p-4'}>
      <div>
        <h2 className={'text-lg font-semibold'}>Side Peek Toggle Examples</h2>
      </div>
      <div>
        <h3 className={'text-md font-medium'}>Collapsed</h3>
        <SidePeekToggle collapsed />
      </div>
      <div>
        <h3 className={'text-md font-medium'}>Expanded</h3>
        <SidePeekToggle />
      </div>
    </div>
  )
}

export default SidePeekToggleExample
