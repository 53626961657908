import React, { FC } from 'react'
import useOrganization from '../../../hooks/useOrganization'
import ViewHeader from '../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../components/shared/BreadcrumbBar'
import useRouteId from '../../../hooks/useRouteId'

const OrganizationHeader: FC = () => {
  const organization = useOrganization()

  const { name, routeId } = useRouteId({
    baseRoute: '/organization',
  })

  const breadcrumb = (
    <BreadcrumbBar
      routeTitleMap={{
        organization: organization?.name || name,
      }}
      routePathMap={{
        organization: `/organization/${routeId}`,
      }}
    />
  )

  return <ViewHeader start={[breadcrumb]} />
}

export default OrganizationHeader
