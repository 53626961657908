import React, { FC } from 'react'
import { Icon, Text } from '../../../components/ui'
import classNames from 'classnames'
import { PersonDetailQueryResult } from '../../../graphql'
import { phaseStyles } from '../../connections/views/ConnectionsGeneral'

type JourneyStepProps = {
  journey?: NonNullable<PersonDetailQueryResult['data']>['person']['journey']
}

const JourneySteps: FC<JourneyStepProps> = ({ journey }) => {
  if (!journey) return

  const renderJourneyStep = (
    journeyPhase: NonNullable<NonNullable<PersonDetailQueryResult['data']>['person']['journey']>['phases'][0],
  ) => {
    const currentPhase = journey?.currentPhase?.phase?.id
    const phase = journeyPhase?.phase?.name
    const color = journeyPhase?.phase?.color

    const isTailwindColor = (color: string) => !color.startsWith('#') && !color.match(/^[a-z]+$/i)

    const stepsCompleted = journeyPhase?.stepsCompleted || 0
    const totalSteps = journeyPhase?.totalSteps || 0
    const percent = totalSteps ? Math.round((stepsCompleted / totalSteps) * 100) : 0

    const { phaseTextColor } = phaseStyles(phase)

    return (
      <div
        className={classNames(
          'relative flex flex-col bg-white overflow-hidden h-[62px] rounded-[10px]',
          currentPhase !== journeyPhase?.phase?.id ? 'opacity-50' : '',
        )}>
        <div className={`relative w-full h-[5px] overflow-visible`}>
          <div className={`w-full h-[5px] rounded-0 mix-blend-multiply absolute bottom-0 left-0 bg-gray-light`} />
          <div
            className={`absolute left-0 h-[5px] bottom-0 ${isTailwindColor(color) ? `bg-${color}` : color}`}
            style={{
              width: `${percent}%`,
              backgroundColor: isTailwindColor(color) ? undefined : color,
              borderRadius: '0px 5px 5px 0px',
            }}
          />
        </div>
        <div className={'flex items-center self-center w-full h-full justify-between pl-[20px] pr-[20px]'}>
          <div className={'flex items-center gap-2 justify-start'}>
            {journeyPhase?.phase?.iconName && (
              <Icon size={22} color={color} name={journeyPhase?.phase?.iconName as IconName} />
            )}
            <Text variant={'h6'} className={classNames(isTailwindColor(color) ? `text-${color}` : phaseTextColor)}>
              {phase}
            </Text>
          </div>
          <div className={'flex items-center gap-2 justify-end'}>
            {currentPhase === journeyPhase?.phase?.id && (
              <div className={'flex items-center justify-center rounded-[7px] h-[24px] w-[66px] bg-gray-100'}>
                <Text variant={'h5'} className={'leading-[14px] text-gray-dark'}>
                  Current
                </Text>
              </div>
            )}
            <Text
              variant={'p'}
              size={'sm'}
              className={'text-gray-dark'}>{`${stepsCompleted} / ${totalSteps} steps completed`}</Text>
          </div>
        </div>
      </div>
    )
  }

  return journey?.phases?.length ? journey?.phases?.map((phase) => renderJourneyStep(phase)) : null
}

export default JourneySteps
