import { useEffect } from 'react'
import { useProgramDetailQuery } from '../graphql'
import useRouteId from './useRouteId'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { selectProgram, setSelectedProgram } from '../store/program/programSlice'

type UseProgramDetailParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  id?: string
}

const useProgramDetail = (
  { fetchPolicy = 'cache-first', id: _id }: UseProgramDetailParams = { fetchPolicy: 'cache-first' },
) => {
  const dispatch = useDispatch()
  const { selected } = useSelector(selectProgram)

  const { id } = useRouteId({
    baseRoute: '/program',
    selected: selected || undefined,
  })

  const { data, loading } = useProgramDetailQuery({
    variables: {
      where: {
        id: _id || id || '',
      },
    },
    fetchPolicy,
  })

  useEffect(() => {
    if (data) {
      dispatch(
        setSelectedProgram({
          name: data.program.name,
          id: data.program.id,
        }),
      )
    }
  }, [data, dispatch])

  return { program: data?.program, loading }
}

export default useProgramDetail
