import React, { FC } from 'react'
import classNames from 'classnames'

type DialogueBodyProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const DialogueBody: FC<DialogueBodyProps> = ({ children, className }) => (
  <div className={classNames('flex-grow overflow-y-auto text-sm', className)}>{children}</div>
)

export default DialogueBody
