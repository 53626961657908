import React, { ForwardedRef, useRef, forwardRef, useState } from 'react'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import classNames from 'classnames'
import { FieldInputProps, FormikProps } from 'formik'

type EditableTextProps<FormValues> = {
  className?: string
  placeholder?: string
  field?: FieldInputProps<string>
  form?: FormikProps<FormValues>
  name?: string
  value?: string | null
  onChange?: (event: ContentEditableEvent) => void
}

const EditableText = forwardRef(function EditableText<FormValues>(
  { className, placeholder, field, value, form, onChange, name }: EditableTextProps<FormValues>,
  ref: ForwardedRef<HTMLDivElement | null>,
) {
  const divRef = useRef<HTMLDivElement | null>(null)
  const fieldHelpers = form?.getFieldHelpers(name || field?.name || '')

  const textRef = useRef<HTMLDivElement | null>(null)

  const [text, setText] = useState<string | null>(null)

  let _text = field?.value || value || text
  const html = _text || ''
  const noText = _text === null

  const handleOnFocus = () => {
    if (text === null) {
      setText('')
    }
    // This code below is to set the cursor within the content editable
    const range = document.createRange()
    range.selectNodeContents(textRef.current as Node)
    range.collapse(false)
    const selection = window.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)
  }

  return (
    <div className={'flex flex-col relative'}>
      <ContentEditable
        innerRef={(node: HTMLDivElement) => {
          textRef.current = node
          if (typeof ref === 'function') {
            ref(node)
          } else if (ref) {
            ref.current = node
          }
        }}
        className={classNames(
          'whitespace-break-spaces outline-none w-full cursor-text',
          noText && 'opacity-0',
          className,
        )}
        html={html}
        onBlur={async (event) => {
          if (event.target.innerText === '') {
            setText(null)
          }
          await fieldHelpers?.setTouched(true)
        }}
        onFocus={handleOnFocus}
        onChange={async (event) => {
          const _event = { ...event }
          if (event.target.value === '<br>') {
            _event.target.value = ''
          }

          if (divRef?.current) {
            divRef.current.innerHTML = _event.target.value
            _event.target.value = divRef.current.textContent || divRef.current.innerText || ''
          }

          setText(_event.target.value)
          onChange?.(_event)
          await fieldHelpers?.setValue(_event.target.value)
        }}
      />
      <div ref={divRef} className={'hidden'} />
      <div onClick={handleOnFocus} className={classNames('text-gray absolute', !!_text && 'hidden', className)}>
        {placeholder}
      </div>
    </div>
  )
})

export default EditableText
