import React from 'react'
import NewProgramForm from './NewProgramForm'

const NewProgram = () => {
  return (
    <div className={'flex flex-col justify-center h-screen min-h-[614px]'}>
      <NewProgramForm />
    </div>
  )
}

export default NewProgram
