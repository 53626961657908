import React from 'react'
import { ProgramDropdownFieldsFragment } from '../../../../../graphql'
import { Avatar, Icon } from '../../../../ui'

export type ProgramDropdownItemProps = {
  program: ProgramDropdownFieldsFragment
  selected?: boolean
}

const ProgramDropdownItem = ({ program, selected = false }: ProgramDropdownItemProps) => {
  return (
    <div className={`flex items-center flex-1 gap-[10px]`}>
      <Avatar
        size={40}
        className={'border border-gray-light'}
        shape={'rounded'}
        name={program.name}
        src={program.image?.url}
        alt={`${program.name}-icon`}
      />
      <div className={`flex flex-col flex-1 items-start`}>
        <h4 className={'m-0'}>{program.name}</h4>
        <div className={'body-sm text-gray-dark'}>{program.process.name}</div>
      </div>
      {selected && <Icon name={'check'} />}
    </div>
  )
}

export default ProgramDropdownItem
