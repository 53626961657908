import React from 'react'
import Table from '../../../components/ui/Table/Table'
import MyJourneyTableHeader from './MyJourneyTableHeader'
import MyJourneyTableBody from './MyJourneyTableBody'

const MyJourneyTable: React.FC = () => {
  return (
    <Table className={'max-w-[1040px]'}>
      <MyJourneyTableHeader />
      <MyJourneyTableBody />
    </Table>
  )
}

export default MyJourneyTable
