import React, { FC } from 'react'
import Icon, { IconProps } from '../../../../ui/Icon'

interface SidePeekToggleProps extends Omit<IconProps, 'name'> {
  collapsed?: boolean
}

const ComposeButton: FC<SidePeekToggleProps> = ({ collapsed = false, onClick, ...rest }) => {
  return <Icon name={'edit'} {...rest} size={24} />
}

export default ComposeButton
