import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import View from '../../components/shared/View'
import ConnectionsHeader from './components/ConnectionsHeader'
import ConnectionsGeneral from './views/ConnectionsGeneral'
import usePrograms from '../../hooks/usePrograms'
import useRouteId from '../../hooks/useRouteId'
import useConnections from '../../hooks/useConnections'
import formatRouteId from '../../utils/formatRouteId'

const Connections = () => {
  const { selectedProgram } = usePrograms()

  const navigate = useNavigate()

  // Get the programId from the route
  const { id: programId } = useRouteId({
    baseRoute: '/connections',
    selected: selectedProgram,
    updateSelected: (parsed) => {
      console.log('Try to update the selected program', parsed)
    },
  })

  useEffect(() => {
    if (selectedProgram?.id) {
      const { routeId } = formatRouteId(selectedProgram.name, selectedProgram.id)
      navigate(`/connections/${routeId}`)
    }
  }, [selectedProgram?.id, navigate, selectedProgram?.name])

  const { loading, connections } = useConnections({ fetchPolicy: 'cache-and-network', programId })

  return (
    <View loading={loading && !connections} header={<ConnectionsHeader />}>
      <Routes>
        <Route path={'/'} element={<ConnectionsGeneral connections={connections} />} />
      </Routes>
    </View>
  )
}

export default Connections
