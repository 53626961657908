import React, { useMemo } from 'react'
import Tabs from '../../../components/ui/Tabs'
import Icon from '../../../components/ui/Icon'
import { useMatch, useNavigate } from 'react-router-dom'

const defaultTab = 'general'

const OrganizationTabbar = () => {
  const navigate = useNavigate()

  const match = useMatch('/organization/:id/:tab')
  const idMatch = useMatch('/organization/:id')
  const peopleMatch = useMatch('/organization/:id/:tab/:peopleTab')
  const tab = useMemo(() => {
    const tab = match?.params.tab || peopleMatch?.params.tab
    if (!tab) {
      return defaultTab
    }
    return tab
  }, [match?.params.tab, peopleMatch?.params.tab])

  const peopleTab = useMemo(() => {
    const peopleTab = peopleMatch?.params.peopleTab
    if (!peopleTab) {
      return 'members'
    }
    return peopleTab
  }, [peopleMatch?.params.peopleTab])

  const id = idMatch?.params.id || peopleMatch?.params.id || match?.params.id

  return (
    <Tabs
      className={'mb-[15px]'}
      value={tab}
      variant={'pill'}
      onChange={(tab) => {
        if (tab === defaultTab) {
          navigate(`/organization/${id}`)
        } else {
          if (tab === 'people') {
            navigate(`/organization/${id}/people/${peopleTab}`)
          } else {
            navigate(`/organization/${id}/${tab}`)
          }
        }
      }}>
      <Tabs.TabList>
        <Tabs.TabNav value={'general'} icon={<Icon name={'settings'} color={'gray-dark'} />}>
          General
        </Tabs.TabNav>
        <Tabs.TabNav value={'programs'} icon={<Icon name={'book'} color={'gray-dark'} />}>
          Programs
        </Tabs.TabNav>
        <Tabs.TabNav value={'people'} icon={<Icon name={'users'} color={'gray-dark'} />}>
          People
        </Tabs.TabNav>
      </Tabs.TabList>
    </Tabs>
  )
}

export default OrganizationTabbar
