import React, { FC } from 'react'
import useStepDetail from '../../../hooks/useStepDetail'
import { Divider, Icon, ProgressLoader, Text } from '../../../components/ui'
import useAuth from '../../../hooks/useAuth'
import { ViewAs } from '../../../graphql'
import classNames from 'classnames'
import useMyJourney from '../../../hooks/useMyJourney'

const StepInfo: FC = () => {
  const { user } = useAuth()
  const { step } = useStepDetail()
  const { myJourney } = useMyJourney()

  const stepsCompleted = myJourney?.currentPhase?.stepsCompleted || 0
  const totalSteps = myJourney?.currentPhase?.totalSteps || 0

  const progressPercentage = totalSteps ? Math.round((stepsCompleted / totalSteps) * 100) : 0

  console.log('Progress', progressPercentage)

  return user?.viewingAs === ViewAs.Leader ? (
    <div className={'flex flex-col gap-[30px]'}>
      {step && (
        <div className={'flex flex-col gap-[2px]'}>
          <Text className={'text-gray'}>{step?.name || ''}</Text>
          <Text variant={'h3'} className={'mb-[8px]'}>
            Leader Notes
          </Text>
          <div dangerouslySetInnerHTML={step?.leaderInformation ? { __html: step.leaderInformation } : undefined} />
        </div>
      )}
    </div>
  ) : (
    <div className={'flex flex-col gap-[30px]'}>
      <div className={'flex gap-[9px] items-center'}>
        <Icon size={24} name={'trending-up'} />
        <Text variant={'h2'}>My Phase Progress</Text>
      </div>
      <Text variant={'h4'}>Current Phase:</Text>
      <div className={'flex flex-col gap-[20px]'}>
        <ProgressLoader percent={progressPercentage} showInfo={false} />
        <div className={'flex justify-between'}>
          <div
            className={classNames(
              `whitespace-nowrap flex items-center gap-[10px] pl-2.5 pr-2.5 border-2 rounded-[10px] h-[35px] w-fit`,
            )}>
            {step?.phase.iconName && (
              <Icon size={19} color={step?.phase.color} name={step?.phase.iconName as IconName} />
            )}
            <Text variant={'h6'} className={classNames(`leading-[13px] text-${step?.phase.color}`)}>
              {step?.phase.name}
            </Text>
          </div>
          <Text size={'sm'} className={'text-gray'}>{`${stepsCompleted} of ${totalSteps} steps completed`}</Text>
        </div>
      </div>
      {step && (
        <div className={'flex flex-col gap-[2px]'}>
          <Text variant={'h3'} className={'mb-[8px]'}>
            Instructions
          </Text>
          <Divider className={'mt-[1px] mb-[10px]'} />
          <Text>{step?.instructions}</Text>
        </div>
      )}
    </div>
  )
}

export default StepInfo
