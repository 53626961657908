import React, { ReactNode, Children, isValidElement, ReactElement } from 'react'
import BreadcrumbItem, { BreadcrumbItemProps } from './BreadcrumbItem'
import { Dropdown } from '../index'
import classNames from 'classnames'

export type BreadcrumbProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'children'
> & {
  className?: string
  children?: ReactElement<BreadcrumbItemProps>[]
  onClickItem?: (path?: string) => void
}

const Breadcrumb = ({ className, children, onClickItem }: BreadcrumbProps) => {
  const childrenArray = Children.toArray(children).filter((child) =>
    isValidElement(child),
  ) as ReactElement<BreadcrumbItemProps>[]
  const shouldCollapse = childrenArray.length > 3
  let content: ReactNode[] = []

  if (shouldCollapse) {
    const firstItem = childrenArray[0]
    const lastItem = childrenArray[childrenArray.length - 1]
    const itemsForDropdown = childrenArray.slice(1, -1)

    content.push(
      <BreadcrumbItem
        onClick={(path) => {
          onClickItem?.(path)
        }}
        {...firstItem.props}
      />,
    )

    content.push(
      <Dropdown
        key={'breadcrumb-dropdown'}
        className={'text-sm'}
        renderToggle={
          <span className={'cursor-pointer hover:text-gray-700 hover:bg-gray-100 p-1 rounded text-lg'}>...</span>
        }
        trigger={'click'}>
        {itemsForDropdown.map((item, index) => (
          <Dropdown.Item
            key={`dropdown-item-${index}`}
            eventKey={`dropdown-item-${index}`}
            className={'hover:bg-gray-100'}
            title={item.props.title}
            onClick={() => {
              onClickItem?.(item.props.path)
            }}
          />
        ))}
      </Dropdown>,
    )

    content.push(
      <BreadcrumbItem
        onClick={(path) => {
          onClickItem?.(path)
        }}
        {...lastItem.props}
      />,
    )
  } else {
    content = childrenArray.map((child) => <BreadcrumbItem onClick={(path) => onClickItem?.(path)} {...child.props} />)
  }

  return (
    <div className={classNames('inline-flex items-center gap-2', className)}>
      {content.map((item, index) => {
        if (content.length > 1 && index < content.length - 1) {
          return (
            <div className={'flex gap-2'} key={`breadcrumb-item-${index}`}>
              {item}
              <div className={'flex justify-center items-center'}>
                <div className={'text-gray'}>/</div>
              </div>
            </div>
          )
        } else {
          return <div key={`breadcrumb-item-${index}`}>{item}</div>
        }
      })}
    </div>
  )
}

export default Breadcrumb as typeof Breadcrumb & {
  Item: typeof BreadcrumbItem
}
