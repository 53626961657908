import React, { FC } from 'react'
import { Icon, MiniBadge } from '../ui'
import classNames from 'classnames'

type JoinedBadgeProps = {
  bgColor?: string
}

const JoinedBadge: FC<JoinedBadgeProps> = ({ bgColor }) => {
  const tooltipTitle = "You're a member of this program"
  return (
    <MiniBadge
      className={classNames('gap-[2px]', bgColor ? `bg-${bgColor}` : '')}
      tooltip={{ title: tooltipTitle }}
      prefix={<Icon name={'check-bold'} size={12} />}>
      Joined
    </MiniBadge>
  )
}

export default JoinedBadge
