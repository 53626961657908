import { combineReducers } from 'redux'
import base from './base'
import data from './data'
import journey from './journey'
import program from './program'
import layout from './layout'
import upload from './upload/uploadSlice'

const rootReducer = () => (state: any, action: any) => {
  const combinedReducer = combineReducers({
    base,
    data,
    journey,
    program,
    layout,
    upload,
  })
  return combinedReducer(state, action)
}

export default rootReducer
