import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

export type TableSorts = { [index: string]: 'asc' | 'desc' }
export type TableOption<Value> = { value: Value }
export type TableFilter<Value = unknown, Option extends TableOption<Value> = unknown & { value: Value }> =
  | Option[]
  | (Option | null)
export type TableFilters<Value = unknown, Option extends TableOption<Value> = unknown & { value: Value }> = {
  [index: string]: TableFilter<Value, Option>
}
export type TableRow<Row = object> = Row & { id: string | number }

type DataCurrentPageState = {
  index: number
  size: number
}

export type TableState<Row, Filters extends TableFilters = TableFilters> = {
  query: string
  totalRecords: number
  currentPage: DataCurrentPageState
  sorts: TableSorts
  filters: Filters
  selection: TableRow<Row>[]
  search?: string
}

export type DataTableState<Row = unknown, Filters extends TableFilters = TableFilters> = {
  [index: string]: TableState<Row, Filters>
}

export type SetTableFiltersPayload<Filters extends TableFilters> = {
  key: string
  filters: Filters
}

export type SetFilterValuePayload<FilterOption extends { value: unknown }> = {
  key: string
  filterKey: string
  newValue: Draft<TableFilter<FilterOption['value'], FilterOption>>
}

const defaultTableState = {
  query: '',
  totalRecords: 0,
  currentPage: { index: 0, size: 10 },
  sorts: {},
  filters: {},
  selection: [],
}

const tableSlice = createSlice({
  name: 'table',
  initialState: {} as DataTableState,
  reducers: {
    setFilters: function <Row, Filters extends TableFilters>(
      state: Draft<DataTableState<Row>>,
      action: PayloadAction<SetTableFiltersPayload<Filters>>,
    ) {
      let table = state[action.payload.key]
      if (table) {
        table.filters = action.payload.filters
        table.currentPage.index = 0
        state[action.payload.key] = table
      } else {
        state[action.payload.key] = {
          ...defaultTableState,
          filters: action.payload.filters as Draft<Filters>,
        }
      }
    },
    clearFilters: function <Row>(state: Draft<DataTableState<Row>>, action: PayloadAction<string>) {
      state[action.payload] = {
        ...state[action.payload],
        filters: {},
        search: undefined,
        currentPage: { ...state[action.payload]?.currentPage, index: 0 },
      } as Draft<TableState<Row>>
    },
    setFilterValue: function <Row, FilterValue, FilterOption extends { value: FilterValue }>(
      state: Draft<DataTableState<Row>>,
      action: PayloadAction<SetFilterValuePayload<FilterOption>>,
    ) {
      const { key, filterKey, newValue: value } = action.payload
      let table = state[key]
      if (table) {
        table.filters[filterKey] = value
        table.currentPage.index = 0
      } else {
        state[key] = {
          ...defaultTableState,
          filters: { [filterKey]: value },
        }
      }
    },
    setPageIndex: (state, action: PayloadAction<{ key: string; index: number }>) => {
      const { key, index } = action.payload
      let table = state[key]
      if (table) {
        table.currentPage.index = index
        state[key] = table
      } else {
        state[key] = {
          ...defaultTableState,
          currentPage: { ...defaultTableState.currentPage, index },
        }
      }
    },
    setTotalRecords: (state, action: PayloadAction<{ key: string; total: number }>) => {
      const { key, total } = action.payload
      let table = state[key]
      if (table) {
        table.totalRecords = total
        state[key] = table
      } else {
        state[key] = {
          ...defaultTableState,
          totalRecords: total,
        }
      }
    },
    setPageSize: (state, action: PayloadAction<{ key: string; size: number }>) => {
      const { key, size } = action.payload
      let table = state[key]
      if (table) {
        table.currentPage.size = size
        table.currentPage.index = 0
        state[key] = table
      } else {
        state[key] = {
          ...defaultTableState,
          currentPage: { ...defaultTableState.currentPage, size },
        }
      }
    },
    setSearch: (state, action: PayloadAction<{ key: string; search: string }>) => {
      const { key, search } = action.payload
      let table = state[key]
      if (table) {
        table.search = search
        table.currentPage.index = 0
        state[key] = table
      } else {
        state[key] = {
          ...defaultTableState,
          search,
        }
      }
    },
    setSelection: function <Row>(
      state: Draft<DataTableState<Row>>,
      action: PayloadAction<{ key: string; selection: Draft<TableRow<Row>>[] }>,
    ) {
      const { key, selection } = action.payload
      let table = state[key]
      if (table) {
        table.selection = selection
        state[key] = table
      } else {
        state[key] = {
          ...defaultTableState,
          selection,
        }
      }
    },
    selectRow: function <Row>(
      state: Draft<DataTableState<Row>>,
      action: PayloadAction<{ key: string; row: Draft<TableRow<Row>>; selected: boolean }>,
    ) {
      const { key, row, selected } = action.payload
      let table = state[key]
      if (table) {
        if (selected) {
          table.selection.push(row)
        } else {
          table.selection = table.selection.filter((r: { id: string | number }) => r.id !== row.id)
        }
        state[key] = table
      } else {
        state[key] = {
          ...defaultTableState,
          selection: selected ? [row] : [],
        }
      }
    },
  },
})

export type FilterValue<T extends TableFilter> =
  T extends TableFilter<infer V> ? (T extends Array<unknown> ? V[] : V) : never

export type FilterValues<Filters extends TableFilters> = {
  [P in keyof Filters]: FilterValue<Filters[P]>
}

export type FilterOption<T extends TableFilter> = T extends Array<infer O> ? O : T extends object ? T : never

export type TableStateSelection<Row, Filters extends TableFilters> = TableState<Row, Filters> & {
  filterValues: FilterValues<Filters>
}

export function selectTable<Row, Filters extends TableFilters>(key: string) {
  return createSelector(
    (state: { data: { tables: DataTableState<Row, Filters> } }) => {
      const tableState = state.data.tables[key]
      if (!tableState) {
        return { ...defaultTableState, filterValues: [] }
      }

      const table: TableStateSelection<Row, Filters> = {
        ...tableState,
        filterValues: {} as FilterValues<Filters>,
      }
      Object.keys(table.filters).forEach((k: keyof Filters) => {
        const filter = table.filters[k]
        if (filter) {
          if (Array.isArray(filter)) {
            table.filterValues[k] = filter.map((f) => f.value) as FilterValue<Filters[keyof Filters]>
          } else {
            table.filterValues[k] = filter.value as FilterValue<Filters[keyof Filters]>
          }
        }
      })
      return table
    },
    (table) => table as TableStateSelection<Row, Filters>,
  )
}

export const {
  setSearch,
  selectRow,
  setSelection,
  setFilterValue,
  clearFilters,
  setPageSize,
  setPageIndex,
  setTotalRecords,
} = tableSlice.actions

export default tableSlice.reducer
