import { DrawerConfig } from './index'
import StepDrawer from '../../views/step/views/StepDrawer'
import Tasks from '../../views/tasks'
import JourneysInfo from '../../views/journeys/views/JourneysInfo'

const leaderDrawers: DrawerConfig[] = [
  {
    key: 'step-drawer',
    component: <StepDrawer />,
  },
  {
    key: 'utility',
    component: <Tasks />,
  },
  {
    key: 'journey-info',
    component: <JourneysInfo />,
  },
]

export default leaderDrawers
