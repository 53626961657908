import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import useOrganization from './useOrganization'
import usePrograms from './usePrograms'
import useAuth from './useAuth'
import useViewAs from './useViewAs'
import { ViewAs } from '../graphql'

function useAuthority(authority: UserRole[] = [], emptyCheck = false) {
  const organization = useOrganization({
    fetchPolicy: 'cache-and-network',
  })
  const { selectedProgram } = usePrograms()
  const { user } = useAuth()
  const { setViewAs } = useViewAs()

  const userRoles = useMemo(() => {
    const programRoles = selectedProgram?.member?.roles || []
    const memberRole = organization?.member?.role
    return [...programRoles, memberRole]
      .map((role) => {
        if (role) {
          return role.toLowerCase()
        } else {
          return null
        }
      })
      .filter((role) => role) as UserRole[]
  }, [organization?.member?.role, selectedProgram?.member?.roles])

  const userAuthority = [organization?.member?.role?.toLowerCase(), user?.viewingAs?.toLowerCase()].filter(
    (role) => role,
  ) as UserRole[]

  const roleMatched = useMemo(() => {
    return authority.some((role) => userAuthority.includes(role))
  }, [userAuthority, authority])

  const canSwitch = useMemo(() => {
    if (!roleMatched) {
      // The current user authority doesn't match but the user can switch their view to a matching role
      if (authority.includes('apprentice') && userRoles.includes('apprentice')) {
        setViewAs(ViewAs.Apprentice)
        return true
      } else if (authority.includes('leader') && userRoles.includes('leader')) {
        setViewAs(ViewAs.Leader)
        return true
      }
    }
    return false
  }, [setViewAs, userRoles, authority, roleMatched])

  if (canSwitch) {
    return true
  }

  if (isEmpty(authority) || isEmpty(userAuthority) || typeof authority === 'undefined') {
    return !emptyCheck
  }

  return roleMatched
}

export default useAuthority
