import React, { useState } from 'react'
import classNames from 'classnames'
import Header from './Header'
import { getDecadeRange, formatYear } from '../utils'
import useTheme from '../../../../hooks/useTheme'

type YearTableProps = {
  value: number
  onChange: (year: number) => void
  minYear: number
  maxYear: number
  preventFocus: boolean
  labelFormat: { weekday: string; month: string; year: string }
  className?: string
}

const YearTable = (props: YearTableProps) => {
  const { className, value, onChange, minYear, maxYear, preventFocus, labelFormat, ...rest } = props

  const { color, colorLevel } = useTheme()

  const [decade, setDecade] = useState(value)
  const range = getDecadeRange(decade)

  const years = range.map((year) => {
    const disabled = year < minYear || year > maxYear

    const active = year === value

    return (
      <button
        type={'button'}
        key={year}
        onClick={() => onChange(year)}
        disabled={disabled}
        onMouseDown={(event) => preventFocus && event.preventDefault()}
        className={classNames(
          'year-picker-cell',
          active && !disabled && `bg-${color}-${colorLevel} text-white year-picker-cell-active`,
          !active && !disabled && 'hover:bg-gray-100',
          disabled && 'year-picker-cell-disabled',
        )}>
        {formatYear(year, labelFormat.year)}
      </button>
    )
  })

  return (
    <div className={classNames('year-picker', className)} {...rest}>
      <Header
        label={`${formatYear(range[0], labelFormat.year)} – ${formatYear(range[range.length - 1], labelFormat.year)}`}
        hasPrevious={minYear < range[0]}
        hasNext={maxYear > range[range.length - 1]}
        onNext={() => setDecade((current) => current + 10)}
        onPrevious={() => setDecade((current) => current - 10)}
        nextLevelDisabled
        nextLabel={'Next decade'}
        previousLabel={'Previous decade'}
        preventFocus={preventFocus}
      />
      <div className="year-table">{years}</div>
    </div>
  )
}

export default YearTable
