import React, { FC, useEffect, useState } from 'react'
import { Button, Dialogue, Divider, Text, Icon } from '../../../components/ui'
import Select from '../../../components/ui/Select'
import { ProgramMemberRole } from '../../../graphql'
import useOrganizationDetail from '../../../hooks/useOrganizationDetail'

type ManageProgramsProps = {
  open: boolean
  onClose?: () => void
  onSubmit?: () => void
  id?: string
  programs: {
    id: string
    roles: ProgramMemberRole[]
    program: {
      id: string
      name: string
      image?: {
        id: string
        url: string
      } | null
    }
  }[]
  tenantProgramsList: {
    id: string
    name: string
    image?: {
      id: string
      url: string
    } | null
  }[]
}

const programUserRoleOptions = Object?.values(ProgramMemberRole)?.map((role) => {
  return { label: role as any, value: role as any }
})

const ManagePrograms: FC<ManageProgramsProps> = ({ open, onClose, onSubmit, id, programs, tenantProgramsList }) => {
  const { updateProgramUsers, updatingProgramUsers } = useOrganizationDetail()

  const [memberPrograms, setMemberPrograms] = useState<any[]>([])
  const [tenantPrograms, setTenantPrograms] = useState<any[]>([])

  useEffect(() => {
    if (!memberPrograms.length && programs.length) {
      setMemberPrograms(
        programs.map((memberProgram) => {
          return {
            ...memberProgram,
            roles: memberProgram?.roles?.map((role: ProgramMemberRole) => {
              return { label: role, value: role }
            }),
          }
        }),
      )
    }
  }, [memberPrograms.length, programs])

  useEffect(() => {
    setTenantPrograms(tenantProgramsList)
  }, [tenantProgramsList])

  const handleOnClose = () => {
    onClose?.()
    setMemberPrograms([])
    setTenantPrograms([])
  }

  const handleOnSubmit = () => {
    if (!id) return
    const data = memberPrograms?.map((mp) => {
      return {
        id: mp?.id,
        programId: mp?.program?.id,
        roles: mp?.roles?.map((role: { label: string; value: string }) => role.value),
      }
    })
    updateProgramUsers(id, data).then(() => {
      onSubmit?.()
      handleOnClose()
    })
  }

  const tenantProgramOptions = tenantPrograms
    ?.filter((tp) => !memberPrograms.find((mp) => mp?.program?.id === tp.id))
    ?.map((option: any) => {
      return {
        label: (
          <div>
            <div className={'flex items-center gap-[10px] truncate'}>
              {option?.image?.url && (
                <img
                  className={`w-[30px] h-[30px] rounded-[7px]`}
                  src={option?.image?.url}
                  alt={`${option?.name}-icon`}
                />
              )}
              <Text variant={'h4'} className={'truncate'}>
                {option?.name}
              </Text>
            </div>
          </div>
        ),
        value: option.id,
      }
    })

  const renderProgramRow = (
    memberProgram: {
      id: string
      roles: ProgramMemberRole[]
      program: {
        id: string
        name: string
        image?: {
          id: string
          url: string
        } | null
      }
    },
    index: number,
  ) => {
    const program = memberProgram.program

    return (
      <div className={'flex flex-col gap-[10px]'}>
        <div className={'w-full flex justify-between'}>
          {program?.id ? (
            <div className={'flex items-center'}>
              <div className={'flex items-center gap-[10px] truncate'}>
                {program?.image?.url && (
                  <img
                    className={`w-[30px] h-[30px] rounded-[7px]`}
                    src={program?.image?.url}
                    alt={`${program?.name}-icon`}
                  />
                )}
                <Text variant={'h4'} className={'truncate'}>
                  {program?.name}
                </Text>
              </div>
            </div>
          ) : (
            <div className={'w-[225px]'}>
              <Select
                size={'sm'}
                className={'tenant-programs'}
                // @ts-ignore
                options={tenantProgramOptions}
                onChange={(option) => {
                  const currentMemberPrograms = memberPrograms
                  // @ts-ignore
                  currentMemberPrograms[index].program = tenantPrograms?.find((program) => program.id === option?.value)
                  setMemberPrograms(currentMemberPrograms)
                }}
              />
            </div>
          )}
          <div className={'flex items-center gap-[10px]'}>
            <div className={'min-w-[130px]'}>
              <Select
                size={'sm'}
                className={'program-user-roles'}
                isClearable={false}
                isMulti={true}
                // @ts-ignore
                options={programUserRoleOptions}
                // @ts-ignore
                defaultValue={memberProgram.roles}
                onChange={(option) => {
                  const currentMemberPrograms = memberPrograms
                  currentMemberPrograms[index].roles = option
                  setMemberPrograms(currentMemberPrograms)
                }}
              />
            </div>
            <Button
              shape={'rounded'}
              size={'sm'}
              color={'red-400'}
              variant={'plain'}
              disabled={false}
              onClick={() => {
                let currentMemberPrograms = [...memberPrograms]
                currentMemberPrograms.splice(index, 1)
                setMemberPrograms(currentMemberPrograms)
              }}>
              Remove
            </Button>
          </div>
        </div>
        <Divider />
      </div>
    )
  }

  const addProgram = () => {
    let currentMemberPrograms = [...memberPrograms]
    currentMemberPrograms.push({
      id: null,
      roles: [],
      program: {
        id: null,
        name: null,
        image: {
          id: null,
          url: null,
        },
      },
    })
    setMemberPrograms(currentMemberPrograms)
  }

  return (
    <Dialogue width={750} isOpen={open} onRequestClose={handleOnClose}>
      <Dialogue.Header closable onClose={handleOnClose}>
        Manage Programs
      </Dialogue.Header>
      <Dialogue.Body className={'!overflow-visible'}>
        <div className={'flex flex-row items-center w-full gap-[20px] flex-wrap'}>
          <div className={'flex flex-col gap-[10px] w-full'}>
            {memberPrograms?.map((program, index) => renderProgramRow(program, index))}
          </div>
          <Button
            icon={<Icon size={14} name={'plus'} />}
            onClick={addProgram}
            size={'xs'}
            variant={'default'}
            label={'Add program'}
          />
        </div>
      </Dialogue.Body>
      <Dialogue.Footer className={'flex justify-end space-x-2'}>
        <Button shape={'rounded'} size={'sm'} variant={'plain'} isWide={true} onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          shape={'rounded'}
          size={'sm'}
          variant={'solid'}
          isWide={true}
          loading={updatingProgramUsers}
          disabled={updatingProgramUsers}
          onClick={handleOnSubmit}>
          Confirm
        </Button>
      </Dialogue.Footer>
    </Dialogue>
  )
}

export default ManagePrograms
