import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import View from './components/View'
import useAuth from '../../hooks/useAuth'
//import { Role } from '../../graphql'
import AdminLayout from './AdminLayout'
//import LeaderLayout from './LeaderLayout'
//import ApprenticeLayout from './ApprenticeLayout'
import AuthLayout from './AuthLayout'
import OnboardingLayout from './OnboardingLayout'
import { publicRoutes, protectedRoutes } from '../../config/routes'

const Layout = () => {
  const { pathname } = useLocation()

  const { authenticated, user /*, user*/ } = useAuth()

  //const viewingAs = user?.viewingAs
  //const roles: Role[] | undefined = user ? [] : undefined// user?.roles

  const AppLayout = useMemo(() => {
    const pathParts = pathname.split('/')
    let noLayout = false
    if (pathParts.length > 1 && pathParts[1] === 'dev') {
      noLayout = true
    }
    const routeConfig =
      publicRoutes.find((route) => route.path === pathname) || protectedRoutes.find((route) => route.path === pathname)
    if (routeConfig?.layout?.element) {
      return routeConfig.layout.element
    }
    if (!noLayout) {
      if (!authenticated) {
        //} || (!user?.tenants.length && !user?.programs.length)) {
        return AuthLayout
      }
      if (authenticated && (!user?.tenants.length || !user?.programs.length)) {
        return OnboardingLayout
      }
      /*let layout: Role | undefined
      if (roles?.includes(Role.Apprentice)) {
        layout = Role.Apprentice
      }
      if (roles?.includes(Role.Leader)) {
        if (viewingAs === 'leader' || viewingAs === undefined) {
          layout = Role.Leader
        }
      }
      if (roles?.includes(Role.Admin)) {
        if (viewingAs === 'admin' || viewingAs === undefined) {
          layout = Role.Admin
        }
      }*/
      return AdminLayout
      // Todo: fix layout switching logic
      /*if (layout) {
        switch (layout) {
          case Role.Apprentice:
            return ApprenticeLayout
          case Role.Leader:
            return LeaderLayout
          case Role.Admin:
            return AdminLayout
        }
      }*/
    }
    return View
  }, [authenticated, /*roles, viewingAs,*/ pathname, user])

  return <AppLayout />
}

export default Layout
