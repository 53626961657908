import { env } from '../env'

const appConfig = {
  key: 'tms',
  apiPrefix: 'api',
  productSite: 'https://www.oos-studio.com',
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  onboardingEntryPath: '/onboarding',
  issuePath: env.ISSUE_ID ? '/' + env.ISSUE_ID : '',
  api: {
    baseUrl: env.API_BASE_URL,
    graphQLPath: env.API_GRAPHQL_PATH,
  },
  assets: {
    baseUrl: env.ASSET_BASE_URL,
  },
  /*tiptap: {
    appId: process.env.REACT_APP_PUBLIC_TIPTAP_COLLAB_APP_ID,
    collabSecret: process.env.REACT_APP_TIPTAP_COLLAB_SECRET,
  },*/
  mock: env.MOCK,
}

export default appConfig
