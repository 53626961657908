import React, { FC } from 'react'
import { ProgramMemberRole } from '../../../graphql'
import { Avatar, Icon, Tooltip } from '../../../components/ui'
import Dropdown from '../../../components/ui/Dropdown'
import TableTools from '../../../components/ui/Table/TableTools'
import DataTable, { DataTableColumn } from '../../../components/ui/Table/DataTable'
import Text from '../../../components/ui/Text'
import pluralize from 'pluralize'
import useOrganizationDetail from '../../../hooks/useOrganizationDetail'
import formatRouteId from '../../../utils/formatRouteId'
import { useNavigate } from 'react-router-dom'
import AddProgram from './ProgramDetail/views/AddProgram'

type OrganizationProgramDataTableProps = {
  id: string
  title: string
  image?: { url: string } | null
  member: boolean
  process: string
  journeys: { id?: string; name?: string }[]
  members: any[]
}

const OrganizationPrograms: FC = () => {
  const { organization } = useOrganizationDetail()
  const navigate = useNavigate()

  const [openAddProgram, setOpenAddProgram] = React.useState(false)

  const columns: DataTableColumn<OrganizationProgramDataTableProps>[] = [
    {
      header: 'Title',
      accessorKey: 'title',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        return original?.title ? (
          <div className={'flex items-center gap-[10px] min-w-[300]'}>
            <Tooltip delay={1000} title={original?.title} className={'flex items-center gap-[10px] truncate'}>
              {original?.image?.url && (
                <img
                  className={`w-[30px] h-[30px] rounded-[7px]`}
                  src={original?.image?.url}
                  alt={`${original?.title}-icon`}
                />
              )}
              <Text variant={'h4'} className={'truncate'}>
                {original?.title}
              </Text>
            </Tooltip>
            {original?.member && (
              <Tooltip delay={1000} title={'You are a member of this program'}>
                <div
                  className={'bg-gray-200 flex justify-center items-center gap-[2px] py-[3px] px-[8px] rounded-[7px]'}>
                  <Icon name={'check-bold'} size={12} color={'#737373'} />
                  <Text variant={'h5'} className={'tex-gray-dark'}>
                    Joined
                  </Text>
                </div>
              </Tooltip>
            )}
          </div>
        ) : null
      },
    },
    {
      header: 'Process',
      accessorKey: 'process',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        return original?.process ? (
          <div
            className={
              'flex items-center justify-center w-fit rounded-[5px] gap-[6px] pt-[4px] pb-[4px] pl-[10px] pr-[10px] border-[2px] border-[#F3F3F3]'
            }>
            <span className={'font-semibold text-[13px] leading-[13px] tracking-[1.3px]'}>{original?.process}</span>
          </div>
        ) : null
      },
    },
    {
      header: 'Journeys',
      accessorKey: 'journeys',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        return original?.journeys ? (
          <Dropdown
            onSelect={(eventKey) => console.log('Journey', eventKey)}
            title={`${original?.journeys?.length} ${pluralize('Journey', original?.journeys?.length)}`}
            toggleVariant={'plain'}
            toggleClassName={'truncate'}
            placement={'bottom-end'}
            selectable={false}>
            {original.journeys.map((journey, i) => {
              return (
                <Dropdown.Item
                  key={i}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    const { routeId } = formatRouteId(journey.name, journey.id)
                    navigate(`/journey/${routeId}`)
                  }}
                  title={journey?.name}
                  icon={<Icon name={'compass'} size={16} color={'#519D9F'} />}
                  eventKey={`dropdown-journey-item-${i + 1}`}
                />
              )
            })}
          </Dropdown>
        ) : null
      },
    },
    {
      header: 'Leaders',
      accessorKey: 'members',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        const members = (original?.members || []).filter((member) => member?.roles?.includes(ProgramMemberRole.Leader))
        const avatarGroup = members?.map((leader) => (
          <Avatar shape="circle" size="sm" src={leader?.profileImage?.url} name={leader?.person.name} />
        ))
        return avatarGroup?.length ? (
          <Avatar.Group chained maxCount={4} omittedAvatarTooltip omittedAvatarProps={{ shape: 'circle', size: 'sm' }}>
            {avatarGroup}
          </Avatar.Group>
        ) : null
      },
    },
    {
      header: '',
      accessorKey: 'more',
      sortable: false,
      cell: ({ row }) => {
        //const { original } = row
        //const { routeId } = formatRouteId(original.title, original.id)
        return (
          <div className={'flex items-center justify-center'}>
            <Icon name={'chevron-right'} size={20} />
          </div>
        )
        /*return (
          <div className={'flex items-center justify-center'}>
            <Button
              onClick={() => {
                navigate(`/program/${routeId}`)
              }}
              variant={'plain'}
              icon={<Icon name={'chevron-right'} size={20} />}
            />
          </div>
        )*/
      },
    },
  ]

  const data: OrganizationProgramDataTableProps[] = []

  if (organization) {
    const programs = organization?.programs
      ? !Array.isArray(organization?.programs)
        ? [organization?.programs]
        : organization?.programs
      : []
    if (programs?.length) {
      programs?.forEach((program) => {
        data.push({
          id: program.id,
          title: program.name,
          image: program.image,
          process: program.process.name,
          journeys: program.journeys,
          members: program.members?.filter((member) => member?.roles?.includes(ProgramMemberRole.Leader)),
          member: !!program.member?.id,
        })
      })
    }
  }

  return organization ? (
    <div className={'flex justify-center mt-[15px]'}>
      <div className={'flex flex-col w-full max-w-[1200px]'}>
        <TableTools
          tableName={'programs'}
          onSearchInputChange={(val: string) => val}
          search={true}
          viewAll={false}
          addText={'Add program'}
          onClickAdd={() => setOpenAddProgram(true)}
        />
        <DataTable
          name={'programs'}
          columns={columns as DataTableColumn<any>[]}
          data={data}
          total={data.length}
          pagination={true}
          selectable={false}
          sortable={false}
          onRowClick={(row) => {
            const { routeId } = formatRouteId(row.title, row.id)
            navigate(`/program/${routeId}`)
          }}
        />
      </div>
      <AddProgram open={openAddProgram} onClose={() => setOpenAddProgram(false)} />
    </div>
  ) : null
}

export default OrganizationPrograms
