import React, { FC, useState } from 'react'
import { Alert, Button, Dialogue, Form, Icon, Input } from '../../../components/ui'
import { Field, Formik } from 'formik'
import useToast from '../../../hooks/useToast'
import useOrganizationDetail from '../../../hooks/useOrganizationDetail'
import * as Yup from 'yup'
import { useUpdateOrganizationMutation } from '../../../graphql'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

const OrganizationGeneral: FC = () => {
  const { organization } = useOrganizationDetail()
  const toast = useToast()
  const [deleteOrganizationModal, setDeleteOrganizationModal] = useState<{ open: boolean }>({
    open: false,
  })

  const [updateOrganization, { data }] = useUpdateOrganizationMutation({
    onCompleted: (data) => {
      if (data.updateTenant.__typename === 'UpdateTenantSuccess') {
        toast.push(data.updateTenant.message, data.updateTenant.tenant.name, 'success')
      }
    },
    onError: (error) => {
      toast.push('Error', error.message, 'error')
    },
  })

  const showAlert = data?.updateTenant.__typename === 'UpdateTenantError'
  const alertMessage = data?.updateTenant.message

  return (
    !!organization && (
      <div className={'flex justify-center'}>
        <div className={'flex flex-col gap-[25px] grow max-w-[540px]'}>
          <div>
            <h2>{organization.name}</h2>
            <p>Manage your organization's name</p>
          </div>
          <Formik
            initialValues={{
              name: organization.name,
            }}
            validationSchema={validationSchema}
            onSubmit={async ({ name }) => {
              await updateOrganization({
                variables: {
                  where: {
                    id: organization.id,
                  },
                  data: {
                    name,
                  },
                },
              })
            }}>
            {({ isSubmitting, errors, isValid, values }) => (
              <Form>
                <div className={'flex flex-col gap-[25px]'}>
                  <Form.Item
                    label={'Name'}
                    errorMessage={errors.name ? errors.name : undefined}
                    invalid={!!errors.name}>
                    <Field size={'sm'} name={'name'} component={Input} />
                  </Form.Item>
                  <Alert show={showAlert} type={'danger'} label={alertMessage} />
                  <Form.Actions layout={'vertical'}>
                    <Button
                      disabled={values.name.trim() === organization.name || !isValid}
                      type={'submit'}
                      variant={'solid'}
                      size={'sm'}
                      loading={isSubmitting}>
                      Update
                    </Button>
                  </Form.Actions>
                </div>
              </Form>
            )}
          </Formik>
          <div className={'flex flex-col gap-[20px]'}>
            <div>
              <h4>Delete organization</h4>
              <p>
                If you want to permanently delete this organization and all of its data, including but not limited to
                users, programs, and steps, you can do so below.
              </p>
            </div>
            <Button
              icon={<Icon color={'red'} name={'trash-2'} />}
              className={'border-red'}
              color={'red'}
              size={'sm'}
              onClick={() => {
                setDeleteOrganizationModal({
                  open: true,
                })
              }}>
              Delete this organization
            </Button>
          </div>
        </div>
        <Dialogue
          isOpen={deleteOrganizationModal.open}
          width="500px"
          height="185px"
          onRequestClose={() => {
            setDeleteOrganizationModal({
              open: false,
            })
          }}>
          <Dialogue.Header className={'flex justify-between !items-start'}>
            <span
              className={
                'min-w-0'
              }>{`Are you sure you want to permanently delete the organization ${organization.name}?`}</span>
            <div
              onClick={() => {
                setDeleteOrganizationModal({
                  open: false,
                })
              }}
              className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
              <Icon name="x" size={'20'} color={'#333132'} />
            </div>
          </Dialogue.Header>
          <Dialogue.Body>This action cannot be undone.</Dialogue.Body>
          <Dialogue.Footer className={'flex justify-end space-x-2'}>
            <Button
              shape={'rounded'}
              size={'sm'}
              variant={'plain'}
              isWide={true}
              onClick={() => {
                setDeleteOrganizationModal({
                  open: false,
                })
              }}>
              Cancel
            </Button>
            <Button
              shape={'rounded'}
              size={'sm'}
              variant={'solid'}
              color={'red-400'}
              isWide={true}
              onClick={() => {
                setDeleteOrganizationModal({
                  open: false,
                })
              }}>
              Delete organization
            </Button>
          </Dialogue.Footer>
        </Dialogue>
      </div>
    )
  )
}

export default OrganizationGeneral
