import React from 'react'
import { Alert, Button, Form, Input } from '../../../components/ui'
import { OnboardingFlow, TenantType, TenantUserRole, useRegisterTenantMutation } from '../../../graphql'
//import AvatarGroup from '../../../components/ui/Avatar/AvatarGroup'
import { Field, Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import * as Yup from 'yup'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

/*const footerButton = (color: string) => {
  return (
    <div className={'ml-auto'}>
      <Button color={color} size={'md'} iconLocation={'right'} label={'Join'} variant={'plain'} />
    </div>
  )
}

type OrgnizationInviteCardProps = {
  invitation: MemberInvitationFieldsFragment
  onClick: () => void
}

const OrgnizationInviteCard = ({ invitation, onClick }: OrgnizationInviteCardProps) => {
  return (
    <div className={'w-[279px]'}>
      <Card
        headerIcon={<Icon name={'briefcase'} />}
        header={(
          <div className={'text-left ml-3'}>
              {invitation.organization?.name}
          </div>
        )}
        headerDivider={false}
        footer={
          <div className={'flex justify-between w-full items-center'}>
            <AvatarGroup chained maxCount={5} omittedAvatarTooltip omittedAvatarProps={{ size: 'sm'}}>
              <Avatar
                shape={'circle'}
                size={'sm'}
                name={'Colin'}
              />
              <Avatar
                shape={'circle'}
                size={'sm'}
                src={'https://randomuser.me/api/portraits/men/7.jpg'}
                name={'User 1'}
              />
              <Avatar
                shape={'circle'}
                size={'sm'}
                src={'https://randomuser.me/api/portraits/men/7.jpg'}
                name={'User 1'}
              />
              <Avatar
                shape={'circle'}
                size={'sm'}
                src={'https://randomuser.me/api/portraits/men/7.jpg'}
                name={'User 1'}
              />
              <Avatar
                shape={'circle'}
                size={'sm'}
                src={'https://randomuser.me/api/portraits/men/7.jpg'}
                name={'User 1'}
              />
              <Avatar
                shape={'circle'}
                size={'sm'}
                src={'https://randomuser.me/api/portraits/men/7.jpg'}
                name={'User 1'}
              />
              <Avatar
                shape={'circle'}
                size={'sm'}
                src={'https://randomuser.me/api/portraits/men/7.jpg'}
                name={'User 1'}
              />
            </AvatarGroup>
            {footerButton('#428183')}
          </div>
        }
        headerClass={'text-lg font-semibold'}
        footerClass={'text-blue-500 font-bold'}>
      </Card>
    </div>
  )
}*/

const NewOrganizationForm = () => {
  /*const { data: inviteData } = useMemberInvitationsQuery({
    fetchPolicy: 'cache-and-network',
  })*/

  const { user, token, set: setAuth } = useAuth()

  const onboardingFlow = user?.onboardingFlow

  const navigate = useNavigate()

  const [registerTenant, { data, loading }] = useRegisterTenantMutation({
    refetchQueries: ['Me'],
    awaitRefetchQueries: true,
    onCompleted: ({ registerTenant }) => {
      if (registerTenant.__typename === 'RegisterTenantSuccess') {
        if (token && user) {
          setAuth({
            token,
            user: {
              ...user,
              tenants: user.tenants.length
                ? [
                    ...user.tenants,
                    {
                      id: uuid(),
                      tenant: registerTenant.tenant,
                      role: TenantUserRole.Owner,
                    },
                  ]
                : [
                    {
                      id: uuid(),
                      tenant: registerTenant.tenant,
                      role: TenantUserRole.Owner,
                    },
                  ],
            },
          })
        }
        navigate('/new-program')
      }
    },
  })

  const errorMessage = data?.registerTenant.__typename === 'RegisterTenantError' ? data?.registerTenant?.message : null

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  })

  return (
    /*<div className={'text-center'}>
      <h2>Create organization</h2>
      {/*<p className={'body-md'}>We'll tailor your setup accordingly.</p>}
      <div className="h-[44px]" />
      <div className={'flex flex-col justify-center items-center'}>
      {
        // Todo: Finish member invite list
        /*inviteData?.invitations.list.map((invitation) => (
          <OrgnizationInviteCard invitation={invitation} onClick={() => {
            // Skip to program setup step if personal use
            //onClick('program')
          }} />
        ))
      }
      </div>
      <div className={'flex justify-center gap-6 flex-wrap'}>
      </div>
    </div>*/
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: '',
      }}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true)
        await registerTenant({
          variables: {
            data: {
              ...data,
              type: onboardingFlow === OnboardingFlow.Personal ? TenantType.Person : TenantType.Organization,
            },
          },
        })
        setSubmitting(false)
      }}>
      {({ errors, touched }) => (
        <div className="w-full max-w-[319px] self-center justify-self-center">
          <Form.Container>
            <div>
              <h2>New organization</h2>
              {/*<p className={'body-md'}>We'll tailor your setup accordingly.</p>*/}
            </div>
            <Form.Item
              label={"What is your organization's name?"}
              size={'sm'}
              errorMessage={touched.name && errors.name ? errors.name : undefined}
              invalid={touched.name && !!errors.name}>
              <Field size={'sm'} name={'name'} placeholder={'e.g. Lifestone Inc.'} component={Input} />
            </Form.Item>
            <AnimatePresence>{errorMessage && <Alert type={'danger'} label={errorMessage} />}</AnimatePresence>
            <div className={'flex justify-center'}>
              <Button type={'submit'} size={'sm'} label={'Continue'} loading={loading} block variant={'solid'} />
            </div>
          </Form.Container>
        </div>
      )}
    </Formik>
  )
}

export default NewOrganizationForm
