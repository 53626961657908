import React, { FC } from 'react'
import ViewHeader from '../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../components/shared/BreadcrumbBar'
import DrawerButton from '../../../components/shared/DrawerButton'

const JourneysHeader: FC = () => {
  return (
    <ViewHeader
      start={[
        <BreadcrumbBar
          routeTitleMap={{
            journeys: 'Journeys',
          }}
        />,
      ]}
      end={[<DrawerButton drawer={'journey-info'} />]}
    />
  )
}

export default JourneysHeader
