import React, { FC, useMemo } from 'react'
import { ContentStepType, UserStepFieldsFragment } from '../../../graphql'
import Table from '../../../components/ui/Table/Table'
import Icon from '../../../components/ui/Icon'
import StepIcon from '../../../components/shared/StepIcon'
import StatusTag from '../../../components/shared/StatusTag'
import BlockedBadge from '../../../components/shared/BlockedBadge'
import { Link } from 'react-router-dom'
import formatRouteId from '../../../utils/formatRouteId'

type MyJourneyTableRowProps = {
  step: UserStepFieldsFragment
  key: string
  iconColor?: string
}

export const getContentTypeTitlePrefix = (contentType: ContentStepType) => {
  switch (contentType) {
    case ContentStepType.Video:
      return 'Watch'
    case ContentStepType.File:
      return 'Download'
    default:
      return 'Read'
  }
}

const MyJourneyTableRow: FC<MyJourneyTableRowProps> = ({ step, key, iconColor }) => {
  const disabled = step.blocked

  const opacityStyle = disabled ? { opacity: 0.5 } : {}

  const routeId = useMemo(() => {
    const { routeId } = formatRouteId(step?.name, step?.id)
    return routeId
  }, [step?.name, step?.id])

  return (
    <Table.Tr className={!disabled ? 'cursor-pointer' : ''} key={key}>
      <Table.Td colSpan={4} className={'!p-0'}>
        <Link to={`/step/${routeId}`} className={'flex gap-[20px] items-center px-[24px] py-[16px]'}>
          <div style={opacityStyle}>
            <StepIcon color={iconColor} type={step.type} contentType={step.contentType} />
          </div>
          <span className={'flex-1 font-semibold'} style={opacityStyle}>
            {step.contentType ? `${getContentTypeTitlePrefix(step.contentType)}: ` : ''}
            {step.name}
          </span>
          {step.blocked && <BlockedBadge count={step.blockedBy.length} />}
          <div style={opacityStyle}>
            <StatusTag status={step.status} />
          </div>
          <div style={opacityStyle}>
            <Icon name={'chevron-right'} />
          </div>
        </Link>
      </Table.Td>
    </Table.Tr>
  )
}

export default MyJourneyTableRow
