import React, { ElementType, HTMLProps, ReactNode, forwardRef } from 'react'
import classNames from 'classnames'

type THeadProps<HTMLPropsT = HTMLTableSectionElement> = HTMLProps<HTMLPropsT> & {
  asElement?: ElementType
  children?: ReactNode
}

const THead = forwardRef(({ children, asElement: Component = 'thead', className, ...rest }: THeadProps, ref) => {
  const tHeadClass = classNames(Component !== 'thead' && 'thead', 'h-[40px]', className)
  return (
    <Component className={tHeadClass} {...rest} ref={ref}>
      {children}
    </Component>
  )
})

export default THead
