import React from 'react'
import { AssigneesDropdownQueryResult, TenantMemberRole, useAssigneesDropdownLazyQuery } from '../graphql'
import { Avatar, Icon } from '../components/ui'
import Option from '../components/shared/Option'
import usePrograms from './usePrograms'
import useOrganization from './useOrganization'

type AssigneeOption =
  | NonNullable<AssigneesDropdownQueryResult['data']>['apprentices']['list'][0]
  | { id: null; name: string }

type LoadAssigneeOptions = (
  inputValue: string,
  callback: (options: AssigneeOption[]) => void,
) => Promise<AssigneeOption[]>

type UseAssigneeOptionsParams = {
  size?: 'xs' | 'sm'
  emptyOption?: boolean
  programId?: string
}

const useAssigneeOptions = ({ size = 'sm', emptyOption = false, programId }: UseAssigneeOptionsParams = {}) => {
  const { selectedProgram } = usePrograms()
  const [query, { loading }] = useAssigneesDropdownLazyQuery()
  const org = useOrganization()

  const mainIconSize = size === 'xs' ? 18 : 24
  const smallIconSize = size === 'xs' ? 11 : 16
  const fontSize = size === 'xs' ? 'sm' : 'md'

  const placeholder = (
    <Option iconName={'users'} name={'My Connections'} iconSize={mainIconSize} fontSize={fontSize} iconColor={'gray'} />
  )

  const loadOptions: LoadAssigneeOptions = (inputValue) =>
    new Promise<AssigneeOption[]>(async (resolve) => {
      const _programId = programId || selectedProgram?.id
      if (!_programId) {
        return resolve([])
      }
      console.log(inputValue)
      const response = await query({
        variables: {
          programId: _programId,
        },
        fetchPolicy: 'cache-and-network',
      })
      const options: AssigneeOption[] | undefined = [...(response.data?.apprentices?.list || [])]

      if (org?.member?.role === TenantMemberRole.Owner || org?.member?.role === TenantMemberRole.Admin) {
        options.unshift({
          id: 'default',
          name: 'Make default',
        })
      }

      if (options && emptyOption) {
        options.unshift({
          id: null,
          name: 'No assignee',
        })
      }
      resolve(options?.filter((i) => i?.name?.toLowerCase().includes(inputValue.toLowerCase())) || [])
    })

  const circleIcon = <Icon name={'circle'} size={smallIconSize} />
  const slashIcon = <Icon name={'slash'} size={smallIconSize} />

  const formatOptionLabel = (option: AssigneeOption) => {
    if (option.id === 'default') {
      return <Option name={option.name} fontSize={fontSize} prefix={<Avatar size={mainIconSize} icon={circleIcon} />} />
    } else if (option.id) {
      return (
        <Option
          name={option.name}
          fontSize={fontSize}
          prefix={<Avatar name={option.name} size={mainIconSize} src={option.profileImage?.url} />}
        />
      )
    } else {
      return (
        <Option name={'No assignee'} fontSize={fontSize} prefix={<Avatar size={mainIconSize} icon={slashIcon} />} />
      )
    }
  }

  const isOptionSelected = (option: AssigneeOption, selectValue: ReadonlyArray<AssigneeOption | null>) => {
    if (selectValue.length) {
      return !!selectValue?.find((v) => v?.id === option?.id || v === option?.id)
    } else {
      return option.id === null
    }
  }

  const selectProps = {
    loadOptions,
    placeholder,
    formatOptionLabel,
    isOptionSelected,
    isLoading: loading,
  }

  return [selectProps, query] as [typeof selectProps, typeof query]
}

export default useAssigneeOptions
