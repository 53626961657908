import React, { FC } from 'react'
import { Button, Icon, Avatar, Text } from '../../../components/ui'
import Checkbox from '../../../components/ui/Checkbox'
import { TaskFieldsFragment } from '../../../graphql'
import { months } from '../../../utils/dates'
import classNames from 'classnames'

type TaskItemProps = {
  task?: TaskFieldsFragment
  onClick?: (id: string) => void
  onClickCheckbox?: (id: string) => void
  onRemove?: (id: string) => void
  containerClassName?: string
  contentClassName?: string
}

export const renderDueAtLabel = (date: Date | string | undefined | null) => {
  if (!date) return

  let dueAt = new Date(date)
  dueAt = new Date(dueAt.getFullYear(), dueAt.getMonth(), dueAt.getDate())

  let today = new Date()
  today = new Date(today.getFullYear(), today.getMonth(), today.getDate())

  let tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate())

  if (dueAt.getTime() < today.getTime()) {
    return (
      <Text variant={'h6'} className={'text-red whitespace-nowrap'}>
        PAST DUE
      </Text>
    )
  } else if (dueAt.getTime() === today.getTime()) {
    return (
      <Text variant={'h6'} className={'text-orange whitespace-nowrap'}>
        DUE TODAY
      </Text>
    )
  } else if (dueAt.getTime() === tomorrow.getTime()) {
    return (
      <Text variant={'h6'} className={'text-yellow whitespace-nowrap'}>
        DUE TOMORROW
      </Text>
    )
  }

  return (
    <Text variant={'p'} size={'sm'} className={'text-gray-dark whitespace-nowrap'}>
      {months[dueAt.getMonth()] + ' ' + dueAt.getDate()}
    </Text>
  )
}

const TaskItem: FC<TaskItemProps> = ({
  task,
  onClick,
  onClickCheckbox,
  onRemove,
  containerClassName,
  contentClassName,
}) => {
  if (!task) return

  const handleOnClick = () => {
    onClick?.(task?.id)
  }

  const handleOnClickCheckbox = (e: any) => {
    onClickCheckbox?.(task?.id)
  }

  const handleOnRemove = (e: any) => {
    e?.preventDefault()
    e?.stopPropagation()
    onRemove?.(task?.id)
  }

  const dueAt = task.dueAt ? new Date(task.dueAt) : undefined

  return (
    <div
      className={classNames(
        'flex relative items-center justify-start w-[340px] h-[116px] p-[20px] gap-[10px]',
        containerClassName,
      )}>
      <div>
        <Checkbox size={'md'} checked={task?.complete} onClick={handleOnClickCheckbox} />
      </div>
      <div className={classNames('relative flex cursor-pointer w-[220px]', contentClassName)} onClick={handleOnClick}>
        <div
          onClick={handleOnClick}
          className={classNames(
            'flex flex-col gap-[8px] relative justify-center w-[220px]',
            task?.complete ? 'opacity-50' : '',
            contentClassName,
          )}>
          <Text variant={'h4'} className={'ext-ellipsis overflow-hidden line-clamp-2 max-h-[44.781px]'}>
            {task?.name}
          </Text>
          <div className={'flex items-center gap-[15px]'}>
            {task?.complete && dueAt ? (
              <Text variant={'p'} size={'sm'} className={'text-gray-dark whitespace-nowrap'}>
                {months[dueAt.getMonth()] + ' ' + dueAt.getDate()}
              </Text>
            ) : (
              renderDueAtLabel(task?.dueAt)
            )}
            {task?.relatedPerson && (
              <>
                {task?.dueAt && <div className={'h-[24px] w-[1px] bg-gray-light'} />}
                <div className={'flex items-center gap-[10px]'}>
                  <Avatar
                    size={24}
                    name={task?.relatedPerson?.firstName}
                    src={task?.relatedPerson?.profileImage?.url}
                  />
                  <Text
                    variant={'p'}
                    size={'sm'}
                    className={'text-gray-dark whitespace-nowrap overflow-hidden text-ellipsis'}>
                    {task?.relatedPerson?.firstName}
                  </Text>
                </div>
              </>
            )}
          </div>
        </div>
        {task?.complete && (
          <div className={'absolute right-[-40px] top-[-4px]'}>
            <Button variant={'plain'} size={'xs'} icon={<Icon name={'x'} />} onClick={handleOnRemove} />
          </div>
        )}
      </div>
    </div>
  )
}

export default TaskItem
