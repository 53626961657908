import React, { FC } from 'react'
import Breadcrumb from '../../../components/ui/Breadcrumb'

const BreadcrumbExample: FC = () => {
  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 40 }}>
        <Breadcrumb>
          <Breadcrumb.Item title={'Equipping Phase'} />
          <Breadcrumb.Item title={'Series: Operation Timothy Book 1'} />
        </Breadcrumb>
        <Breadcrumb>
          <Breadcrumb.Item title={'Equipping Phase'} />
          <Breadcrumb.Item title={'Series: Operation Timothy Book 1'} />
          <Breadcrumb.Item title={'Session 1: What is the Purpose of Life?'} />
          <Breadcrumb.Item title={'Session 2: Now that you know the Purpose of Life!'} />
        </Breadcrumb>
        <Breadcrumb>
          <Breadcrumb.Item title={'Equipping Phase'} />
          <Breadcrumb.Item title={'Series: Operation Timothy Book 1'} />
          <Breadcrumb.Item title={'Session 1: What is the Purpose of Life?'} />
          <Breadcrumb.Item title={'Session 2: Now that you know the Purpose of Life!'} />
          <Breadcrumb.Item title={'Session 3: Life, Liberty and the Pursuit of Happiness!'} />
        </Breadcrumb>
      </div>
    </div>
  )
}

const styles: CSSObject = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default BreadcrumbExample
