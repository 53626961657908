import React, { FC, PropsWithChildren } from 'react'
import SideCarousel from './SideCarousel'

const Side: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={'flex h-full'}>
      <div className={'hidden lg:block flex-1 overflow-hidden'}>
        <SideCarousel />
      </div>
      <div className={'w-full lg:w-1/3 lg:overflow-y-scroll'}>{children}</div>
    </div>
  )
}

export default Side
