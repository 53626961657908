import React, { FC } from 'react'
import { Alert, Button, Dialogue, Form, Input, Icon, Text } from '../../../../../components/ui'
import { Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import * as Yup from 'yup'
import { useCreateJourneyMutation, ProgramDetailDocument } from '../../../../../graphql'
import useProgramDetail from '../../../../../hooks/useProgramDetail'
import useToast from '../../../../../hooks/useToast'

type AddConnectionProps = {
  open?: boolean
  onClose?: () => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string().optional(),
})

const AddJourney: FC<AddConnectionProps> = ({ open = false, onClose }) => {
  const toast = useToast()
  const { program } = useProgramDetail()

  const [createJourney, { data }] = useCreateJourneyMutation({
    refetchQueries: [ProgramDetailDocument],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.createJourney.__typename === 'CreateJourneySuccess') {
        toast.push(data.createJourney.message, data.createJourney.journey.name, 'success')
        onClose?.()
      }
    },
    onError: (error) => {
      toast.push('Error', error.message, 'error')
      onClose?.()
    },
  })

  const showAlert = data?.createJourney.__typename === 'CreateJourneyError'
  const alertMessage = data?.createJourney.message

  return (
    <Dialogue isOpen={open} className={'bg-gray-900 bg-opacity-30'} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
          description: '',
        }}
        onSubmit={async (data) => {
          console.log(data)
          await createJourney({
            variables: {
              data: {
                name: data.name,
                description: data.description,
                program: {
                  connect: {
                    id: program!.id,
                  },
                },
              },
            },
          })
        }}>
        {({ isSubmitting, errors, touched, getFieldProps, values }) => (
          <div id="add-connection-modal" className="w-[339px] self-center justify-self-center">
            <Form.Container className={'!p-0'}>
              <Text variant={'h2'} className={'leading-[22px]'}>
                Add a New Journey
              </Text>
              <Form.Item
                label={'Name'}
                errorMessage={touched.name && errors.name ? errors.name : undefined}
                invalid={touched.name && !!errors.name}>
                <Input
                  size={'sm'}
                  type={'name'}
                  className={'bg-gray-50'}
                  placeholder={''}
                  invalid={touched.name && !!errors.name}
                  {...getFieldProps('name')}
                />
              </Form.Item>
              <Form.Item
                label={'Description'}
                errorMessage={touched.description && errors.description ? errors.description : undefined}
                invalid={touched.description && !!errors.description}>
                <Input
                  size={'sm'}
                  type={'description'}
                  placeholder={''}
                  className={'bg-gray-50'}
                  textArea
                  invalid={touched.description && !!errors.description}
                  {...getFieldProps('description')}
                />
              </Form.Item>
              <AnimatePresence>{showAlert && <Alert type={'danger'} label={alertMessage} />}</AnimatePresence>
              <div className={`flex flex-col justify-center gap-5`}>
                <Button
                  disabled={isSubmitting}
                  type={'submit'}
                  size={'md'}
                  icon={<Icon name={'plus'} size={20} color={'white'} />}
                  label={'Add Journey'}
                  loading={isSubmitting}
                  block
                  variant={'solid'}
                />
                <Button
                  type={'button'}
                  size={'md'}
                  label={'Cancel'}
                  onClick={onClose}
                  loading={false}
                  block
                  variant={'default'}
                />
              </div>
            </Form.Container>
          </div>
        )}
      </Formik>
    </Dialogue>
  )
}

export default AddJourney
