import View from './components/View'
import Header from './components/Header'
import SidePeek from './components/SidePeek'
import React from 'react'
import HeaderItem from './components/Header/HeaderItem'
import Drawer from '../ui/Drawer'
import useLayout from '../../hooks/useLayout'
import UtilityDrawer from './components/UtilityDrawer'
import Modal from './components/Modal'
import useAuth from '../../hooks/useAuth'
import { ViewAs } from '../../graphql'

const AdminLayout = () => {
  const { UserDropdown, NotificationDropdown, UtilityToggle, SidePeekToggle, ComposeButton } = HeaderItem
  const {
    drawer: { show },
  } = useLayout()
  const { user } = useAuth()

  const start = user?.viewingAs === ViewAs.Leader ? [<SidePeekToggle />, <ComposeButton />] : [<SidePeekToggle />]

  return (
    <div>
      <Modal />
      <SidePeek>
        <Header start={start} end={[<UserDropdown />, <UtilityToggle />, <NotificationDropdown />]} />
        <Drawer.Container>
          <Drawer.View>
            <View />
          </Drawer.View>
          <Drawer isOpen={show}>
            <UtilityDrawer />
          </Drawer>
        </Drawer.Container>
      </SidePeek>
    </div>
  )
}

export default AdminLayout
