import React from 'react'
import { Button, Dropdown, Icon } from '../../../components/ui'
import useJourneysDropdown from '../../../hooks/useJourneysDropdown'
import usePrograms from '../../../hooks/usePrograms'
import { useSelector } from 'react-redux'
import { selectJourneys } from '../../../store/journey/journeysSlice'
import { useNavigate } from 'react-router-dom'
import formatRouteId from '../../../utils/formatRouteId'

const JourneysDropdown = () => {
  const navigate = useNavigate()
  const { selectedProgram } = usePrograms()

  const { journeys } = useJourneysDropdown({ fetchPolicy: 'cache-and-network', programId: selectedProgram?.id })

  const { selected } = useSelector(selectJourneys)
  const title =
    selected && selected.id !== 'all' ? journeys?.find((journey) => journey.id === selected.id)?.name : 'All journeys'

  return journeys?.length === 1 ? (
    <div className={'font-semibold text-[16px] leading-[20px]'}>{journeys[0].name}</div>
  ) : (
    <Dropdown
      selectedKey={selected?.id}
      onSelect={({ eventKey }) => {
        const { routeId } = formatRouteId(journeys?.find((j) => j.id === eventKey)?.name, eventKey || undefined)
        navigate(`/journeys${eventKey === 'all' ? '/all' : `/${routeId}`}`)
      }}
      title={title}
      renderToggle={
        <Button
          className={'-translate-x-[10px] text-[16px] leading-[20px]'}
          size={'xs'}
          icon={<Icon name={'chevron-down'} />}
          iconLocation={'right'}
          variant={'plain'}
        />
      }>
      <Dropdown.Item key={'all'} eventKey={'all'} title={'All journeys'} />
      {journeys &&
        journeys.map((journey) => <Dropdown.Item key={journey.id} eventKey={journey.id} title={journey.name} />)}
    </Dropdown>
  )
}

export default JourneysDropdown
