import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useMetaTags, MetaTagsConfig } from 'react-metatags-hook'

type ProtectedViewProps = {
  meta?: MetaTagsConfig
  title?: string
}

const ProtectedView: FC<PropsWithChildren<ProtectedViewProps>> = ({ meta = {}, title = '', children }) => {
  const programName = 'Program Name' // Todo: Get the selected program's name
  useMetaTags(
    {
      title: `${title} – ${programName}`,
      ...meta,
    },
    [title, meta],
  )

  useEffect(() => {
    document.body.classList.remove('bg-white')
    document.body.classList.add('bg-gray-200')
  }, [])

  return <div className={'flex grow min-w-0 bg-gray-200'}>{children}</div>
}

export default ProtectedView
