import React from 'react'
import MyJourneyTable from '../components/MyJourneyTable'

const MyJourneyPhase: React.FC = () => {
  return (
    <div className={'flex justify-center'}>
      <MyJourneyTable />
    </div>
  )
}

export default MyJourneyPhase
