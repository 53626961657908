import { TasksDocument } from '../../graphql'

const today = new Date()
today.setHours(today.getHours() + 4)
const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
const yesterday = new Date()
yesterday.setDate(yesterday.getDate() - 1)
const future = new Date()
future.setDate(future.getDate() + 7)

const taskMocks = [
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: TasksDocument,
      variables: {
        where: {
          program: {
            id: {
              equals: '1',
            },
          },
        },
      },
    },
    result: {
      data: {
        tasks: {
          hasMore: false,
          total: 6,
          list: [
            {
              id: '1',
              name: 'Followup about starting Discipleship Basics',
              relatedPerson: {
                id: '1',
                firstName: 'Robert',
                profileImage: {
                  id: '3',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                __typename: 'Person',
              },
              dueAt: yesterday,
              complete: false,
              __typename: 'Task',
            },
            {
              id: '2',
              name: 'Schedule coffee',
              relatedPerson: {
                id: '2',
                firstName: 'Brad',
                profileImage: {
                  id: '6',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                __typename: 'Person',
              },
              dueAt: today,
              complete: false,
              __typename: 'Task',
            },
            {
              id: '3',
              name: 'Create a personal journey',
              relatedPerson: {
                id: '3',
                firstName: 'Jim',
                profileImage: {
                  id: '7',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                __typename: 'Person',
              },
              dueAt: tomorrow,
              complete: false,
              __typename: 'Task',
            },
            {
              id: '4',
              name: 'Invite to join Halieus',
              relatedPerson: {
                id: '4',
                firstName: 'Nathan',
                profileImage: {
                  id: '8',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                __typename: 'Person',
              },
              dueAt: future,
              complete: false,
              __typename: 'Task',
            },
            {
              id: '5',
              name: 'Invite to church',
              relatedPerson: {
                id: '1',
                firstName: 'Robert',
                profileImage: {
                  id: '3',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                __typename: 'Person',
              },
              dueAt: future,
              complete: true,
              __typename: 'Task',
            },
            {
              id: '6',
              name: 'Plan summer small group',
              relatedPerson: null,
              dueAt: future,
              complete: true,
              __typename: 'Task',
            },
          ],
          __typename: 'Tasks',
        },
      },
    },
  },
]

export default taskMocks
