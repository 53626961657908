import {
  AuthFieldsFragment,
  ReceiveConfirmationMutation,
  SignInInput,
  SignInMutation,
  ViewAs,
  useSignInMutation,
  useProgramDropdownLazyQuery,
  useOrganizationLazyQuery,
} from '../graphql'
import { REDIRECT_URL_KEY } from '../constants'
import appConfig from '../config/app'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import useParams from './useParams'
import { persistor } from '../services/ApolloService'
import { ApolloError, FetchResult, useApolloClient } from '@apollo/client'
import { setAuth } from './useAuth'
import { ProgramDropdownDocument, OrganizationDocument } from '../graphql'

type SignInMutationResult = FetchResult<SignInMutation>
type ReceiveConfirmationMutationResult = FetchResult<ReceiveConfirmationMutation>

type HandleSignInParams = {
  params: URLSearchParams
  navigate: NavigateFunction
  queryPrograms: (token: string) => Promise<unknown>
  queryOrganization: (token: string, id: string) => Promise<unknown>
  persist?: boolean
}

export const handleAuthRequest = async <Result extends SignInMutationResult | ReceiveConfirmationMutationResult>(
  request: Promise<Result>,
  { params, navigate, queryPrograms, queryOrganization, persist = false }: HandleSignInParams,
): Promise<Result> => {
  if (!persist) {
    persistor.pause()
  }

  let result: Result
  try {
    result = await request

    if (result.data) {
      let auth: AuthFieldsFragment | undefined

      if ('signIn' in result.data) {
        if (result.data.signIn.__typename === 'SignInSuccess') {
          auth = result.data.signIn.auth
        }
      } else if ('receiveConfirmation' in result.data) {
        if (result.data.receiveConfirmation.__typename === 'ReceiveConfirmationSuccess') {
          auth = result.data.receiveConfirmation.auth
        }
      }

      if (auth) {
        if (auth.__typename === 'UserAuth') {
          let lastViewedProgram = auth.user.lastViewedProgram
          if (!lastViewedProgram) {
            lastViewedProgram = auth.user.programs[0]?.program
            auth.user.lastViewedProgram = lastViewedProgram
              ? {
                  id: lastViewedProgram.id,
                  tenant: {
                    id: lastViewedProgram.tenant.id,
                    __typename: 'Tenant',
                  },
                  member: lastViewedProgram.member,
                  __typename: 'Program',
                }
              : null
          }
          const member = lastViewedProgram?.member
          //if (!member) {
          // Todo: better error handling when can't determine the last viewed as role for the user's last viewed program
          //throw new Error('User is not a member of this program')
          //}
          const lastViewedAs =
            member?.lastViewedAs ||
            member?.roles.find((role) => role === 'LEADER') ||
            member?.roles.find((role) => role === 'APPRENTICE')
          const lastViewedAsDisplay = lastViewedAs === 'LEADER' ? member?.leaderDisplay : member?.apprenticeDisplay
          auth.user.viewingAs = lastViewedAs as ViewAs | null | undefined
          auth.user.viewingAsDisplay = lastViewedAsDisplay
          auth.user.viewingProgramId = lastViewedProgram?.id
          if (lastViewedProgram?.tenant) {
            await Promise.all([queryPrograms(auth.token), queryOrganization(auth.token, lastViewedProgram.tenant.id)])
          }
          setAuth(auth)
          //localStorage.setItem('viewingAs', lastViewedAs ? lastViewedAs : initialViewAs)
          const redirectUrl = params.get(REDIRECT_URL_KEY)
          navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
        } else {
          setAuth(auth)
        }
      }
    }

    if (!persist) {
      setTimeout(() => persistor.resume(), 1500)
    }

    return result
  } catch (error) {
    if (!persist) {
      setTimeout(() => persistor.resume(), 1500)
    }
    throw error
  }
}

function useSignIn() {
  const navigate = useNavigate()
  const params = useParams()

  const client = useApolloClient()

  const [programs] = useProgramDropdownLazyQuery({
    onCompleted: (data) => {
      if (data?.programs) {
        // Super-duper hacky way to update query cache after a lazy query
        setTimeout(() => {
          client.writeQuery({
            query: ProgramDropdownDocument,
            data: {
              programs: {
                list: [...data.programs.list],
                __typename: 'Programs',
              },
            },
          })
        }, 0)
      }
    },
  })
  const [organization] = useOrganizationLazyQuery({
    onCompleted: (data) => {
      if (data?.tenant) {
        // More super-duper hacky way to update query cache after a lazy query
        setTimeout(() => {
          client.writeQuery({
            query: OrganizationDocument,
            data: {
              tenant: {
                ...data.tenant,
                __typename: 'Tenant',
              },
            },
          })
        }, 0)
      }
    },
  })

  const [login] = useSignInMutation({
    //refetchQueries: [ProgramDropdownDocument, OrganizationDocument],
    //awaitRefetchQueries: true,
    update: (cache, { data }) => {
      if (data?.signIn.__typename === 'SignInSuccess') {
        //const lastViewedAs = Role.Admin // data?.login.user.lastViewedAs
        //let initialViewAs = Role.Admin
        //if (data?.login.user.roles.includes(Role.Admin)) {
        //  initialViewAs = Role.Admin
        //} else if (data?.login.user.roles.includes(Role.Leader)) {
        //  initialViewAs = Role.Leader
        //}
        //const viewingAs = lastViewedAs ? lastViewedAs : initialViewAs
        //const { auth } = data.login
        //auth.user.viewingAs = ViewAs.Admin
        /*cache.writeQuery({
          query: MeDocument,
          data: {
            user: auth.user,
          },
        })

        setAuth(data.login.auth, cache)*/
      }
    },
  })

  return async (data: (SignInInput | { token: string }) & { staySignedIn: boolean }) => {
    let message = ''
    let status: 'failed' | 'success' | 'invalid' = 'success'
    try {
      const { staySignedIn: persist, ...rest } = data

      const request = login({
        variables:
          'email' in rest
            ? {
                data: rest,
              }
            : undefined,
        context:
          'token' in rest
            ? {
                headers: {
                  authorization: `Basic ${rest.token}`,
                },
              }
            : undefined,
      })
      const result = await handleAuthRequest(request, {
        params,
        navigate,
        persist,
        queryPrograms: async (token: string) =>
          await programs({
            context: {
              headers: {
                authorization: `Bearer ${token}`,
              },
            },
            fetchPolicy: 'cache-and-network',
          }),
        queryOrganization: async (token: string, id: string) =>
          await organization({
            context: {
              headers: {
                authorization: `Bearer ${token}`,
              },
            },
            variables: {
              id,
            },
          }),
      })
      if (result.data?.signIn.__typename === 'SignInError') {
        message = 'Invalid email or password' // resp.data.login.message
        status = 'failed'
      }
    } catch (error) {
      message = 'Something went wrong'
      status = 'failed'
      if (error instanceof ApolloError) {
        if (error.graphQLErrors.length > 0) {
          const gqlError = error.graphQLErrors[0]
          message = gqlError.message
          if (gqlError.extensions?.code === 'FORBIDDEN') {
            status = 'invalid'
          }
        }
      } else if (error instanceof Error) {
        message = error.message
      }
    }

    return {
      status,
      message,
    }
  }
}

export default useSignIn
