import React, { cloneElement } from 'react'
import Icon from '../Icon'
import Button from '../Button'

type DropdownToggleProps = {
  title?: string
  renderToggle?: React.ReactElement
  toggleClassName?: string
  toggleVariant?: 'default' | 'solid' | 'plain'
  disabled?: boolean
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end'
  selected?: boolean
}

const DropdownToggle = React.forwardRef<HTMLDivElement, DropdownToggleProps>(
  (
    { title, renderToggle, toggleClassName, toggleVariant, disabled, selected = false, placement = 'bottom-start' },
    ref,
  ) => {
    if (renderToggle) {
      return (
        <div ref={ref} className={'cursor-pointer'}>
          {cloneElement(renderToggle, {
            selected,
            disabled,
            label: title,
            className: toggleClassName,
            ...renderToggle.props,
          })}
        </div>
      )
    } else {
      let iconName: IconName = 'chevron-down'
      let alignment: 'right' | 'left' = 'right'
      if (placement && placement.includes('right')) {
        iconName = 'chevron-right'
      } else if (placement && placement.includes('left')) {
        iconName = 'chevron-left'
        alignment = 'left'
      } else if (placement && placement.includes('top')) {
        iconName = 'chevron-up'
      }
      const icon = <Icon name={iconName} />
      return (
        <div ref={ref} className={'w-fit'}>
          <Button
            className={toggleClassName}
            variant={toggleVariant}
            type={'button'}
            label={title}
            size={'sm'}
            icon={icon}
            iconLocation={alignment}
            disabled={disabled}
          />
        </div>
      )
    }
  },
)

export default DropdownToggle
