import React, { FC } from 'react'
import { Card, Text } from '../../../components/ui'
import { useWindowSize } from '../../../hooks/useWindowSize'

type NewStepButtonProps = {
  title: string
  description: string
  image: string
  onClick: () => void
}

const NewStepButton: FC<NewStepButtonProps> = ({ title, description, onClick, image }) => {
  const { width } = useWindowSize()
  const mobile = width <= 380
  return (
    <Card
      onClick={onClick}
      border={true}
      media={<img src={image} alt={title} className={'h-[191px] w-full object-cover'} />}
      headerDivider={false}
      containerClass={'max-w-[340px] min-h-[344px] rounded-[10px] border-gray-light'}
      bodyClass={mobile ? '' : 'h-[153px]'}>
      <div className={'flex flex-col items-start gap-[10px] min-h-[153px] self-stretch bg-white'}>
        <Text variant={'h3'} className={''}>
          {title}
        </Text>
        <Text variant={'p'} className={'text-sm text-gray-dark'}>
          {description}
        </Text>
      </div>
    </Card>
  )
}

export default NewStepButton
