import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useMetaTags, MetaTagsConfig } from 'react-metatags-hook'
import { APP_TITLE } from '../../../../constants'

type PublicViewProps = {
  meta?: MetaTagsConfig
  title?: string
}
const PublicView: FC<PropsWithChildren<PublicViewProps>> = ({ children, meta = {}, title = '' }) => {
  useMetaTags(
    {
      title: `${title} – ${APP_TITLE}`,
      ...meta,
    },
    [title, meta],
  )

  useEffect(() => {
    document.body.classList.remove('bg-gray-200')
    document.body.classList.add('bg-white')
  }, [])

  return <div>{children}</div>
}

export default PublicView
