import React, { FC, PropsWithChildren } from 'react'
import SidePeekItem from './SidePeekItem'
import { useDispatch, useSelector } from 'react-redux'
import { selectSidePeek, setShowSidePeek } from '../../../../store/layout/sidePeekSlice'
import { ProgramDropdown } from './ProgramDropdown'
import SidePeekBrand from './SidePeekBrand'
import { adminNavigationItems, apprenticeNavigationItems, leaderNavigationItems } from '../../../../config/navigation'
import useAuth from '../../../../hooks/useAuth'
import { TenantMemberRole, ViewAs } from '../../../../graphql'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { AnimatePresence, motion } from 'framer-motion'
import useLayout from '../../../../hooks/useLayout'
import useOrganization from '../../../../hooks/useOrganization'

const SidePeek: FC<PropsWithChildren> = ({ children }) => {
  const { collapsed } = useSelector(selectSidePeek)

  const {
    sidePeek: { show },
  } = useLayout()

  const dispatch = useDispatch()

  const { width } = useWindowSize()

  const mobile = width < 768

  const { user } = useAuth()
  const organization = useOrganization({
    fetchPolicy: 'cache-and-network',
  })

  let items = user?.viewingAs === ViewAs.Leader ? [...leaderNavigationItems] : [...apprenticeNavigationItems]

  if (organization?.member?.role === TenantMemberRole.Admin || organization?.member?.role === TenantMemberRole.Owner) {
    items = [...items, ...adminNavigationItems]
  }

  const duration = 'duration-[150ms]'
  const containerWidth = mobile ? (show ? 'w-[260px]' : 'w-0') : collapsed ? 'w-[64px]' : 'w-[260px]'

  return (
    <div className={'relative z-0 flex h-screen'}>
      <div
        className={`${
          mobile ? 'overflow-hidden' : ''
        } fixed z-10 h-full md:static transition-all ${duration} ${containerWidth} bg-gray-900 flex flex-col`}>
        <div className={'mb-[25px]'}>
          <ProgramDropdown />
        </div>
        <div className={'flex-1'}>
          {items.map((item, index) => (
            <SidePeekItem
              {...item}
              key={`side-peek-item-${item.key}-${index}`}
              routeKey={item.key}
              collapsed={collapsed}
            />
          ))}
        </div>
        <SidePeekBrand collapsed={collapsed} />
      </div>
      <div className={'z-0 flex flex-1 flex-col h-full min-w-0'}>{children}</div>
      <AnimatePresence>
        {mobile && show && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div
              onClick={() => dispatch(setShowSidePeek(false))}
              className={'fixed top-0 bottom-0 right-0 left-0 bg-gray-900 opacity-30'}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default SidePeek
