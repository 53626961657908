import React, { useEffect } from 'react'
import usePersonDetail from '../../hooks/usePersonDetail'
import { Route, Routes } from 'react-router-dom'
import View from '../../components/shared/View'
import PersonDetailHeader from './components/PersonDetailHeader'
import PersonDetailGeneral from './views/PersonDetailGeneral'

const PersonDetail = () => {
  const { person, type, loading } = usePersonDetail({
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (person) {
      console.log('Person:', person)
    }
  }, [person, type])

  useEffect(() => {
    console.log('Root page:', type)
  }, [type])

  return (
    <View loading={loading && !person} header={<PersonDetailHeader />}>
      <Routes>
        <Route path={'/'} element={<PersonDetailGeneral />} />
      </Routes>
    </View>
  )
}

export default PersonDetail
