import { protectedRoutes } from '../../config/routes'
import { useLocation } from 'react-router-dom'
import FloatingBrand from './AuthLayout/FloatingBrand'
import View from './components/View'
import React from 'react'

const OnboardingLayout = () => {
  const { pathname } = useLocation()

  const route = protectedRoutes.find((r) => r.path === pathname)

  const layout = route?.layout || {}

  return (
    <div className={'h-screen'}>
      <FloatingBrand side={layout.side} />
      <View />
    </div>
  )
}

export default OnboardingLayout
