import { PersonDetailDocument, usePersonDetailQuery, useUpdatePersonMutation } from '../graphql'
import { WatchQueryFetchPolicy } from '@apollo/client'
import useRouteId from './useRouteId'
import { useMatch } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useToast from './useToast'

type UsePersonDetailParams = {
  fetchPolicy?: WatchQueryFetchPolicy
}

type UpdatePersonDetailNameParams = {
  firstName: string
  middleName?: string
  lastName?: string
  profileImage?: {
    create: {
      hash: string
      ext: string
      mime: string
      size: number
      name: string
      url: string
      folder: string
      folderPath: string
    }
  }
}

// Todo: Should we make the usePersonDetail hook update the selected program based on the connection ID?

const usePersonDetail = (
  { fetchPolicy = 'cache-first' }: UsePersonDetailParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const toast = useToast()
  const [selected, setSelected] = useState<{ name?: string; id: string } | undefined>(undefined)

  const connectionMatch = useMatch('/connection/:connectionId/*')
  const connectionId = connectionMatch?.params.connectionId

  const type: 'connection' | 'organization' = connectionId ? 'connection' : 'organization'

  const userMatch = useMatch('/organization/:organizationId/user/:userId/*')

  // Todo: Generate organization route ID using route ID utility
  const { id } =
    useRouteId({
      baseRoute:
        type === 'connection' ? `/connection/${connectionId}` : `organization/${userMatch?.params.organizationId}/user`,
      selected,
    }) || ''

  const variables = {
    id,
    connectionId,
  }

  const { data, loading } = usePersonDetailQuery({
    variables,
    fetchPolicy,
  })

  useEffect(() => {
    if (data) {
      setSelected({
        name: data.person.fullName,
        id: data.person.id,
      })
    }
  }, [data])

  const [updatePersonMutation, { loading: updating }] = useUpdatePersonMutation({
    refetchQueries: [{ query: PersonDetailDocument, variables }],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.updatePerson.__typename === 'UpdatePersonSuccess') {
        toast.push('Success', 'Person updated', 'success')
      }
    },
    onError: (error) => {
      toast.push('Error', error.message, 'error')
    },
  })

  const updatePersonDetailName = async (personDetailName: UpdatePersonDetailNameParams) => {
    console.log('personDetailName', personDetailName)
    await updatePersonMutation({
      variables: {
        where: {
          id: data?.person.id || '',
        },
        data: personDetailName,
      },
    })
  }

  return {
    person: data?.person,
    type,
    loading,
    updatePersonDetailName,
    updating,
  }
}

export default usePersonDetail
