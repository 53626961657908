import React from 'react'
import { Dropdown } from '../../../../ui'
import { ProgramDropdownButton, ProgramDropdownItem } from './index'
import { ProgramDropdownFieldsFragment } from '../../../../../graphql'
import { useDispatch, useSelector } from 'react-redux'
import { selectSidePeek, setShowSidePeek } from '../../../../../store/layout/sidePeekSlice'
import usePrograms from '../../../../../hooks/usePrograms'

const ProgramDropdown = () => {
  const { programs, selectProgram, selectedProgram } = usePrograms()
  const dispatch = useDispatch()

  const { collapsed } = useSelector(selectSidePeek)

  const tenants: { id: string; name: string }[] = []
  programs.forEach((program) => {
    if (!tenants.find((t) => t.id === program.tenant.id)) {
      tenants.push(program.tenant)
    }
  })

  const handleClick = (program: ProgramDropdownFieldsFragment) => {
    // Wait a split second before closing the side peek (gives page time to load and smooths out the transition experience)
    setTimeout(() => {
      dispatch(setShowSidePeek(false))
    }, 350)
    selectProgram(program.id)
  }

  return programs.length && selectedProgram ? (
    <Dropdown
      className={'w-full'}
      toggleClassName={'w-full'}
      delayCloseOnSelect={true}
      closeDelayDuration={250}
      renderToggle={<ProgramDropdownButton selectedProgram={selectedProgram} collapsed={collapsed} />}
      menuClass={'translate-y-[-20px] min-w-[355px]'}>
      {tenants.map((tenant) => {
        return (
          <div key={`program-dropdown-group-${tenant.id}`}>
            <Dropdown.Group title={tenant.name} className={'mt-[15px] mb-[10px]'} />
            {programs
              .filter((p) => p.tenant.id === tenant.id)
              .map((program) => {
                return (
                  <Dropdown.Item
                    onClick={() => handleClick(program)}
                    key={`program-dropdown-item-${program.id}`}
                    eventKey={program.id}
                    render={<ProgramDropdownItem program={program} selected={selectedProgram.id === program.id} />}
                  />
                )
              })}
          </div>
        )
      })}
    </Dropdown>
  ) : null
}

export default ProgramDropdown
