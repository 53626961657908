import {
  AssigneesDropdownDocument,
  PhasesDropdownDocument,
  JourneysDropdownDocument,
  StepDetailDocument,
  StepType,
  ContentStepType,
  CreateStepDocument,
} from '../../graphql'

/*const meaningOfLifeQuestion = {
  id: '1',
  text: 'What is the meaning of life?',
  required: true,
  order: 1,
  __typename: 'Question',
}*/

const howCanIKnowStep = {
  id: '1',
  name: 'How can I know?',
  description: null,
  leaderInformation: null,
  instructions: null,
  type: StepType.Content,
  contentType: ContentStepType.Text,
  content: null,
  questions: [],
  phase: {
    id: '1',
    name: 'Equipping',
    color: '#C2410C',
    iconName: 'users',
    __typename: 'Phase',
  },
  journey: {
    id: '1',
    name: 'Small Group Leaders',
    __typename: 'Journey',
  },
  assignees: [],
  __typename: 'Step',
}

const stepMocks = [
  // Queries
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: StepDetailDocument,
      variables: {
        where: {
          id: '1',
        },
      },
    },
    result: {
      data: {
        step: howCanIKnowStep,
      },
    },
  },
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: JourneysDropdownDocument,
      variables: {
        programId: '1',
      },
    },
    result: {
      data: {
        journeys: {
          hasMore: false,
          total: 2,
          list: [
            {
              id: '1',
              name: 'Small Group Leaders',
              __typename: 'Journey',
            },
            {
              id: '2',
              name: 'Elder Training',
              __typename: 'Journey',
            },
          ],
          __typename: 'Journeys',
        },
      },
    },
  },
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: PhasesDropdownDocument,
      variables: {
        programId: '1',
      },
    },
    result: {
      data: {
        phases: {
          hasMore: false,
          total: 2,
          list: [
            {
              id: '2',
              name: 'Witnessing',
              iconName: 'message-square',
              color: '#737373',
              __typename: 'Phase',
            },
            {
              id: '3',
              name: 'Establishing',
              iconName: 'leaf',
              color: '#2D6769',
              __typename: 'Phase',
            },
            {
              id: '1',
              name: 'Equipping',
              iconName: 'users',
              color: '#C2410C',
              __typename: 'Phase',
            },
          ],
          __typename: 'Journeys',
        },
      },
    },
  },
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: AssigneesDropdownDocument,
      variables: {
        programId: '1',
      },
    },
    result: {
      data: {
        apprentices: {
          list: [
            {
              id: '2',
              name: 'John Doe',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              __typename: 'Person',
            },
            {
              id: '3',
              name: 'John Doe',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              __typename: 'Person',
            },
            {
              id: '4',
              name: 'John Doe',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              __typename: 'Person',
            },
            {
              id: '5',
              name: 'John Doe',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              __typename: 'Person',
            },
            {
              id: '6',
              name: 'John Doe',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              __typename: 'Person',
            },
            {
              id: '7',
              name: 'John Doe',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              __typename: 'Person',
            },
            {
              id: '8',
              name: 'John Doe',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              __typename: 'Person',
            },
          ],
        },
      },
    },
  },
  // Mutations
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: CreateStepDocument,
      variables: {
        data: {
          name: 'How can I know?',
          description: null,
          phase: {
            connect: {
              id: '1',
            },
          },
          journey: {
            connect: {
              id: '1',
            },
          },
          type: StepType.Content,
          contentType: ContentStepType.Text,
          assignee: null,
        },
      },
    },
    result: {
      data: {
        createStep: {
          __typename: 'CreateStepSuccess',
          message: 'Step created',
          step: howCanIKnowStep,
        },
      },
    },
  },
]

export default stepMocks
