import usePrograms from './usePrograms'
import { useMyJourneyQuery } from '../graphql'
import { WatchQueryFetchPolicy } from '@apollo/client'

type UseMyJourneyParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  programId?: string
}

const useMyJourney = (
  { fetchPolicy = 'cache-first', programId }: UseMyJourneyParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const { selectedProgram } = usePrograms()

  const { data, loading } = useMyJourneyQuery({
    variables: {
      program: {
        id: programId || selectedProgram?.id || '',
      },
    },
    fetchPolicy,
  })

  return { myJourney: data?.myJourney, loading }
}

export default useMyJourney
