import React, { FC } from 'react'
import ConfirmEmailForm from './ConfirmEmailForm'
import CreateUserForm from './CreateUserForm'
import useAuth from '../../../hooks/useAuth'

const SignUp: FC = () => {
  const { confirmation } = useAuth()

  return (
    <div className={'pt-[100px]'}>
      <div className={'flex flex-col mt-[12vh]'}>{!confirmation ? <ConfirmEmailForm /> : <CreateUserForm />}</div>
    </div>
  )
}

export default SignUp
