import { EditorContent } from '@tiptap/react'
import React, { useRef, useEffect, FC } from 'react'

import { LinkMenu, TextMenu, ContentItemMenu } from '../menus'

import { useBlockEditor } from '../../../../hooks/useBlockEditor'

import '../../../../assets/styles/tiptap/index.css'

import ImageBlockMenu from '../extensions/ImageBlock/components/ImageBlockMenu'
import VideoBlockMenu from '../extensions/VideoBlock/components/VideoBlockMenu'
import { ColumnsMenu } from '../extensions/MultiColumn/menus'
import { TableColumnMenu, TableRowMenu } from '../extensions/Table/menus'

type BlockEditorProps = {
  editable?: boolean
  variant?: 'default' | 'simple'
  onChange?: (content: string) => void
  content?: string | null
}

export const BlockEditor: FC<BlockEditorProps> = ({ editable = true, variant = 'default', onChange, content }) => {
  const menuContainerRef = useRef(null)

  const className =
    variant === 'simple'
      ? 'w-[calc(100%+22px)] pl-[22px] translate-x-[-22px]'
      : 'w-[calc(100%+46px)] pl-[46px] translate-x-[-46px]'

  const { editor } = useBlockEditor({ editable, className, onChange, content: content || undefined })

  useEffect(() => {
    document.documentElement.classList.toggle('dark', false)
  }, [])

  if (!editor) {
    return null
  }

  return (
    <div className="flex" ref={menuContainerRef}>
      {/*<Sidebar isOpen={leftSidebar.isOpen} onClose={leftSidebar.close} editor={editor} />*/}
      <div className={'relative flex flex-col flex-1 h-full'}>
        {/*<EditorHeader
          editor={editor}
          collabState={collabState}
          users={users}
          isSidebarOpen={leftSidebar.isOpen}
          toggleSidebar={leftSidebar.toggle}
        />*/}
        <EditorContent editor={editor} className={'flex-1 flex flex-col items-center overflow-visible'} />
        {editable ? (
          <div>
            <ContentItemMenu editor={editor} variant={variant} />
            <LinkMenu editor={editor} appendTo={menuContainerRef} />
            <TextMenu editor={editor} variant={variant} />
            <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
            <TableRowMenu editor={editor} appendTo={menuContainerRef} />
            <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
            <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
            <VideoBlockMenu editor={editor} appendTo={menuContainerRef} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BlockEditor
