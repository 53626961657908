import React, { FC, ReactElement, useMemo } from 'react'
import { RouteType } from '../../store/base/commonSlice'
import Breadcrumb from '../ui/Breadcrumb'
import protectedRoutes from '../../config/routes/protectedRoutes'
import publicRoutes from '../../config/routes/publicRoutes'
import onboardingRoutes from '../../config/routes/onboardingRoutes'
import { useNavigate } from 'react-router'
import { BreadcrumbItemProps } from '../ui/Breadcrumb/BreadcrumbItem'
import useRouteConfig from '../../hooks/useRouteConfig'
import { useWindowSize } from '../../hooks/useWindowSize'

const getRouteConfig = (type: RouteType, key: string) => {
  switch (type) {
    case 'protected':
      return protectedRoutes.find((route) => route.key === key)
    case 'public':
      return publicRoutes.find((route) => route.key === key)
    default:
      return onboardingRoutes.find((route) => route.key === key)
  }
}

type BreadcrumbBarProps = {
  routeType?: RouteType
  routeTitleMap?: Record<string, string | undefined>
  routePathMap?: Record<string, string | undefined>
  itemClassName?: string
}

const BreadcrumbBar: FC<BreadcrumbBarProps> = ({
  itemClassName,
  routeType = 'protected',
  routeTitleMap = {},
  routePathMap = {},
}) => {
  const routeConfig = useRouteConfig()
  const navigate = useNavigate()
  const { size } = useWindowSize()

  const maxWidth = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'max-w-[75px]'
      case 'sm':
        return 'max-w-[100px]'
      case 'md':
        return 'max-w-[125px]'
      case 'lg':
        return 'max-w-[150px]'
      default:
        return 'max-w-[225px]'
    }
  }, [size])

  const key = routeConfig?.key

  const items = useMemo(() => {
    const parts = key?.split('.').filter((part) => part !== '') || []

    return parts
      .map((_, index) => {
        const subKey = parts.slice(0, index + 1).join('.')
        const partKey = parts[index]

        const subRouteConfig = getRouteConfig(routeType, subKey)

        const title: string = routeTitleMap[partKey] || subRouteConfig?.title || ''
        const path: string = routePathMap[partKey] || subRouteConfig?.path.replaceAll('/*', '') || ''

        return subRouteConfig ? (
          <Breadcrumb.Item className={`${maxWidth} truncate`} key={subKey} title={title} path={path} />
        ) : null
      })
      .filter((item) => item !== null) as ReactElement<BreadcrumbItemProps>[]
  }, [key, routeType, routeTitleMap, routePathMap, maxWidth])

  const handleOnClickItem = (path?: string) => {
    if (path) {
      navigate(path)
    }
  }

  return (
    <Breadcrumb className={'ml-[10px]'} onClickItem={handleOnClickItem}>
      {items}
    </Breadcrumb>
  )
}

export default BreadcrumbBar
