import usePrograms from './usePrograms'
import {
  ConnectionsDocument,
  useConnectionsQuery,
  useCreateConnectionMutation,
  useDeleteConnectionMutation,
} from '../graphql'
import { WatchQueryFetchPolicy } from '@apollo/client'
import useRouteId from './useRouteId'
import formatRouteId from '../utils/formatRouteId'
import useToast from './useToast'
import { useNavigate } from 'react-router-dom'

type UseConnectionsParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  programId?: string
}

type CreateConnectionParams = {
  firstName: string
  middleName?: string | null
  lastName?: string | null
  notes?: string
}

const useConnections = (
  { fetchPolicy = 'cache-first', programId }: UseConnectionsParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const { selectedProgram } = usePrograms()
  const navigate = useNavigate()
  const toast = useToast()

  const { id } = useRouteId({
    baseRoute: '/connections',
    selected: selectedProgram,
  })

  const variables = {
    programId: id || programId || '',
  }

  const { data, loading } = useConnectionsQuery({
    variables,
    fetchPolicy,
  })

  const [createConnectionMutation, { loading: creating }] = useCreateConnectionMutation({
    refetchQueries: [{ query: ConnectionsDocument, variables }],
    awaitRefetchQueries: true,
  })

  const [deleteConnectionMutation, { loading: deleting }] = useDeleteConnectionMutation({
    refetchQueries: [{ query: ConnectionsDocument, variables }],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.deleteConnection.__typename === 'DeleteConnectionSuccess') {
        toast.push('Success', data.deleteConnection.message, 'success')
        if (selectedProgram) {
          const { routeId } = formatRouteId(selectedProgram.name, selectedProgram.id)
          navigate(`/connections/${routeId}`)
        }
      }
    },
  })

  const createConnection = async (connection: CreateConnectionParams) => {
    await createConnectionMutation({
      variables: {
        data: {
          person: {
            create: {
              firstName: connection.firstName,
              middleName: connection.middleName || null,
              lastName: connection.lastName || null,
              notes: connection.notes
                ? {
                    create: {
                      content: connection.notes,
                    },
                  }
                : undefined,
            },
          },
          program: {
            connect: {
              id: id || programId || selectedProgram?.id || '',
            },
          },
        },
      },
    })
  }

  const removeConnection = async (id: string) => {
    await deleteConnectionMutation({
      variables: {
        where: {
          id,
        },
      },
    })
  }

  return {
    connections: data?.connections,
    loading,
    createConnection,
    creating,
    removeConnection,
    deleting,
  }
}

export default useConnections
