import { combineReducers } from 'redux'
import sidePeek, { SidePeekState } from './sidePeekSlice'
import drawer, { DrawerState } from './drawerSlice'
import modal from './modalSlice'
import { createSelector } from '@reduxjs/toolkit'

export type LayoutState = {
  drawer: DrawerState
  sidePeek: SidePeekState
}

export const selectLayout = createSelector(
  (state: { layout: LayoutState }) => state.layout,
  (layout) => layout,
)

const reducer = combineReducers({
  sidePeek,
  drawer,
  modal,
})

export default reducer
