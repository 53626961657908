import { BubbleMenu as BaseBubbleMenu, useEditorState } from '@tiptap/react'
import React, { useCallback, useRef } from 'react'
import { Instance, sticky } from 'tippy.js'
import { v4 as uuid } from 'uuid'
import deepEql from 'fast-deep-equal'

import { Toolbar } from '../../../ui/Toolbar'
import { Icon } from '../../../ui/Icon'
import { VideoBlockWidth } from './VideoBlockWidth'
import { MenuProps } from '../../../menus/types'
import { getRenderContainer } from '../../../lib/utils'

export const VideoBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null)
  const tippyInstance = useRef<Instance | null>(null)

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-videoBlock')
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0)

    return rect
  }, [editor])

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('videoBlock')

    return isActive
  }, [editor])

  const onAlignVideoLeft = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setVideoBlockAlign('left').run()
  }, [editor])

  const onAlignVideoCenter = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setVideoBlockAlign('center').run()
  }, [editor])

  const onAlignVideoRight = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setVideoBlockAlign('right').run()
  }, [editor])

  const onWidthChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setVideoBlockWidth(value).run()
    },
    [editor],
  )
  const { isVideoCenter, isVideoLeft, isVideoRight, width } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isVideoLeft: ctx.editor.isActive('videoBlock', { align: 'left' }),
        isVideoCenter: ctx.editor.isActive('videoBlock', { align: 'center' }),
        isVideoRight: ctx.editor.isActive('videoBlock', { align: 'right' }),
        width: parseInt(ctx.editor.getAttributes('videoBlock')?.width || 0),
      }
    },
    equalityFn: deepEql,
  })

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`videoBlockMenu-${uuid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance
        },
        appendTo: () => {
          return appendTo?.current
        },
        plugins: [sticky],
        sticky: 'popper',
      }}>
      <Toolbar.Wrapper shouldShowContent={shouldShow()} ref={menuRef}>
        <Toolbar.Button tooltip="Align video left" active={isVideoLeft} onClick={onAlignVideoLeft}>
          <Icon name="AlignHorizontalDistributeStart" />
        </Toolbar.Button>
        <Toolbar.Button tooltip="Align video center" active={isVideoCenter} onClick={onAlignVideoCenter}>
          <Icon name="AlignHorizontalDistributeCenter" />
        </Toolbar.Button>
        <Toolbar.Button tooltip="Align video right" active={isVideoRight} onClick={onAlignVideoRight}>
          <Icon name="AlignHorizontalDistributeEnd" />
        </Toolbar.Button>
        <Toolbar.Divider />
        <VideoBlockWidth onChange={onWidthChange} value={width} />
      </Toolbar.Wrapper>
    </BaseBubbleMenu>
  )
}

export default VideoBlockMenu
