import React, { FC } from 'react'
import { Icon, MiniBadge } from '../ui'
import pluralize from 'pluralize'

type BlockedBadgeProps = {
  count?: number
}

const BlockedBadge: FC<BlockedBadgeProps> = ({ count }) => {
  const tooltipTitle = `Blocked by ${count} ${pluralize('step', count)}`
  return (
    <MiniBadge tooltip={{ title: tooltipTitle }} prefix={<Icon name={'divide-circle-bold'} size={12} />}>
      {count}
    </MiniBadge>
  )
}

export default BlockedBadge
