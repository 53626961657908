import React, { FC, useState } from 'react'
import { Avatar, Badge, Button, Dialogue, Icon } from '../../../components/ui'
import BronzeSvg from '../../../assets/BronzeSVG'

const DialogueExample: FC = () => {
  const [isOpenOne, setIsOpenOne] = useState(false)
  const [isOpenTwo, setIsOpenTwo] = useState(false)
  const [isOpenThree, setIsOpenThree] = useState(false)
  const [isOpenFour, setIsOpenFour] = useState(false)

  return (
    <>
      <div>
        <div>
          <Button variant={'solid'} onClick={() => setIsOpenOne(true)}>
            Open Scrollable Dialogue
          </Button>
        </div>
        <Dialogue isOpen={isOpenOne} width="500px" height="550px" onRequestClose={() => setIsOpenOne(false)}>
          <Dialogue.Header className={'flex justify-between items-start'}>
            Dialogue Title
            <div
              onClick={() => setIsOpenOne(false)}
              className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
              <Icon name="x" size={'20'} color={'#333132'} />
            </div>
          </Dialogue.Header>
          <Dialogue.Body style={{ maxHeight: 300 }}>
            Tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum varius
            sit amet mattis vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat
            semper viverra nam libero justo laoreet sit amet cursus sit amet dictum sit amet justo donec enim diam
            vulputate ut pharetra Sit amet aliquam id diam maecenas ultricies mi eget mauris pharetra et ultrices neque
            ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl rhoncus mattis
            rhoncus urna neque viverra justo nec ultrices dui sapien eget mi proin sed libero enim sed faucibus turpis
            in eu mi bibendum neque egestas congue quisque egestas diam in arcu cursus euismod quis viverra nib. Cras
            pulvinar mattis nunc sed blandit libero volutpat sed cras ornare arcu dui vivamus arcu felis bibendum ut
            tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce ut placerat orci nulla
            pellentesque dignissim enim sit amet venenatis urna cursus eget nunc scelerisque viverra mauris in aliquam
            sem fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque massa placerat duis ultricies
            lacus sed turpis tincidunt id aliquet risus feugiat in ante metus dictum at tempor commodo ullamcorper a
            lacus vestibulum sed arcu non odio. ullamcorper sit amet risus nullam eget felis eget nunc lobortis mattis
            aliquam faucibus purus in massa tempor nec feugiat nisl pretium fusce id velit ut tortor pretium viverra
            suspendisse potenti nu. Alam ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra
            tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras
            tincidunt lobortis feugiat vivamus at augue eget arcu dictum varius duis at consectetur lorem donec massa
            sapien faucibus et molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi
            tincidunt ornare massa eget egestas purus viverra accumsan in nisl nisi scelerisque eu ultrices vitae auctor
            eu augue ut lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra ipsum
            nunc aliquet bibendum enim facilisis gravida neque convallis a cras semper auctor neque vitae tempus quam
            pellentesque nec nam aliquam sem et tortor consequat id porta nibh venenatis cras sed felis eget velit
            aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim neque volutpat ac
            tincidunt vitae semper quis lectus nulla at volutpat diam ut venenatis tellus in metus vulputate eu
            scelerisque felis imperdiet proin fermentum leo vel orci porta non pulvinar neque laoreet. Tincidunt nunc
            pulvinar sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum varius sit amet mattis
            vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut consequat semper viverra
            nam libero justo laoreet sit amet cursus sit amet dictum sit amet justo donec enim diam vulputate ut
            pharetra Sit amet aliquam id diam maecenas ultricies mi eget mauris pharetra et ultrices neque ornare aenean
            euismod elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna
            neque viverra justo nec ultrices dui sapien eget mi proin sed libero enim sed faucibus turpis in eu mi
            bibendum neque egestas congue quisque egestas diam in arcu cursus euismod quis viverra nib. Cras pulvinar
            mattis nunc sed blandit libero volutpat sed cras ornare arcu dui vivamus arcu felis bibendum ut tristique et
            egestas quis ipsum suspendisse ultrices gravida dictum fusce ut placerat orci nulla pellentesque dignissim
            enim sit amet venenatis urna cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla ut morbi
            tincidunt augue interdum velit euismod in pellentesque massa placerat duis ultricies lacus sed turpis
            tincidunt id aliquet risus feugiat in ante metus dictum at tempor commodo ullamcorper a lacus vestibulum sed
            arcu non odio. ullamcorper sit amet risus nullam eget felis eget nunc lobortis mattis aliquam faucibus purus
            in massa tempor nec feugiat nisl pretium fusce id velit ut tortor pretium viverra suspendisse potenti nu.
            Alam ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus in hac habitasse
            platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt lobortis
            feugiat vivamus at augue eget arcu dictum varius duis at consectetur lorem donec massa sapien faucibus et
            molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare
            massa eget egestas purus viverra accumsan in nisl nisi scelerisque eu ultrices vitae auctor eu augue ut
            lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra ipsum nunc aliquet
            bibendum enim facilisis gravida neque convallis a cras semper auctor neque vitae tempus quam pellentesque
            nec nam aliquam sem et tortor consequat id porta nibh venenatis cras sed felis eget velit aliquet sagittis
            id consectetur purus ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt vitae semper
            quis lectus nulla at volutpat diam ut venenatis tellus in metus vulputate eu scelerisque felis imperdiet
            proin fermentum leo vel orci porta non pulvinar neque laoreet.
          </Dialogue.Body>
          <Dialogue.Footer className={'flex justify-end space-x-2'}>
            <Button shape={'rounded'} size={'sm'} variant={'plain'} isWide={true} onClick={() => setIsOpenOne(false)}>
              Close
            </Button>
            <Button shape={'rounded'} size={'sm'} variant={'solid'} isWide={true} onClick={() => setIsOpenOne(false)}>
              Okay
            </Button>
          </Dialogue.Footer>
        </Dialogue>
      </div>
      <div>
        <div>
          <Button variant={'solid'} onClick={() => setIsOpenTwo(true)}>
            Open Simple Dialogue
          </Button>
        </div>
        <Dialogue isOpen={isOpenTwo} width="500px" height="225" onRequestClose={() => setIsOpenTwo(false)}>
          <Dialogue.Header className={'flex justify-between items-start'}>
            Dialogue Title
            <div
              onClick={() => setIsOpenTwo(false)}
              className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
              <Icon name="x" size={'20'} color={'#333132'} />
            </div>
          </Dialogue.Header>
          <Dialogue.Body>
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in
            some form, by injected humour, or randomised words which dont look even slightly believable.
          </Dialogue.Body>
          <Dialogue.Footer className={'flex justify-end space-x-2'}>
            <Button shape={'rounded'} size={'sm'} variant={'plain'} isWide={true} onClick={() => setIsOpenTwo(false)}>
              Close
            </Button>
            <Button shape={'rounded'} size={'sm'} variant={'solid'} isWide={true} onClick={() => setIsOpenTwo(false)}>
              Okay
            </Button>
          </Dialogue.Footer>
        </Dialogue>
      </div>
      <div>
        <div>
          <Button variant={'solid'} onClick={() => setIsOpenThree(true)}>
            Open Simple Avatar Dialogue
          </Button>
        </div>
        <Dialogue isOpen={isOpenThree} width="500px" height="225" onRequestClose={() => setIsOpenThree(false)}>
          <Dialogue.Avatar
            avatar={
              <Badge position="bottom-right" content="1" render={() => <BronzeSvg />}>
                <Avatar
                  size={'md'}
                  name={'Ned Stark'}
                  src={'https://randomuser.me/api/portraits/men/85.jpg'}
                  shape={'circle'}
                />
              </Badge>
            }>
            <Dialogue.Header className={'flex justify-between items-start'}>
              <span className={'text-ellipsis overflow-hidden whitespace-nowrap min-w-0'}>Dialogue Title</span>
              <div
                onClick={() => setIsOpenThree(false)}
                className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
                <Icon name="x" size={'20'} color={'#333132'} />
              </div>
            </Dialogue.Header>
            <Dialogue.Body>
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
              in some form, by injected humour, or randomised words which dont look even slightly believable.
            </Dialogue.Body>
            <Dialogue.Footer className={'flex justify-end space-x-2'}>
              <Button
                shape={'rounded'}
                size={'sm'}
                variant={'plain'}
                isWide={true}
                onClick={() => setIsOpenThree(false)}>
                Close
              </Button>
              <Button
                shape={'rounded'}
                size={'sm'}
                variant={'solid'}
                isWide={true}
                onClick={() => setIsOpenThree(false)}>
                Okay
              </Button>
            </Dialogue.Footer>
          </Dialogue.Avatar>
        </Dialogue>
      </div>
      <div>
        <div>
          <Button variant={'solid'} onClick={() => setIsOpenFour(true)}>
            Open Scrollable Avatar Dialogue
          </Button>
        </div>
        <Dialogue isOpen={isOpenFour} width="500px" height="550px" onRequestClose={() => setIsOpenFour(false)}>
          <Dialogue.Avatar
            avatar={
              <Badge position="bottom-right" content="1" render={() => <BronzeSvg />}>
                <Avatar
                  size={'md'}
                  name={'Ned Stark'}
                  src={'https://randomuser.me/api/portraits/men/85.jpg'}
                  shape={'circle'}
                />
              </Badge>
            }>
            <Dialogue.Header className={'flex justify-between items-start'}>
              <span className={'text-ellipsis overflow-hidden whitespace-nowrap min-w-0'}>
                Extended Avatar Dialogue Title
              </span>
              <div
                onClick={() => setIsOpenFour(false)}
                className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
                <Icon name="x" size={'20'} color={'#333132'} />
              </div>
            </Dialogue.Header>
            <Dialogue.Body style={{ maxHeight: 300 }}>
              Tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada proin libero nunc consequat interdum varius
              sit amet mattis vulputate enim nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut
              consequat semper viverra nam libero justo laoreet sit amet cursus sit amet dictum sit amet justo donec
              enim diam vulputate ut pharetra Sit amet aliquam id diam maecenas ultricies mi eget mauris pharetra et
              ultrices neque ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae proin sagittis
              nisl rhoncus mattis rhoncus urna neque viverra justo nec ultrices dui sapien eget mi proin sed libero enim
              sed faucibus turpis in eu mi bibendum neque egestas congue quisque egestas diam in arcu cursus euismod
              quis viverra nib. Cras pulvinar mattis nunc sed blandit libero volutpat sed cras ornare arcu dui vivamus
              arcu felis bibendum ut tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce ut
              placerat orci nulla pellentesque dignissim enim sit amet venenatis urna cursus eget nunc scelerisque
              viverra mauris in aliquam sem fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque
              massa placerat duis ultricies lacus sed turpis tincidunt id aliquet risus feugiat in ante metus dictum at
              tempor commodo ullamcorper a lacus vestibulum sed arcu non odio. ullamcorper sit amet risus nullam eget
              felis eget nunc lobortis mattis aliquam faucibus purus in massa tempor nec feugiat nisl pretium fusce id
              velit ut tortor pretium viverra suspendisse potenti nu. Alam ac tortor vitae purus faucibus ornare
              suspendisse sed nisi lacus sed viverra tellus in hac habitasse platea dictumst vestibulum rhoncus est
              pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at augue eget arcu dictum
              varius duis at consectetur lorem donec massa sapien faucibus et molestie ac feugiat sed lectus vestibulum
              mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget egestas purus viverra accumsan
              in nisl nisi scelerisque eu ultrices vitae auctor eu augue ut lectus arcu bibendum at varius vel pharetra
              vel turpis nunc eget lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis gravida neque
              convallis a cras semper auctor neque vitae tempus quam pellentesque nec nam aliquam sem et tortor
              consequat id porta nibh venenatis cras sed felis eget velit aliquet sagittis id consectetur purus ut
              faucibus pulvinar elementum integer enim neque volutpat ac tincidunt vitae semper quis lectus nulla at
              volutpat diam ut venenatis tellus in metus vulputate eu scelerisque felis imperdiet proin fermentum leo
              vel orci porta non pulvinar neque laoreet. Tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada
              proin libero nunc consequat interdum varius sit amet mattis vulputate enim nulla aliquet porttitor lacus
              luctus accumsan tortor posuere ac ut consequat semper viverra nam libero justo laoreet sit amet cursus sit
              amet dictum sit amet justo donec enim diam vulputate ut pharetra Sit amet aliquam id diam maecenas
              ultricies mi eget mauris pharetra et ultrices neque ornare aenean euismod elementum nisi quis eleifend
              quam adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque viverra justo nec ultrices dui
              sapien eget mi proin sed libero enim sed faucibus turpis in eu mi bibendum neque egestas congue quisque
              egestas diam in arcu cursus euismod quis viverra nib. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed cras ornare arcu dui vivamus arcu felis bibendum ut tristique et egestas quis ipsum
              suspendisse ultrices gravida dictum fusce ut placerat orci nulla pellentesque dignissim enim sit amet
              venenatis urna cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla ut morbi tincidunt
              augue interdum velit euismod in pellentesque massa placerat duis ultricies lacus sed turpis tincidunt id
              aliquet risus feugiat in ante metus dictum at tempor commodo ullamcorper a lacus vestibulum sed arcu non
              odio. ullamcorper sit amet risus nullam eget felis eget nunc lobortis mattis aliquam faucibus purus in
              massa tempor nec feugiat nisl pretium fusce id velit ut tortor pretium viverra suspendisse potenti nu.
              Alam ac tortor vitae purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus in hac habitasse
              platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt lobortis
              feugiat vivamus at augue eget arcu dictum varius duis at consectetur lorem donec massa sapien faucibus et
              molestie ac feugiat sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare
              massa eget egestas purus viverra accumsan in nisl nisi scelerisque eu ultrices vitae auctor eu augue ut
              lectus arcu bibendum at varius vel pharetra vel turpis nunc eget lorem dolor sed viverra ipsum nunc
              aliquet bibendum enim facilisis gravida neque convallis a cras semper auctor neque vitae tempus quam
              pellentesque nec nam aliquam sem et tortor consequat id porta nibh venenatis cras sed felis eget velit
              aliquet sagittis id consectetur purus ut faucibus pulvinar elementum integer enim neque volutpat ac
              tincidunt vitae semper quis lectus nulla at volutpat diam ut venenatis tellus in metus vulputate eu
              scelerisque felis imperdiet proin fermentum leo vel orci porta non pulvinar neque laoreet.
            </Dialogue.Body>
            <Dialogue.Footer className={'flex justify-end space-x-2'}>
              <Button
                shape={'rounded'}
                size={'sm'}
                variant={'plain'}
                isWide={true}
                onClick={() => setIsOpenFour(false)}>
                Close
              </Button>
              <Button
                shape={'rounded'}
                size={'sm'}
                variant={'solid'}
                isWide={true}
                onClick={() => setIsOpenFour(false)}>
                Okay
              </Button>
            </Dialogue.Footer>
          </Dialogue.Avatar>
        </Dialogue>
      </div>
    </>
  )
}

export default DialogueExample
