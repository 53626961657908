import { WatchQueryFetchPolicy } from '@apollo/client'
import { useMeQuery, useUpdatePasswordMutation, useUpdateUserMutation } from '../graphql'
import useToast from './useToast'

type UseAccountSettingsParams = {
  fetchPolicy?: WatchQueryFetchPolicy
}

type UpdateUserInformationParams = {
  name: string
  email: string
  profileImage?: {
    create: {
      hash: string
      ext: string
      mime: string
      size: number
      name: string
      url: string
      folder: string
      folderPath: string
    }
  }
}

type UpdatePasswordParams = {
  currentPassword: string
  newPassword: string
}

const useAccountSettings = (
  { fetchPolicy = 'cache-first' }: UseAccountSettingsParams = { fetchPolicy: 'cache-first' },
) => {
  const { data, loading } = useMeQuery({
    fetchPolicy,
  })

  const toast = useToast()

  const [updateUserInformationMutation, { loading: updatingUserInformation }] = useUpdateUserMutation({
    onCompleted: (data) => {
      if (data.updateUser.__typename === 'UpdateUserSuccess') {
        toast.push('Success', data.updateUser.message, 'success')
      }
    },
    onError: (error) => {
      toast.push('Error', error.message, 'error')
    },
  })

  const [updatePasswordMutation, { loading: updatingPassword, data: updatePasswordData }] = useUpdatePasswordMutation({
    onError: (error) => {
      toast.push('Error', error.message, 'error')
    },
  })

  const updateUserInformation = async (data: UpdateUserInformationParams) => {
    await updateUserInformationMutation({
      variables: {
        data,
      },
    })
  }

  const updatePassword = async (data: UpdatePasswordParams, resetForm: () => void) => {
    await updatePasswordMutation({
      variables: {
        data,
      },
      onCompleted: (data) => {
        if (data.updatePassword.__typename === 'UpdatePasswordSuccess') {
          toast.push('Success', data.updatePassword.message, 'success')
          resetForm()
        }
      },
    })
  }

  return {
    user: data?.me,
    loading,
    updateUserInformation,
    updatingUserInformation,
    updatePassword,
    updatingPassword,
    updatePasswordData,
  }
}

export default useAccountSettings
