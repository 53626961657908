import React, { FC, ReactElement, PropsWithChildren } from 'react'
import Indicator from '../ui/Indicator'
import classNames from 'classnames'

type ViewProps = {
  header?: ReactElement
  tabbar?: ReactElement
  loading?: boolean
  contentClassName?: string
}

const View: FC<PropsWithChildren<ViewProps>> = ({
  header = null,
  tabbar = null,
  loading = false,
  children,
  contentClassName,
}) => {
  return (
    <div className={'flex gap-[10px] flex-1 min-h-0 min-w-0 flex-col grow'}>
      {header}
      <div className={'flex flex-1 min-h-0 flex-col h-full'}>
        <div
          className={
            'flex shrink-0 overflow-x-scroll pl-[16px] pr-[16px] sm:pl-[20px] sm:pr-[20px] md:pl-[40px] md:pr-[40px]'
          }>
          {tabbar}
        </div>
        <div
          className={classNames(
            'flex grow min-h-0 flex-col overflow-x-scroll pl-[16px] pr-[16px] pb-[16px] sm:pl-[20px] sm:pr-[20px] sm:pb-[20px] md:pl-[40px] md:pr-[40px] md:pb-[40px]',
            contentClassName,
          )}>
          {loading ? (
            <div className={'mt-20 flex justify-center'}>
              <Indicator isSpinning />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  )
}

export default View
