import { SignInDocument, ViewAs, ProgramMemberRole, TenantUserRole } from '../../graphql'
import { GraphQLError } from 'graphql'
import appConfig from '../../config/app'

const authMocks = [
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: SignInDocument,
      variables: {
        data: {
          email: 'test@test.com',
          password: '1234',
        },
      },
    },
    result: {
      data: {
        signIn: {
          message: 'Signed in successfully',
          auth: {
            token: '1234',
            user: {
              id: '1',
              email: 'test@test.com',
              lastViewedProgramId: '1',
              lastViewedProgram: {
                id: '1',
                member: {
                  id: '1',
                  lastViewedAs: ViewAs.Apprentice,
                  lastViewedAsDisplay: 'Apprentice',
                  apprenticeDisplay: 'Apprentice',
                  leaderDisplay: 'Leader',
                  roles: [ProgramMemberRole.Apprentice, ProgramMemberRole.Leader],
                },
                tenant: {
                  id: '1',
                  __typename: 'Tenant',
                },
              },
              person: {
                id: '1',
                firstName: 'Joe',
                lastName: 'Schmoe',
                name: 'Joe Schmoe',
                profileImage: {
                  id: '1',
                  url: `${appConfig.issuePath}/img/samples/Avatar_Person.png`,
                  __typename: 'Media',
                },
                __typename: 'Person',
              },
              tenants: [
                {
                  role: TenantUserRole.Owner,
                  tenant: {
                    id: '1',
                    name: 'New Life Church',
                    __typename: 'Tenant',
                  },
                  __typename: 'TenantUser',
                },
              ],
              programs: [
                {
                  id: '1',
                  name: 'Discipleship',
                  process: {
                    name: 'Christian Discipleship',
                    template: {
                      name: 'Christian Discipleship',
                      type: 'DTMS',
                      __typename: 'ProcessTemplate',
                    },
                    __typename: 'Process',
                  },
                  tenant: {
                    id: '1',
                    name: 'New Life Church',
                    __typename: 'Tenant',
                  },
                  __typename: 'Program',
                },
              ],
              __typename: 'User',
            },
            __typename: 'UserAuth',
          },
          __typename: 'SignInSuccess',
        },
      },
    },
  },
  {
    request: {
      query: SignInDocument,
      variables: {
        data: {
          email: 'invalid@test.com',
          password: '1234',
        },
      },
    },
    result: {
      errors: [
        new GraphQLError('Invalid email or password', null, null, null, null, null, {
          name: 'ForbiddenError',
          code: 'FORBIDDEN',
        }),
      ],
    },
  },
  {
    request: {
      query: SignInDocument,
      variables: {
        data: {
          email: 'error@test.com',
          password: '1234',
        },
      },
    },
    error: new Error('Network error. This message should be hidden from user'),
  },
]

export default authMocks
