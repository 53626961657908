import React, { FC, useState } from 'react'
import TableTools from '../../../../../components/ui/Table/TableTools'
import DataTable, { DataTableColumn } from '../../../../../components/ui/Table/DataTable'
import { ProgramDetailFieldsFragment, ProgramMemberRole } from '../../../../../graphql'
import { Avatar, Button, Dialogue, Dropdown, Icon, Tooltip, Text } from '../../../../../components/ui'
import Label from '../../../../../components/ui/Label'
import formatRouteId from '../../../../../utils/formatRouteId'
import { useNavigate } from 'react-router-dom'
import useOrganization from '../../../../../hooks/useOrganization'

type ProgramMembersProps = {
  program?: ProgramDetailFieldsFragment
}

type ProgramMembersDataTableProps = {
  id?: string
  email?: string
  name?: string
  url?: string
  role?: string
}

const ProgramMembers: FC<ProgramMembersProps> = ({ program }) => {
  const [removePersonModal, setRemovePersonModal] = useState<{
    id: string | undefined
    name: string | undefined
    open: boolean
  }>({
    id: undefined,
    name: undefined,
    open: false,
  })
  const organization = useOrganization()

  const navigate = useNavigate()

  const members: ProgramMembersDataTableProps[] = []

  if (program?.members) {
    program?.members?.forEach((member: any) => {
      const role = member?.roles[0]
      members.push({
        id: member?.id,
        email: member?.email,
        name: `${member?.person?.firstName} ${member?.person?.lastName}`,
        url: member?.profileImage?.url,
        role,
      })
    })
  }

  const columns: DataTableColumn<ProgramMembersDataTableProps>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        return original?.name ? (
          <div className={'flex items-center gap-[10px]'}>
            <Tooltip delay={1000} title={original?.name} className={'flex items-center gap-[10px] truncate'}>
              <Avatar name={original?.name} src={original?.url} alt={`${original?.name}-icon`} />
              <div className={'truncate'}>
                <Text variant={'h4'} className={'truncate'}>
                  {original?.name}
                </Text>
                <Text variant={'p'} size={'sm'} className={'text-gray-dark truncate'}>
                  {original?.email}
                </Text>
              </div>
            </Tooltip>
          </div>
        ) : null
      },
    },
    {
      header: 'Role',
      accessorKey: 'role',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        let color =
          original?.role === ProgramMemberRole.Leader
            ? 'gold-400'
            : original?.role === ProgramMemberRole.Apprentice
              ? 'bronze-400'
              : 'silver-400'
        return original?.role ? <Label label={original?.role} color={color} /> : null
      },
    },
    {
      header: '',
      accessorKey: 'more',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        return (
          <div className={'flex items-center justify-end'}>
            <Dropdown
              onSelect={(eventKey) => console.log('More', eventKey)}
              placement={'bottom-end'}
              renderToggle={<Button variant={'plain'} icon={<Icon name={'more-horizontal'} size={20} />} />}>
              <Dropdown.Item
                key={'more-manage'}
                onClick={() => console.log('Manage programs')}
                title={'Manage programs'}
                icon={<Icon name={'compass'} size={16} />}
                eventKey={`dropdown-more-item-1`}
              />
              <Dropdown.Item
                key={'more-remove'}
                onClick={() => {
                  setRemovePersonModal({
                    id: original?.id,
                    name: original?.name,
                    open: true,
                  })
                }}
                title={'Remove from program'}
                icon={<Icon name={'alert-triangle'} size={16} color={'#EF4444'} />}
                eventKey={`dropdown-journey-item-2`}
                className={'!text-[#EF4444]'}
              />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div className={'flex justify-center'}>
      <div className={'flex flex-col w-full max-w-[540px]'}>
        <TableTools
          tableName={'members'}
          onSearchInputChange={(val: string) => val}
          search={true}
          viewAll={false}
          addText={'Add member'}
        />
        <DataTable
          name={'members'}
          columns={columns as DataTableColumn<any>[]}
          data={members}
          total={members.length}
          pagination={true}
          selectable={false}
          sortable={false}
          onRowClick={(row) => {
            const { routeId } = formatRouteId(row?.name, row?.id)
            const { routeId: orgRouteId } = formatRouteId(organization?.name, organization?.id)
            navigate(`/organization/${orgRouteId}/user/${routeId}`)
          }}
        />
      </div>
      <Dialogue
        isOpen={removePersonModal.open}
        width="500px"
        height="182px"
        onRequestClose={() => {
          setRemovePersonModal({
            id: undefined,
            name: undefined,
            open: false,
          })
        }}>
        <Dialogue.Header className={'flex justify-between items-start'}>
          <span className={'text-ellipsis overflow-hidden whitespace-nowrap min-w-0'}>Are you sure?</span>
          <div
            onClick={() => {
              setRemovePersonModal({
                id: undefined,
                name: undefined,
                open: false,
              })
            }}
            className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
            <Icon name="x" size={'20'} color={'#333132'} />
          </div>
        </Dialogue.Header>
        <Dialogue.Body>
          {`Are you sure you want to remove ${removePersonModal?.name} from the program ${program?.name}?`}
        </Dialogue.Body>
        <Dialogue.Footer className={'flex justify-end space-x-2'}>
          <Button
            shape={'rounded'}
            size={'sm'}
            variant={'plain'}
            isWide={true}
            onClick={() => {
              setRemovePersonModal({
                id: undefined,
                name: undefined,
                open: false,
              })
            }}>
            Cancel
          </Button>
          <Button
            shape={'rounded'}
            size={'sm'}
            variant={'solid'}
            isWide={true}
            onClick={() => {
              setRemovePersonModal({
                id: undefined,
                name: undefined,
                open: false,
              })
            }}>
            Confirm
          </Button>
        </Dialogue.Footer>
      </Dialogue>
    </div>
  )
}

export default ProgramMembers
