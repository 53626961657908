import React, { FC } from 'react'
import { Skeleton } from '../../../components/ui'

const SkeletonExample: FC = () => {
  return (
    <div className={'flex items-center justify-center gap-20 flex-col align-center !p-[10%]'}>
      <div className={'flex flex-col gap-4'}>
        <Skeleton height={100} width={300} />
        <div className="flex items-center gap-4" style={{ maxWidth: 300 }}>
          <div>
            <Skeleton variant="circle" height={20} width={20} />
          </div>
          <Skeleton height={20} width={300} />
        </div>
        <div className="flex items-center gap-4" style={{ maxWidth: 300 }}>
          <div>
            <Skeleton variant="circle" height={20} width={20} />
          </div>
          <Skeleton height={20} width={300} />
        </div>
        <div className="flex items-center gap-4" style={{ maxWidth: 300 }}>
          <div>
            <Skeleton variant="circle" height={20} width={20} />
          </div>
          <Skeleton height={20} width={300} />
        </div>
      </div>
      <div className={'flex flex-col gap-4'}>
        <div className="flex items-center gap-4" style={{ width: 300 }}>
          <div>
            <Skeleton variant="circle" />
          </div>
          <Skeleton />
        </div>
      </div>
      <div className="flex flex-col gap-4" style={{ maxWidth: 300 }}>
        <Skeleton height={150} width={300} />
        <div className="flex flex-auto items-center gap-2">
          <div>
            <Skeleton variant="circle" height={35} width={35} />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <Skeleton height={10} width={250} />
            <Skeleton height={10} width={200} />
          </div>
        </div>
      </div>
      <div className={'flex flex-col gap-4'}>
        <Skeleton animation={false} height={100} width={300} />
        <div className="flex items-center gap-4" style={{ maxWidth: 300 }}>
          <div>
            <Skeleton animation={false} variant="circle" height={20} width={20} />
          </div>
          <Skeleton animation={false} height={20} width={300} />
        </div>
        <div className="flex items-center gap-4" style={{ maxWidth: 300 }}>
          <div>
            <Skeleton animation={false} variant="circle" height={20} width={20} />
          </div>
          <Skeleton animation={false} height={20} width={300} />
        </div>
        <div className="flex items-center gap-4" style={{ maxWidth: 300 }}>
          <div>
            <Skeleton animation={false} variant="circle" height={20} width={20} />
          </div>
          <Skeleton animation={false} height={20} width={300} />
        </div>
      </div>
    </div>
  )
}

export default SkeletonExample
