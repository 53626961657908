import React, { MouseEvent } from 'react'
import StatusIcon from '../../../../../ui/StatusIcon'
import getTimeLapsedString from '../../../../../../utils/getTimeLapsedString'
import { NotificationType } from '../../../../../../graphql'
import { StatusIconProps } from '../../../../../ui/StatusIcon/StatusIcon'
import { Text } from '../../../../../ui'

export type NotificationDropdownItemProps = {
  prefix?: string | null
  text?: string | null
  onPrefixClick?: (e: MouseEvent<HTMLSpanElement>) => void
  textHtml?: string | null
  timestamp?: Date
  unread?: boolean
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  type?: NotificationType | StatusIconProps['type']
  customIcon?: React.ReactNode
}
const NotificationDropdownItem = ({
  prefix,
  text,
  onPrefixClick,
  timestamp,
  unread = true,
  onClick = () => {},
  customIcon,
  type = NotificationType.Info,
  textHtml,
}: NotificationDropdownItemProps) => {
  const handlePrefixClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (onPrefixClick) {
      e.stopPropagation()
      onPrefixClick(e)
    }
  }

  let prefixClass = 'w-fit font-bold'
  if (onPrefixClick) {
    prefixClass += ' hover:underline'
  }

  const maxTextLength = prefix ? 89 - prefix.length : 89
  if (text && text.length >= maxTextLength) {
    text = text.slice(0, maxTextLength - 3) + '...'
  }

  return (
    <div onClick={onClick} className={'flex w-full w-[449px] py-[3px] gap-[15px] items-center cursor-pointer'}>
      <div className={'flex justify-center items-center w-[40px] h-[40px]'}>
        {customIcon || <StatusIcon type={type.toLowerCase() as StatusIconProps['type']} />}
      </div>
      <div className={'flex flex-col flex-1 justify-start items-start'}>
        {textHtml ? (
          <div className={''} dangerouslySetInnerHTML={{ __html: textHtml }} />
        ) : (
          <Text size={'sm'} className={'text-left'}>
            <span className={prefixClass} onClick={handlePrefixClick}>
              {prefix}
            </span>{' '}
            {text}
          </Text>
        )}
        {timestamp && (
          <Text size={'xs'} className={'text-secondary text-gray-dark font-semibold'}>
            {getTimeLapsedString(timestamp)}
          </Text>
        )}
      </div>
      {unread && <div className={'self-center w-[8px] h-[8px] bg-blue rounded-full'} />}
    </div>
  )
}

export default NotificationDropdownItem
