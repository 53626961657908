import React from 'react'
import useAccountSettings from '../../hooks/useAccountSettings'
import View from '../../components/shared/View'
import AccountSettingsHeader from './components/AccountSettingsHeader'
import { Field, Formik } from 'formik'
import { Alert, Button, Form, Input } from '../../components/ui'
import * as Yup from 'yup'
import ImageUpload from '../../components/shared/ImageUpload'
import { generateHash } from '../../utils/crypto'
import useOrganization from '../../hooks/useOrganization'

type AccountSettingsFields = {
  name: string
  email: string
  image?: {
    name: string
    blob: File
    src: string
    key: string
  }
}

const userInformationValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email().required('Email is required'),
})

const passwordValidationSchema = Yup.object().shape({
  newPassword: Yup.string().required('New Password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match')
    .required('Confirm New Password is required'),
  currentPassword: Yup.string().required('Current Password is required'),
})

const AccountSettings = () => {
  const {
    user,
    loading,
    updateUserInformation,
    updatingUserInformation,
    updatePassword,
    updatingPassword,
    updatePasswordData,
  } = useAccountSettings({
    fetchPolicy: 'cache-and-network',
  })
  const organization = useOrganization()

  console.log(user)

  return (
    <View loading={loading && !user} header={<AccountSettingsHeader />}>
      <div className={'flex justify-center pt-[25px]'}>
        <div className={'flex flex-col gap-[25px] grow max-w-[540px] min-w-0'}>
          <Formik
            initialValues={
              {
                name: user?.person.fullName || '',
                email: user?.email || '',
                image: user?.person.profileImage?.url
                  ? { name: '', blob: null, src: user.person.profileImage.url, key: '' }
                  : undefined,
              } as AccountSettingsFields
            }
            validationSchema={userInformationValidationSchema}
            onSubmit={async ({ image, ...rest }) => {
              await updateUserInformation({
                ...rest,
                profileImage:
                  image?.blob && organization
                    ? {
                        create: {
                          hash: await generateHash(image.blob),
                          ext: image.blob.type.split('/')[1],
                          mime: image.blob.type,
                          size: image.blob.size,
                          name: image.name,
                          url: image.src,
                          folder: organization.id,
                          folderPath: image.key.replace(image.name, ''),
                        },
                      }
                    : undefined,
              })
            }}>
            {({ isValid, touched, errors, values }) => (
              <Form>
                <div className={'flex flex-col gap-[25px]'}>
                  <span className={'h4'}>User Information</span>
                  <div className={'flex gap-[20px] items-center'}>
                    <Field name={'image'} component={ImageUpload} />
                    <Form.Item
                      label={'Name'}
                      errorMessage={touched.name && errors.name ? errors.name : undefined}
                      invalid={!!errors.name}>
                      <Field size={'sm'} name={'name'} component={Input} />
                    </Form.Item>
                  </div>
                  <div className={'flex gap-[20px] items-center'}>
                    <Form.Item
                      label={'Email'}
                      errorMessage={touched.email && errors.email ? errors.email : undefined}
                      invalid={!!errors.email}>
                      <Field size={'sm'} name={'email'} component={Input} />
                    </Form.Item>
                  </div>
                  <Form.Actions layout={'vertical'}>
                    <Button
                      disabled={
                        !isValid ||
                        updatingUserInformation ||
                        (values.name.trim() === user?.person.fullName &&
                          values.email.trim() === user?.email &&
                          values.image?.src === user?.person.profileImage?.url)
                      }
                      type={'submit'}
                      variant={'solid'}
                      size={'sm'}
                      loading={updatingUserInformation}>
                      Update
                    </Button>
                  </Form.Actions>
                </div>
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={{
              newPassword: '',
              confirmNewPassword: '',
              currentPassword: '',
            }}
            validationSchema={passwordValidationSchema}
            onSubmit={async ({ currentPassword, newPassword }, { resetForm }) => {
              await updatePassword(
                {
                  currentPassword,
                  newPassword,
                },
                resetForm,
              )
            }}>
            {({ isValid, touched, errors, values }) => (
              <Form>
                <div className={'flex flex-col gap-[25px]'}>
                  <span className={'h4'}>Password</span>
                  <div className={'flex gap-[20px] items-center'}>
                    <Form.Item
                      label={'New Password'}
                      errorMessage={touched.newPassword && errors.newPassword ? errors.newPassword : undefined}
                      invalid={!!errors.newPassword}>
                      <Field type={'password'} size={'sm'} name={'newPassword'} component={Input} />
                    </Form.Item>
                  </div>
                  <div className={'flex gap-[20px] items-center'}>
                    <Form.Item
                      label={'Confirm New Password'}
                      errorMessage={
                        touched.confirmNewPassword && errors.confirmNewPassword ? errors.confirmNewPassword : undefined
                      }
                      invalid={!!errors.confirmNewPassword}>
                      <Field type={'password'} size={'sm'} name={'confirmNewPassword'} component={Input} />
                    </Form.Item>
                  </div>
                  <div className={'flex gap-[20px] items-center'}>
                    <Form.Item
                      label={'Current Password'}
                      errorMessage={
                        touched.currentPassword && errors.currentPassword ? errors.currentPassword : undefined
                      }
                      invalid={!!errors.currentPassword}>
                      <Field type={'password'} size={'sm'} name={'currentPassword'} component={Input} />
                    </Form.Item>
                  </div>
                  <Alert
                    type={'danger'}
                    show={updatePasswordData?.updatePassword.__typename === 'UpdatePasswordError'}
                    label={updatePasswordData?.updatePassword.message}
                  />
                  <Form.Actions layout={'vertical'}>
                    <Button
                      disabled={
                        !isValid ||
                        updatingPassword ||
                        !values.newPassword.length ||
                        !values.confirmNewPassword.length ||
                        !values.currentPassword.length
                      }
                      type={'submit'}
                      variant={'solid'}
                      size={'sm'}
                      loading={false}>
                      Update Password
                    </Button>
                  </Form.Actions>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </View>
  )
}

export default AccountSettings
