import { Alert, Button, Checkbox, Form, Input, Tag } from '../../../components/ui'
import { Field, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useSignUpMutation, InvitationQuery } from '../../../graphql'
import * as Yup from 'yup'
import { removeConfirmation } from '../../../utils/confirmation'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import useAuth from '../../../hooks/useAuth'

type CreateUserFormProps = {
  invitation: InvitationQuery['invitation']
}

const CreateUserForm = ({ invitation }: CreateUserFormProps) => {
  const { set: setAuth } = useAuth()

  const navigate = useNavigate()

  const [signUp, { data, loading }] = useSignUpMutation({
    update: (cache, { data }) => {
      if (data?.signup.__typename === 'SignupSuccess') {
        setAuth(data.signup.auth, cache)
      }
    },
    onCompleted: ({ signup }) => {
      if (signup.__typename === 'SignupSuccess') {
        navigate('/onboarding')
      }
    },
    /*onCompleted: ({ signup }, clientOptions) => {
      if (signup.__typename === 'SignupSuccess') {
        navigate('/onboarding')
        cache.writeQuery({
          query: MeDocument,
          data: {
            ...signup.user,
            viewingAs: Role.Admin,
            viewingProgramId: null,
          },
        })

        cache.writeFragment({
          data: {
            ...signup,
            __typename: 'LoginSuccess',
            user: {
              ...signup.user,
              viewingAs: Role.Admin,
              viewingProgramId: null,
            },
          },
          id: 'Auth:{}',
          fragment: LoginFieldsFragmentDoc,
        })

        removeConfirmation()
      } else if (signup.__typename === 'SignupError') {
        //setErrorMessage(signup.message)
        //removeConfirmation()
        //navigate('/sign-up')
      }
    },*/
  })

  useEffect(() => {}, [])

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    password: Yup.string().required('Password is required'),
    subscribe: Yup.boolean().nullable(),
  })

  const errorMessage = data?.signup?.__typename?.endsWith('Error') ? data.signup.message : null

  const onClick = () => {
    if (errorMessage) {
      removeConfirmation()
      navigate('/sign-in')
    }
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ name: '', password: '', subscribe: true }}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true)
        await signUp({
          variables: {
            data: {
              ...data,
              email: invitation.email,
            },
          },
        })
        setSubmitting(false)
      }}>
      {({ errors, touched }) => (
        <div className="w-full max-w-[319px] self-center justify-self-center">
          <Form.Container>
            <div>
              <h2>Welcome to Halieus</h2>
              <p className={'body-md'}>To accept this invitation, please enter the following information.</p>
            </div>
            <div>
              <Tag type={'outline'} color={'gray'}>
                {invitation.email}
              </Tag>
            </div>
            <Form.Item
              label={'What is your name?'}
              size={'sm'}
              errorMessage={touched.name && errors.name ? errors.name : undefined}
              invalid={touched.name && !!errors.name}>
              <Field
                disabled={!!errorMessage}
                size={'sm'}
                name={'name'}
                placeholder={'e.g. Joe Schmoe'}
                component={Input}
              />
            </Form.Item>
            <Form.Item
              label={'Set a password'}
              size={'sm'}
              errorMessage={touched.password && errors.password ? errors.password : undefined}
              invalid={touched.password && !!errors.password}>
              <Field
                disabled={!!errorMessage}
                type={'password'}
                size={'sm'}
                name={'password'}
                placeholder={'New password'}
                component={Input}
              />
            </Form.Item>
            <Form.Item size={'sm'}>
              <Field
                disabled={!!errorMessage}
                size={'sm'}
                label={'I agree to Lifestone sending marketing communications about TMS to me.'}
                name={'subscribe'}
                component={Checkbox}
              />
            </Form.Item>
            <div className="h-[4px]" />
            <AnimatePresence>
              {errorMessage && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3, type: 'tween' }}
                  className={'overflow-hidden'}>
                  <Alert label={errorMessage} closeable={false} type={'danger'} />
                </motion.div>
              )}
            </AnimatePresence>
            <Button
              block
              loading={loading}
              label={errorMessage ? 'Back to sign in' : 'Continue'}
              onClick={onClick}
              variant={'solid'}
              size={'sm'}
            />
            <p className={'body-sm'}>
              You may unsubscribe from receiving marketing communications at any time. Lifestone's websites and
              communications are subject to our Privacy Policy
            </p>
          </Form.Container>
        </div>
      )}
    </Formik>
  )
}

export default CreateUserForm
