import React, { ForwardedRef, useMemo, useRef } from 'react'
import ReactSelect, {
  GroupBase,
  Props,
  SelectInstance,
  components,
  CSSObjectWithLabel,
  SingleValue,
  ActionMeta,
} from 'react-select'
import { AsyncPaginateProps, WithAsyncPaginateType } from 'react-select-async-paginate'
import AsyncReactSelect, { AsyncProps } from 'react-select/async'
import Icon from '../Icon'
import Indicator from '../Indicator'
import './_select.css'
import classNames from 'classnames'
import { PortalStyleArgs } from 'react-select/dist/declarations/src/components/Menu'
import Tooltip, { TooltipProps } from '../Tooltip'
import { FieldInputProps, FormikProps } from 'formik'
import { get } from 'lodash'

const DefaultOption = (props: any) => {
  const { label, isSelected, isDisabled, isFocused, innerProps, selectProps, data } = props
  if (isFocused) {
    console.log('focused', props)
  }
  return (
    <div
      className={`p-2 flex gap-2 select-option ${isSelected && 'selected'} ${isDisabled && 'disabled'} ${isFocused && 'bg-gray-50'}`}
      {...innerProps}>
      <span className={`ml-2 ${isSelected ? '' : 'mr-2'}`}>
        {selectProps.formatOptionLabel ? selectProps.formatOptionLabel(data) : label}
      </span>
      {isSelected && <Icon name={'check'} className={'mr-2'} />}
    </div>
  )
}

const DefaultDropdownIndicator = () => {
  return (
    <div className="select-dropdown-indicator">
      <Icon name={`chevron-down`} />
    </div>
  )
}

const DefaultClearIndicator = (props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props
  const clearOnNullValue = !!props.selectProps.clearOnNullValue
  const value = props.getValue()
  if (clearOnNullValue && value?.length === 1 && value[0].value === null) {
    return (
      <div {...restInnerProps} ref={ref}>
        <div className={'select-clear-indicator'} />
      </div>
    )
  }
  return (
    <div {...restInnerProps} ref={ref}>
      <div className="select-clear-indicator">
        <Icon name={'x'} size={16} />
      </div>
    </div>
  )
}

const DefaultLoadingIndicator = () => {
  return <Indicator isSpinning={true} />
}

const DefaultInvalidIndicator = () => {
  return <Icon name={'alert-triangle'} className={'pl-[5px]'} color={'red'} />
}

const DefaultValueContainer = ({ children, ...props }: any) => {
  const { getValue, selectProps } = props
  const clearOnNullValue = !!selectProps.clearOnNullValue
  const value = getValue()
  let newChildren = [...children]
  if (
    !selectProps.inputValue &&
    clearOnNullValue &&
    value?.length === 1 &&
    (value[0].value === null || value[0].id === null)
  ) {
    newChildren[0] = <components.Placeholder {...props}>{selectProps.placeholder}</components.Placeholder>
  }
  return <components.ValueContainer {...props}>{newChildren}</components.ValueContainer>
}

const DefaultSelectContainer = ({ children, ...props }: any) => {
  let _tooltip: TooltipProps | undefined = props.selectProps.tooltip
  if (props.selectProps.menuIsOpen) {
    _tooltip = undefined
  }

  return (
    <Tooltip {..._tooltip}>
      <components.SelectContainer {...props}>{children}</components.SelectContainer>
    </Tooltip>
  )
}

type PaginateProps<Option, IsMulti extends true | false, Additional> = AsyncPaginateProps<
  Option,
  GroupBase<Option>,
  Additional,
  IsMulti
>

export type SelectProps<Option, IsMulti extends true | false, Additional> = (
  | Omit<Props<Option, IsMulti, GroupBase<Option>>, 'form'>
  | Omit<AsyncProps<Option, IsMulti, GroupBase<Option>>, 'form'>
  | Omit<PaginateProps<Option, IsMulti, Additional>, 'form'>
) & {
  size?: 'lg' | 'md' | 'sm' | 'xs'
  componentAs?: ReactSelect | AsyncReactSelect | WithAsyncPaginateType
  errorMessage?: string
  clearOnNullValue?: boolean
  tooltip?: TooltipProps
  invalid?: boolean
  field?: FieldInputProps<unknown>
  form?: FormikProps<unknown>
  name?: string
}

declare module 'react' {
  function forwardRef<T, P>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

const Select = React.forwardRef(function Select<Option, IsMulti extends true | false, Additional>(
  {
    size = 'md',
    components,
    componentAs: Component = ReactSelect,
    errorMessage,
    className,
    styles,
    onChange,
    field,
    form,
    invalid: _invalid,
    ...rest
  }: SelectProps<Option, IsMulti, Additional>,
  ref: ForwardedRef<SelectInstance<Option> | null>,
) {
  const fieldHelpers = form?.getFieldHelpers(field?.name || rest.name || '')

  const invalid = useMemo(() => {
    let isInvalid = false
    let fieldName = field?.name || rest.name
    if (_invalid !== undefined) {
      isInvalid = _invalid
    } else if (form && fieldName) {
      const { touched, errors } = form
      const touchedField = get(touched, fieldName)
      const errorField = get(errors, fieldName)
      isInvalid = !!touchedField && !!errorField
    }
    return isInvalid
  }, [form, _invalid, field, rest.name])

  const selectClass = `select w-full select-${size} ${invalid ? 'invalid' : ''}`
  const SelectComponent: any = Component
  const selectRef = useRef<SelectInstance<Option> | null>(null)

  const isField = !!(field?.name || rest.name)

  return (
    <SelectComponent
      className={classNames(selectClass, className)}
      classNamePrefix={'select'}
      ref={(node: SelectInstance<Option>) => {
        selectRef.current = node
        if (typeof ref === 'function') {
          ref(node)
        } else if (ref) {
          ref.current = node
        }
      }}
      menuPortalTarget={document.body}
      value={isField ? field?.value || undefined : undefined}
      onBlur={async () => {
        await fieldHelpers?.setTouched(true)
      }}
      onChange={async (
        option: IsMulti extends true ? ReadonlyArray<Option> : SingleValue<null> | SingleValue<Option>,
        actionMeta: ActionMeta<Option>,
      ) => {
        await fieldHelpers?.setValue(option)
        onChange?.(option, actionMeta)
      }}
      styles={{
        ...styles,
        menuPortal: (provided: CSSObjectWithLabel, props: PortalStyleArgs) => {
          const style = {
            ...provided,
            zIndex: 10000000,
          }
          if (styles?.menuPortal) {
            return styles.menuPortal(style, props)
          }
          return style
        },
      }}
      menuPosition={'absolute'}
      components={{
        IndicatorSeparator: invalid ? DefaultInvalidIndicator : null,
        Option: DefaultOption,
        LoadingIndicator: DefaultLoadingIndicator,
        DropdownIndicator: DefaultDropdownIndicator,
        ClearIndicator: DefaultClearIndicator,
        ValueContainer: DefaultValueContainer,
        SelectContainer: DefaultSelectContainer,
        ...components,
      }}
      {...rest}
    />
  )
})

export default Select
