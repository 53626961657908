import React, { FC } from 'react'
import { Alert, Button, Dialogue, Form, Input, Text } from '../../../components/ui'
import { Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import * as Yup from 'yup'
import usePersonDetail from '../../../hooks/usePersonDetail'

type AddConnectionProps = {
  open?: boolean
  onClose?: () => void
}

const UpdatePersonDetailContactNotes: FC<AddConnectionProps> = ({ open = false, onClose }) => {
  const { person } = usePersonDetail()
  const { updating } = usePersonDetail()
  const errorMessage = null

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    phone: Yup.string().optional(),
    notes: Yup.string().optional(),
  })

  return (
    <Dialogue isOpen={open} className={'bg-gray-900 bg-opacity-30'} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          email: person?.email || '',
          phone: person?.primaryContact?.phone || '',
          notes: person?.notes[0]?.content || '',
        }}
        onSubmit={async (data) => {
          console.log(data)
        }}>
        {({ errors, touched, getFieldProps, values }) => (
          <div id="edit-user-modal" className="w-[339px] self-center justify-self-center">
            <Form.Container className={'!p-0'}>
              <Text variant={'h2'} className={'leading-[22px]'}>
                Update Info
              </Text>
              <Form.Item
                label={'Email'}
                errorMessage={touched.email && errors.email ? errors.email : undefined}
                invalid={touched.email && !!errors.email}>
                <Input
                  size={'sm'}
                  type={'email'}
                  className={'bg-gray-50'}
                  placeholder={''}
                  invalid={touched.email && !!errors.email}
                  disabled={!!person?.user}
                  {...getFieldProps('email')}
                />
              </Form.Item>
              <Form.Item label={'Phone'}>
                <Input
                  size={'sm'}
                  type={'phone'}
                  placeholder={''}
                  className={'bg-gray-50'}
                  disabled={!!person?.user}
                  {...getFieldProps('phone')}
                />
              </Form.Item>
              <Form.Item label={'Notes'} size={'sm'} className={'relative'}>
                <Text variant={'p'} size={'sm'} className={'text-gray-dark absolute top-[3px] right-0'}>
                  Optional
                </Text>
                <Input
                  size={'sm'}
                  onChange={(e) => {
                    values.notes = e.target.value
                  }}
                  textArea={true}
                  className={'bg-gray-50'}
                  placeholder={''}
                  value={values.notes}
                />
              </Form.Item>
              <AnimatePresence>{errorMessage && <Alert type={'danger'} label={errorMessage} />}</AnimatePresence>
              <div className={`flex flex-col justify-center gap-5`}>
                <Button
                  disabled={updating}
                  type={'submit'}
                  size={'md'}
                  label={'Save'}
                  loading={updating}
                  block
                  variant={'solid'}
                />
                <Button
                  type={'button'}
                  size={'md'}
                  label={'Cancel'}
                  block
                  variant={'default'}
                  onClick={() => onClose?.()}
                />
              </div>
            </Form.Container>
          </div>
        )}
      </Formik>
    </Dialogue>
  )
}

export default UpdatePersonDetailContactNotes
