import React, { ReactNode, PropsWithChildren, MouseEvent } from 'react'
import Indicator from '../Indicator'
import classNames from 'classnames'

export type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  PropsWithChildren & {
    variant?: 'solid' | 'default' | 'plain'
    size?: 'xs' | 'sm' | 'md' | 'lg'
    loading?: boolean
    shape?: 'rounded' | 'circle'
    disabled?: boolean
    icon?: ReactNode
    label?: string
    iconLocation?: 'left' | 'right'
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void
    color?: string
    block?: boolean
    onMouseDown?: (e: MouseEvent<HTMLButtonElement>) => void
    isWide?: boolean
    type?: 'button' | 'submit' | 'reset'
    className?: string
  }

const Button = ({
  variant = 'default',
  size = 'md',
  loading = false,
  shape = 'rounded',
  disabled = false,
  icon,
  label = '',
  iconLocation = 'left',
  onClick,
  children,
  block = false,
  onMouseDown = () => {},
  color: _color,
  isWide = false,
  className = '',
  ...rest
}: ButtonProps) => {
  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (disabled || loading) {
      e.preventDefault()
      return
    }
    onClick?.(e)
  }

  const color = _color || (variant === 'solid' ? 'brand' : 'gray-900')

  const pureColor = color.split('-')[0]

  const getStyles = () => {
    let classList =
      'font-figtree font-semibold flex justify-center items-center leading-[20px] disabled:opacity-50 box-border transition-all'

    switch (variant) {
      case 'solid':
        classList += ` bg-${color} enabled:hover:bg-${color}-400 enabled:active:bg-${color}-600 text-white border-[2px] border-${color} enabled:hover:border-${color}-400 enabled:active:border-${color}-600`
        break
      case 'plain':
        classList += ` border-[2px] text-${color} border-transparent enabled:hover:bg-${pureColor}-100 enabled:hover:border-${pureColor}-100 enabled:active:border-${pureColor}-light enabled:active:bg-${pureColor}-light bg-transparent`
        break
      default:
        classList += ` border-[2px] text-${color} border-gray-light enabled:hover:bg-${pureColor}-100 enabled:active:bg-${pureColor}-light bg-transparent`
        break
    }

    switch (size) {
      case 'xs':
        classList += ` ${label || typeof children === 'string' ? 'py-[3px] px-[8px]' : 'p-[3px]'} text-[14px] gap-[3px]`
        break
      case 'sm':
        classList += ` ${
          label || typeof children === 'string' ? 'py-[6px] px-[10px]' : 'p-[6px]'
        } text-[14px] gap-[3px]`
        break
      case 'md':
        classList += ' p-[10px] text-[14px] gap-[4px]'
        break
      default:
        classList += ' p-[16px] text-[16px] gap-[4px]'
        break
    }

    if (isWide && size === 'xs') {
      classList += ' px-[16px]'
    } else if (isWide && size === 'sm') {
      classList += ' px-[22px]'
    } else if (isWide && size === 'md') {
      classList += ' px-[26px]'
    } else if (isWide && size === 'lg') {
      classList += ' px-[42px]'
    }

    classList += shape === 'circle' ? ' rounded-full' : ' rounded-[10px]'
    classList += block ? ' w-full' : ' w-fit'

    if (className?.length) {
      classList += classNames(classList, className)
    }
    return classList
  }

  const indicator = <Indicator isSpinning={loading} color={variant === 'solid' ? 'white' : 'gray-900'} />

  return (
    <button {...rest} className={getStyles()} disabled={disabled} onClick={handleOnClick} onMouseDown={onMouseDown}>
      {(icon && iconLocation === 'left') || ((label || typeof children === 'string') && !icon && loading)
        ? loading
          ? indicator
          : icon
        : null}
      {label}
      {children}
      {icon && iconLocation === 'right' ? (loading ? indicator : icon) : null}
    </button>
  )
}

export default Button
