import React from 'react'
import { RouteConfig } from './index'
import { Onboarding, NewOrganization, NewProgram } from '../../views/onboard'

const onboardingRoutes: RouteConfig[] = [
  {
    title: 'Onboarding',
    key: 'onboarding',
    path: '/onboarding',
    component: <Onboarding />,
    body: {
      className: 'bg-gray-200',
    },
  },
  {
    title: 'New Organization',
    key: 'newOrganization',
    path: '/new-organization',
    component: <NewOrganization />,
  },
  {
    title: 'New Program',
    key: 'newProgram',
    path: '/new-program',
    component: <NewProgram />,
  },
]

export default onboardingRoutes
