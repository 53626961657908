import React from 'react'
import NewOrganizationForm from './NewOrganizationForm'

const NewOrganization = () => {
  return (
    <div className={'flex flex-col justify-center h-screen min-h-[614px]'}>
      <NewOrganizationForm />
    </div>
  )
}

export default NewOrganization
