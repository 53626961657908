import { useProcessTemplatesQuery, ProcessTemplateFieldsFragment } from '../graphql'
import useOrganization from './useOrganization'
import { useMemo } from 'react'

const useProcessTemplates = () => {
  const organization = useOrganization()

  const { data, loading } = useProcessTemplatesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        OR: [
          {
            tenant: {
              id: {
                equals: organization?.id || '',
              },
            },
          },
          {
            tenant: null,
          },
        ],
      },
    },
  })

  const processTemplates: ProcessTemplateFieldsFragment[] = useMemo(() => data?.processTemplates.list || [], [data])

  return {
    processTemplates,
    loading,
  }
}

export default useProcessTemplates
