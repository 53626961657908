import React, { FC } from 'react'
import Button from '../../components/ui/Button/index'
import Icon from '../../components/ui/Icon/index'
import Divider from '../../components/ui/Divider/Divider'

type Question = {
  question: string
}

type Step = {
  title: string
  questions: Question[]
}

export type SessionData = {
  sessionNumber: number
  sessionTitle: string
  steps: Step[]
}

type Props = {
  sessionData: SessionData
}

const SessionView: FC<Props> = ({ sessionData }) => {
  return (
    <div className={'w-full px-4 lg:px-0 pt-6'}>
      <div className={'max-w-4xl w-full mx-auto'}>
        <div className={'p-10'}>
          <div className={'flex justify-between items-center py-2'}>
            <div style={{ width: '20px' }}></div>{' '}
            {/* placeholder for dropdown, keeps justify-between styling consistent  */}
            <div className={'mb-4'}>
              <Button
                icon={<Icon color={'white'} name={'play'} size={20} />}
                variant={'solid'}
                label={'Resume'}
                size={'md'}
                onClick={() => console.log('Resume clicked')}
              />
            </div>
          </div>
          <Divider className={'mb-6'} />
          <div className={'flex items-center mb-10'}>
            <div
              className={
                'text-md flex justify-center items-center h-10 w-10 bg-white rounded-full text-black font-semibold mr-4 mt-5'
              }>
              {sessionData.sessionNumber}
            </div>
            <h2 className={'text-xl font-semibold leading-none'}>{sessionData.sessionTitle}</h2>
          </div>
          <div className={'bg-white shadow-lg rounded-lg p-4'}>
            <h3 className={'text-lg font-semibold px-5'}>Assignments</h3>
            {sessionData.steps.map((step, index) => (
              <div key={index} className={'mt-4 mb-6 px-6'}>
                <div className={'flex items-center'}>
                  <span className={'text-md font-semibold mb-4'}>
                    {index + 1}. {step.title}
                  </span>
                </div>
                {step.questions.map((question, qIndex) => (
                  <p key={qIndex} className={'text-gray-dark mt-1 ml-4'}>
                    {question.question}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionView
