import React, { FC } from 'react'
import { ProgramDetailFieldsFragment } from '../../../../../graphql'
import DataTable, { DataTableColumn } from '../../../../../components/ui/Table/DataTable'
import { Icon, Text, Tooltip } from '../../../../../components/ui'
import TableTools from '../../../../../components/ui/Table/TableTools'
import AddJourney from './AddJourney'
import formatRouteId from '../../../../../utils/formatRouteId'
import { useNavigate } from 'react-router-dom'

type ProgramJourneyProps = {
  program?: ProgramDetailFieldsFragment
}

type ProgramJourneyDataTableProps = {
  id?: string
  name?: string
}

const ProgramJourneys: FC<ProgramJourneyProps> = ({ program }) => {
  const journeys = program?.journeys ? program?.journeys : []

  const navigate = useNavigate()

  const [openAddJourney, setOpenAddJourney] = React.useState(false)

  const columns: DataTableColumn<ProgramJourneyDataTableProps>[] = [
    {
      header: 'Journey',
      accessorKey: 'name',
      filterFn: 'includesString',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        return original ? (
          <div className={'flex gap-[7px] items-center'}>
            <Tooltip delay={1000} title={original?.name} className={'flex items-center gap-[7px] truncate'}>
              <Icon name={'compass'} size={20} color={'#519D9F'} />
              <Text variant={'h4'} className={'truncate'}>
                {original?.name}
              </Text>
            </Tooltip>
          </div>
        ) : null
      },
    },
    {
      header: '',
      accessorKey: 'more',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        //const { routeId } = formatRouteId(original.name, original.id)
        return original ? (
          <div className={'flex items-center justify-end'}>
            <Icon name={'chevron-right'} size={20} />
          </div>
        ) : null
        /*return original ? (
          <div className={'flex items-center justify-end'}>
            <Button
              variant={'plain'}
              icon={
                <Icon
                  name={'chevron-right'}
                  size={20}
                  onClick={() => console.log('Journey', original?.id, original?.name)}
                />
              }
            />
          </div>
        ) : null*/
      },
    },
  ]

  return (
    <div className={'flex justify-center'}>
      <div className={'flex flex-col w-full max-w-[540px]'}>
        <TableTools
          tableName={'journeys'}
          onSearchInputChange={(val: string) => val}
          search={true}
          viewAll={false}
          addText={'Add journey'}
          onClickAdd={() => setOpenAddJourney(true)}
        />
        <DataTable
          name={'journeys'}
          columns={columns as DataTableColumn<any>[]}
          data={journeys}
          total={journeys.length}
          pagination={true}
          selectable={false}
          sortable={false}
          onRowClick={(row) => {
            const { routeId } = formatRouteId(row.name, row.id)
            navigate(`/journey/${routeId}`)
            console.log('Journey', row?.id)
          }}
        />
      </div>
      <AddJourney open={openAddJourney} onClose={() => setOpenAddJourney(false)} />
    </div>
  )
}

export default ProgramJourneys
