import React from 'react'
import { Route, Routes } from 'react-router-dom'
import View from '../../components/shared/View'
import SeriesHeader from './components/SeriesHeader'
import SeriesView from './views/SeriesView'

const series = {
  name: 'The Name of the Series',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  sessionsCompleted: 5,
  totalSessions: 12,
  phase: {
    id: '1',
    name: 'Equipping',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam',
    color: '#EA580C',
    information:
      '<h2>Instructions and Tips</h2><p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit.</p>',
    __typename: 'Phase',
  },
  sessions: [
    {
      id: '1',
      name: 'The Name of the Session 1',
      totalSteps: 12,
      stepsCompleted: 4,
      status: 'CURRENT',
    },
    {
      id: '2',
      name: 'The Name of the Session 2',
      totalSteps: 6,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
    {
      id: '3',
      name: 'The Name of the Session 3',
      totalSteps: 4,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
    {
      id: '4',
      name: 'The Name of the Session 4',
      totalSteps: 8,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
    {
      id: '5',
      name: 'The Name of the Session 5',
      totalSteps: 2,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
    {
      id: '6',
      name: 'The Name of the Session 6',
      totalSteps: 10,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
    {
      id: '7',
      name: 'The Name of the Session 7',
      totalSteps: 5,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
    {
      id: '8',
      name: 'The Name of the Session 8',
      totalSteps: 7,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
    {
      id: '9',
      name: 'The Name of the Session 9',
      totalSteps: 3,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
    {
      id: '10',
      name: 'The Name of the Session 10',
      totalSteps: 1,
      stepsCompleted: 0,
      status: 'UPCOMING',
    },
  ],
}

const Series = () => {
  // const { series, loading } = useSeries({
  //   fetchPolicy: 'cache-and-network',
  // })
  //
  // useEffect(() => {
  //   if (series) {
  //     console.log('Series:', series)
  //   }
  // }, [series])

  return (
    <View loading={false} header={<SeriesHeader />}>
      <div className={'pt-[10px] w-full flex flex-col justify-center items-center'}>
        <div className={'w-full max-w-[1040px] flex flex-col gap-[25px]'}>
          <Routes>
            <Route path={'/'} element={<SeriesView series={series} />} />
          </Routes>
        </div>
      </div>
    </View>
  )
}

export default Series
