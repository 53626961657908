import React, { FC } from 'react'
import Icon, { IconProps } from '../../../../ui/Icon'
import { useWindowSize } from '../../../../../hooks/useWindowSize'

interface SidePeekToggleProps extends Omit<IconProps, 'name'> {
  collapsed?: boolean
}

const SidePeekToggle: FC<SidePeekToggleProps> = ({ collapsed = false, onClick, ...rest }) => {
  const { width } = useWindowSize()
  const mobile = width < 768
  const iconName = mobile ? 'menu' : collapsed ? 'menu' : 'menu-expanded'

  return <Icon name={iconName} {...rest} size={24} />
}

export default SidePeekToggle
