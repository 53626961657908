import React from 'react'
import { People, Phases, Steps } from '../dummyComponents'
import Organization, { ProgramDetail } from '../../views/organization'
import MyJourney from '../../views/myJourney'
import Dashboard from '../../views/dashboard'
import JourneyDetail from '../../views/organization/views/JourneyDetail'
import PersonDetail from '../../views/person'
import { RouteConfig } from './index'
import Journeys from '../../views/journeys'
import Step from '../../views/step'
import Connections from '../../views/connections'
import SessionExample from '../../views/session/SessionExample'
import Series from '../../views/series'
import Drafts from '../../views/drafts'
import AccountSettings from '../../views/accountSettings'

const protectedRoutes: RouteConfig[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    path: '/dashboard',
    component: <Dashboard />,
    authority: ['apprentice', 'leader'],
  },
  {
    title: 'Session',
    key: 'session',
    path: '/session',
    component: <SessionExample />,
    authority: ['apprentice', 'leader'],
  },
  {
    title: 'People',
    key: 'people',
    path: '/people',
    component: <People />,
  },
  {
    title: 'Connections',
    key: 'connections',
    path: '/connections/:id',
    component: <Connections />,
  },
  {
    title: 'Steps',
    key: 'steps',
    path: '/steps',
    component: <Steps />,
  },
  {
    title: 'Phases',
    key: 'phases',
    path: '/phases',
    component: <Phases />,
  },
  {
    title: 'Journeys',
    key: 'journeys',
    path: '/journeys/:id',
    component: <Journeys />,
    alternate: 'myJourney',
    authority: ['leader'],
  },
  {
    title: 'My Journey',
    key: 'myJourney',
    alternate: 'journeys',
    path: '/my-journey/:id',
    component: <MyJourney />,
    authority: ['apprentice'],
  },
  {
    title: 'Organization',
    key: 'organization',
    path: '/organization/:id/*',
    component: <Organization />,
    authority: ['admin', 'owner'],
  },
  {
    title: 'Program',
    key: 'organization.program',
    path: '/program/:id/*',
    component: <ProgramDetail />,
  },
  {
    title: 'Step',
    key: 'myJourney.step',
    path: '/step/:id',
    component: <Step />,
    authority: ['apprentice', 'leader'],
  },
  {
    title: 'Journey',
    key: 'organization.program.journey',
    path: '/journey/:id',
    component: <JourneyDetail />,
    authority: ['apprentice', 'leader'],
  },
  {
    title: 'User',
    key: 'organization.user',
    path: '/organization/:organizationId/user/:userId',
    component: <PersonDetail />,
    authority: ['admin', 'owner'],
  },
  {
    title: 'Person',
    key: 'connections.person',
    path: '/connection/:connectionId/:personId',
    component: <PersonDetail />,
    authority: ['leader'],
  },
  {
    title: 'Series',
    key: 'phase.series',
    path: '/series/example',
    component: <Series />,
    authority: ['apprentice', 'leader'],
  },
  {
    title: 'Drafts',
    key: 'drafts',
    path: '/drafts',
    component: <Drafts />,
    authority: ['leader'],
  },
  {
    title: 'Account Settings',
    key: 'account',
    path: '/account',
    component: <AccountSettings />,
    authority: ['apprentice', 'leader'],
  },
]

export default protectedRoutes
