import React from 'react'
import MyJourneyHeader from './components/MyJourneyHeader'
import MyJourneyPhase from './views/MyJourneyPhase'
import { Route, Routes } from 'react-router-dom'
import View from '../../components/shared/View'
import useMyJourney from '../../hooks/useMyJourney'
import useRouteId from '../../hooks/useRouteId'
import usePrograms from '../../hooks/usePrograms'

const MyJourney = () => {
  const { selectedProgram } = usePrograms()

  // Get the programId from the route
  const { id: programId } = useRouteId({
    baseRoute: '/my-journey',
    selected: selectedProgram,
    updateSelected: (parsed) => {
      console.log('Try to update the selected program', parsed)
    },
  })

  // Refetch the data from the server in background whenever this component mounts by using cache-and-network
  const { loading, myJourney } = useMyJourney({ fetchPolicy: 'cache-and-network', programId })

  console.log('My journey', myJourney)

  return (
    <View loading={loading && !myJourney} header={<MyJourneyHeader />}>
      <Routes>
        <Route path={'/'} element={<MyJourneyPhase />} />
      </Routes>
    </View>
  )
}

export default MyJourney
