import React, { FC } from 'react'
import { Dialogue } from '../../../components/ui'
import { useDispatch } from 'react-redux'
import { closeModal, toggleModal } from '../../../store/layout/modalSlice'
import NewStepButton from '../components/NewStepButton'
import NewStepImage from '../../../assets/NewStepImage.png'
import NewSeriesImage from '../../../assets/NewSeriesImage.png'

const Compose: FC = () => {
  const dispatch = useDispatch()
  return (
    <>
      <Dialogue.Header className={'grow'} closable onClose={() => dispatch(closeModal())}>
        What would you like to create?
      </Dialogue.Header>
      <Dialogue.Body className={'!overflow-visible'}>
        <div className={'flex flex-row items-center justify-center gap-[20px] flex-wrap'}>
          <NewStepButton
            title={'New Step'}
            description={
              'Create a single step for disciples to complete. Steps may include text to read, a video to watch, or instructions for a task to be completed outside the app.'
            }
            image={NewStepImage}
            onClick={() => dispatch(toggleModal('new-step'))}
          />
          <NewStepButton
            title={'New Series'}
            description={'Create a series of assignments for disciples to complete as one step in their journey.'}
            image={NewSeriesImage}
            onClick={() => console.log('New Series')}
          />
        </div>
      </Dialogue.Body>
    </>
  )
}

export default Compose
