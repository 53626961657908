import React, { FC } from 'react'
import { StepProgressionStatus } from '../../graphql'
import Tag, { TagProps } from '../ui/Tag/Tag'

const formatStatus = (status: string): string => {
  return (status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()).replaceAll('_', '-')
}

const statusStyle = (status: StepProgressionStatus) => {
  switch (status) {
    case 'COMPLETE':
      return {
        color: 'green' as const,
        type: 'solid' as const,
      }
    case 'IN_PROGRESS':
      return {
        color: 'blue' as const,
        type: 'solid' as const,
      }
    default:
      return {
        color: 'gray' as const,
        type: 'outline' as const,
      }
  }
}

type StatusTagProps = Omit<TagProps, 'type' | 'color'> & {
  status: StepProgressionStatus
  tagColor?: 'blue' | 'fuchsia' | 'green' | 'orange' | 'red' | 'yellow' | 'gray' | 'darkGray'
  tagType?: 'solid' | 'outline'
}

const StepIcon: FC<StatusTagProps> = ({ status, tagColor, tagType, ...rest }) => {
  const { color, type } = statusStyle(status)

  return (
    <Tag {...rest} type={tagType ? tagType : type} color={tagColor ? tagColor : color}>
      {formatStatus(status)}
    </Tag>
  )
}

export default StepIcon
