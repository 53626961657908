import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import useControllableState from '../../../hooks/useControllableState'
import DateTable from './tables/DateTable'
import MonthTable from './tables/MonthTable'
import YearTable from './tables/YearTable'
import { CalendarModifiers } from './Calendar'
import useTheme from '../../../hooks/useTheme'

export type CalendarBaseProps = {
  dayClassName?: (date: Date, modifiers: CalendarModifiers) => string
  dayStyle?: (date: Date, modifiers: CalendarModifiers) => React.CSSProperties
  defaultMonth?: Date
  disableDate?: (date: Date) => boolean
  defaultView?: 'date' | 'month' | 'year'
  disableOutOfMonth: boolean
  hideOutOfMonthDates: boolean
  hideWeekdays: boolean
  labelFormat: { weekday: string; month: string; year: string }
  locale: string
  month: Date
  onChange: (date: Date) => void
  onMonthChange: (date?: Date) => void
  preventFocus: boolean
  renderDay?: (date: Date) => React.ReactNode
  value: Date | Date[]
  weekendDays: number[]
  maxDate?: Date
  minDate?: Date
  style?: React.CSSProperties
  range?: [Date, Date]
  dateViewCount?: number
  paginateBy?: number
  enableHeaderLabel?: boolean
  onDayMouseEnter?: (date: Date) => void
  firstDayOfWeek?: 'monday' | 'sunday'
  className?: string
  isDateFirstInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  isDateInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  isDateLastInRange?: (date: Date, modifiers: CalendarModifiers) => boolean
  onMouseLeave?: (e: MouseEvent) => void
  ref?: React.ForwardedRef<unknown>
}

const CalendarBase = (props: CalendarBaseProps) => {
  const {
    className,
    dateViewCount = 1,
    dayClassName = (date: Date, modifiers: CalendarModifiers) => '',
    dayStyle = () => ({}) as React.CSSProperties,
    defaultMonth,
    defaultView = 'date',
    disableDate = () => false,
    disableOutOfMonth,
    enableHeaderLabel = true,
    firstDayOfWeek = 'monday',
    hideOutOfMonthDates,
    hideWeekdays,
    isDateFirstInRange,
    isDateInRange,
    isDateLastInRange,
    labelFormat,
    locale = 'en',
    maxDate,
    minDate,
    month,
    onChange,
    onDayMouseEnter,
    onMonthChange,
    paginateBy = dateViewCount,
    preventFocus,
    range,
    renderDay = (date: Date) => date.getDate().toString(),
    style,
    value,
    weekendDays,
    ref,
    onMouseLeave,
    ...rest
  } = props

  const [selectionState, setSelectionState] = useState<'month' | 'date' | 'year'>(defaultView)

  const { locale: themeLocale } = useTheme()

  const finalLocale = locale || themeLocale

  const daysRefs = useRef<HTMLButtonElement[][][]>(
    Array(dateViewCount)
      .fill(0)
      .map(() => []),
  )

  const [_month, setMonth] = useControllableState({
    prop: month,
    defaultProp: defaultMonth !== undefined ? defaultMonth : new Date(),
    onChange: onMonthChange,
  })

  const [yearSelection, setYearSelection] = useState(_month && 'getFullYear' in _month ? _month.getFullYear() : -1)
  const [monthSelection, setMonthSelection] = useState(_month && 'getFullYear' in _month ? _month.getMonth() : -1)

  const minYear = minDate instanceof Date ? minDate.getFullYear() : 100
  const maxYear = maxDate instanceof Date ? maxDate.getFullYear() : 10000

  const daysPerRow = 6

  const focusOnNextFocusableDay = (direction: string, monthIndex: number, payload: KeyDownPayload, n = 1) => {
    const changeRow = ['down', 'up'].includes(direction)

    const rowIndex = changeRow ? payload.rowIndex + (direction === 'down' ? n : -n) : payload.rowIndex

    const cellIndex = changeRow ? payload.cellIndex : payload.cellIndex + (direction === 'right' ? n : -n)

    const dayToFocus = daysRefs.current[monthIndex][rowIndex][cellIndex]

    if (!dayToFocus) {
      return
    }

    if (dayToFocus.disabled) {
      focusOnNextFocusableDay(direction, monthIndex, payload, n + 1)
    } else {
      dayToFocus.focus()
    }
  }

  const handleDayKeyDown = (monthIndex: number, payload: KeyDownPayload, event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault()

        const hasRowBelow = payload.rowIndex + 1 < daysRefs.current[monthIndex].length
        if (hasRowBelow) {
          focusOnNextFocusableDay('down', monthIndex, payload)
        }
        break
      }
      case 'ArrowUp': {
        event.preventDefault()

        const hasRowAbove = payload.rowIndex > 0
        if (hasRowAbove) {
          focusOnNextFocusableDay('up', monthIndex, payload)
        }
        break
      }
      case 'ArrowRight': {
        event.preventDefault()

        const isNotLastCell = payload.cellIndex !== daysPerRow
        if (isNotLastCell) {
          focusOnNextFocusableDay('right', monthIndex, payload)
        } else if (monthIndex + 1 < dateViewCount) {
          if (daysRefs.current[monthIndex + 1][payload.rowIndex]) {
            daysRefs.current[monthIndex + 1][payload.rowIndex][0]?.focus()
          }
        }
        break
      }
      case 'ArrowLeft': {
        event.preventDefault()

        if (payload.cellIndex !== 0) {
          focusOnNextFocusableDay('left', monthIndex, payload)
        } else if (monthIndex > 0) {
          if (daysRefs.current[monthIndex - 1][payload.rowIndex]) {
            daysRefs.current[monthIndex - 1][payload.rowIndex][daysPerRow].focus()
          }
        }
        break
      }
      default:
        break
    }
  }

  return (
    <div className={classNames('picker-view', className)} {...rest}>
      {selectionState === 'year' && (
        <YearTable
          value={yearSelection}
          minYear={minYear}
          maxYear={maxYear}
          onChange={(year) => {
            if (typeof setMonth === 'function') {
              setMonth(new Date(year, monthSelection, 1))
            }
            setYearSelection(year)
            setSelectionState('date')
          }}
          className={className}
          preventFocus={preventFocus}
          labelFormat={labelFormat}
        />
      )}
      {selectionState === 'month' && (
        <MonthTable
          value={{
            month: _month && 'getMonth' in _month ? _month.getMonth() : -1,
            year: _month && 'getFullYear' in _month ? _month.getFullYear() : -1,
          }}
          year={yearSelection}
          onYearChange={setYearSelection}
          onNextLevel={() => setSelectionState('year')}
          locale={finalLocale}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(monthValue: number) => {
            if (typeof setMonth === 'function') {
              setMonth(new Date(yearSelection, monthValue, 1))
            }
            setMonthSelection(monthValue)
            setSelectionState('date')
          }}
          className={className}
          preventFocus={preventFocus}
          labelFormat={labelFormat}
        />
      )}
      {selectionState === 'date' && (
        <DateTable
          dateViewCount={dateViewCount}
          paginateBy={paginateBy}
          month={typeof _month === 'object' ? _month : new Date()}
          locale={finalLocale}
          minDate={minDate}
          maxDate={maxDate}
          enableHeaderLabel={enableHeaderLabel}
          daysRefs={daysRefs}
          onMonthChange={typeof setMonth === 'function' ? setMonth : () => {}}
          onNextLevel={(view: 'month' | 'date' | 'year') => setSelectionState(view)}
          onDayKeyDown={handleDayKeyDown}
          dayClassName={dayClassName}
          dayStyle={dayStyle}
          disableOutOfMonth={disableOutOfMonth}
          disableDate={disableDate}
          hideWeekdays={hideWeekdays}
          preventFocus={preventFocus}
          firstDayOfWeek={firstDayOfWeek}
          // @ts-ignore
          value={Array.isArray(value) && value.length > 0 ? value[0] : [value]}
          range={range}
          onChange={onChange}
          labelFormat={labelFormat}
          onDayMouseEnter={onDayMouseEnter || (() => {})}
          renderDay={renderDay}
          hideOutOfMonthDates={hideOutOfMonthDates}
          isDateInRange={isDateInRange}
          isDateFirstInRange={isDateFirstInRange}
          isDateLastInRange={isDateLastInRange}
          weekendDays={weekendDays}
        />
      )}
    </div>
  )
}

export default CalendarBase
