import React, { FC, ReactNode } from 'react'
import { Form } from 'formik'
import classNames from 'classnames'

type FormContainerProps = {
  children: ReactNode
  className?: string
}

const FormContainer: FC<FormContainerProps> = ({ children, className }) => {
  return (
    <Form>
      <div className={classNames('w-full flex flex-col gap-5 p-[20px] bg-white', className)}>{children}</div>
    </Form>
  )
}

export default FormContainer
