import React from 'react'
import Select, { SelectProps } from '../../Select'

function DefaultFilter<Option, IsMulti extends true | false, Additional>({
  ...props
}: SelectProps<Option, IsMulti, Additional>) {
  return <Select size={'xs'} {...props} />
}

export default DefaultFilter
