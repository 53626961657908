import React, { FC, ReactNode, forwardRef } from 'react'
import Line from './Line'
import Circle from './Circle'

type ProgressLoaderProps = {
  color?: string
  customInfo?: string | ReactNode
  icon?: ReactNode
  gapDegree?: number
  gapPosition?: 'top' | 'right' | 'bottom' | 'left'
  percent?: number
  showInfo?: boolean
  size?: 'sm' | 'md' | 'lg'
  strokeLinecap?: 'round' | 'square'
  strokeWidth?: number
  variant?: 'line' | 'circle'
  width?: number | string
  className?: string
  infoPosition?: 'center' | 'right'
}

const ProgressLoader: FC<ProgressLoaderProps> = forwardRef<HTMLDivElement, ProgressLoaderProps>((props, ref) => {
  const {
    variant = 'line',
    color,
    percent = 0,
    showInfo = true,
    customInfo,
    icon,
    className,
    width,
    strokeLinecap = 'round',
    strokeWidth = 6,
    gapDegree = 0,
    gapPosition = 'top',
    size,
    infoPosition = 'right',
  } = props

  const renderProcessInfo = () => {
    if (!showInfo) return null
    return (
      <span className={`inline-block ${variant === 'line' ? (document.dir === 'rtl' ? 'mr-2' : 'ml-2') : ''}`}>
        {customInfo || `${percent}%`}
      </span>
    )
  }

  const renderProgress = () => {
    const progressInfo = renderProcessInfo()
    if (variant === 'line') {
      return (
        <Line
          strokeColor={color}
          percent={percent}
          size={size}
          strokeLinecap={strokeLinecap}
          customInfo={progressInfo}
          showInfo={showInfo}
        />
      )
    }
    if (variant === 'circle') {
      return (
        <div style={{ position: 'relative' }}>
          <Circle
            showInfo={showInfo}
            percent={percent}
            size={size}
            customInfo={progressInfo}
            strokeLinecap={strokeLinecap}
            strokeWidth={strokeWidth}
            gapDegree={gapDegree}
            gapPosition={gapPosition}
            width={width}
            infoPosition={infoPosition}
            strokeColor={color}
          />
          {icon && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              {icon}
            </div>
          )}
        </div>
      )
    }
  }

  return (
    <div ref={ref} className={className}>
      {renderProgress()}
    </div>
  )
})

export default ProgressLoader
