import React, { FC } from 'react'
import ViewHeader from '../../../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../../../components/shared/BreadcrumbBar'
import useJourneyDetail from '../../../../../hooks/useJourneyDetail'
import useRouteId from '../../../../../hooks/useRouteId'
import useOrganization from '../../../../../hooks/useOrganization'
import useProgramDetail from '../../../../../hooks/useProgramDetail'
import formatRouteId from '../../../../../utils/formatRouteId'

const JourneyHeader: FC = () => {
  const organization = useOrganization()
  const { program } = useProgramDetail()
  const { journey } = useJourneyDetail()

  const { name, routeId } = useRouteId({
    baseRoute: '/journey',
    selected: journey,
  })

  const _program = journey?.program || program
  const _organization = journey?.program?.tenant || organization

  const { routeId: programRouteId } = formatRouteId(_program?.name, _program?.id)
  const { routeId: organizationRouteId } = formatRouteId(_organization?.name, _organization?.id)

  return (
    <ViewHeader
      start={[
        <BreadcrumbBar
          routeTitleMap={{
            organization: _organization?.name,
            program: _program?.name,
            journey: journey?.name || name,
          }}
          routePathMap={{
            organization: `/organization/${organizationRouteId}`,
            program: `/program/${programRouteId}`,
            journey: `/journey/${routeId}`,
          }}
        />,
      ]}
    />
  )
}

export default JourneyHeader
