import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import useToast from '../../../../../hooks/useToast'
import { Alert, Button, Dialogue, Form, Icon, Input, Text, Tooltip } from '../../../../../components/ui'
import { Field, Formik } from 'formik'
import Select from '../../../../../components/ui/Select'
import { MultiValue, SingleValue } from 'react-select'
import usePrograms from '../../../../../hooks/usePrograms'
import useOrganization from '../../../../../hooks/useOrganization'
import useProcessTemplates from '../../../../../hooks/useProcessTemplates'
import { AnimatePresence } from 'framer-motion'

type AddProgramProps = {
  open?: boolean
  onClose?: () => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

const AddProgram: FC<AddProgramProps> = ({ open = false, onClose }) => {
  const organization = useOrganization()
  const toast = useToast()
  const [process, setProcess] = useState<
    | MultiValue<{ value: string | undefined; label: string | undefined }>
    | SingleValue<{ value: string | undefined; label: string | undefined }>
  >({
    label: undefined,
    value: undefined,
  })
  const { processTemplates } = useProcessTemplates()

  useEffect(() => {
    setProcess({
      label: processTemplates[0]?.name,
      value: processTemplates[0]?.id,
    })
  }, [processTemplates])

  const { createProgram, loadingCreateProgram, createProgramData } = usePrograms()

  const showAlert = createProgramData?.createProgram.__typename === 'CreateProgramError'
  const alertMessage = createProgramData?.createProgram.message

  return (
    <Dialogue isOpen={open} className={'bg-gray-900 bg-opacity-30'} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: '',
        }}
        onSubmit={async (data) => {
          if (organization) {
            await createProgram({
              variables: {
                data: {
                  name: data.name,
                  tenantId: organization.id,
                  processTemplateId: processTemplates[0]?.id || '',
                },
              },
              onCompleted: (data) => {
                if (data.createProgram.__typename === 'CreateProgramSuccess') {
                  toast.push(data.createProgram.message, data.createProgram.program.name, 'success')
                  onClose?.()
                }
              },
              onError: (error) => {
                toast.push('Error', error.message, 'error')
              },
            })
          } else {
            toast.push('Error', 'There was a problem creating the program', 'error')
          }
        }}>
        {({ errors, touched, getFieldProps }) => (
          <div id="add-program-modal" className="w-[339px] self-center justify-self-center">
            <Form.Container className={'!p-0'}>
              <Text variant={'h2'} className={'leading-[22px]'}>
                Add a New Program
              </Text>
              <Form.Item
                label={'Name'}
                errorMessage={touched.name && errors.name ? errors.name : undefined}
                invalid={touched.name && !!errors.name}>
                <Input
                  size={'sm'}
                  type={'name'}
                  className={'bg-gray-50'}
                  placeholder={''}
                  invalid={touched.name && !!errors.name}
                  {...getFieldProps('name')}
                />
              </Form.Item>
              <Form.Item label={'Process'} size={'sm'}>
                <Tooltip
                  delay={1000}
                  title={"This feature isn't available yet. Please contact LifeStone to make changes to your process"}>
                  <Field
                    nsme={'process'}
                    key={'program-process-dropdown'}
                    isDisabled
                    cacheOptions
                    defaultOptions
                    value={process}
                    options={processTemplates.map((template) => ({
                      label: template.name,
                      value: template.id,
                    }))}
                    className={'program-process-dropdown'}
                    component={Select}
                  />
                </Tooltip>
              </Form.Item>
              <AnimatePresence>{showAlert && <Alert type={'danger'} label={alertMessage} />}</AnimatePresence>
              <div className={`flex flex-col justify-center gap-5`}>
                <Button
                  disabled={loadingCreateProgram}
                  type={'submit'}
                  size={'md'}
                  icon={<Icon name={'plus'} size={20} color={'white'} />}
                  label={'Add Program'}
                  loading={loadingCreateProgram}
                  block
                  variant={'solid'}
                />
                <Button
                  type={'button'}
                  size={'md'}
                  label={'Cancel'}
                  onClick={onClose}
                  loading={false}
                  block
                  variant={'default'}
                />
              </div>
            </Form.Container>
          </div>
        )}
      </Formik>
    </Dialogue>
  )
}

export default AddProgram
