import React, { FC } from 'react'
import { Icon, Text } from '../ui'

type StepTypeInfoProps = {
  color?: string
  name: string
  description: string
  icon: IconName | null
}

const StepTypeInfo: FC<StepTypeInfoProps> = ({ color, name, description, icon }) => {
  return (
    <div className={'flex flex-col gap-[10px]'}>
      <div className={'flex gap-[8px] items-center'}>
        {icon && <Icon size={24} name={icon} color={color} />}
        <Text variant={'h4'}>{name}</Text>
      </div>
      <Text>{description}</Text>
    </div>
  )
}

export default StepTypeInfo
