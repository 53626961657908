import { useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import useOrganization from '../../hooks/useOrganization'
import { ViewAs } from '../../graphql'
import { useNavigate } from 'react-router'

const Dashbaord = () => {
  const { user } = useAuth()
  const organization = useOrganization()

  const navigate = useNavigate()

  useEffect(() => {
    if (organization?.member) {
      navigate('/organization/:id')
    } else if (user?.viewingAs === ViewAs.Apprentice) {
      navigate('/my-journey/:id')
    } else if (user?.viewingAs === ViewAs.Leader) {
      navigate('/journeys/:id')
    }
  }, [navigate, user?.viewingAs, organization?.member])

  return null
}

export default Dashbaord
