import React, { FC, useState } from 'react'
import useJourneyDetail from '../../../../hooks/useJourneyDetail'
import View from '../../../../components/shared/View'
import JourneyHeader from './components/JourneyHeader'
import { Formik } from 'formik'
import { Button, Dialogue, Form, Icon, Input } from '../../../../components/ui'

type FormValues = {
  name: string
  description: string
}

const JourneyView: FC = () => {
  const { journey, loading, updateJourney, updating } = useJourneyDetail({ fetchPolicy: 'cache-and-network' })

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const handleDeleteModalOpen = () => setDeleteModalOpen(true)
  const handleDeleteModalClose = () => setDeleteModalOpen(false)

  const handleDelete = () => {
    console.log('Program deleted')
    handleDeleteModalClose()
  }

  return (
    <View loading={loading && !journey} header={<JourneyHeader />}>
      <div className={'flex justify-center'}>
        <div className={'flex flex-col gap-[25px] grow max-w-[540px]'}>
          <div>
            <h2>{journey?.name}</h2>
            <p>{"Manage this journey's name and description"}</p>
          </div>
          <Formik
            initialValues={{
              name: journey?.name,
              description: journey?.description,
            }}
            enableReinitialize={true}
            validate={(values) => {
              const errors: Partial<FormValues> = {}
              if (!values?.name?.trim()) {
                errors.name = 'Name is required'
              }
              return errors
            }}
            onSubmit={async (values) => {
              await updateJourney({
                name: values.name || '',
                description: values.description || undefined,
              })
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={'flex flex-col gap-[20px]'}>
                  <Form.Item label={'Name'}>
                    <Input size={'sm'} name={'name'} value={values.name} onChange={handleChange} onBlur={handleBlur} />
                    {errors.name && touched.name && <div className={'text-red'}>{errors.name}</div>}
                  </Form.Item>
                  <Form.Item label={'Description'}>
                    <Input
                      size={'sm'}
                      name={'description'}
                      value={values?.description || undefined}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      textArea
                    />
                  </Form.Item>
                  <Form.Actions layout={'vertical'}>
                    <Button
                      type={'submit'}
                      variant={'solid'}
                      size={'sm'}
                      loading={updating}
                      disabled={
                        updating ||
                        !values?.name?.trim() ||
                        (values.name.trim() === journey?.name && values.description?.trim() === journey?.description)
                      }>
                      {'Update'}
                    </Button>
                  </Form.Actions>
                </div>
              </form>
            )}
          </Formik>
          <div className={'flex flex-col gap-[20px]'}>
            <div>
              <h4>{'Delete Journey'}</h4>
              <p>{'If you want to permanently delete this journey and all its data, you can do so below.'}</p>
            </div>
            <Button
              icon={<Icon color={'red'} name={'trash-2'} />}
              className={'border-red'}
              color={'red'}
              size={'sm'}
              onClick={handleDeleteModalOpen}>
              {'Delete this Journey'}
            </Button>
          </div>
        </div>
        <Dialogue
          isOpen={isDeleteModalOpen}
          width="500px"
          height="185px"
          onRequestClose={() => {
            setDeleteModalOpen(false)
          }}>
          <Dialogue.Header className={'flex justify-between !items-start'}>
            <span
              className={'min-w-0'}>{`Are you sure you want to permanently delete the journey ${journey?.name}?`}</span>
            <div
              onClick={() => {
                setDeleteModalOpen(false)
              }}
              className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
              <Icon name="x" size={'20'} color={'#333132'} />
            </div>
          </Dialogue.Header>
          <Dialogue.Body>This action cannot be undone.</Dialogue.Body>
          <Dialogue.Footer className={'flex justify-end space-x-2'}>
            <Button
              shape={'rounded'}
              size={'sm'}
              variant={'plain'}
              isWide={true}
              onClick={() => {
                setDeleteModalOpen(false)
              }}>
              Cancel
            </Button>
            <Button
              shape={'rounded'}
              size={'sm'}
              variant={'solid'}
              color={'red-400'}
              isWide={true}
              onClick={() => {
                handleDelete()
                setDeleteModalOpen(false)
              }}>
              Delete journey
            </Button>
          </Dialogue.Footer>
        </Dialogue>
      </div>
    </View>
  )
}

export default JourneyView
