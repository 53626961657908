import React, { FC, useMemo } from 'react'
import useMyJourney from '../../../hooks/useMyJourney'
import { Text } from '../../../components/ui'
import StepTypeInfo from '../../../components/shared/StepTypeInfo'

const placeHolderStepDescription =
  'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.'

const MyJourneyInfo: FC = () => {
  const { myJourney } = useMyJourney()

  // Remove top margin from first HTML element
  const removeTopMargin = (html: string) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    doc.body.firstElementChild?.classList.add('mt-0')

    return doc.body.innerHTML
  }

  const innerHTML = useMemo(() => {
    // Todo: Replacing the inner HTML of a phase description can be deprecated since this is now done via css
    if (myJourney?.currentPhase?.phase.information) {
      return removeTopMargin(myJourney.currentPhase.phase.information)
    }
    return undefined
  }, [myJourney?.currentPhase?.phase.information])

  return (
    <div className={'flex flex-col gap-[30px]'}>
      <div>
        <Text className={'text-gray'}>{myJourney?.currentPhase?.phase.name}</Text>
        <div dangerouslySetInnerHTML={innerHTML ? { __html: innerHTML } : undefined} />
      </div>
      <div className={'flex flex-col gap-[20px]'}>
        <Text variant={'h2'}>Types of Steps</Text>
        <div className={'border-[1px] border-gray-300'} />
        <StepTypeInfo
          color={myJourney?.currentPhase?.phase.color}
          name={'Read'}
          icon={'file-text'}
          description={placeHolderStepDescription}
        />
        <StepTypeInfo
          color={myJourney?.currentPhase?.phase.color}
          name={'Watch'}
          icon={'tv'}
          description={placeHolderStepDescription}
        />
        <StepTypeInfo
          color={myJourney?.currentPhase?.phase.color}
          name={'Task'}
          icon={'check-square'}
          description={placeHolderStepDescription}
        />
        <StepTypeInfo
          color={myJourney?.currentPhase?.phase.color}
          name={'Series'}
          icon={'layers'}
          description={placeHolderStepDescription}
        />
      </div>
    </div>
  )
}

export default MyJourneyInfo
