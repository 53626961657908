import React, { FC } from 'react'
import Icon, { IconProps } from '../ui/Icon'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '../../store/layout/drawerSlice'
import useLayout from '../../hooks/useLayout'

type DrawerButtonProps = Omit<IconProps, 'name'> & {
  drawer?: string
  selected?: boolean
  iconName?: IconName
}

const DrawerButton: FC<DrawerButtonProps> = ({
  className,
  onClick,
  drawer,
  selected: _selected,
  iconName = 'info',
  ...props
}) => {
  const dispatch = useDispatch()
  const {
    drawer: { key, show },
  } = useLayout()

  const selected = _selected || (key === drawer && show)

  return (
    <div
      className={'relative'}
      onClick={(event) => {
        if (onClick) {
          onClick(event)
        }
        if (drawer && !event.defaultPrevented) {
          dispatch(toggleDrawer(drawer))
        }
      }}>
      <div
        className={`${selected ? 'bg-gray-light cursor-pointer' : ''} absolute top-[-4px] bottom-[-4px] left-[-4px] right-[-4px] rounded-[5px] transition duration-150`}
      />
      <Icon
        size={24}
        name={iconName}
        {...props}
        className={classNames(`${selected ? '' : 'hover:text-gray'}`, 'relative cursor-pointer', className)}
      />
    </div>
  )
}

export default DrawerButton
