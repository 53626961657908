import { useMemo } from 'react'
import { apprenticeDrawers } from '../../../../config/drawers'
// Todo: differentiate between leader and apprentice drawers
import useLayout from '../../../../hooks/useLayout'

const ApprenticeUtilities = () => {
  const {
    drawer: { key },
  } = useLayout()

  const component = useMemo(() => {
    return apprenticeDrawers.find((drawer) => drawer.key === key)?.component
  }, [key])

  return component ? component : null
}

export default ApprenticeUtilities
