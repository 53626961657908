const formatRouteId = (name?: string, id?: string) => {
  const selectedName =
    name && name !== ''
      ? `${name
          .replaceAll(/[^A-Za-z0-9]/g, ' ') // Remove everything but letters and numbers and replace with spaces
          .replaceAll(/\s+/g, ' ') // Condense multiple spaces into one
          .trim() // Remove any leading or trailing spaces
          .replaceAll(' ', '-')}-` // Replace spaces with dashes and add a trailing dash for the ID
      : '' // If there is no name, don't add anything
  const selectedId = id?.replaceAll('-', '')

  return {
    routeId: `${selectedName}${selectedId}`,
    formattedName: selectedName,
    formattedId: selectedId,
  }
}

export default formatRouteId
