import React, { FC, Children, ReactElement } from 'react'
import Avatar, { AvatarProps } from '../Avatar/index'
import Tooltip from '../Tooltip/index'

export type AvatarGroupProps = {
  chained?: boolean
  maxCount?: number
  omittedAvatarProps?: Partial<AvatarProps>
  children?: React.ReactNode
  onOmittedAvatarClick?: () => void
  omittedAvatarContent?: string | React.ReactNode
  omittedAvatarTooltip?: boolean
}

const AvatarGroup: FC<AvatarGroupProps> = ({
  chained = false,
  maxCount = 4,
  omittedAvatarProps = {},
  children,
  onOmittedAvatarClick,
  omittedAvatarContent,
  omittedAvatarTooltip = false,
}) => {
  let avatars = Children.toArray(children) as ReactElement[]
  const displayedAvatars = avatars.slice(0, maxCount)
  const remainingAvatars = avatars.slice(maxCount)

  const overlapClass = chained ? '-ml-2' : ''
  const { shape = 'circle', size = 'md', ...otherOmittedAvatarProps } = omittedAvatarProps
  const omittedAvatarCount = Math.abs(maxCount - avatars.length)

  const avatar = (
    <Tooltip title={omittedAvatarTooltip ? `+${omittedAvatarCount} More` : undefined}>
      <Avatar
        onClick={onOmittedAvatarClick ? () => onOmittedAvatarClick() : undefined}
        {...otherOmittedAvatarProps}
        shape={shape}
        size={size}
        grouped>
        {typeof omittedAvatarContent === 'string' ? omittedAvatarContent : `+${omittedAvatarCount}`}
      </Avatar>
    </Tooltip>
  )

  return (
    <div className="flex flex-row">
      {displayedAvatars.map((avatar, index) => (
        <div className={index === 0 ? '' : overlapClass} key={index}>
          {React.cloneElement(avatar, { ...avatar.props, grouped: true })}
        </div>
      ))}
      {remainingAvatars.length > 0 && <div className={overlapClass}>{avatar}</div>}
    </div>
  )
}

export default AvatarGroup
