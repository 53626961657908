import { Avatar, Icon, Indicator } from '../../components/ui'
import React, { ChangeEvent, useRef, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import useToast from '../../hooks/useToast'
import { FieldProps } from 'formik'
import { Text } from '../../components/ui'
import useUpload from '../../hooks/useUpload'

type ImageUploadProps = {
  fileList?: File[]
  //beforeUpload?: (file: FileList | null, fileList: File[]) => boolean | string
  onChange?: (file: File[], fileList: File[]) => void
  field?: FieldProps['field']
  form?: FieldProps['form']
}

type FileField = {
  name: string
  blob: File
  src: string
}

const filesToArray = (files: File[]) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.keys(files).map((key) => files[key as any])

const ImageUpload = ({ fileList = [], onChange, field, form }: ImageUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState(fileList)
  const [fileField, setFileField] = useState<FileField | null>(null)

  const toast = useToast()

  const { start: startUpload, uploading } = useUpload({
    onComplete: ({ data, src, blob, fileName }) => {
      if (form && field) {
        form?.setFieldValue(field?.name, {
          name: fileName,
          blob,
          src: src,
          key: data.Key,
        })
        setFileField(null)
      }
    },
  })

  const triggerMessage = (msg = 'Failed to upload file') => {
    toast.push('Upload Failed!', msg, 'error')
  }

  const beforeUpload = (file: FileList | null) => {
    let valid: boolean | string = true as boolean

    const allowedFileType = ['image/jpeg', 'image/jpg', 'image/png']
    const maxFileSize = 1e7

    if (file) {
      for (const f of Array.from(file)) {
        if (!allowedFileType.includes(f.type)) {
          valid = 'Please upload a .jpeg or .png file!'
        }

        if (f.size >= maxFileSize) {
          valid = 'Upload image cannot more then 1MB!'
        }
      }
    }

    return valid
  }

  const pushFile = (newFiles: FileList | null, file: File[]) => {
    if (newFiles) {
      for (const f of Array.from(newFiles)) {
        file.push(f)
      }
    }

    return file
  }

  const addNewFiles = (newFiles: FileList | null) => {
    let file = cloneDeep(files)
    // Hardcode upload limit to just 1 file for this component
    const uploadLimit: number = 1
    if (typeof uploadLimit === 'number' && uploadLimit !== 0) {
      if (Object.keys(file).length >= uploadLimit) {
        if (uploadLimit === 1) {
          file.shift()
          file = pushFile(newFiles, file)
        }

        return filesToArray({ ...file })
      }
    }
    file = pushFile(newFiles, file)
    return filesToArray({ ...file })
  }

  const onNewFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files: newFiles } = e.target
    let result: boolean | string = true

    if (beforeUpload) {
      result = beforeUpload(newFiles /*, files*/)

      if (result === false) {
        triggerMessage()
        return
      }

      if (typeof result === 'string' && result.length > 0) {
        triggerMessage(result)
        return
      }
    }

    if (result) {
      const updatedFiles = addNewFiles(newFiles) as any[]
      setFiles(updatedFiles)

      if (updatedFiles[0]) {
        const file = {
          name: updatedFiles[0].name as string,
          blob: updatedFiles[0] as File,
          src: URL.createObjectURL(updatedFiles[0]),
        }
        setFileField(file)
        startUpload(file)
      }

      onChange?.(updatedFiles, files)
    }
  }

  return (
    <div className={'relative cursor-pointer'} onClick={() => fileInputRef.current?.click()}>
      <input
        accept={'.jpg,.png,.jpeg'}
        ref={fileInputRef}
        className={'hidden'}
        type={'file'}
        onChange={onNewFileUpload}
      />
      {field?.value?.src && (
        <div
          className={
            'absolute flex items-center justify-center top-0 bottom-0 left-0 right-0 opacity-0 transition-opacity hover:opacity-100 bg-white bg-opacity-80'
          }>
          <Text className={'text-brand text-sm font-bold'}>Change</Text>
        </div>
      )}
      {uploading && (
        <div className={'absolute flex items-center justify-center top-0 bottom-0 left-0 right-0'}>
          <Indicator isSpinning />
        </div>
      )}
      <Avatar
        size={65}
        shape={'rounded'}
        className={`bg-gray-400 transition-colors ${uploading ? 'opacity-15' : ''} ${!field?.value?.src ? 'hover:bg-gray-light' : ''}`}
        src={fileField?.src || field?.value?.src}
        icon={<Icon size={24} name={'plus'} />}
      />
    </div>
  )
}

export default ImageUpload
