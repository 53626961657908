import React, { useEffect, useCallback } from 'react'
import { findDOMNode } from 'react-dom'

const domContains = (context: HTMLDivElement, node: Node) => {
  if (context.contains) {
    return context.contains(node)
  } else if (context.compareDocumentPosition) {
    return context === node || !!(context.compareDocumentPosition(node) & 16)
  }
  if (node) {
    do {
      if (node === context) {
        return true
      }
    } while (node.parentNode && (node = node.parentNode))
  }
  return false
}

const getRefTarget = (ref: React.ForwardedRef<HTMLDivElement>) => {
  return ref && ('current' in ref ? ref.current : ref)
}

function getDOMNode(elementOrRef: HTMLDivElement | React.ForwardedRef<HTMLDivElement>) {
  if (!elementOrRef) return null
  const element: HTMLDivElement | null =
    'root' in elementOrRef
      ? (elementOrRef.root as HTMLDivElement)
      : 'child' in elementOrRef
        ? (elementOrRef.child as HTMLDivElement)
        : 'current' in elementOrRef
          ? (getRefTarget(elementOrRef) as HTMLDivElement)
          : null

  if (element?.nodeType) {
    return element
  }

  // eslint-disable-next-line react/no-find-dom-node
  return findDOMNode(element)
}

function isLeftClickEvent(e: MouseEvent) {
  return e?.button === 0
}

function isModifiedEvent(e: KeyboardEvent) {
  return e.metaKey || e.altKey || e.ctrlKey || e?.shiftKey
}

function onEventListener(
  target: Node,
  eventType: string,
  listener: EventListenerOrEventListenerObject | null,
  options?: boolean | EventListenerOptions | undefined,
) {
  target.addEventListener(eventType, listener, options)

  return {
    off() {
      target.removeEventListener(eventType, listener, options)
    },
  }
}

function useRootClose(
  onRootClose: (e: KeyboardEvent | MouseEvent) => void,
  {
    disabled,
    triggerTarget,
    overlayTarget,
  }: {
    disabled: boolean
    triggerTarget: HTMLDivElement | null
    overlayTarget: HTMLDivElement | null
    listenEscape?: boolean
  },
) {
  const handleDocumentMouseDown = useCallback(
    (event: KeyboardEvent | MouseEvent) => {
      const triggerElement = getDOMNode(triggerTarget) as HTMLDivElement
      const overlayElement = getDOMNode(overlayTarget) as HTMLDivElement

      if (triggerElement && domContains(triggerElement, event.target as Node)) {
        return
      }

      if (overlayElement && domContains(overlayElement, event.target as Node)) {
        return
      }

      if (('code' in event && isModifiedEvent(event)) || ('button' in event && !isLeftClickEvent(event))) {
        return
      }

      onRootClose?.(event)
    },
    [onRootClose, triggerTarget, overlayTarget],
  )

  useEffect(() => {
    const currentTarget = getDOMNode(triggerTarget)

    if (disabled || !currentTarget) return

    const doc = () => (currentTarget && currentTarget.ownerDocument) || document
    const onDocumentMouseDownListener = onEventListener(
      doc(),
      'mousedown',
      handleDocumentMouseDown as EventListener,
      true,
    )

    return () => {
      onDocumentMouseDownListener?.off()
    }
  }, [triggerTarget, disabled, onRootClose, handleDocumentMouseDown])
}

export default useRootClose
