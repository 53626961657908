import React from 'react'
import { NavigationItemConfig } from '../../../../config/navigation/navigationItems'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '../../../ui'
import { selectCurrentRouteKey } from '../../../../store/selectors/Selectors'
import { useNavigate } from 'react-router-dom'
import { setShowSidePeek } from '../../../../store/layout/sidePeekSlice'

export type SidePeekItemProps = NavigationItemConfig & {
  routeKey: string
  collapsed?: boolean
}

const SidePeekItem = ({ routeKey, title, path, icon, collapsed }: SidePeekItemProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentRouteKey = useSelector(selectCurrentRouteKey)
  const parentRouteKey = currentRouteKey.split('.')[0]
  const active = parentRouteKey === routeKey

  const textOpacity = collapsed ? 'opacity-0' : 'opacity-1'
  const textWidth = collapsed ? 'w-0' : 'w-fit'
  const textMarginLeft = collapsed ? 'ml-[0px]' : 'ml-[10px]'
  const duration = 'duration-[150ms]'
  const textColor = active ? 'text-white' : 'text-gray'
  const iconPaddingY = collapsed ? 'py-[12px]' : 'py-[7px]'
  const containerWidth = collapsed ? 'w-fit' : 'w-full'

  const handleOnClick = () => {
    // Wait a split second before closing the side peek (gives page time to load and smooths out the transition experience)
    setTimeout(() => {
      dispatch(setShowSidePeek(false))
    }, 350)
    navigate(path)
  }

  return (
    <div
      onClick={handleOnClick}
      className={`${containerWidth} overflow-hidden relative cursor-pointer py-[5px] px-[12px]`}>
      {active && <div className={'bg-white h-[36px] w-[15px] left-[-10px] rounded-[5px] absolute'} />}
      <div className={`${iconPaddingY} flex items-center px-[12px] hover:bg-gray-800/[0.25] rounded-[10px] h-[38px]`}>
        <div>
          {typeof icon === 'string' ? (
            <Icon name={icon as IconName} color={active ? 'white' : 'gray'} size={16} />
          ) : icon ? (
            icon({ color: active ? 'white' : 'gray', size: 16 })
          ) : null}
        </div>
        <div
          className={`transition-all ${duration} ${textWidth} ${textOpacity} ${textMarginLeft} ${textColor} font-figtree font-medium text-[16px] leading-[24px] whitespace-nowrap`}>
          {title}
        </div>
      </div>
    </div>
  )
}

export default SidePeekItem
