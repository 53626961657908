import { PersonDetailDocument, ProgramMemberRole } from '../../graphql'
import appConfig from '../../config/app'

const today = new Date()
today.setHours(today.getHours() + 4)
const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
const yesterday = new Date()
yesterday.setDate(yesterday.getDate() - 1)
const future = new Date()
future.setDate(future.getDate() + 7)

const robertJonesPerson = {
  id: '2',
  profileImage: {
    id: '3',
    url: 'https://randomuser.me/api/portraits/men/1.jpg',
    __typename: 'Image',
  },
  name: 'Robert Jones',
  fullName: 'Robert Jay Jones',
  email: 'robert.jones@newlife.org',
  primaryContact: {
    phone: '555-555-5555',
    __typename: 'Contact',
  },
  notes: [
    {
      id: '1',
      content:
        'Met at church; has three kids; works in software; possibly interested in one-on-one or group discipleship',
      __typename: 'Note',
    },
  ],
  connection: {
    id: '2',
    __typename: 'Connection',
  },
  user: {
    id: '2',
    programs: [
      {
        roles: [ProgramMemberRole.Apprentice],
        program: {
          id: '1',
          name: 'Christian Discipleship',
          image: {
            url: `${appConfig.issuePath}/img/samples/NewLifeChristianDiscipleshipIcon.png`,
            __typename: 'Media',
          },
          __typename: 'Program',
        },
        __typename: 'ProgramMember',
      },
      {
        roles: [ProgramMemberRole.Leader],
        program: {
          id: '3',
          name: 'Celebrate Freedom',
          image: {
            url: `${appConfig.issuePath}/img/samples/ProvidenceFreedomIcon.jpeg`,
            __typename: 'Media',
          },
          __typename: 'Program',
        },
        __typename: 'ProgramMember',
      },
    ],
    journey: {
      journey: {
        id: '1',
        name: 'Small Group Leaders',
        __typename: 'Journey',
      },
      currentPhase: {
        phase: {
          id: '1',
          state: {
            name: 'Disciple',
            color: '#9CA3AF',
            __typename: 'State',
          },
          __typename: 'Phase',
        },
        __typename: 'UserJourneyPhase',
      },
      phases: [
        {
          phase: {
            id: '2',
            name: 'Establishing',
            iconName: 'leaf',
            color: '#2D6769',
            order: 1,
            __typename: 'Phase',
          },
          totalSteps: 12,
          stepsCompleted: 12,
          __typename: 'UserJourneyPhase',
        },
        {
          phase: {
            id: '1',
            name: 'Equipping',
            iconName: 'users',
            color: '#C2410C',
            order: 2,
            __typename: 'Phase',
          },
          totalSteps: 12,
          stepsCompleted: 5,
          __typename: 'UserJourneyPhase',
        },
        {
          phase: {
            id: '3',
            name: 'In-Depth Training',
            iconName: 'training',
            color: '#A21CAF',
            order: 3,
            __typename: 'Phase',
          },
          totalSteps: 12,
          stepsCompleted: 0,
          __typename: 'UserJourneyPhase',
        },
      ],
      __typename: 'UserJourney',
    },
    __typename: 'User',
  },
  myRelatedTasks: {
    total: 5,
    hasMore: false,
    list: [
      {
        id: '1',
        complete: false,
        name: 'Followup about starting Discipleship Basics',
        dueAt: yesterday,
        __typename: 'Task',
      },
      {
        id: '2',
        complete: false,
        name: 'Schedule coffee',
        dueAt: today,
        __typename: 'Task',
      },
      {
        id: '3',
        complete: false,
        name: 'Create a personal journey',
        dueAt: tomorrow,
        __typename: 'Task',
      },
      {
        id: '4',
        complete: false,
        name: 'Invite to join Halieus',
        dueAt: future,
        __typename: 'Task',
      },
      {
        id: '5',
        complete: true,
        name: 'Invite to church',
        dueAt: future,
        __typename: 'Task',
      },
      {
        id: '6',
        complete: true,
        name: 'Plan summer small group',
        dueAt: future,
        __typename: 'Task',
      },
    ],
    __typename: 'Tasks',
  },
  __typename: 'Person',
}

const personMocks = [
  // Queries
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: PersonDetailDocument,
      variables: {
        // Robert Jones is person ID 2 and connection ID 2
        id: '2',
        connectionId: '2',
      },
    },
    result: {
      data: {
        person: robertJonesPerson,
      },
    },
  },
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: PersonDetailDocument,
      variables: {
        // Robert Jones is person ID 2 and connection ID is undefined (viewing from the organization screen)
        id: '2',
      },
    },
    result: {
      data: {
        person: robertJonesPerson,
      },
    },
  },
]

export default personMocks
