import React, { ReactNode } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames'

type FormItemProps = {
  size?: 'lg' | 'md' | 'sm'
  errorMessage?: string
  invalid?: boolean
  asterisk?: boolean
  label?: string
  children: ReactNode
  optional?: boolean
  className?: string
}

const FormItem: React.FC<FormItemProps> = ({
  size = 'md',
  errorMessage,
  invalid = false,
  asterisk = false,
  label,
  children,
  optional = false,
  className,
}) => {
  const isInvalid = !optional && invalid

  const itemClasses = `flex flex-col w-full ${size} ${isInvalid ? 'text-red' : ''}`
  const labelContainerClasses = 'flex w-full justify-between items-end mb-[5px]'
  const labelClasses = `h5 text-gray-900 font-bold ${asterisk ? 'required-field' : ''}`
  const optionalLabelClasses = `text-gray-600 text-sm`

  const enterStyle = { opacity: 1 }
  const exitStyle = { opacity: 0 }

  return (
    <div className={classNames(itemClasses, className)}>
      {(label || optional) && (
        <div className={labelContainerClasses}>
          <label className={labelClasses}>{label}</label>
          {optional && <span className={optionalLabelClasses}>Optional</span>}
        </div>
      )}
      {children}
      <AnimatePresence mode={'wait'}>
        {isInvalid && (
          <motion.div
            initial={exitStyle}
            animate={enterStyle}
            exit={exitStyle}
            transition={{ duration: 0.3, type: 'tween' }}
            className={'text-red font-semibold text-sm rounded mt-1'}>
            {errorMessage}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default FormItem
