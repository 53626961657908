import usePrograms from './usePrograms'
import { useJourneysLazyQuery } from '../graphql'
import { WatchQueryFetchPolicy } from '@apollo/client'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import formatRouteId from '../utils/formatRouteId'

type UseJourneysParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  programId?: string
  lazy?: boolean
}

const useJourneys = (
  { fetchPolicy = 'cache-first', programId, lazy = false }: UseJourneysParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { selectedProgram } = usePrograms()

  const [query, { data, loading }] = useJourneysLazyQuery({
    variables: {
      programId: programId || selectedProgram?.id || '',
    },
    fetchPolicy,
  })

  useEffect(() => {
    if (!lazy) {
      query()
    }
  }, [lazy, query, programId, selectedProgram?.id, fetchPolicy])

  useEffect(() => {
    if (data) {
      if (data.journeys.list.length === 1) {
        const journey = data.journeys.list[0]
        const { routeId } = formatRouteId(journey.name, journey.id)
        navigate('/journeys/' + routeId)
      }
    }
  }, [data, dispatch, navigate])

  return { journeys: data?.journeys.list, query, loading }
}

export default useJourneys
