import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ModalState = {
  show: boolean
  key?: string | null
}

export const initialState: ModalState = {
  show: false,
  key: null,
}

export const modalSlice = createSlice({
  name: 'layout/modal',
  initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<string | undefined>) => {
      const key = action.payload
      if (key && state.key !== key) {
        state.show = true
      } else {
        state.show = !state.show
      }
      state.key = key
    },
    closeModal: (state) => {
      state.show = false
    },
  },
})

export const selectModal = createSelector(
  (state: { layout: { modal: ModalState } }) => state.layout.modal,
  (modal) => modal,
)

export const { toggleModal, closeModal } = modalSlice.actions

export default modalSlice.reducer
