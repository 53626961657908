import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

type SelectedProgram = {
  id: string
  name?: string
} | null

export interface ProgramState {
  selected: SelectedProgram
}

export const initialState: ProgramState = {
  selected: {
    name: '',
    id: '',
  },
}

export const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setSelectedProgram: (state, action: PayloadAction<SelectedProgram>) => {
      state.selected = action.payload
    },
  },
})

export const selectProgram = createSelector(
  (state: { program: ProgramState }) => state.program,
  (program) => program,
)

export const { setSelectedProgram } = programSlice.actions

export default programSlice.reducer
