import React from 'react'
import useAuth from '../../../hooks/useAuth'
import { TenantUserRole, /*useGuestInvitationsQuery,*/ useMemberInvitationsQuery } from '../../../graphql'
import MemberInvitations from '../MemberInvitations'
import { Indicator } from '../../../components/ui'
import { Navigate } from 'react-router-dom'
import TenantPrograms from '../TenantPrograms'

const OrganizationalOnboarding = () => {
  const { user } = useAuth()
  //const [step, setStep] = React.useState<'organization' | 'program'>('organization')

  const { data: memberInvitationsData, loading: loadingMemberInvitations } = useMemberInvitationsQuery({
    fetchPolicy: 'cache-and-network',
  })

  /*const { data: guestInvitationsData, loading: loadingGuestInvitations } = useGuestInvitationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        program: {
          tenant: {
            id: {
              equals: user?.tenants[0]?.tenant.id,
            },
          },
        }
      }
    }
  })*/

  const invitations = memberInvitationsData?.invitations?.list || []

  const hasInvitations = invitations.length > 0
  const hasOrganizations = !!user?.tenants.length
  const hasPrograms = !!user?.programs.length

  const loading = loadingMemberInvitations && !memberInvitationsData

  // We can assume that hasOrganizations and hasPrograms is never true since that is handled by the layout route components
  return loading ? (
    // Show loading indicator while we look for existing invitations for organizational onboarding
    <div className={'h-full flex justify-center items-center'}>
      <Indicator size={30} indicator={'loader'} isSpinning />
    </div>
  ) : // If the user has organizations and programs then they are good to go
  // onward to the dashboard
  hasOrganizations && hasPrograms ? (
    <Navigate to={'/dashboard'} />
  ) : // If the user is a member of an organization but has no programs, show the program setup step
  // because members can't join existing programs on their own but can create their own
  hasOrganizations && user.tenants[0].role === TenantUserRole.Member ? (
    <Navigate to={'/new-program'} />
  ) : // If the user is an admin or owner of an organization but has no programs, show the tenant programs list
  // because admins and owners can join existing programs
  hasOrganizations ? (
    <TenantPrograms />
  ) : // If the user has organization invitations and isn't yet part of an organization
  // then show the member invitation list, so they can optionally join an existing organization
  hasInvitations && !hasOrganizations ? (
    <MemberInvitations invitations={invitations} />
  ) : (
    // If the user isn't part of an organization and has no invitations
    // then take them directly to the 'create new organization' page
    <Navigate to={'/new-organization'} />
  )
}

export default OrganizationalOnboarding
