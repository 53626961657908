import React, { FC } from 'react'
import ViewHeader from '../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../components/shared/BreadcrumbBar'
import DrawerButton from '../../../components/shared/DrawerButton'
import useStepDetail from '../../../hooks/useStepDetail'
import { Icon, MiniBadge } from '../../../components/ui'
import { useWindowSize } from '../../../hooks/useWindowSize'
import moment from 'moment'
import useAuth from '../../../hooks/useAuth'
import { ViewAs } from '../../../graphql'
import formatRouteId from '../../../utils/formatRouteId'
import usePrograms from '../../../hooks/usePrograms'
import { useNavigate } from 'react-router'

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1M',
    MM: '%dM',
    y: '1Y',
    yy: '%dY',
  },
})

type EditButtonsProps = {
  drawerButton: JSX.Element
  onCancel: () => void
}

const EditButtons = ({ drawerButton, onCancel }: EditButtonsProps) => {
  const { width, screens, size } = useWindowSize()
  const { step, saveStep, saving, publishStep, publishing } = useStepDetail()
  const small = width < screens.lg
  const xs = size === 'xs'

  const updatedAgo = moment(step?.updatedAt).fromNow()
  const updatedAt = moment(step?.updatedAt).format('MMMM Do YYYY, h:mm a')

  return (
    <div
      className={`flex gap-[15px] py-[11.5px] flex-wrap flex-wrap-reverse justify-end items-center ${xs ? 'w-full' : ''}`}>
      {step?.draft && (
        <>
          <MiniBadge tooltip={{ title: updatedAt, delay: 300 }} className={'italic'}>
            draft saved {updatedAgo}
          </MiniBadge>
        </>
      )}
      <div className={'flex gap-[15px] items-center'}>
        {!!step?.main && (
          <ViewHeader.Button onClick={() => onCancel()} icon={small && <Icon name={'chevron-left'} />}>
            {small ? '' : 'Cancel'}
          </ViewHeader.Button>
        )}
        <ViewHeader.Button
          size={'sm'}
          loading={saving}
          icon={<Icon name={'save'} />}
          variant={'default'}
          onClick={() => saveStep()}
          className={'whitespace-nowrap'}>
          {small ? '' : 'Save'}
        </ViewHeader.Button>
        <ViewHeader.Button
          size={'sm'}
          loading={publishing}
          icon={<Icon name={'upload-cloud'} color={'white'} />}
          color={'green'}
          variant={'solid'}
          onClick={() => publishStep()}>
          {small ? '' : 'Publish'}
        </ViewHeader.Button>
        {!xs && <div>{drawerButton}</div>}
      </div>
    </div>
  )
}

const StepHeader: FC = () => {
  const { step, createDraft, creatingDraft } = useStepDetail()
  const { user } = useAuth()
  const { size } = useWindowSize()
  const { selectedProgram } = usePrograms()
  const navigate = useNavigate()
  const xs = size === 'xs'

  const drawerButton = step?.draft ? (
    <DrawerButton iconName={'sliders'} drawer={'step-drawer'} />
  ) : (
    <DrawerButton drawer={'step-drawer'} />
  )

  const editButton =
    user?.viewingAs === ViewAs.Leader ? (
      <ViewHeader.Button
        loading={creatingDraft}
        variant={'plain'}
        icon={<Icon name={'edit-3'} />}
        onClick={async () => {
          if (!step?.draft) {
            await createDraft()
          }
        }}>
        Edit
      </ViewHeader.Button>
    ) : null

  const end = step?.draft
    ? [<EditButtons drawerButton={drawerButton} onCancel={() => navigate(-1)} />]
    : xs
      ? []
      : [drawerButton, editButton]

  const { routeId } = formatRouteId(step?.name, step?.id)

  const journey = step?.journey
  const { routeId: journeyRouteId } = formatRouteId(journey?.name, journey?.id)
  const { routeId: programRouteId } = formatRouteId(selectedProgram?.name, selectedProgram?.id)

  const breadcrumb = (
    <BreadcrumbBar
      routeTitleMap={{
        myJourney: step?.phase.name || 'Phase',
        step: step?.name || (step && step.name === undefined ? 'Untitled' : 'Step'),
      }}
      routePathMap={{
        myJourney:
          user?.viewingAs === ViewAs.Leader
            ? `/journeys/${journeyRouteId}`
            : user?.viewingAs === ViewAs.Apprentice
              ? `/my-journey/${programRouteId}`
              : undefined,
        step: `/step/${routeId}`,
      }}
    />
  )

  const start = xs
    ? [
        <div className={'flex gap-[15px] justify-between items-center'}>
          {breadcrumb}
          <div className={'flex gap-[15px] items-center'}>
            {!step?.draft && editButton}
            {drawerButton}
          </div>
        </div>,
      ]
    : [
        <div className={'h-full flex'}>
          <div className={'pt-[15px]'}>{breadcrumb}</div>
        </div>,
      ]

  return <ViewHeader start={start} end={end} />
}

export default StepHeader
