import { FC } from 'react'
import RadioComponent, { RadioProps } from './Radio'
import GroupComponent from './RadioGroup'

interface RadioType extends FC<RadioProps> {
  Group: typeof GroupComponent
}

const Radio = RadioComponent as RadioType
Radio.Group = GroupComponent

export default Radio
