import React, { FC } from 'react'
import ViewHeader from '../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../components/shared/BreadcrumbBar'
import useMyJourney from '../../../hooks/useMyJourney'
import DrawerButton from '../../../components/shared/DrawerButton'
import formatRouteId from '../../../utils/formatRouteId'
import usePrograms from '../../../hooks/usePrograms'

const MyJourneyHeader: FC = () => {
  const { myJourney } = useMyJourney()
  const { selectedProgram } = usePrograms()

  const { routeId } = formatRouteId(selectedProgram?.name, selectedProgram?.id)

  return (
    <ViewHeader
      start={[
        <BreadcrumbBar
          routeTitleMap={{
            myJourney: myJourney?.currentPhase?.phase.name,
          }}
          routePathMap={{
            myJourney: `/my-journey/${routeId}`,
          }}
        />,
      ]}
      end={[<DrawerButton drawer={'my-journey-info'} />]}
    />
  )
}

export default MyJourneyHeader
