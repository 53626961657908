import React, { forwardRef, ReactNode, Ref, ReactElement, Children, FC } from 'react'
import { Icon } from '../index'

const STEPS_STATUS = {
  COMPLETE: 'complete',
  PENDING: 'pending',
  IN_PROGRESS: 'in-progress',
  ERROR: 'error',
}

type StepItemProps = {
  className?: string
  status?: 'complete' | 'pending' | 'in-progress' | 'error'
  customIcon?: ReactNode
  stepNumber?: number
  description?: ReactNode
  title?: ReactNode
  isLast?: boolean
  vertical?: boolean
  onStepChange?: () => void
  color?: string
  clickable?: boolean
}

type StepsProps = {
  className?: string
  children: ReactNode
  vertical?: boolean
  current?: number
  status?: 'complete' | 'pending' | 'in-progress' | 'error'
  onChange?: (index: number) => void
  clickable?: boolean
}

const mapCloneElement = (children: ReactNode, fn: (child: ReactElement, index: number) => any) => {
  return Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, fn(child, index))
    }
    return child
  })
}

const StepItem: FC<StepItemProps> = forwardRef((props: StepItemProps, ref: Ref<HTMLDivElement>) => {
  const {
    clickable = true,
    color = 'gray-light',
    status,
    customIcon,
    stepNumber,
    title,
    isLast,
    vertical,
    onStepChange,
  } = props

  const STEP_STATUS_ICON = {
    [STEPS_STATUS.COMPLETE]: <Icon name={'check'} color={'white'} size={20} />,
  }

  let stepIcon = <span>{STEP_STATUS_ICON[status as keyof typeof STEPS_STATUS] ?? stepNumber}</span>

  if (customIcon) {
    stepIcon = <span>{customIcon}</span>
  }

  const stepWrapperStyle = `flex p-2 gap-2 cursor-pointer`
  let titleClass = 'mt-2 text-gray-dark whitespace-nowrap'
  let stepIconClass =
    'min-w-[38px] max-w-[38px] min-h-[38px] max-h-[38px] border-2 rounded-lg flex items-center justify-center text-sm text-[#737373] font-semibold'

  let connectClass = vertical ? `w-0.5 h-20 ml-[26px] -my-2.5` : `w-full h-0.5 -mr-2`

  switch (status) {
    case STEPS_STATUS.COMPLETE:
      stepIconClass += ` ${status} bg-${color} border-${color}`
      connectClass += ` ${status} bg-${color}`
      break
    case STEPS_STATUS.ERROR:
      stepIconClass += ` ${status} bg-red-500 border-red-500`
      break
    case STEPS_STATUS.IN_PROGRESS:
      stepIconClass += ` ${status} text-${color} border-${color}`
      connectClass += ` ${status} bg-gray-light`
      break
    default:
      stepIconClass += ' bg-white border-gray-300 border-gray-light'
      connectClass += ` ${status} bg-gray-light`
  }

  const handleStepChange = () => {
    if (clickable && status !== STEPS_STATUS.COMPLETE) {
      onStepChange?.()
    }
  }

  return (
    <div
      className={`${isLast ? '' : 'flex-grow'} ${vertical ? 'flex-row items-center' : 'flex items-center'}`}
      ref={ref}
      onClick={handleStepChange}>
      <div className={stepWrapperStyle}>
        <div className={stepIconClass}>{stepIcon}</div>
        {title ? <h4 className={titleClass}>{title}</h4> : null}
      </div>
      {!isLast && <div className={connectClass} />}
    </div>
  )
})

const Steps: StepsWithItem = forwardRef<HTMLDivElement, StepsProps>((props, ref) => {
  const { clickable = true, className, children, vertical, current = 0, status, onChange, ...rest } = props

  const count = Children.count(children)

  const items = mapCloneElement(children, (item, index) => {
    const itemStyles = {
      flexBasis: index < count - 1 ? `${100 / (count - 1)}%` : undefined,
      maxWidth: index === count - 1 ? `${100 / count}%` : undefined,
    }

    let itemClassStyle = ''
    if (status === STEPS_STATUS.ERROR && index === current - 1) {
      itemClassStyle = 'bg-red-400'
    } else if (!item.props.status) {
      if (index === current) {
        itemClassStyle = `bg-blue-400`
      } else if (index < current) {
        itemClassStyle = 'bg-green-400'
      }
    }

    const itemProps = {
      className: itemClassStyle,
      stepNumber: index + 1,
      status: STEPS_STATUS.PENDING,
      style: !vertical ? itemStyles : undefined,
      isLast: index === count - 1,
      vertical: vertical,
      clickable: clickable,
      onStepChange: onChange
        ? () => {
            if (index >= current) {
              onChange(index)
            }
          }
        : undefined,
      ...item.props,
    }

    return itemProps
  })

  const stepsClassTailwind = `flex justify-between ${vertical ? 'flex-col' : 'flex-row'} ${className}`

  return (
    <div ref={ref} className={stepsClassTailwind} {...rest}>
      {items}
    </div>
  )
}) as StepsWithItem

interface StepsWithItem extends React.ForwardRefExoticComponent<StepsProps & React.RefAttributes<HTMLDivElement>> {
  Item: React.FC<StepItemProps>
}

;(Steps as StepsWithItem).Item = StepItem

export default Steps
