import { createSelector, createSlice } from '@reduxjs/toolkit'
import protectedRoutes from '../../config/routes/protectedRoutes'
import publicRoutes from '../../config/routes/publicRoutes'
import onboardingRoutes from '../../config/routes/onboardingRoutes'

export interface CommonState {
  currentRouteKey: string
  isModalOpen: boolean
}

export const initialState: CommonState = {
  currentRouteKey: '',
  isModalOpen: false,
}

export const commonSlice = createSlice({
  name: 'base/common',
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload
    },
    openModal: (state) => {
      state.isModalOpen = true
    },
    closeModal: (state) => {
      state.isModalOpen = false
    },
  },
})

export type RouteType = 'protected' | 'public' | 'onboarding'

export function selectCurrentRouteConfig(type: RouteType) {
  return createSelector(
    (state: { base: { common: CommonState } }) => state.base.common.currentRouteKey,
    (key: string) => {
      switch (type) {
        case 'protected':
          return protectedRoutes.find((route) => route.key === key)
        case 'public':
          return publicRoutes.find((route) => route.key === key)
        default:
          return onboardingRoutes.find((route) => route.key === key)
      }
    },
  )
}

export const { setCurrentRouteKey, openModal, closeModal } = commonSlice.actions

export default commonSlice.reducer
