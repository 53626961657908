import React, { useEffect } from 'react'
import View from '../../components/shared/View'
import { Route, Routes, useLocation } from 'react-router-dom'
import JourneysHeader from './components/JourneysHeader'
import JourneysDropdown from './components/JourneysDropdown'
import useJourney from '../../hooks/useJourney'
import JourneysView from './views/JourneysView'
import Text from '../../components/ui/Text'
import useJourneys from '../../hooks/useJourneys'

const Journeys = () => {
  // Todo: Use lazy queries so journeys isn't always fetched unecessarily while filtering by a specific journey on the Journeys view
  const {
    journey,
    loading: loadingJourney,
    query: queryJourney,
  } = useJourney({ fetchPolicy: 'cache-and-network', lazy: true })
  const {
    journeys,
    loading: loadingJourneys,
    query: queryJourneys,
  } = useJourneys({ fetchPolicy: 'cache-and-network', lazy: true })

  const location = useLocation()
  const all = location.pathname === '/journeys/all' || location.pathname === '/journeys/:id'

  useEffect(() => {
    if (location.pathname.startsWith('/journeys')) {
      if (all) {
        queryJourneys()
      } else {
        queryJourney()
      }
    }
  }, [all, queryJourneys, queryJourney, location.pathname])

  const journeyLoading = loadingJourney && !journey
  const journeysLoading = loadingJourneys && !journeys
  const loading = all ? journeysLoading : journeyLoading

  return (
    <View loading={loading} header={<JourneysHeader />}>
      <div className={'pt-[10px] w-full flex flex-col justify-center items-center'}>
        <div className={'w-full max-w-[1040px] flex flex-col gap-[25px]'}>
          <div className={'w-full flex flex-col gap-[10px]'}>
            <JourneysDropdown />
            <Text>
              {journey
                ? journey.description
                : 'A journey is a specific collection of steps, or path, that small group leaders must complete as they move through their training phase of the program.'}
            </Text>
          </div>
          <Routes>
            <Route path={'/'} element={<JourneysView journey={journey} />} />
          </Routes>
        </div>
      </div>
    </View>
  )
}

export default Journeys
