import MyJourneyInfo from '../../views/myJourney/views/MyJourneyInfo'
import MyJourneyUtility from '../../views/myJourney/views/MyJourneyUtility'
import { DrawerConfig } from './index'
import StepDrawer from '../../views/step/views/StepDrawer'

const apprenticeDrawers: DrawerConfig[] = [
  {
    key: 'my-journey-info',
    component: <MyJourneyInfo />,
  },
  {
    key: 'utility',
    component: <MyJourneyUtility />,
  },
  {
    key: 'step-drawer',
    component: <StepDrawer />,
  },
]

export default apprenticeDrawers
