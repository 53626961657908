import { Button, Tooltip } from '../../../components/ui'
import Table from '../../../components/ui/Table/Table'
import React from 'react'
import useStepDetail from '../../../hooks/useStepDetail'
import StepQuestion from './StepQuestion'

const StepQuestions = () => {
  const { step, addQuestion } = useStepDetail()

  const questions = step?.questions || []

  const hasUncreatedQuestion = questions.some((question) => question.id === null)

  const tooltipTitle = hasUncreatedQuestion
    ? 'Finish creating the current question before adding another'
    : 'Add a question to this step'

  return (
    <div style={{ overflow: 'visible' }} className={'flex flex-col gap-[10px] overflow-x-visible overflow-visible'}>
      {step?.draft ? (
        <Table className={'overflow-visible'}>
          <Table.TBody className={'overflow-visible'}>
            {questions.map((question) => (
              <StepQuestion key={question.id} question={question} />
            ))}
          </Table.TBody>
        </Table>
      ) : questions.length > 0 ? (
        <div className={'flex flex-col gap-[30px]'}>
          {questions.map((question) => (
            <StepQuestion key={question.id} question={question} />
          ))}
        </div>
      ) : null}
      {step?.draft && (
        <div className={'w-[inherit]'}>
          <Tooltip className={'max-w-max'} delay={1000} placement={'right'} title={tooltipTitle}>
            <Button disabled={hasUncreatedQuestion} variant={'plain'} onClick={() => addQuestion()}>
              + Add question
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default StepQuestions
