import React from 'react'
import ProgramHeader from './components/ProgramHeader'
import { Route, Routes } from 'react-router-dom'
import View from '../../../../components/shared/View'
import ProgramGeneral from './views/ProgramGeneral'
import ProgramJourneys from './views/ProgramJourneys'
import ProgramMembers from './views/ProgramMembers'
import ProgramTabbar from './components/ProgramTabbar'
import useProgramDetail from '../../../../hooks/useProgramDetail'
import useRouteId from '../../../../hooks/useRouteId'
import usePrograms from '../../../../hooks/usePrograms'

const ProgramDetail = () => {
  const { selectedProgram } = usePrograms()

  const { id } = useRouteId({
    baseRoute: '/program',
    selected: {
      name: selectedProgram?.name,
      id: selectedProgram?.id,
    },
    updateSelected: (parsed) => {
      //Add logic to change the program dropdown to a program from providence church
      // If the mutation to make that change fails because I don't have permission to a program from that org
      // Then we can show a "You don't have permission to view this page" view or something
      console.log('Try to update the selected organization', parsed)
    },
  })

  const { program, loading } = useProgramDetail({
    fetchPolicy: 'cache-and-network',
    id,
  })

  return (
    <View loading={loading && !program} header={<ProgramHeader />} tabbar={<ProgramTabbar />}>
      <div className={'flex justify-center pt-[25px]'}>
        <div className={'flex flex-col gap-[25px] grow max-w-[540px] min-w-0'}>
          <Routes>
            <Route path={'/'} element={<ProgramGeneral program={program} />} />
            <Route path={'/journeys'} element={<ProgramJourneys program={program} />} />
            <Route path={'/members'} element={<ProgramMembers program={program} />} />
          </Routes>
        </div>
      </div>
    </View>
  )
}

export default ProgramDetail
