import useAuth, { setAuth } from './useAuth'
import { ProgramMemberRole, ViewAs } from '../graphql'
import useRouteConfig from './useRouteConfig'
import protectedRoutes from '../config/routes/protectedRoutes'
import { useNavigate } from 'react-router-dom'
import app from '../config/app'
import { useDispatch } from 'react-redux'
import { toggleDrawer } from '../store/layout/drawerSlice'
import useLayout from './useLayout'
import usePrograms from './usePrograms'
import { useEffect, useMemo } from 'react'

function useViewAs() {
  const { user, token, ...auth } = useAuth()
  const routeConfig = useRouteConfig()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { selectedProgram } = usePrograms()

  const {
    drawer: { show },
  } = useLayout()

  let switchViewDisplay
  switch (user?.viewingAs) {
    case ViewAs.Apprentice:
      switchViewDisplay = selectedProgram?.member?.leaderDisplay || ''
      break
    case ViewAs.Leader:
      switchViewDisplay = selectedProgram?.member?.apprenticeDisplay || ''
      break
  }

  const setViewAs = useMemo(
    () => (viewAs: ViewAs) => {
      if (token && user) {
        let viewingAsDisplay
        switch (viewAs) {
          case ViewAs.Apprentice:
            viewingAsDisplay = selectedProgram?.member?.apprenticeDisplay || ''
            break
          case ViewAs.Leader:
            viewingAsDisplay = selectedProgram?.member?.leaderDisplay || ''
            break
        }

        if (routeConfig) {
          const roleMatched = routeConfig.authority?.includes(viewAs.toLowerCase() as UserRole)
          const alternate = routeConfig.alternate
          if (!roleMatched && alternate) {
            const altRouteConfig = protectedRoutes.find((route) => route.key === alternate)
            const altRoleMatched = altRouteConfig?.authority?.includes(viewAs.toLowerCase() as UserRole)
            if (altRouteConfig && altRoleMatched) {
              navigate(altRouteConfig.defaultPath || altRouteConfig.path)
            } else {
              navigate(app.authenticatedEntryPath)
            }
          }
        }

        if (show) {
          dispatch(toggleDrawer())
        }

        setAuth({
          ...auth,
          token,
          user: {
            ...user,
            viewingAs: viewAs,
            viewingAsDisplay,
          },
        })
      }
    },
    [
      auth,
      dispatch,
      navigate,
      selectedProgram?.member?.apprenticeDisplay,
      selectedProgram?.member?.leaderDisplay,
      show,
      token,
      user,
      routeConfig,
    ],
  )

  useEffect(() => {
    if (user?.viewingAs === ViewAs.Leader && !selectedProgram?.member?.roles.includes(ProgramMemberRole.Leader)) {
      setViewAs(ViewAs.Apprentice)
      navigate('/my-journey/:id')
    } else if (
      user?.viewingAs === ViewAs.Apprentice &&
      !selectedProgram?.member?.roles.includes(ProgramMemberRole.Apprentice) &&
      selectedProgram?.member?.roles.includes(ProgramMemberRole.Leader)
    ) {
      setViewAs(ViewAs.Leader)
      navigate('/journeys/:id')
    } else if (user && !user.viewingAs) {
      if (selectedProgram?.member?.roles.includes(ProgramMemberRole.Apprentice)) {
        setViewAs(ViewAs.Apprentice)
        navigate('/my-journey/:id')
      } else if (selectedProgram?.member?.roles.includes(ProgramMemberRole.Leader)) {
        setViewAs(ViewAs.Leader)
        navigate('/journeys/:id')
      }
    }
    if (!user?.viewingAsDisplay || user?.viewingAsDisplay === '') {
      const member = selectedProgram?.member
      if (member) {
        const viewingAs =
          user?.viewingAs ||
          member.roles.find((role) => role === 'LEADER') ||
          member.roles.find((role) => role === 'APPRENTICE')
        setViewAs(viewingAs as ViewAs)
      }
    }
  }, [navigate, user?.viewingAsDisplay, user?.viewingAs, selectedProgram?.member, setViewAs, user])

  const toggleViewAs = () => {
    if (user?.viewingAs === ViewAs.Apprentice) {
      setViewAs(ViewAs.Leader)
    } else {
      setViewAs(ViewAs.Apprentice)
    }
  }

  return {
    canSwitchView: selectedProgram?.member?.roles.length === 2,
    switchViewDisplay,
    toggleViewAs,
    setViewAs,
  }
}

export default useViewAs
