import {
  CompletedMultipartUpload,
  CompleteMultipartUploadCommand,
  CreateMultipartUploadCommand,
  S3Client,
  UploadPartCommand,
  CompletedPart,
  CompleteMultipartUploadCommandOutput,
} from '@aws-sdk/client-s3'
import { AwsCredentialsFieldsFragment } from '../graphql'
import app from '../config/app'

export type UploadFileProgress = {
  percentComplete: number
  estRemainingSeconds: number
  elapsedSeconds: number
  startTime: number
}

export type UploadFileParts = {
  totalParts: number
  remainingParts: number
}

export type UploadFileCompleteEvent = {
  totalElapsedSeconds: number
  totalParts: number
  data: CompleteMultipartUploadCommandOutput
  src: string
  blob: File
  fileName: string
}

type UploadFileParams = {
  src?: string
  blob: File
  //key: string
  fileName: string
  partSize?: number
  region?: string
  maxRetries?: number
  getCredentials: () => Promise<AwsCredentialsFieldsFragment>
  onProgress?: (progress: UploadFileProgress, parts: UploadFileParts) => void
  onComplete?: (event: UploadFileCompleteEvent) => void
}

class UploadFile {
  private readonly params: UploadFileParams & { partSize: number; region: string; maxRetries: number }
  private key?: string
  private client?: S3Client
  private bucket?: string
  private initialized = false
  private input?: {
    Key: string
    Bucket: string
    UploadId: string
  }
  private multipartMap: CompletedMultipartUpload & {
    Parts: CompletedPart[]
  } = {
    Parts: [],
  }
  private numPartsLeft = -1
  private numParts = -1
  private startTime = Date.now()

  constructor({ region = 'us-east-2', partSize = 1024 * 1024 * 5, maxRetries = 3, ...rest }: UploadFileParams) {
    this.params = {
      region,
      partSize,
      maxRetries,
      ...rest,
    }
  }

  private readonly getCredentials = async (tryNum = 1): Promise<AwsCredentialsFieldsFragment> => {
    const { getCredentials } = this.params
    try {
      return await getCredentials()
    } catch (e) {
      return await this.retry(this.getCredentials, tryNum, new Error('Failed to get credentials for upload.'))
    }
  }

  private readonly initialize = async () => {
    const { region } = this.params
    const { resourceName, resource: _, key, ...credentials } = await this.getCredentials()
    this.client = new S3Client({
      region,
      credentials,
    })
    this.key = `${key}/${this.params.fileName}`
    this.bucket = resourceName
    this.initialized = true
  }

  private readonly retry = async <R>(
    fn: (tryNum: number) => Promise<R>,
    tryNum = 1,
    error?: Error,
    maxRetries = this.params.maxRetries,
  ) => {
    if (tryNum <= this.params.maxRetries) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { resourceName: _, resource: _1, key: _2, ...credentials } = await this.getCredentials()
      if (this.client) {
        this.client.destroy()
      }
      this.client = new S3Client({
        region: this.params.region,
        credentials,
      })
      return await fn(tryNum + 1)
    }
    console.error('Attempted maximum retries', maxRetries)
    if (error) {
      throw error
    }
    throw new Error('Failed to retry')
  }

  private readonly createMultipartUpload = async (
    tryNum = 1,
  ): Promise<{ Key: string; Bucket: string; UploadId: string }> => {
    const { blob } = this.params
    if (this.client) {
      try {
        const multiCommand = new CreateMultipartUploadCommand({
          Bucket: this.bucket,
          Key: this.key,
          ContentType: blob.type,
        })
        const { Key, Bucket, UploadId } = await this.client.send(multiCommand)

        if (Key && Bucket && UploadId) {
          this.input = {
            Key,
            Bucket,
            UploadId,
          }
          return this.input
        }
      } catch (e) {
        return await this.retry(
          this.createMultipartUpload,
          tryNum,
          new Error('Failed to authenticate or create multipart upload.'),
        )
      }
      throw new Error('Failed to create multipart upload.')
    }
    throw new Error('S3Client must be initialized before creating a multipart upload.')
  }

  private readonly getDeltaSeconds = () => {
    return (Date.now() - this.startTime) / 1000
  }

  private readonly completeMultipartUpload = async (tryNum = 1) => {
    const { input, multipartMap, client } = this
    const { onComplete } = this.params
    if (input && client) {
      try {
        const completeMultipartUpload = new CompleteMultipartUploadCommand({
          ...input,
          MultipartUpload: multipartMap,
        })
        const data = await client.send(completeMultipartUpload)
        const delta = this.getDeltaSeconds()
        const src = `${app.assets.baseUrl}/${data.Key}`
        onComplete?.({
          data,
          totalElapsedSeconds: delta,
          totalParts: this.numParts,
          blob: this.params.blob,
          fileName: this.params.fileName,
          src,
        })
        console.log('Completed upload in', delta, 'seconds')
      } catch (e) {
        await this.retry(this.completeMultipartUpload, tryNum, new Error('Failed to complete multipart upload.'))
      }
    } else {
      if (!client) {
        throw new Error('S3Client must be initialized in order to complete a multipart upload.')
      }
      throw new Error('Multipart upload must be created in order to complete it.')
    }
  }

  private readonly percentComplete = () => {
    return Math.round(10 * ((this.numParts - this.numPartsLeft) / this.numParts)) / 10
  }

  private readonly getProgress = () => {
    const percentComplete = this.percentComplete()
    const elapsedSeconds = this.getDeltaSeconds()
    const estTotalSeconds = Math.round(100 * ((1 / percentComplete) * elapsedSeconds)) / 100
    const estRemainingSeconds = estTotalSeconds - elapsedSeconds
    return {
      percentComplete,
      elapsedSeconds,
      estRemainingSeconds,
      startTime: this.startTime,
    }
  }

  private readonly getParts = () => {
    return {
      totalParts: this.numParts,
      remainingParts: this.numPartsLeft,
    }
  }

  private readonly reportProgress = () => {
    const { onProgress } = this.params
    onProgress?.(this.getProgress(), this.getParts())
  }

  private readonly uploadPart = async (body: Uint8Array, partNum: number, tryNum = 1) => {
    const { input, client, multipartMap, reportProgress } = this
    if (input && client) {
      const uploadPart = new UploadPartCommand({
        Body: body,
        PartNumber: partNum,
        ...input,
      })
      try {
        reportProgress()

        const mData = await client.send(uploadPart)

        multipartMap.Parts[partNum - 1] = {
          ETag: mData.ETag,
          PartNumber: partNum,
        }

        this.numPartsLeft--

        if (this.numPartsLeft > 0) return // complete only when all parts uploaded

        await this.completeMultipartUpload()
      } catch (e) {
        await this.retry(
          (attempt) => this.uploadPart(body, partNum, attempt),
          tryNum,
          new Error('Failed to upload part: #' + partNum),
        )
      }
    } else {
      if (!input) {
        throw new Error('Multipart upload must be created in order to upload part.')
      } else {
        throw new Error('S3Client must be initialized in order to upload part.')
      }
    }
  }

  private readonly uploadParts = async () => {
    const { blob } = this.params
    const buffer = new Uint8Array(await blob.arrayBuffer())
    if (buffer) {
      const { partSize } = this.params
      let partNum = 0
      this.numParts = Math.ceil(buffer.byteLength / partSize)
      this.numPartsLeft = this.numParts
      for (let rangeStart = 0; rangeStart < buffer.length; rangeStart += partSize) {
        partNum++
        const end = Math.min(rangeStart + partSize, buffer.length)
        console.log('Uploading part: #', partNum, ', Range start:', rangeStart)
        await this.uploadPart(buffer.subarray(rangeStart, end), partNum)
      }
    } else {
      throw new Error('Multipart upload must be created before uploading parts.')
    }
  }

  readonly start = async () => {
    try {
      this.startTime = Date.now()
      await this.initialize()
      await this.createMultipartUpload()
      await this.uploadParts()
    } catch (e) {
      console.error(e)
    }
  }
}

export const uploadFile = async (params: UploadFileParams) => {
  const fileUpload = new UploadFile(params)
  await fileUpload.start()
}
