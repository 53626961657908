import { SlidesDocument, Slider } from '../../graphql'

const sideMocks = [
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: SlidesDocument,
      variables: {
        where: {
          slider: {
            equals: Slider.Auth,
          },
        },
      },
    },
    result: {
      data: {
        slides: {
          list: [
            {
              title: 'John Doe',
              subtitle: 'founder, anychurch ministries',
              text: '"Sit amet mauris commodo quis imperdiet massa tincidunt nunc pulvinar sapien et ligula."',
              image: {
                url: `/img/samples/Coffee_Shop_Stock.jpeg`,
                __typename: 'Media',
              },
              slider: 'AUTH',
              __typename: 'Slide',
            },
            {
              title: 'Joe Doe',
              subtitle: 'founder, everychurch ministries',
              text: '"Amici, romani, cives, date aures vestras mihi sepultum neque laudatum venio."',
              image: {
                url: `/img/samples/AdobeStock_240325420.jpeg`,
                __typename: 'Media',
              },
              slider: 'AUTH',
              __typename: 'Slide',
            },
          ],
          __typename: 'Slides',
        },
      },
    },
  },
]

export default sideMocks
