import { ContentStepType, StepType } from '../graphql'
import Option from '../components/shared/Option'
import { useMemo } from 'react'

type UseStepTypeOptionsParams = {
  size?: 'xs' | 'sm'
  type?: StepType | null
  contentType?: ContentStepType | null
}

const useStepTypeOptions = (
  { size = 'sm', type = null, contentType = null }: UseStepTypeOptionsParams = { size: 'sm' },
) => {
  const iconSize = size === 'xs' ? 18 : 24
  const videoIconSize = size === 'xs' ? 18 : 20
  const fontSize = size === 'xs' ? 'sm' : 'md'

  const placeholder = (
    <Option iconName={'grid'} name={'Type'} iconSize={iconSize} fontSize={fontSize} iconColor={'gray'} />
  )

  const options = useMemo(
    () => [
      {
        value: 'task',
        detail: {
          type: StepType.Task,
          contentType: null,
        },
        label: <Option iconName={'check-circle'} iconSize={iconSize} fontSize={fontSize} name={'Task'} />,
      },
      {
        value: 'reading',
        detail: {
          type: StepType.Content,
          contentType: ContentStepType.Text,
        },
        label: <Option iconName={'book'} iconSize={iconSize} fontSize={fontSize} name={'Reading'} />,
      },
      {
        value: 'video',
        detail: {
          type: StepType.Content,
          contentType: ContentStepType.Video,
        },
        label: <Option iconName={'video'} iconSize={videoIconSize} fontSize={fontSize} name={'Video'} />,
      },
    ],
    [iconSize, videoIconSize, fontSize],
  )

  const value = useMemo(() => {
    const selectedOption = options.find((option) => {
      return option.detail.type === type && option.detail.contentType === contentType
    })
    return selectedOption
  }, [options, type, contentType])

  const selectProps = {
    options,
    placeholder,
    value,
  }

  return [selectProps]
}

export default useStepTypeOptions
