import { OrganizationDetailDocument, OrganizationDocument, TenantMemberRole } from '../../graphql'
import appConfig from '../../config/app'

const tenantMocks = [
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: OrganizationDocument,
      variables: {
        id: '1',
      },
    },
    result: {
      data: {
        tenant: {
          id: '1',
          name: 'New Life Church',
          member: {
            id: '1',
            role: TenantMemberRole.Owner,
            __typename: 'TenantMember',
          },
          __typename: 'Tenant',
        },
      },
    },
  },
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: OrganizationDetailDocument,
      variables: {
        id: '1',
      },
    },
    result: {
      data: {
        tenant: {
          id: '1',
          name: 'New Life Church',
          member: {
            id: '1',
            role: TenantMemberRole.Owner,
            __typename: 'TenantMember',
          },
          programs: {
            id: '1',
            name: 'Christian Discipleship',
            image: {
              id: 'program-image-1',
              url: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
              __typename: 'Media',
            },
            process: {
              id: '1',
              name: 'Discipleship',
              __typename: 'Process',
            },
            journeys: [
              {
                id: '1',
                name: 'Small Group Leaders',
                __typename: 'Journey',
              },
              {
                id: '1',
                name: 'Elder Training',
                __typename: 'Journey',
              },
            ],
            member: {
              id: '1',
              email: 'test@test.com',
              roles: ['LEADER', 'APPRENTICE'],
            },
            members: [
              {
                id: '2',
                email: 'example@email.com',
                roles: ['LEADER'],
                profileImage: {
                  id: 'profile-image-2',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                person: {
                  id: 'person-2',
                  name: 'John Doe',
                  __typename: 'Person',
                },
                __typename: 'ProgramMember',
              },
              {
                id: '3',
                email: 'example@email.com',
                roles: ['LEADER'],
                profileImage: {
                  id: 'profile-image-3',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                person: {
                  id: 'person-3',
                  name: 'John Doe',
                  __typename: 'Person',
                },
                __typename: 'ProgramMember',
              },
              {
                id: '4',
                email: 'example@email.com',
                roles: ['LEADER'],
                profileImage: {
                  id: 'profile-image-4',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                person: {
                  id: 'person-4',
                  name: 'John Doe',
                  __typename: 'Person',
                },
                __typename: 'ProgramMember',
              },
              {
                id: '5',
                email: 'example@email.com',
                roles: ['LEADER'],
                profileImage: {
                  id: 'profile-image-5',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                person: {
                  id: 'person-5',
                  name: 'John Doe',
                  __typename: 'Person',
                },
                __typename: 'ProgramMember',
              },
              {
                id: '6',
                email: 'example@email.com',
                roles: ['LEADER'],
                profileImage: {
                  id: 'profile-image-6',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                person: {
                  id: 'person-6',
                  name: 'John Doe',
                  __typename: 'Person',
                },
                __typename: 'ProgramMember',
              },
              {
                id: '7',
                email: 'example@email.com',
                roles: ['LEADER'],
                profileImage: {
                  id: 'profile-image-7',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                person: {
                  id: 'person-7',
                  name: 'John Doe',
                  __typename: 'Person',
                },
                __typename: 'ProgramMember',
              },
              {
                id: '8',
                email: 'example@email.com',
                roles: ['LEADER'],
                profileImage: {
                  id: 'profile-image-8',
                  url: 'https://randomuser.me/api/portraits/men/1.jpg',
                  __typename: 'Media',
                },
                person: {
                  id: 'person-8',
                  name: 'John Doe',
                  __typename: 'Person',
                },
                __typename: 'ProgramMember',
              },
            ],
            __typename: 'Program',
          },
          members: [
            {
              id: '9',
              email: 'jim.smith@gmail.com',
              role: 'OWNER',
              profileImage: {
                id: 'profile-image-9',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-9',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
                {
                  id: '2',
                  name: 'Another Pretend Program',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
                {
                  id: '3',
                  name: 'Another Another Pretend Program',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantMember',
            },
            {
              id: '10',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-10',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-10',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              role: 'ADMIN',
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
                {
                  id: '2',
                  name: 'Another Pretend Program',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantMember',
            },
            {
              id: '11',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-11',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-11',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              role: 'ADMIN',
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantMember',
            },
            {
              id: '12',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-12',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-12',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              role: 'MEMBER',
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantMember',
            },
            {
              id: '13',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-13',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-13',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              role: 'MEMBER',
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantMember',
            },
          ],
          guests: [
            {
              id: '14',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-14',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-14',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
                {
                  id: '2',
                  name: 'Another Pretend Program',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
                {
                  id: '3',
                  name: 'Another Another Pretend Program',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantGuest',
            },
            {
              id: '15',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-15',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-15',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
                {
                  id: '2',
                  name: 'Another Pretend Program',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantGuest',
            },
            {
              id: '16',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-16',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-16',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantGuest',
            },
            {
              id: '17',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-17',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-17',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantGuest',
            },
            {
              id: '18',
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: 'profile-image-18',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: 'person-18',
                name: 'Jim Smith',
                __typename: 'Person',
              },
              /*programs: [
                {
                  id: '1',
                  name: 'Christian Discipleship',
                  icon: {
                    src: `${appConfig.issuePath}/img/samples/LifeStoneChristianDiscipleshipIcon.jpeg`,
                    __typename: 'Video',
                  },
                  __typename: 'Program',
                },
              ],*/
              __typename: 'TenantGuest',
            },
          ],
          __typename: 'Tenant',
        },
      },
    },
  },
]

export default tenantMocks
