import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

export type BreadcrumbItemProps = {
  title?: string
  path?: string
  onClick?: (path?: string) => void
  className?: string
}

const BreadcrumbItem = ({ title, className, path, onClick }: BreadcrumbItemProps) => {
  const Item = path ? Link : 'div'
  return (
    <div className={'flex justify-center items-center'}>
      <Item
        to={path || ''}
        className={classNames(
          `font-figtree font-semibold 'text-gray-900' text-ellipsis overflow-hidden items-center text-[14px] leading-[19.6px] ${path ? 'hover:underline cursor-pointer' : ''}`,
          className,
        )}>
        {title}
      </Item>
    </div>
  )
}

export default BreadcrumbItem
