import React from 'react'
import Icon from '../Icon'

type IndicatorProps = {
  color?: string
  indicator?: IconName | undefined
  isSpinning?: boolean
  size?: number | string
}

const Indicator = ({ indicator, color = 'gray-900', isSpinning = false, size = 20 }: IndicatorProps) => {
  const name = indicator ? indicator : 'loader'
  return <Icon name={name} color={color} className={`${isSpinning ? 'animate-spin ' : ''} shrink-0`} size={size} />
}

export default Indicator
