import React, { FC, useMemo } from 'react'
import { Text } from '../../../components/ui'
import useJourney from '../../../hooks/useJourney'
import { JourneyPhaseFieldsFragment } from '../../../graphql'
import StepTypeInfo from '../../../components/shared/StepTypeInfo'
import useJourneys from '../../../hooks/useJourneys'

const JourneysInfo: FC = () => {
  const { journey } = useJourney()
  const { journeys } = useJourneys()

  // Remove top margin from first HTML element
  const removeTopMargin = (html: string) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    doc.body.firstElementChild?.classList.add('mt-0')

    return doc.body.innerHTML
  }

  const innerHTML = useMemo(() => {
    if (journey?.leaderInformation) {
      return removeTopMargin(journey.leaderInformation)
    }
    return undefined
  }, [journey?.leaderInformation])

  const renderPhase = (phase: JourneyPhaseFieldsFragment) => {
    return (
      <StepTypeInfo
        color={phase.color}
        name={phase.name}
        icon={(phase.iconName as IconName) || null}
        description={phase.description || ''}
      />
    )
  }

  const phases = useMemo(() => {
    return journey?.phases || journeys?.[0]?.phases || []
  }, [journey, journeys])

  return (
    <div className={'flex flex-col gap-[30px]'}>
      {journey && (
        <div>
          <Text className={'text-gray'}>{journey?.name || ''}</Text>
          <div dangerouslySetInnerHTML={innerHTML ? { __html: innerHTML } : undefined} />
        </div>
      )}
      <div className={'flex flex-col gap-[20px]'}>
        <Text variant={'h2'}>Phases</Text>
        <div className={'border-[1px] border-gray-300'} />
        {phases.map((phase) => {
          return renderPhase(phase)
        })}
      </div>
    </div>
  )
}

export default JourneysInfo
