import { useEffect, useState } from 'react'
import { JourneyDetailDocument, useJourneyDetailQuery, useUpdateJourneyMutation } from '../graphql'
import useRouteId from './useRouteId'
import { WatchQueryFetchPolicy } from '@apollo/client'
import useToast from './useToast'

type UseJourneyDetailParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  id?: string
}

type UpdateJourneyParams = {
  name: string
  description?: string
}

const useJourneyDetail = ({ fetchPolicy = 'cache-first', id: _id }: UseJourneyDetailParams = {}) => {
  const toast = useToast()

  const [selected, setSelected] = useState<{ name?: string; id: string } | undefined>(undefined)

  const { id } = useRouteId({
    baseRoute: '/journey',
    selected,
  })

  const variables = {
    where: {
      id: _id || id || '',
    },
  }

  const { data, loading } = useJourneyDetailQuery({
    variables,
    fetchPolicy,
  })

  useEffect(() => {
    if (data) {
      setSelected({
        name: data.journey.name,
        id: data.journey.id,
      })
    }
  }, [data])

  const [updateJourneyMutation, { loading: updating }] = useUpdateJourneyMutation({
    refetchQueries: [{ query: JourneyDetailDocument, variables }],
    onCompleted: (data) => {
      if (data.updateJourney.__typename === 'UpdateJourneySuccess') {
        toast.push('Success', data.updateJourney.message, 'success')
      }
    },
    onError: (error) => {
      toast.push('Error', error.message, 'error')
    },
  })

  const updateJourney = async (journey: UpdateJourneyParams) => {
    await updateJourneyMutation({
      variables: {
        where: {
          id: _id || id || '',
        },
        data: journey,
      },
    })
  }

  return { journey: data?.journey, loading, updateJourney, updating }
}

export default useJourneyDetail
