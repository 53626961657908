import { createSelector, createSlice } from '@reduxjs/toolkit'

export type SidePeekState = {
  collapsed: boolean
  show: boolean
}

export const initialState: SidePeekState = {
  collapsed: false,
  show: false,
}

export const sidePeekSlice = createSlice({
  name: 'layout/sidePeek',
  initialState,
  reducers: {
    toggleSidePeek: (state) => {
      state.collapsed = !state.collapsed
    },
    setShowSidePeek: (state, action) => {
      state.show = action.payload
    },
  },
})

export const selectSidePeek = createSelector(
  (state: { layout: { sidePeek: SidePeekState } }) => state.layout.sidePeek,
  (sidePeek) => sidePeek,
)

export const { toggleSidePeek, setShowSidePeek } = sidePeekSlice.actions

export default sidePeekSlice.reducer
