import React, { FC } from 'react'
import ViewHeader from '../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../components/shared/BreadcrumbBar'
import usePersonDetail from '../../../hooks/usePersonDetail'
import useRouteId from '../../../hooks/useRouteId'
import formatRouteId from '../../../utils/formatRouteId'
import usePrograms from '../../../hooks/usePrograms'

const PersonDetailHeader: FC = () => {
  const { person } = usePersonDetail()
  const { selectedProgram } = usePrograms()
  const { id: connectionId } = useRouteId({
    baseRoute: '/connection',
  })
  const { routeId: personId } = formatRouteId(person?.fullName, person?.id)
  const { routeId: programId } = formatRouteId(selectedProgram?.name, selectedProgram?.id)

  return (
    <div className={'flex flex-col gap-[25px]'}>
      <ViewHeader
        start={[
          <BreadcrumbBar
            routeTitleMap={{
              person: person?.fullName,
            }}
            routePathMap={{
              connections: `/connections/${programId}`,
              person: `/connection/${connectionId}/${personId}`,
            }}
          />,
        ]}
      />
    </div>
  )
}

export default PersonDetailHeader
