import { FC } from 'react'
import useStepDetail from '../../../hooks/useStepDetail'
import StepProperties from './StepProperties'
import StepInfo from './StepInfo'

const StepDrawer: FC = () => {
  const { step } = useStepDetail()

  return step?.draft ? <StepProperties /> : <StepInfo />
}

export default StepDrawer
