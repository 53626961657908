import React, { FC } from 'react'
import ViewHeader from '../../../components/shared/ViewHeader'
import BreadcrumbBar from '../../../components/shared/BreadcrumbBar'

const SeriesHeader: FC = () => {
  // const { series } = useSeries()

  // const seriesId = useRouteId({
  //   baseRoute: '/series',
  // })

  return (
    <div className={'flex flex-col gap-[25px]'}>
      <ViewHeader
        start={[
          <BreadcrumbBar
            routeTitleMap={{
              phase: 'Equipping Phase',
              series: 'The Name of the Series',
            }}
          />,
        ]}
      />
    </div>
  )
}

export default SeriesHeader
