import React, { FC } from 'react'
import Table from '../../../components/ui/Table/Table'
import Icon from '../../../components/ui/Icon'
import StatusTag from '../../../components/shared/StatusTag'
import { Text, Button } from '../../../components/ui'
import Tooltip from '../../../components/ui/Tooltip'

type SeriesViewProps = {
  series?: any
}

const SeriesView: FC<SeriesViewProps> = ({ series }) => {
  const phase = series?.phase
  const sessionsCompleted = series?.sessionsCompleted || 0
  const totalSessions = series?.totalSessions || 0
  const sessions = series?.sessions || []

  const progressPercentage = totalSessions ? Math.round((sessionsCompleted / totalSessions) * 100) : 0

  const getTagColor = (phase: string) => {
    switch (phase) {
      case 'Equipping':
        return 'orange'
      case 'Establishing':
        return 'blue'
      case 'In-Depth Training':
        return 'fuchsia'
    }
  }

  return (
    <div className={'flex w-full justify-center items-center flex-col'}>
      <div className={'pt-[10px] pb-[50px] w-full'}>
        <div className={'w-full rounded-[10px] mb-[25px] overflow-hidden'}>
          <Table className={'w-full overflow-x-scroll'}>
            <Table.THead>
              <div>
                <div className={`relative w-full h-[5px] overflow-visible`}>
                  <div
                    className={`w-full h-[5px] rounded-0 mix-blend-multiply absolute bottom-0 left-0 bg-gray-light`}
                  />
                  <div
                    className={`absolute left-0 h-[5px] bottom-0 bg-[${phase?.color}]`}
                    style={{
                      width: `${progressPercentage}%`,
                      backgroundColor: phase?.color,
                      borderRadius: '0px 5px 5px 0px',
                    }}
                  />
                </div>
                <div className={'flex flex-col gap-[25px] p-[25px]'}>
                  <div className={'flex justify-between items-center flex-wrap gap-[10px]'}>
                    <div className="flex gap-[10px] items-center">
                      <Icon name={'layers'} size={24} color={phase?.color} />
                      <Text variant={'h3'}>{series?.name}</Text>
                    </div>
                    <Text
                      variant={'p'}
                      size={'sm'}
                      className={'text-gray-dark'}>{`${sessionsCompleted} / ${totalSessions} sessions completed`}</Text>
                  </div>
                  <Text variant={'p'} size={'sm'} className={'text-gray-900'}>
                    {series?.description}
                  </Text>
                </div>
              </div>
            </Table.THead>
            <Table.TBody>
              {sessions.map((session: any) => {
                return (
                  <Table.Tr className={'cursor-pointer'} key={session?.id}>
                    <Table.Td colSpan={4} className={'max-w-none'}>
                      <div className={'flex gap-[20px] items-center justify-between'}>
                        <div className={'gap-[20px] flex items-center'}>
                          <div
                            className={
                              'flex justify-self-start rounded-[15px] bg-gray-200 items-center justify-center h-[30px] w-[30px] min-h-[30px] min-w-[30px]'
                            }>
                            <Text variant={'p'} size={'xs'} className={'leading-[14px] text-gray-900 font-semibold'}>
                              {session?.id}
                            </Text>
                          </div>
                          <Tooltip title={session?.name} delay={1250}>
                            <div
                              style={{
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                display: '-webkit-box',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                              className={'font-semibold'}>
                              <Text variant={'h4'} className={'truncate justify-self-start'}>
                                {session?.name}
                              </Text>
                            </div>
                          </Tooltip>
                        </div>
                        <div className={'gap-[20px] flex items-center'}>
                          <StatusTag
                            status={session.status}
                            tagType={session?.status !== 'UPCOMING' ? 'solid' : 'outline'}
                            tagColor={session?.status !== 'UPCOMING' ? getTagColor(phase?.name) : undefined}
                          />
                          <div className={'justify-self-end'}>
                            <Button icon={<Icon name={'chevron-right'} />} variant={'plain'} />
                          </div>
                        </div>
                      </div>
                    </Table.Td>
                  </Table.Tr>
                )
              })}
            </Table.TBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default SeriesView
