import React, { FC, useEffect, useState } from 'react'
import { Alert, Button, Dialogue, Form, Input, Icon, DatePicker, Text } from '../../../components/ui'
import Select from '../../../components/ui/Select'
import Checkbox from '../../../components/ui/Checkbox'
import { PersonDetailQueryResult, TaskFieldsFragment } from '../../../graphql'
import { Field, Formik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import * as Yup from 'yup'
import { renderDueAtLabel } from '../components/TaskItem'
import useTasks from '../../../hooks/useTasks'
import './_modal.css'
import useConnectionOptions from '../../../hooks/useConnectionOptions'
import AsyncSelect from 'react-select/async'

type AddUpdateTaskProps = {
  task?: TaskFieldsFragment | null
  open?: boolean
  onClose?: () => void
  connection?: NonNullable<PersonDetailQueryResult['data']>['person'] // { label: JSX.Element; value: string }
}

const AddUpdateTask: FC<AddUpdateTaskProps> = ({ task, open = false, onClose, connection }) => {
  const [dueAt, setDueAt] = useState<Date | undefined>(undefined)
  const { createTask, creating, saveTask, updating, removeTask: _removeTask } = useTasks()

  useEffect(() => {
    if (!open) {
      setDueAt(undefined)
    }
  }, [open])

  useEffect(() => {
    if (task?.dueAt) {
      setDueAt(task?.dueAt)
    }
  }, [task])

  const [connectionProps] = useConnectionOptions()

  // const { selectedProgram } = usePrograms()

  // const [connectionsDropdown] = useConnectionsDropdownLazyQuery()

  // const loadConnectionsOptions = () =>
  //   new Promise<OptionType[]>(async (resolve) => {
  //     if (!selectedProgram) {
  //       return resolve([])
  //     }
  //     const response = await connectionsDropdown({
  //       variables: {
  //         programId: selectedProgram.id,
  //       }
  //     })
  //     resolve(response.data?.connections?.list.map((connection: ConnectionFieldsFragment) => ({
  //       value: connection.id,
  //       label: <Option name={connection?.name} avatar={connection?.profileImage?.url}/>,
  //     })) || [])
  //   })

  const errorMessage = null

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    dueAt: Yup.date().optional().nullable(),
    relatedPerson: Yup.object().optional().nullable(),
  })

  const removeTask = (taskId: string) => {
    _removeTask(taskId)
    onClose?.()
  }

  return (
    <Dialogue isOpen={open} className={'bg-gray-900 bg-opacity-30'} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          name: task?.name,
          dueAt: task?.dueAt ? new Date(task.dueAt) : undefined,
          relatedPerson: connection
            ? connection
            : task?.relatedPerson?.id
              ? {
                  id: task?.relatedPerson?.id,
                  name: task?.relatedPerson?.name,
                  profileImage: task?.relatedPerson?.profileImage,
                }
              : null,
          complete: task?.complete,
        }}
        onSubmit={async (data, { setSubmitting }) => {
          console.log(data)
          if (task?.id) {
            await saveTask(task.id, {
              name: data.name || '',
              dueAt: data?.dueAt || null,
              relatedPerson: { connect: data?.relatedPerson?.id ? { id: data.relatedPerson.id } : undefined },
              complete: data.complete || false,
              completedAt: !task.complete && data.complete ? new Date() : !data.complete ? null : undefined,
            })
            onClose?.()
          } else {
            await createTask({
              name: data.name || '',
              dueAt: data?.dueAt,
              relatedPerson: { connect: data?.relatedPerson?.id ? { id: data.relatedPerson.id } : undefined },
            })
            onClose?.()
          }
        }}>
        {({ isSubmitting, errors, touched, getFieldProps, values }) => (
          <div id="add-update-task-modal" className="w-[339px] self-center justify-self-center">
            <Form.Container className={'!p-0'}>
              <div className={'flex items-center justify-between w-full'}>
                <div className={'flex items-center gap-2.5'}>
                  <Icon name={'check-square'} size={24} />
                  <Text variant={'h2'} className={'leading-[22px]'}>
                    To-Do
                  </Text>
                </div>
                <div>{renderDueAtLabel(dueAt)}</div>
              </div>
              <Form.Item
                size={'sm'}
                errorMessage={touched.name && errors.name ? errors.name : undefined}
                invalid={touched.name && !!errors.name}>
                <Input
                  size={'sm'}
                  invalid={!!(touched.name && errors.name)}
                  defaultValue={task?.name}
                  onChange={(e) => {
                    values.name = e.target.value
                  }}
                  textArea={true}
                  className={'bg-gray-50'}
                  placeholder={'Describe the task you need to accomplish'}
                />
              </Form.Item>
              <Form.Item label={'Due date'} size={'sm'} invalid={touched.dueAt && !!errors.dueAt}>
                <DatePicker
                  placeholder={'Pick a date'}
                  size={'sm'}
                  value={values.dueAt}
                  className={'bg-gray-50'}
                  onChange={(e) => {
                    values.dueAt = e ? new Date(e) : undefined
                    setDueAt(values.dueAt)
                  }}
                />
              </Form.Item>
              <Form.Item
                label={'Related Connection'}
                size={'sm'}
                errorMessage={touched.relatedPerson && errors.relatedPerson ? errors.relatedPerson : undefined}
                invalid={touched.relatedPerson && !!errors.relatedPerson}>
                <Field
                  size={'sm'}
                  name={'relatedPerson'}
                  key={`connection-dropdown`}
                  {...connectionProps}
                  isClearable
                  //defaultValue={values.relatedPerson}
                  //options={connection ? [connection] : loadConnectionsOptions}
                  //placeholder={'None'}
                  isDisabled={!!connection?.id}
                  cacheOptions
                  defaultOptions
                  // loadOptions={loadConnectionsOptions}
                  // componentAs={AsyncSelect}
                  className={'connection-dropdown'}
                  component={Select}
                  componentAs={AsyncSelect}
                  //onChange={(e: any) => {
                  //values.relatedPerson = e
                  //}}
                />
              </Form.Item>
              {task?.id && (
                <Form.Item>
                  <div className={'flex items-center gap-[4px]'}>
                    <Checkbox
                      size={'sm'}
                      checked={values?.complete}
                      onClick={(e) => (values.complete = !values?.complete)}
                    />
                    <Text variant={'p'} size={'md'} className={'text-gray-dark leading-[15px]'}>
                      Mark as complete
                    </Text>
                  </div>
                </Form.Item>
              )}
              <AnimatePresence>{errorMessage && <Alert type={'danger'} label={errorMessage} />}</AnimatePresence>
              <div className={`flex flex-col justify-center gap-5 ${!task?.id ? 'mt-[27px]' : ''}`}>
                {!task?.id ? (
                  <>
                    <Button
                      disabled={creating}
                      type={'submit'}
                      size={'md'}
                      icon={<Icon name={'plus'} size={20} color={'white'} />}
                      label={'Add To-Do'}
                      loading={creating}
                      block
                      variant={'solid'}
                    />
                    <Button
                      type={'button'}
                      size={'md'}
                      label={'Cancel'}
                      onClick={onClose}
                      loading={false}
                      block
                      variant={'default'}
                    />
                  </>
                ) : (
                  <>
                    <Button
                      disabled={updating}
                      type={'submit'}
                      size={'md'}
                      label={'Update'}
                      loading={updating}
                      block
                      variant={'solid'}
                    />
                    <Button
                      disabled={updating}
                      onClick={() => removeTask(task.id)}
                      type={'button'}
                      size={'md'}
                      icon={<Icon name={'trash-2'} size={20} />}
                      label={'Delete'}
                      loading={false}
                      block
                      variant={'default'}
                    />
                  </>
                )}
              </div>
            </Form.Container>
          </div>
        )}
      </Formik>
    </Dialogue>
  )
}

export default AddUpdateTask
