import { Figure } from '../Figure'
import { Quote } from './Quote'

declare module '@tiptap/core' {
  // eslint-disable-next-line no-unused-vars
  interface Commands<ReturnType> {
    blockquoteFigure: {
      setBlockquote: () => ReturnType
    }
  }
}

export const BlockquoteFigure = Figure.extend({
  name: 'blockquoteFigure',

  group: 'block',

  content: 'quote',

  isolating: true,

  addExtensions() {
    return [Quote]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'figure',
      { 'data-type': this.name },
      [
        'div',
        {
          class: 'flex gap-[20px]',
        },
        ['div', { class: 'bg-quote min-w-[3px] w-[3px] opacity-25' }],
        ['div', {}, 0],
      ],
    ]
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => false,
    }
  },

  addAttributes() {
    return {
      ...this.parent?.(),
    }
  },

  addCommands() {
    return {
      setBlockquote:
        () =>
        ({ state, chain }) => {
          const position = state.selection.$from.start()
          const selectionContent = state.selection.content()

          return chain()
            .focus()
            .insertContent({
              type: this.name,
              content: [
                {
                  type: 'quote',
                  content: selectionContent.content.toJSON() || [
                    {
                      type: 'paragraph',
                      attrs: {
                        textAlign: 'left',
                      },
                    },
                  ],
                },
              ],
            })
            .focus(position + 1)
            .run()
        },
    }
  },
})

export default BlockquoteFigure
