import React, { ElementType, ReactNode } from 'react'
import classNames from 'classnames'
import THead from './THead'
import TBody from './TBody'
import TFoot from './TFoot'
import Tr from './Tr'
import Th from './Th'
import Td from './Td'
import Sorter from './Sorter'
import './_table.css'

type TableProps = {
  hoverable?: boolean
  compact?: boolean
  asElement?: ElementType
  borderlessRow?: boolean
  children?: ReactNode
  className?: string
  overflow?: boolean
}

const Table = React.forwardRef(
  (
    {
      borderlessRow = false,
      children,
      className,
      hoverable = true,
      compact = false,
      overflow = true,
      asElement: Component = 'table',
      ...rest
    }: TableProps,
    ref,
  ) => {
    const tableClass = classNames(
      'rounded-[10px]',
      Component === 'table' ? 'table-default' : 'table-flex',
      hoverable && 'table-hover',
      compact && 'table-compact',
      borderlessRow && 'borderless-row',
      className,
    )
    return (
      // Todo: Evaluate in the Table ui component if the below commented out line can be used instead
      <div className={classNames('rounded-[10px] w-full', className)}>
        <Component className={tableClass} {...rest} ref={ref}>
          {children}
        </Component>
      </div>
    )
  },
)

export default Table as typeof Table & {
  THead: typeof THead
  TBody: typeof TBody
  TFoot: typeof TFoot
  Tr: typeof Tr
  Th: typeof Th
  Td: typeof Td
  Sorter: typeof Sorter
}
