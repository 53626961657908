import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'
import { all, createLowlight } from 'lowlight'

const lowlight = createLowlight(all)

export const CodeBlock = CodeBlockLowlight.configure({
  lowlight,
  // Don't know why typescript isn't allowing the following line. This property is valid
  // @ts-ignore
  defaultLanguage: 'javascript',
})
