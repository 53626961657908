import React from 'react'
import { Button, Divider, Icon } from '../../../components/ui'
import Dropdown from '../../../components/ui/Dropdown'

const IconComponent = <Icon name={'placeholder'} />

const items = [
  { id: '1', title: 'Dropdown Item 1', value: 'item 1', icon: IconComponent },
  { id: '2', title: 'Dropdown Item 2', value: 'item 2', icon: IconComponent },
  { id: '3', title: 'Dropdown Item 3', value: 'item 3', icon: IconComponent },
  { id: '4', title: 'Dropdown Item 4', value: 'item 4', icon: IconComponent },
  { id: '5', title: 'Dropdown Item 5', value: 'item 5', icon: IconComponent },
  { id: '6', title: 'Dropdown Item 6', value: 'item 6', icon: IconComponent },
]

const Dropdown2Example = () => {
  const [selectedKey, setSelectedKey] = React.useState<string>('dropdown-item-3')
  return (
    <div className={'flex flex-row justify-center items-center'}>
      <div
        style={{
          display: 'flex',
          gap: 40,
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Dropdown
          renderHeader={
            <Dropdown.Header>
              <h6>Render Header</h6>
            </Dropdown.Header>
          }
          title={'Custom Dropdown Button'}
          showSelectedTitle={false}
          onSelect={(item) => {
            console.log('onSelect', item)
            setSelectedKey(item.eventKey || '')
          }}
          onOpen={() => console.log('onOpen')}
          onClose={() => console.log('onClose')}
          onToggle={() => console.log('onToggle')}
          selectedKey={selectedKey}
          defaultSelectedKey={'dropdown-item-3'}
          menuClass={'min-w-[355px]'}
          renderToggle={<Button size={'lg'} shape={'circle'} variant={'solid'} color={'blue'} />}>
          <Dropdown.Group title={'Group Heading'} />
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                disabled
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
          <div className={'p-4'}>
            <Divider />
          </div>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown
          title={'Context Dropdown Button'}
          trigger={'context'}
          onContextMenu={() => console.log('onContextMenu')}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown
          title={'Hover Dropdown Button'}
          trigger={'hover'}
          onMouseEnter={() => console.log('onMouseEnter')}
          onMouseLeave={() => console.log('onMouseLeave')}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Top Start'} placement={'top-start'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Top Center'} placement={'top'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Bottom Start'} placement={'bottom-start'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Bottom center'} placement={'bottom'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Bottom End'} placement={'bottom-end'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Middle Start Top'} placement={'right-start'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Middle Start Bottom'} placement={'right-end'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Middle End Top'} placement={'left-start'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
        <Dropdown title={'Middle End Bottom'} placement={'left-end'}>
          {items.map((item, i) => {
            return (
              <Dropdown.Item
                key={i}
                value={item.value}
                onClick={() => console.log('DropdownItem onClick')}
                title={item.title}
                icon={item.icon}
                eventKey={`dropdown-item-${i + 1}`}
              />
            )
          })}
        </Dropdown>
      </div>
    </div>
  )
}

export default Dropdown2Example
