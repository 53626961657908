import React from 'react'
import { Brand } from '../../../ui'
import appConfig from '../../../../config/app'

export type SidePeekBrandProps = {
  collapsed?: boolean
}

const SidePeekBrand = ({ collapsed }: SidePeekBrandProps) => {
  const fullOpacity = collapsed ? 'opacity-0' : 'opacity-100'
  const streamlineOpacity = collapsed ? 'opacity-100' : 'opacity-0'
  const duration = 'duration-[150ms]'

  const handleClick = () => {
    window.open(appConfig.productSite, '_blank')
  }

  return (
    <div onClick={handleClick} className={`p-[18px] cursor-pointer flex items-center relative h-[65px]`}>
      <Brand type={'full'} mode={'dark'} className={`h-[29px] transition-opacity ${duration} ${fullOpacity}`} />
      <div className={`absolute bottom-[18px] transition-opacity ${duration} ${streamlineOpacity}`}>
        <Brand type={'streamline'} className={'h-[29px]'} mode={'dark'} />
      </div>
    </div>
  )
}

export default SidePeekBrand
