import React, { FC } from 'react'
import { Icon, Text } from '../ui'
import classNames from 'classnames'

type OptionProps = {
  name: string
  className?: string
  iconName?: IconName
  iconColor?: string
  iconSize?: number
  fontSize?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl'
  prefix?: JSX.Element
}

const Option: FC<OptionProps> = ({
  name,
  className,
  iconName,
  iconColor,
  iconSize = 24,
  fontSize = 'md',
  prefix = null,
}) => {
  const Prefix = iconName ? <Icon name={iconName} color={iconColor} size={iconSize} /> : prefix
  return (
    <div className={classNames('flex gap-2 items-center', className)}>
      {Prefix ? Prefix : null}
      <Text className={`text-${fontSize} text-ellipsis overflow-hidden`}>{name}</Text>
    </div>
  )
}

export default Option
