import React, { CSSProperties, ElementType } from 'react'
import classNames from 'classnames'

type SkeletonProps = {
  animation?: boolean
  asElement?: ElementType
  className?: string
  height?: number | string
  width?: number | string
  style?: CSSProperties
  variant?: 'circle' | 'block'
}

const Skeleton = React.forwardRef(
  (
    {
      animation = true,
      asElement: Component = 'span',
      className,
      height,
      style,
      variant = 'block',
      width,
    }: SkeletonProps,
    ref,
  ) => {
    return (
      <Component
        ref={ref}
        className={classNames(
          'bg-gray-200 flex',
          variant === 'circle' && 'rounded-full w-10 h-10',
          variant === 'block' && 'rounded h-2.5 w-full',
          animation && 'animate-pulse',
          className,
        )}
        style={{
          width,
          height,
          ...style,
        }}
      />
    )
  },
)

export default Skeleton
