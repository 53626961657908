import sideMocks from './sideMocks'
import authMocks from './authMocks'
import notificationMocks from './notificationMocks'
import programMocks from './programMocks'
import tenantMocks from './tenantMocks'
import myJourneyMocks from './myJourneyMocks'
import journeysMocks from './journeysMocks'
import journeyMocks from './journeyMocks'
import stepMocks from './stepMocks'
import taskMocks from './taskMocks'
import connectionMocks from './connectionMocks'
import personMocks from './personMocks'

const mocks = [
  ...sideMocks,
  ...authMocks,
  ...notificationMocks,
  ...programMocks,
  ...tenantMocks,
  ...myJourneyMocks,
  ...journeysMocks,
  ...journeyMocks,
  ...stepMocks,
  ...taskMocks,
  ...connectionMocks,
  ...personMocks,
]

export default mocks
