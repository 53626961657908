import React, { FC, useEffect, useState } from 'react'
import { TaskFieldsFragment, TenantUserRole } from '../../../graphql'
import { Button, Form, Icon, Divider, Dialogue, Text } from '../../../components/ui'
import SilverSVG from '../../../assets/SilverSVG'
import Tabs from '../../../components/ui/Tabs'
import JourneySteps from '../components/JourneySteps'
import TaskItem from '../../tasks/components/TaskItem'
import useTasks from '../../../hooks/useTasks'
import AddUpdateTask from '../../tasks/views/AddUpdateTask'
import useOrganization from '../../../hooks/useOrganization'
import { useMatch, useNavigate } from 'react-router-dom'
import usePersonDetail from '../../../hooks/usePersonDetail'
import formatRouteId from '../../../utils/formatRouteId'
import useConnections from '../../../hooks/useConnections'
import useToast from '../../../hooks/useToast'
import UpdatePersonDetailName from './UpdatePersonDetailName'
import UpdatePersonDetailContactNotes from './UpdatePersonDetailContactNotes'

const PersonDetailGeneral: FC = () => {
  const org = useOrganization()
  const { removeTask, toggleTaskStatus } = useTasks()
  const navigate = useNavigate()
  const toast = useToast()
  const connectionMatch = useMatch('/connection/:connectionId/*')
  const connectionId = connectionMatch?.params.connectionId
  const { person, type } = usePersonDetail()
  const { removeConnection, deleting } = useConnections()

  const [removePersonModal, setRemovePersonModal] = useState<{ open: boolean }>({
    open: false,
  })
  const [selected, setSelected] = useState<TaskFieldsFragment | null>(null)
  const [open, setOpen] = useState(false)
  const [editName, setEditName] = useState<boolean>(false)
  const [editContactNotes, setEditContactNotes] = useState<boolean>(false)

  const sortedTasks = person?.myRelatedTasks?.list
    ? [...person.myRelatedTasks.list].sort((a, b) => +a.complete - +b.complete)
    : []

  const user = !!person?.user?.id
  const tenantRole = person?.user?.tenants?.find(({ id }) => id === org?.id)?.role
  const member = tenantRole && tenantRole !== TenantUserRole.Guest

  useEffect(() => {
    if (type === 'organization' && !user) {
      if (person?.myConnection?.id) {
        // If the person is not a user while viewing this from the organization view, but the signed-in user is connected with them
        // then we should redirect to the connection view instead
        const { routeId } = formatRouteId(person.name, person.id)
        navigate(`/connection/${person.myConnection.id}/${routeId}`)
      } else {
        // If the person is not a user while viewing this from the organization view, and the signed-in user is not connected with them
        // then we should show a not found message
        // Todo: Display a "user not found" message
      }
    }
  }, [type, person, user, navigate])

  const renderGeneral = () => (
    <>
      <div className={'flex justify-center mt-[28px]'}>
        <div className={'flex flex-col gap-[40px] grow max-w-[540px]'}>
          <div className={'flex w-full gap-[20px]'}>
            <div className={'flex flex-col gap-[25px] grow'}>
              <div className={'flex w-full items-center gap-2.5'}>
                <div className={'w-1/2'}>
                  <Form.Item label={'Email'}>
                    <Text variant={'p'} size={'sm'} className={'text-gray-dark'}>
                      {person?.email}
                    </Text>
                  </Form.Item>
                </div>
                <div className={'w-1/2'}>
                  <Form.Item label={'Phone'}>
                    <Text variant={'p'} size={'sm'} className={'text-gray-dark'}>
                      {person?.primaryContact?.phone}
                    </Text>
                  </Form.Item>
                </div>
              </div>
              <div className={'w-full relative'}>
                <Form.Item label={'Notes'} size={'sm'}>
                  <Text variant={'p'} size={'sm'} className={'text-gray-dark w-full'}>
                    {person?.notes[0]?.content}
                  </Text>
                </Form.Item>
              </div>
            </div>
            <Button
              className={'self-start'}
              type={'button'}
              variant={'default'}
              size={'xs'}
              onClick={() => setEditContactNotes(true)}>
              Edit
            </Button>
          </div>
          {person?.user?.programs?.length && (
            <div className={'flex flex-col gap-[30px]'}>
              <div className={'flex items-center w-full gap-2.5'}>
                <Text variant={'h6'}>Program{person?.user?.programs?.length > 1 ? 's' : ''}</Text>
                <Divider />
              </div>
              <div className={'flex flex-col gap-[15px]'}>
                {person?.user?.programs?.map((programUser) => (
                  <div className={'flex items-center gap-[10px]'}>
                    {programUser?.program?.image?.url && (
                      <img
                        className={`w-[30px] h-[30px] rounded-[7px] object-cover`}
                        src={programUser?.program?.image?.url}
                        alt={`${programUser?.program?.name}-icon`}
                      />
                    )}
                    <Text variant={'h4'}>{programUser?.program?.name}</Text>
                    <div
                      className={
                        'flex items-center justify-center w-fit rounded-[5px] gap-[10] p-[4px] border border-silver-400'
                      }>
                      <Text variant={'h6'} className={'font-semibold leading-[13px] tracking-[1.3px] text-[#9CA3AF]'}>
                        {programUser?.roles[0]}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {user && (
            <div className={'flex flex-col gap-[30px]'}>
              <div className={'flex items-center w-full gap-2.5'}>
                <Text variant={'h6'}>Journey</Text>
                <Divider />
              </div>
              <div className={'flex flex-col gap-[15px]'}>
                {person?.journey?.journey?.name && (
                  <div className={'flex items-center gap-[10px] mb-[15px]'}>
                    <Icon name={'compass'} size={20} color={'#519D9F'} />
                    <Text variant={'h4'}>{person?.journey?.journey?.name}</Text>
                  </div>
                )}
                <JourneySteps journey={person?.journey} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <Divider />
        <div className={'flex flex-col gap-[25px]'}>
          <div className={'flex flex-col'}>
            <Text variant={'h4'} spacing>
              {type === 'connection' ? 'Remove connection' : 'Deactivate user'}
            </Text>
            <Text variant={'p'} size={'sm'}>
              {type === 'connection'
                ? "Removing this connection will remove any notes about them, and disassociate them from any personal to-dos you've created."
                : 'Deactivating this user will remove their access to the organization and all of the programs they are a member of.'}
            </Text>
          </div>
          <Button
            icon={<Icon color={'red'} name={'trash-2'} />}
            className={'border-red'}
            color={'red'}
            size={'sm'}
            onClick={() => {
              setRemovePersonModal({
                open: true,
              })
            }}>
            {type === 'connection' ? 'Remove this connection' : 'Deactivate this user'}
          </Button>
        </div>
      </div>
    </>
  )

  const renderToDos = () => (
    <div className={'flex flex-col mt-[28px] gap-[10px]'}>
      <div className={'flex items-center justify-between w-full'}>
        <Text variant={'h2'}>My To-Dos with {person?.fullName}</Text>
        <Button
          variant={'solid'}
          icon={<Icon name={'plus'} color={'white'} size={19} />}
          size={'xs'}
          label={'Add'}
          onClick={() => setOpen(true)}
        />
      </div>
      <div className={'flex flex-col'}>
        {sortedTasks?.map((task, index: number) => {
          return (
            <div key={task.id}>
              <TaskItem
                task={task}
                onClickCheckbox={() => toggleTaskStatus(task.id)}
                onRemove={() => removeTask(task.id)}
                containerClassName={'w-[500px] h-fit'}
                contentClassName={'w-[430px]'}
                onClick={() => {
                  setSelected(task)
                  setOpen(true)
                }}
              />
              {index !== sortedTasks?.length - 1 && <Divider />}
            </div>
          )
        })}
      </div>
    </div>
  )

  return person ? (
    <div className={'flex justify-center'}>
      <div className={'flex flex-col gap-[25px] grow max-w-[540px]'}>
        <div className={'flex flex-col gap-[20px]'}>
          <div className={'flex flex-row justify-between items-center'}>
            <div className={'flex gap-[20px] items-center'}>
              {person?.profileImage?.url && (
                <img
                  className={`w-[65px] h-[65px] rounded-[7px] object-cover`}
                  src={person?.profileImage?.url}
                  alt={`${person?.fullName}-icon`}
                />
              )}
              <div className={'flex flex-col gap-[5px]'}>
                <Text variant={'h3'}>{person?.fullName}</Text>
                {person?.journey?.currentPhase?.phase?.state?.name && (
                  <div className={'flex items-center gap-[10px] ml-[-4px]'}>
                    <div className={'flex items-center gap-[4px]'}>
                      <SilverSVG />
                      <Text variant={'p'} size={'sm'} className={'text-gray-dark'}>
                        {person?.journey?.currentPhase?.phase?.state?.name}
                      </Text>
                    </div>
                    <div className={'h-[21px] w-[1px] bg-gray-light'} />
                    <Text variant={'p'} size={'sm'} className={'text-gray-dark'}>
                      {member ? 'Member' : 'Guest'} of {org?.name}
                    </Text>
                  </div>
                )}
              </div>
            </div>
            {!user && (
              <Button
                className={'self-center'}
                type={'button'}
                variant={'default'}
                size={'xs'}
                onClick={() => setEditName(true)}>
                Edit
              </Button>
            )}
          </div>
          {type === 'connection' ? (
            <Tabs defaultValue={'general'}>
              <Tabs.TabList className={'!border-[#D9D9D9]'}>
                <Tabs.TabNav value="general" color={'gray-dark'} className={'flex items-center gap-[4px]'}>
                  <Icon name={'user'} size={20} />
                  General
                </Tabs.TabNav>
                <Tabs.TabNav value="todos" className={'flex items-center gap-[4px]'}>
                  <Icon name={'check-square'} color={'gray-dark'} size={20} />
                  To-Dos
                </Tabs.TabNav>
              </Tabs.TabList>
              <Tabs.TabContent value="general" className={'flex flex-col gap-[40px]'}>
                {renderGeneral()}
              </Tabs.TabContent>
              <Tabs.TabContent value={'todos'}>{renderToDos()}</Tabs.TabContent>
            </Tabs>
          ) : (
            <div className={'flex flex-col gap-[40px]'}>{renderGeneral()}</div>
          )}
        </div>
      </div>
      <Dialogue
        isOpen={removePersonModal.open}
        width="500px"
        height="185px"
        onRequestClose={() => {
          setRemovePersonModal({
            open: false,
          })
        }}>
        <Dialogue.Header className={'flex justify-between !items-start'}>
          <span
            className={
              'min-w-0'
            }>{`Are you sure you want to remove ${person?.fullName} as one of your connections?`}</span>
          <div
            onClick={() => {
              setRemovePersonModal({
                open: false,
              })
            }}
            className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
            <Icon name="x" size={'20'} color={'#333132'} />
          </div>
        </Dialogue.Header>
        <Dialogue.Body>This action cannot be undone.</Dialogue.Body>
        <Dialogue.Footer className={'flex justify-end space-x-2'}>
          <Button
            shape={'rounded'}
            size={'sm'}
            variant={'plain'}
            isWide={true}
            onClick={() => {
              setRemovePersonModal({
                open: false,
              })
            }}>
            Cancel
          </Button>
          <Button
            shape={'rounded'}
            size={'sm'}
            variant={'solid'}
            color={'red-400'}
            isWide={true}
            loading={deleting}
            disabled={deleting}
            onClick={async () => {
              if (connectionId) {
                await removeConnection(connectionId)
              } else {
                return toast.push('Error', 'There was a problem removing the connection', 'error')
              }
            }}>
            Remove user
          </Button>
        </Dialogue.Footer>
      </Dialogue>
      <AddUpdateTask
        open={open}
        task={selected}
        connection={person}
        onClose={() => {
          setOpen(false)
          setSelected(null)
        }}
      />
      <UpdatePersonDetailName open={editName} onClose={() => setEditName(false)} />
      <UpdatePersonDetailContactNotes open={editContactNotes} onClose={() => setEditContactNotes(false)} />
    </div>
  ) : null
}

export default PersonDetailGeneral
