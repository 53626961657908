import LeaderUtilities from './LeaderUtilities'
import ApprenticeUtilities from './ApprenticeUtilities'
import useAuth from '../../../../hooks/useAuth'
import { ViewAs } from '../../../../graphql'
import { useMemo } from 'react'

const UtilityDrawer = () => {
  const { user } = useAuth()

  const utilities = useMemo(() => {
    if (user?.viewingAs === ViewAs.Leader) {
      return <LeaderUtilities />
    } else if (user?.viewingAs === ViewAs.Apprentice) {
      return <ApprenticeUtilities />
    }
  }, [user?.viewingAs])

  return utilities
}

export default UtilityDrawer
