import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { Button } from '../ui'
import { ButtonProps } from '../ui/Button'
import { useWindowSize } from '../../hooks/useWindowSize'

type ViewHeaderButtonProps = ButtonProps

const ViewHeaderButton: FC<ViewHeaderButtonProps> = ({ children, className, ...props }) => {
  return (
    <Button className={classNames('h-[32px]', className)} size={'sm'} {...props}>
      {children}
    </Button>
  )
}

type ViewHeaderProps = {
  start?: ReactNode[]
  end?: ReactNode[]
  className?: string
}

const ViewHeader = ({ start, end, className }: ViewHeaderProps) => {
  const { size } = useWindowSize()
  const small = size === 'xs'

  return (
    <div
      className={classNames(
        `flex justify-between ${end && end.length ? 'divide-gray' : ''} ${small ? 'divide-y flex-wrap' : ''}`,
        className,
      )}>
      <div
        className={`overflow-y-scroll pl-[16px] pr-[16px] sm:pl-[20px] sm:pr-[20px] md:pl-[15px] md:pr-[15px] flex items-center gap-[15px] min-h-[55px] ${small ? 'w-full' : 'flex-1'}`}>
        {start?.map((component, index) => (
          <div key={`start-view-header-item-${index}`} className={'w-[inherit] flex-col h-full flex justify-center'}>
            {component}
          </div>
        ))}
      </div>
      {end?.length ? (
        <div
          className={`justify-end overflow-y-scroll pl-[16px] pr-[16px] sm:pl-[20px] sm:pr-[20px] md:pl-[15px] md:pr-[15px] flex items-center gap-[15px] min-h-[55px] flex-wrap-reverse ${small ? 'w-full' : 'flex-1'}`}>
          {end
            .map((component, index) => (
              <div key={`end-view-header-item-${index}`} className={'w-[inherit] flex-col h-full flex justify-center'}>
                {component}
              </div>
            ))
            .reverse()}
        </div>
      ) : null}
    </div>
  )
}

ViewHeader.Button = ViewHeaderButton

export default ViewHeader
