import React from 'react'

type LineProps = {
  percent: number
  strokeColor?: string
  strokeLinecap?: 'round' | 'square'
  strokeWidth?: number
  backgroundColor?: string
  customInfo?: string | React.ReactNode
  size?: 'sm' | 'md' | 'lg'
  showInfo?: boolean
}

const Line: React.FC<LineProps> = ({
  percent,
  strokeColor = 'blue-600',
  strokeLinecap = 'round',
  backgroundColor = 'bg-gray-200',
  customInfo,
  size,
  strokeWidth = size === 'lg' ? 5 : 3,
  showInfo = false,
}) => {
  const sizeMap = {
    sm: 'w-20',
    md: 'w-48',
    lg: 'w-64',
  }

  const containerHeight = strokeWidth + 20
  const containerBorderRadius = strokeLinecap === 'round' ? `${strokeWidth}px` : '0px'
  const fillBorderRadius = strokeLinecap === 'round' ? `${strokeWidth}px` : `0px ${strokeWidth}px ${strokeWidth}px 0px`
  const fillLeftPosition = strokeLinecap === 'round' ? `-${strokeWidth / 2}px` : '0px'
  const offset = size === 'lg' ? '15px' : '12px'
  const textVerticalPosition = `calc(50% - ${offset})`
  const rightOffset = size === 'lg' ? '10px' : '8px'
  const textHorizontalPosition = `calc(100% + ${rightOffset})`

  const isTailwindColor = (color: string) => !color.startsWith('#') && !color.match(/^[a-z]+$/i)
  const getBackgroundColor = (color: string) => (isTailwindColor(color) ? `bg-${color}` : color)

  const renderProcessInfo = () => {
    if (!showInfo) return null
    return (
      <div
        className="absolute left-[calc(100%+10px)] flex items-center text-gray-600 font-medium"
        style={{ fontSize: '14px', top: textVerticalPosition, left: textHorizontalPosition, minWidth: '200px' }}>
        {customInfo || `${percent}%`}
      </div>
    )
  }

  return (
    <div className={`relative ${size ? sizeMap[size] : 'w-full'} h-${containerHeight} overflow-visible`}>
      <div
        className={`absolute bottom-0 left-0 ${backgroundColor}`}
        style={{
          width: '100%',
          height: `${strokeWidth}px`,
          borderRadius: containerBorderRadius,
          mixBlendMode: 'multiply',
        }}></div>
      <div
        className={`absolute bottom-0 ${isTailwindColor(strokeColor) ? getBackgroundColor(strokeColor) : ''}`}
        style={{
          left: fillLeftPosition,
          width: `calc(${percent}% + ${fillLeftPosition})`,
          height: `${strokeWidth}px`,
          backgroundColor: isTailwindColor(strokeColor) ? undefined : strokeColor,
          borderRadius: fillBorderRadius,
        }}></div>
      {renderProcessInfo()}
    </div>
  )
}

export default Line
