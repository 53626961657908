import React, { ReactNode, MouseEvent, useContext, useEffect } from 'react'
import Icon from '../Icon'
import { DropdownContext } from './Dropdown'
import classNames from 'classnames'
import { useListItem } from '@floating-ui/react'

export type DropdownItemProps<Value = unknown> = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  title?: string
  icon?: ReactNode
  active?: boolean
  disabled?: boolean
  render?: ReactNode
  eventKey?: string
  onClick?: (e: MouseEvent) => void
  className?: string
  value?: Value | null
}

function DropdownItem<Value = unknown>({
  title,
  icon,
  disabled = false,
  render,
  eventKey = 'click',
  onClick,
  value,
  className,
}: DropdownItemProps<Value>) {
  const { handleSelect, selectedKey, defaultSelectedKey, activeIndex, selectedIndex, getItemProps } =
    useContext(DropdownContext)

  const { ref, index } = useListItem({ label: disabled ? null : title })

  const isActive = activeIndex === index
  const isSelected = selectedIndex === index || selectedKey === eventKey

  useEffect(() => {
    if (!selectedIndex && defaultSelectedKey === eventKey) {
      handleSelect(index, true)
    }
    // Note: We don't want to run this effect when the defaultSelectedKey changes
    // ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  return (
    <button
      ref={ref}
      role={'option'}
      data-event-key={eventKey}
      data-value={value}
      aria-selected={isActive && isSelected}
      tabIndex={isActive ? 0 : -1}
      disabled={disabled}
      className={classNames(
        `w-full border-none outline-0 font-figtree text-gray-900 flex items-center text-[16px] leading-[24px] ${
          disabled ? 'opacity-50' : ''
        } py-[7px] px-[20px]`,
        className,
        `${isActive ? 'bg-gray-100' : ''}`,
      )}
      {...getItemProps({
        onClick: (e) => {
          onClick?.(e)
          handleSelect(index)
        },
      })}>
      {render ? (
        render
      ) : (
        <div className={'flex w-full justify-between gap-2.5 items-center'}>
          <div className={'flex items-center gap-2.5'}>
            {icon ? icon : null}
            {title}
          </div>
          {isSelected ? <Icon name={'check'} /> : null}
        </div>
      )}
    </button>
  )
}

export default DropdownItem
