import React from 'react'
import appConfig from '../../../../config/app'
import { REDIRECT_URL_KEY } from '../../../../constants'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'

const { unAuthenticatedEntryPath, authenticatedEntryPath } = appConfig

const OnboardingRoute = () => {
  const { authenticated, user } = useAuth()

  const location = useLocation()

  if (!authenticated) {
    return <Navigate to={`${unAuthenticatedEntryPath}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />
  } else if (user?.tenants.length && user?.programs.length) {
    return <Navigate to={authenticatedEntryPath} replace />
  }

  return <Outlet />
}

export default OnboardingRoute
