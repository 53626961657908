import React from 'react'
import { StepType, ContentStepType } from '../../graphql'
import Icon, { IconProps } from '../ui/Icon'

const getStepIcon = (type: string, contentType?: string | null): IconName => {
  if (type === 'CONTENT') {
    return contentType === 'TEXT' ? 'book' : 'tv'
  } else if (type === 'TASK') {
    return 'check-circle'
  } else if (type === 'SERIES') {
    return 'layers'
  }
  return 'alert-circle'
}

type StepIconProps = Omit<IconProps, 'name'> & {
  type: StepType
  contentType?: ContentStepType | null
}

const StepIcon: React.FC<StepIconProps> = ({ type, contentType, ...rest }) => {
  return <Icon {...rest} name={getStepIcon(type, contentType)} />
}

export default StepIcon
