import usePrograms from './usePrograms'
import { useJourneysDropdownQuery } from '../graphql'
import { WatchQueryFetchPolicy } from '@apollo/client'

type UseJourneysDropdownParams = {
  fetchPolicy?: WatchQueryFetchPolicy
  programId?: string
}

const useJourneysDropdown = (
  { fetchPolicy = 'cache-first', programId }: UseJourneysDropdownParams = {
    fetchPolicy: 'cache-first',
  },
) => {
  const { selectedProgram } = usePrograms()

  const { data, loading } = useJourneysDropdownQuery({
    variables: {
      programId: programId || selectedProgram?.id || '',
    },
    fetchPolicy,
  })

  return { journeys: data?.journeys.list, loading }
}

export default useJourneysDropdown
