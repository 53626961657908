import { JourneyDetailDocument } from '../../graphql'

const journeyMocks = [
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: JourneyDetailDocument,
      variables: {
        where: {
          id: '1',
        },
      },
    },
    result: {
      data: {
        journey: {
          id: '1',
          name: 'Small Group Leaders',
          description:
            'This journey is a specific collection of steps, or path, that small group leaders must complete as they move through their training phase of the program.',
          __typename: 'Journey',
          program: {
            id: '1',
            name: 'Christian Discipleship',
            tenant: {
              id: '1',
              name: 'New Life Church',
              __typename: 'Tenant',
            },
            __typename: 'Program',
          },
        },
      },
    },
  },
]

export default journeyMocks
