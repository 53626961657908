import React, { JSX, MouseEventHandler, useEffect, useRef } from 'react'
import Icon from '../Icon'
import { WrapperType } from 'react-svg'

type AlertProps = {
  showIcon?: boolean
  customIcon?: () => JSX.Element | IconName
  type?: 'info' | 'warning' | 'success' | 'danger'
  closeable?: boolean
  customClose?: () => JSX.Element | IconName
  rounded?: boolean
  onClose?: MouseEventHandler<WrapperType>
  onRemoved?: (e: MouseEvent) => void
  size?: 'sm' | 'lg'
  label?: string | null
  show?: boolean
}

const Alert = ({
  showIcon = true,
  customIcon,
  type = 'warning',
  closeable = true,
  customClose,
  rounded = true,
  onClose,
  size = 'lg',
  label = '',
  show = true,
}: AlertProps) => {
  let backgroundColor = ''
  let borderColor = ''
  let textColor = ''
  let icon: IconName = 'placeholder'
  const CustomCloseComponent = customClose
    ? typeof customClose === 'string'
      ? () => <Icon name={customClose} />
      : customClose
    : null
  const CustomIconComponent = customIcon
    ? typeof customIcon === 'string'
      ? () => <Icon name={customIcon} />
      : customIcon
    : null
  const ref = useRef<any>()

  if (type) {
    switch (type) {
      case 'info':
        backgroundColor = 'bg-blue-50'
        textColor = 'text-blue'
        borderColor = 'border-blue-200'
        icon = 'info'
        break
      case 'warning':
        backgroundColor = 'bg-yellow-50'
        textColor = 'text-yellow'
        borderColor = 'border-yellow-200'
        icon = 'alert-triangle'
        break
      case 'success':
        backgroundColor = 'bg-green-50'
        textColor = 'text-green'
        borderColor = 'border-green-200'
        icon = 'check-circle'
        break
      case 'danger':
        backgroundColor = 'bg-red-50'
        textColor = 'text-red'
        borderColor = 'border-red-200'
        icon = 'x-circle'
        break
      default:
        backgroundColor = 'bg-yellow-50'
        textColor = 'text-yellow'
        borderColor = 'border-yellow-200'
        icon = 'alert-triangle'
        break
    }
  }

  useEffect(() => {
    if (show) {
      ref?.current?.classList?.remove('hidden')
      ref?.current?.classList?.remove('opacity-0')
    } else {
      ref?.current?.classList?.add('opacity-0')
      setTimeout(() => {
        ref?.current?.classList?.add('hidden')
      }, 350)
    }
  }, [show])

  const handleOnClick: MouseEventHandler<WrapperType> = (e) => {
    onClose?.(e)
    ref?.current?.classList?.add('opacity-0')
    setTimeout(() => {
      ref?.current?.classList?.add('hidden')
    }, 350)
  }

  return (
    <div
      ref={ref}
      className={`hidden ${backgroundColor} ${textColor} ${borderColor} ${
        size === 'lg' ? 'body-bold-md' : 'body-bold-sm'
      } flex justify-between items-center font-semibold p-[15px] border-[1px] transition-all duration-300 ${
        rounded ? 'rounded-[10px]' : ''
      }`}>
      <div className={'flex items-center gap-2.5'}>
        {showIcon ? (
          CustomIconComponent ? (
            <CustomIconComponent />
          ) : (
            <Icon name={icon} size={24} color={textColor} />
          )
        ) : null}
        {label}
      </div>
      <div>
        {closeable ? (
          CustomCloseComponent ? (
            <CustomCloseComponent />
          ) : (
            <Icon
              name={'x-bold'}
              color={textColor}
              size={24}
              className={`${textColor} cursor-pointer ml-[10px]`}
              onClick={handleOnClick}
            />
          )
        ) : null}
      </div>
    </div>
  )
}

export default Alert
