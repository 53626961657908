import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Filter, { Filters } from '../Filter'
import {
  setFilterValue,
  selectTable,
  TableRow,
  TableFilters as TableFiltersT,
  FilterOption,
} from '../../../store/data/tableSlice'
import { ActionMeta, OnChangeValue, PropsValue } from 'react-select'

type TableFiltersProps<F extends TableFiltersT, IsMulti extends true | false, FilterAdditional> = {
  tableName: string
  filters?: Filters<F, IsMulti, FilterAdditional>
}

function TableFilters<Row extends TableRow, F extends TableFiltersT, IsMulti extends true | false, FilterAdditional>({
  tableName,
  filters = {} as Filters<F, IsMulti, FilterAdditional>,
}: TableFiltersProps<F, IsMulti, FilterAdditional>) {
  const filterValues = useSelector(selectTable<Row, F>(tableName))?.filters

  const dispatch = useDispatch()

  const filterComponents: JSX.Element[] = []
  Object.keys(filters).forEach((key: keyof Filters<F, IsMulti, FilterAdditional>) => {
    const props = filters[key]
    if (props) {
      const value: OnChangeValue<FilterOption<F[keyof F]>, IsMulti> = filterValues?.[key] as OnChangeValue<
        FilterOption<F[keyof F]>,
        IsMulti
      >

      const onChange = (
        newValue: PropsValue<FilterOption<F[keyof F]>>,
        actionMeta: ActionMeta<FilterOption<F[keyof F]>>,
      ) => {
        dispatch(
          setFilterValue({
            key: tableName,
            filterKey: key.toString(),
            newValue: newValue as FilterOption<F[keyof F]> | FilterOption<F[keyof F]>[] | null,
          }),
        )
        if (props?.onChange) {
          props.onChange(value, actionMeta)
        }
      }

      filterComponents.push(
        <Filter {...props} value={value} key={tableName + ':' + key.toString()} onChange={onChange} />,
      )
    }
  })

  return <>{filterComponents.map((filter) => filter)}</>
}

export default TableFilters
