import React, { useState } from 'react'
import { Radio } from '../../../components/ui'

const RadioExample = () => {
  const [group1Value, setGroup1Value] = useState<string>('banana')
  const [group2Value, setGroup2Value] = useState<string>('baseball')
  const [group3Value, setGroup3Value] = useState<string>('apple')
  const [group4Value, setGroup4Value] = useState<string>('ohio')
  const [group5Value, setGroup5Value] = useState<string>('chair')

  const handleGroup1Change = (value: any, e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup1Value(value)
  }

  const handleGroup2Change = (value: any, e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup2Value(value)
  }

  const handleGroup3Change = (value: any, e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup3Value(value)
  }

  const handleGroup4Change = (value: any, e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup4Value(value)
  }

  const handleGroup5Change = (value: any, e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup5Value(value)
  }

  return (
    <div className="p-4">
      <h2>Simple Radio</h2>
      <Radio.Group value={group1Value} onChange={handleGroup1Change}>
        <Radio name={'Banana'} value="banana" color="red-400" />
        <Radio name={'Orange'} value="orange" color="red-400" />
      </Radio.Group>

      <h2>Radio Group</h2>
      <Radio.Group value={group2Value} onChange={handleGroup2Change}>
        <Radio name={'Baseball'} value="baseball" />
        <Radio name={'Football'} value="football" />
        <Radio name={'Tennis'} value="tennis" />
      </Radio.Group>

      <h2>Disabled Simple Radio</h2>
      <Radio.Group value={group3Value} onChange={handleGroup3Change}>
        <Radio name={'Apple'} value="apple" disabled />
        <Radio name={'Pear'} value="pear" disabled />
      </Radio.Group>

      <h2>Disabled Radio Group</h2>
      <Radio.Group value={group4Value} onChange={handleGroup4Change}>
        <Radio name={'Ohio'} value="ohio" disabled />
        <Radio name={'Pennsylvania'} value="pennsylvania" disabled />
        <Radio name={'New York'} value="newyork" disabled />
      </Radio.Group>

      <h2>Vertical Radio Group</h2>
      <Radio.Group vertical value={group5Value} onChange={handleGroup5Change}>
        <Radio name={'Chair'} value="chair" />
        <Radio name={'Couch'} value="couch" />
        <Radio name={'Stool'} value="stool" />
      </Radio.Group>
    </div>
  )
}

export default RadioExample
