import React, { FC, useEffect, useRef } from 'react'
import Table from '../../../components/ui/Table/Table'
import { StepProgressionStatus, StepQuestionAnswerFieldsFragment, ViewAs } from '../../../graphql'
import { Field, Formik, FieldInputProps, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Button, Dropdown, Form, Icon, Input, Text } from '../../../components/ui'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import useStepDetail from '../../../hooks/useStepDetail'
import useAuth from '../../../hooks/useAuth'
import { Link } from 'react-router-dom'
import formatRouteId from '../../../utils/formatRouteId'

type StepQuestionProps = {
  question: StepQuestionAnswerFieldsFragment
}

type QuestionInputProps = {
  field: FieldInputProps<string>
}

const QuestionInput: FC<QuestionInputProps> = ({ field, ...rest }) => {
  const questionRef = useRef<HTMLDivElement | null>(null)
  const divRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (questionRef.current && field.value === '') {
      const range = document.createRange()
      range.selectNodeContents(questionRef.current as Node)
      range.collapse(false)
      const selection = window.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(range)
    }
  }, [field.value])

  const handleChange = (event: ContentEditableEvent) => {
    //let tmp = document.createElement('div')
    //tmp.innerHTML = event.target.value
    let value = event.target.value
    if (divRef?.current) {
      divRef.current.innerHTML = value
      value = divRef.current.textContent || divRef.current.innerText || ''
    }

    const _event = {
      target: {
        value,
        name: field.name,
      },
    }
    field.onChange(_event)
  }
  return (
    <>
      <div ref={divRef} className={'hidden'} />
      <ContentEditable
        innerRef={questionRef}
        {...rest}
        {...field}
        html={field.value}
        onChange={handleChange}
        className={'whitespace-break-spaces outline-none w-full cursor-text'}
      />
    </>
  )
  /* <ContentEditable {...rest} {...field} html={field.value} onChange={handleChange}  className={'break-words outline-none bg-transparent w-full'} /> */
}

type RequiredDropdownProps = {
  field: FieldInputProps<boolean>
  form: FormikProps<{
    question: string
    required: boolean
  }>
}

const RequiredDropdown: FC<RequiredDropdownProps> = ({ field, form, ...rest }) => {
  const selectedKey = field.value ? 'required' : 'optional'
  return (
    <Dropdown
      menuClass={'!w-fit'}
      placement={'bottom-end'}
      selectedKey={selectedKey}
      title={field.value ? 'Required' : 'Optional'}
      onSelect={async ({ eventKey: option }) => {
        const required = option === 'required'
        await form.setFieldValue('required', required)
        console.log('Required:', required)
      }}
      renderToggle={
        <Button
          size={'xs'}
          type={'button'}
          icon={<Icon name={'chevron-down'} />}
          iconLocation={'right'}
          variant={'plain'}
        />
      }>
      <Dropdown.Item eventKey={'optional'} title={'Optional'} />
      <Dropdown.Item eventKey={'required'} title={'Required'} />
    </Dropdown>
  )
}

type UpdateButtonGroupProps = {
  show: boolean
  onCancel?: () => void
  creating?: boolean
}

const UpdateButtonGroup: FC<UpdateButtonGroupProps> = ({ show, onCancel }) => {
  return (
    <>
      <Button className={`${show ? 'block' : 'hidden'}`} size={'xs'} variant={'plain'} onClick={onCancel}>
        Cancel
      </Button>
      <Button type={'submit'} className={`${show ? 'block' : 'hidden'}`} size={'xs'}>
        Save
      </Button>
    </>
  )
}

const CreateButtonGroup: FC<UpdateButtonGroupProps> = ({ show, onCancel, creating }) => {
  return (
    <>
      <Button className={`${show ? 'block' : 'hidden'}`} size={'xs'} variant={'plain'} onClick={onCancel}>
        Cancel
      </Button>
      <Button loading={creating} type={'submit'} className={`${show ? 'block' : 'hidden'}`} size={'xs'}>
        Create
      </Button>
    </>
  )
}

type AnswerProps = {
  connectionId?: string
  personId: string
  name: string
  date: Date
  text: string
}

const Answer: FC<AnswerProps> = ({ name, date, text, connectionId, personId }) => {
  const { routeId } = formatRouteId(name, personId)
  return (
    <div className={'flex flex-col gap-[10px] border-gray-400 border-[1px] rounded p-[8px] '}>
      <div className={'flex gap-[7px] justify-between'}>
        <div className={'flex gap-[5px]'}>
          <Text size={'sm'}>Answer by </Text>
          <Link to={connectionId ? `/connection/${connectionId}/${routeId}` : ''}>
            <Text size={'sm'} className={'hover:underline font-bold'}>
              {name}
            </Text>
          </Link>
        </div>
        <Text size={'sm'} className={'text-gray'}>
          {date.toDateString()}
        </Text>
      </div>
      <Text>{text}</Text>
    </div>
  )
}

const StepQuestion: FC<StepQuestionProps> = ({ question }) => {
  const { step, resetQuestions, createQuestion } = useStepDetail()
  const { user } = useAuth()

  const validationSchema = Yup.object().shape({
    question: Yup.string()
      .required()
      .test('is-changed-question', '', (value, { parent }) => {
        return value !== question.text || parent.required !== question.required
      }),
    required: Yup.boolean().test('is-changed-required', '', (value, { parent }) => {
      return value !== question.required || parent.question !== question.text
    }),
  })

  const answer = step?.user.answers.find((a) => a.question.id === question.id)

  return user?.viewingAs === ViewAs.Apprentice ? (
    step?.user.status !== StepProgressionStatus.Complete ? (
      <div className={'flex flex-col gap-[5px]'}>
        <div className={'flex gap-[7px] justify-between'}>
          <Text variant={'h4'}>{question.text}</Text>
          {!question.required && <Text className={'text-gray'}>optional</Text>}
        </div>
        <Field name={question.id} textArea className={'text-md'} component={Input} />
      </div>
    ) : (
      <div className={'flex flex-col gap-[5px]'}>
        <div className={'flex gap-[7px] justify-between'}>
          <Text variant={'h4'}>{question.text}</Text>
          {!question.required && <Text className={'text-gray'}>optional</Text>}
        </div>
        <Text>{answer?.text}</Text>
      </div>
    )
  ) : !step?.draft ? (
    <div className={'flex flex-col gap-[10px]'}>
      <Text variant={'h4'}>{question.text}</Text>
      <div className={'flex flex-col gap-[7px]'}>
        {question.answers.map((answer) => (
          <Answer
            key={answer.id}
            connectionId={answer.createdBy.myConnection?.id}
            personId={answer.createdBy.id}
            name={answer.createdBy.name}
            date={new Date(answer.updatedAt)}
            text={answer.text}
          />
        ))}
      </div>
    </div>
  ) : (
    <Table.Tr className={'overflow-visible'}>
      <Table.Td className={'overflow-visible'}>
        <Formik
          validationSchema={validationSchema}
          validateOnChange
          validateOnMount
          initialValues={{
            question: question.text,
            required: question.required,
          }}
          onSubmit={async (values) => {
            console.log('Save question')
            await createQuestion({
              text: values.question,
              required: values.required,
            })
          }}>
          {({ isValid, resetForm, validateForm, isSubmitting }) => (
            <Form>
              <div className={'flex gap-[10px] items-center'}>
                <div className={'flex grow min-w-0'}>
                  <Field
                    className={'justify-center'}
                    size={'sm'}
                    name={'question'}
                    placeholder={'Enter question...'}
                    component={QuestionInput}
                  />
                </div>
                <div className={'flex self-start gap-[7.5px]'}>
                  <Field size={'sm'} name={'required'} placement={'bottom-start'} component={RequiredDropdown} />
                  <UpdateButtonGroup
                    show={isValid && !!question?.id}
                    onCancel={async () => {
                      await resetForm()
                      await validateForm()
                    }}
                  />
                  <CreateButtonGroup
                    show={!question?.id}
                    creating={isSubmitting}
                    onCancel={() => {
                      resetQuestions()
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Table.Td>
    </Table.Tr>
  )
}

export default StepQuestion
