import React, { useState } from 'react'
import JourneyPhaseTable from './JourneyPhaseTable'
import JourneyPhaseHeader from './JourneyPhaseHeader'
import { JourneyPhaseFieldsFragment } from '../../../graphql'
import { AnimatePresence, motion } from 'framer-motion'

type JourneyPhaseProps = {
  phase: JourneyPhaseFieldsFragment
}
const JourneyPhase: React.FC<JourneyPhaseProps> = ({ phase }) => {
  const [collapsed, setCollapsed] = useState(true)

  const onToggleCollapse = () => {
    setCollapsed(!collapsed)
  }

  return (
    <div className={'w-full rounded-[10px] mb-[25px] overflow-hidden'}>
      <div className={`bg-white`}>
        <JourneyPhaseHeader phase={phase} collapsed={collapsed} onToggleCollapse={onToggleCollapse} />
      </div>
      <div className={'w-full bg-white'}>
        <AnimatePresence mode={'wait'}>
          {!collapsed && (
            <motion.div
              key={'journey-phase-' + phase.id}
              className={`bg-white border-0`}
              initial={{
                height: 0,
                opacity: 0,
              }}
              animate={{
                height: 'auto',
                opacity: 1,
                transition: {
                  height: {
                    duration: 0.4,
                  },
                  opacity: {
                    duration: 0.25,
                    delay: 0.15,
                  },
                },
              }}
              exit={{
                height: 0,
                opacity: 0,
                transition: {
                  height: {
                    duration: 0.4,
                  },
                  opacity: {
                    duration: 0.25,
                  },
                },
              }}>
              <div>
                <div className={'p-[25px] pt-[10px]'}>{phase?.description}</div>
                <JourneyPhaseTable phase={phase} />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default JourneyPhase
