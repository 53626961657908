import Table from '../../../components/ui/Table/Table'
import React from 'react'
import JourneyPhaseTableRow from './JourneyPhaseTableRow'
import { JourneyPhaseFieldsFragment } from '../../../graphql'

type JourneyPhaseTableProps = {
  phase: JourneyPhaseFieldsFragment
}

const JourneyPhaseTable: React.FC<JourneyPhaseTableProps> = ({ phase }) => {
  const { steps } = phase
  return (
    <Table className={'rounded-t-[0px] w-full overflow-x-scroll'}>
      <Table.TBody>
        {steps.map((step: any) => (
          <JourneyPhaseTableRow iconColor={phase?.color} key={step.id} step={step} phase={phase} />
        ))}
      </Table.TBody>
    </Table>
  )
}

export default JourneyPhaseTable
