/** @type {import('tailwindcss').Config} */
const safeListFile = 'safelist.txt'
module.exports = {
  darkMode: ['class'],
  content: ['./src/**/*.html', './src/**/*.js', './src/**/*.jsx', './src/**/*.ts', './src/**/*.tsx', './safelist.txt'],
  theme: {
    extend: {
      boxShadow: {
        card: '6px 6px 15px rgba(0, 0, 0, 0.10)',
      },
      height: {
        lg: '47px',
        md: '43px',
        sm: '40px',
        xs: '30px',
      },
      width: {
        drawer: '420px',
        600: '600px',
        500: '500px',
        400: '400px',
      },
      borderRadius: {
        form: '10px',
      },
    },
    fontSize: {
      xs: ['12px', '16.8px'],
      sm: ['14px', '19.6px'],
      base: ['15px', '21px'],
      md: ['15px', '21px'],
      lg: ['18px', '25.2px'],
      xl: ['22px', '33px'],
      '2xl': ['24px', '32px'],
      '3xl': ['30px', '36px'],
      '4xl': ['36px', '40px'],
      '5xl': ['48px', '1'],
      '6xl': ['60px', '1'],
      '7xl': ['72px', '1'],
      '8xl': ['96px', '1'],
      '9xl': ['128px', '1'],
    },
    colors: {
      inherit: 'inherit',
      black: '#000000',
      brand: {
        50: '#EDF8FF',
        100: '#AFE0FF',
        200: '#80CDFF',
        light: '#50BAFD',
        300: '#50BAFD',
        400: '#2D93D5',
        DEFAULT: '#1270AC',
        500: '#1270AC',
        600: '#05568A',
        700: '#004068',
        dark: '#004068',
        800: '#003252',
        900: '#00253C',
      },
      gray: {
        50: '#F9F9F9',
        100: '#F3F3F3',
        200: '#EDEDED',
        light: '#D9D9D9',
        400: '#BFBFBF',
        DEFAULT: '#A6A6A6',
        600: '#8C8C8C',
        dark: '#737373',
        800: '#595959',
        900: '#333132',
      },
      silver: {
        50: '#F9FAFB',
        100: '#F3F4F6',
        200: '#E5E7EB',
        light: '#D1D5DB',
        400: '#9CA3AF',
        DEFAULT: '#6B7280',
        600: '#4B5563',
        dark: '#374151',
        800: '#1F2937',
        900: '#111827',
      },
      bronze: {
        50: '#FFECE5',
        100: '#FFDACC',
        200: '#FDB599',
        light: '#E69679',
        400: '#CC7A5D',
        DEFAULT: '#A45D43',
        600: '#8A4D37',
        dark: '#6A3B29',
        800: '#4E2C20',
        900: '#321E17',
      },
      gold: {
        50: '#ECE0D4',
        100: '#ECE0D4',
        200: '#DEC1A1',
        light: '#C8A47B',
        400: '#A48664',
        DEFAULT: '#897053',
        600: '#6F5A42',
        dark: '#564633',
        800: '#45382C',
        900: '#302A25',
      },
      yellow: {
        50: '#FEFCE8',
        100: '#FEF9C3',
        200: '#FEF08A',
        light: '#FDE047',
        400: '#FACC15',
        DEFAULT: '#EAB308',
        600: '#CA8A04',
        dark: '#A16207',
        800: '#854D0E',
        900: '#713F12',
      },
      orange: {
        50: '#FFF7ED',
        100: '#FFEDD5',
        200: '#FED7AA',
        light: '#FDBA74',
        400: '#FB923C',
        DEFAULT: '#F97316',
        600: '#EA580C',
        dark: '#C2410C',
        800: '#9A3412',
        900: '#7C2D12',
      },
      green: {
        50: '#ECFDF5',
        100: '#D1FAE5',
        200: '#A7F3D0',
        light: '#86EFAC',
        400: '#34D399',
        DEFAULT: '#10B981',
        600: '#059669',
        dark: '#047857',
        800: '#065F46',
        900: '#064E3B',
      },
      link: '#1270AC',
      quote: '#519D9F',
      breakerBay: {
        50: '#EBF9F9',
        100: '#D8F3F3',
        200: '#C0EBEB',
        light: '#97D1D3',
        400: '#6FB8BA',
        DEFAULT: '#519D9F',
        600: '#428183',
        dark: '#2D6769',
        800: '#1A4E50',
        900: '#093738',
      },
      blue: {
        50: '#ECFEFF',
        100: '#CFFAFE',
        200: '#A5F3FC',
        light: '#67E8F9',
        400: '#22D3EE',
        DEFAULT: '#06B6D4',
        600: '#0891B2',
        dark: '#0E7490',
        800: '#155E75',
        900: '#164E63',
      },
      fuchsia: {
        50: '#FDF4FF',
        100: '#FAE8FF',
        200: '#F5D0FE',
        light: '#F0ABFC',
        400: '#E879F9',
        DEFAULT: '#D946EF',
        600: '#C026D3',
        dark: '#A21CAF',
        800: '#86198F',
        900: '#701A75',
      },
      red: {
        50: '#FEF2F2',
        100: '#FEE2E2',
        200: '#FECACA',
        light: '#FCA5A5',
        400: '#F87171',
        DEFAULT: '#EF4444',
        600: '#DC2626',
        dark: '#B91C1C',
        800: '#991B1B',
        900: '#7F1D1D',
      },
      establishing: {
        DEFAULT: '#0891B2',
        dark: '#0E7490',
        light: '#CFFAFE',
      },
      equipping: {
        DEFAULT: '#EA580C',
        dark: '#C2410C',
        light: '#FFEDD5',
      },
      training: {
        DEFAULT: '#C026D3',
        dark: '#A21CAF',
        light: '#FAE8FF',
      },
      white: {
        DEFAULT: '#FFFFFF',
      },
      transparent: 'transparent',
    },
    fontFamily: {
      dmseriftext: ['DM Serif Text', 'serif'],
      figtree: ['Figtree', 'sans-serif'],
      mono: [
        'ui-monospace',
        'SFMono-Regular',
        'Menlo',
        'Monaco',
        'Consolas',
        '"Liberation Mono"',
        '"Courier New"',
        'monospace',
      ],
    },
  },
  plugins: [
    require('tailwind-safelist-generator')({
      path: safeListFile,
      patterns: [
        'text-{colors}',
        'bg-{colors}',
        'hover:text-{colors}',
        'hover:bg-{colors}',
        'hover:border-{colors}',
        'enabled:hover:bg-{colors}',
        'enabled:active:bg-{colors}',
        'enabled:hover:border-{colors}',
        'enabled:active:border-{colors}',
        'active:bg-{colors}',
        'active:border-{colors}',
        'ring-{colors}',
        'hover:ring-{colors}',
        'focus:ring-{colors}',
        'focus-within:ring-{colors}',
        'border-{colors}',
        'focus:border-{colors}',
        'focus-within:border-{colors}',
        'h-{height}',
        'w-{width}',
        'dark:bg-white/20',
      ],
    }),
  ],
}
