import React, { ReactNode, MouseEvent, FC, ReactElement } from 'react'
import { Divider } from '../index'
import classNames from 'classnames'

interface CardProps {
  media?: string | ReactNode
  header?: string | ReactNode
  footer?: string | ReactNode
  border?: boolean
  containerClass?: string
  bodyClass?: string
  headerClass?: string
  footerClass?: string
  headerIcon?: boolean | ReactElement
  headerDivider?: boolean
  headerColor?: string
  onClick?: (e: MouseEvent) => void
  children?: ReactNode
}

const Card: FC<CardProps> = ({
  media,
  header,
  footer,
  containerClass,
  bodyClass,
  headerClass,
  footerClass,
  headerIcon = false,
  headerDivider = true,
  headerColor = '#8C8C8C',
  onClick,
  children,
  border = false,
}) => {
  return (
    <div
      className={classNames(
        'bg-white flex flex-col rounded-lg overflow-hidden hover:shadow-card',
        border ? 'border' : '',
        onClick ? 'cursor-pointer' : '',
        containerClass,
      )}
      onClick={onClick}
      style={{ transition: 'box-shadow 150ms ease-in-out' }}>
      {media &&
        (typeof media === 'string' ? <img src={media} alt="media content" className="w-full object-cover" /> : media)}

      {(header || headerIcon || headerDivider) && (
        <div
          className={`px-5 pt-5 flex items-center justify-between gap-3 flex-wrap ${headerClass}`}
          style={{ color: headerColor }}>
          <div className={`flex items-center ${headerIcon ? 'gap-3' : 'gap-1'}`}>
            {headerIcon &&
              (typeof headerIcon === 'boolean' && headerIcon ? (
                <span></span>
              ) : (
                React.cloneElement(headerIcon, { color: headerColor })
              ))}
            <h6 className="m-0 align-self-center" style={{ color: headerColor }}>
              {header}
            </h6>
          </div>
          {headerDivider && <Divider />}
        </div>
      )}
      <div className={`${bodyClass} px-5 ${footer ? '' : 'pb-5'} ${header ? '' : 'pt-5'}`}>{children}</div>
      {footer && <div className={`px-5 py-5 w-full flex items-center justify-between ${footerClass}`}>{footer}</div>}
    </div>
  )
}

export default Card
