import Breadcrumb, { BreadcrumbProps } from './Breadcrumb'
import BreadcrumbItem from './BreadcrumbItem'

Breadcrumb.Item = BreadcrumbItem

export { type BreadcrumbProps }

export default Breadcrumb as typeof Breadcrumb & {
  Item: typeof BreadcrumbItem
}
