import { ProgramDropdownDocument, ProgramDetailDocument, ProgramMemberRole, ProcessType } from '../../graphql'
import appConfig from '../../config/app'

const programMocks = [
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: ProgramDropdownDocument,
    },
    result: {
      data: {
        programs: {
          list: [
            {
              id: '1',
              name: 'Christian Discipleship',
              process: {
                id: '1',
                name: 'Discipleship',
                __typename: 'Process',
              },
              image: {
                id: 'program-image-1',
                url: `${appConfig.issuePath}/img/samples/NewLifeChristianDiscipleshipIcon.png`,
                __typename: 'Media',
              },
              tenant: {
                id: '1',
                name: 'New Life Church',
                __typename: 'Tenant',
                member: {
                  id: '1',
                  role: 'ADMIN',
                  __typename: 'TenantMember',
                },
              },
              member: {
                id: '1',
                lastViewedAs: ProgramMemberRole.Apprentice,
                lastViewedAsDisplay: 'Apprentice',
                apprenticeDisplay: 'Apprentice',
                leaderDisplay: 'Leader',
                roles: [ProgramMemberRole.Apprentice, ProgramMemberRole.Leader],
                __typename: 'ProgramMember',
              },
              __typename: 'Program',
            },
            {
              id: '2',
              name: 'Christian Discipleship',
              process: {
                id: '1',
                name: 'Discipleship',
                __typename: 'Process',
              },
              image: {
                id: 'program-image-2',
                url: `${appConfig.issuePath}/img/samples/ProvidenceChristianDiscipleshipIcon.jpeg`,
                __typename: 'Media',
              },
              tenant: {
                id: '2',
                name: 'Providence Church',
                __typename: 'Tenant',
                member: {
                  id: '1',
                  role: 'ADMIN',
                  __typename: 'TenantMember',
                },
              },
              member: {
                id: '1',
                lastViewedAs: ProgramMemberRole.Apprentice,
                lastViewedAsDisplay: 'Apprentice',
                apprenticeDisplay: 'Apprentice',
                leaderDisplay: 'Leader',
                roles: [ProgramMemberRole.Apprentice, ProgramMemberRole.Leader],
                __typename: 'ProgramMember',
              },
              __typename: 'Program',
            },
            {
              id: '3',
              name: 'Celebrate Freedom',
              process: {
                id: '2',
                name: 'Freedom Recovery',
                __typename: 'Process',
              },
              image: {
                id: 'program-image-3',
                url: `${appConfig.issuePath}/img/samples/ProvidenceFreedomIcon.jpeg`,
                __typename: 'Media',
              },
              tenant: {
                id: '2',
                name: 'Providence Church',
                __typename: 'Tenant',
                member: {
                  id: '1',
                  role: 'ADMIN',
                  __typename: 'TenantMember',
                },
              },
              member: {
                id: '1',
                lastViewedAs: ProgramMemberRole.Apprentice,
                lastViewedAsDisplay: 'Apprentice',
                apprenticeDisplay: 'Apprentice',
                leaderDisplay: 'Leader',
                roles: [ProgramMemberRole.Apprentice, ProgramMemberRole.Leader],
                __typename: 'ProgramMember',
              },
              __typename: 'Program',
            },
          ],
        },
      },
    },
  },
  {
    maxUsageCount: Number.POSITIVE_INFINITY,
    request: {
      query: ProgramDetailDocument,
      variables: {
        where: {
          id: '1',
        },
      },
    },
    result: {
      data: {
        program: {
          id: '1',
          name: 'Christian Discipleship',
          image: {
            url: `${appConfig.issuePath}/img/samples/NewLifeChristianDiscipleshipIcon.png`,
            __typename: 'Media',
          },
          process: {
            name: 'Discipleship',
            template: {
              type: ProcessType.Dtms,
              __typename: 'ProcessTemplate',
            },
            __typename: 'Process',
          },
          member: {
            id: '1',
            email: 'test@test.com',
            roles: [ProgramMemberRole.Apprentice, ProgramMemberRole.Leader],
            __typename: 'ProgramMember',
          },
          journeys: [
            {
              name: 'Small Group Leaders',
              __typename: 'Journey',
            },
            {
              name: 'Elder Training',
              __typename: 'Journey',
            },
          ],
          members: [
            {
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: '5',
                firstName: 'Jim',
                lastName: 'Smith',
                __typename: 'Person',
              },
              roles: [ProgramMemberRole.Leader],
              __typename: 'ProgramMember',
            },
            {
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: '5',
                firstName: 'Jim',
                lastName: 'Smith',
                __typename: 'Person',
              },
              roles: [ProgramMemberRole.Leader],
              __typename: 'ProgramMember',
            },
            {
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: '5',
                firstName: 'Jim',
                lastName: 'Smith',
                __typename: 'Person',
              },
              roles: [ProgramMemberRole.Leader, ProgramMemberRole.Apprentice],
              __typename: 'ProgramMember',
            },
            {
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: '5',
                firstName: 'Jim',
                lastName: 'Smith',
                __typename: 'Person',
              },
              roles: [ProgramMemberRole.Apprentice],
              __typename: 'ProgramMember',
            },
            {
              email: 'jim.smith@gmail.com',
              profileImage: {
                id: '2',
                url: 'https://randomuser.me/api/portraits/men/1.jpg',
                __typename: 'Media',
              },
              person: {
                id: '5',
                firstName: 'Jim',
                lastName: 'Smith',
                __typename: 'Person',
              },
              roles: [ProgramMemberRole.Apprentice],
              __typename: 'ProgramMember',
            },
          ],
          __typename: 'Program',
        },
      },
    },
  },
]

export default programMocks
