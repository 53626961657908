import React, { FC } from 'react'

type DialogueAvatarProps = {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  avatar: React.ReactNode
}

const DialogueAvatar: FC<DialogueAvatarProps> = ({ children, avatar, className = '', style }) => (
  <div className={'flex'}>
    <div className={`flex-none mr-[20px] ${className}`} style={style}>
      {avatar}
    </div>
    <div className={'min-w-0 flex w-1 flex-1 flex-col'}>{children}</div>
  </div>
)

export default DialogueAvatar
